import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

const selectDomain = (state) => state?.analyticsDashboard || initialState;

export const state = createSelector([selectDomain], (state) => state);

export const selectAnalyticsData = createSelector(
  [selectDomain],
  (state) => state.analyticsData
);

export const selectAnalyticsLoading = createSelector(
  [selectDomain],
  (state) => state.analyticsLoading
);

export const selectBarChartData = createSelector(
  [selectDomain],
  (state) => state.barChartData
);

export const selectBarChartLoading = createSelector(
  [selectDomain],
  (state) => state.barChartLoading
);

export const selectDoughnutChartData = createSelector(
  [selectDomain],
  (state) => state.doughnutChartData
);

export const selectDoughnutChartLoading = createSelector(
  [selectDomain],
  (state) => state.doughnutChartLoading
);

export const selectQuantityInsightsData = createSelector(
  [selectDomain],
  (state) => state.quantityInsightsData
);

export const selectQuantityInsightsLoading = createSelector(
  [selectDomain],
  (state) => state.quantityInsightsLoading
);

export const selectWarehouseInsightsData = createSelector(
  [selectDomain],
  (state) => state.warehouseInsightsData
);

export const selectWarehouseInsightsLoading = createSelector(
  [selectDomain],
  (state) => state.warehouseInsightsLoading
);

export const selectPortfolioLineChartData = createSelector(
  [selectDomain],
  (state) => state.portfolioLineChartData
);

export const selectPortfolioLineChartLoading = createSelector(
  [selectDomain],
  (state) => state.portfolioLineChartLoading
);

export const selectInspectionsLineChartData = createSelector(
  [selectDomain],
  (state) => state.inspectionsLineChartData
);

export const selectInspectionsLineChartLoading = createSelector(
  [selectDomain],
  (state) => state.inspectionsLineChartLoading
);

export const selectQuantityLineChartData = createSelector(
  [selectDomain],
  (state) => state.quantityLineChartData
);

export const selectQuantityLineChartLoading = createSelector(
  [selectDomain],
  (state) => state.quantityLineChartLoading
);

export const selectMapChartData = createSelector(
  [selectDomain],
  (state) => state.mapChartData
);

export const selectMapChartLoading = createSelector(
  [selectDomain],
  (state) => state.mapChartLoading
);

export const selectCommoditiesData = createSelector(
  [selectDomain],
  (state) => state.commoditiesData
);

export const selectCommoditiesLoading = createSelector(
  [selectDomain],
  (state) => state.commoditiesLoading
);

export const selectWarehouseData = createSelector(
  [selectDomain],
  (state) => state.warehousesData
);

export const error = createSelector([selectDomain], (state) => state.error);

export const selectWarehouseScore = createSelector(
  [selectDomain],
  (state) => state.warehouseScore
);

export const selectClientsOfCustomer = createSelector(
  [selectDomain],
  (state) => state.clientsOfCustomer
);