import { extendTheme } from "@chakra-ui/react";
import { globalStyles } from "./styles";
import { breakpoints } from "./foundations/breakpoints";
import { buttonStyles } from "./components/button";
import { badgeStyles } from "./components/badge";
import { linkStyles } from "./components/link";
import { drawerStyles } from "./components/drawer";
import { CardComponent } from "./additions/card/Card";
import { CardBodyComponent } from "./additions/card/CardBody";
import { CardHeaderComponent } from "./additions/card/CardHeader";
import { MainPanelComponent } from "./additions/layout/MainPanel";
import { PanelContentComponent } from "./additions/layout/PanelContent";
import { PanelContainerComponent } from "./additions/layout/PanelContainer";
import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const {
  definePartsStyle,
  defineMultiStyleConfig,
} = createMultiStyleConfigHelpers(selectAnatomy.keys);

const brandPrimary = definePartsStyle({
  // define the part you're going to style
  field: {
    background: "#FFF",
  },
  icon: {
    color: "#718096",
  },
  focusBorder: {
    color: "#E2E8F0",
  },
  border: {
    color: "#E2E8F0",
  },
});

const selectTheme = defineMultiStyleConfig({ variants: { brandPrimary } });

// import { mode } from "@chakra-ui/theme-tools";
export default extendTheme(
  { breakpoints }, // Breakpoints
  globalStyles,
  buttonStyles, // Button styles
  badgeStyles, // Badge styles
  linkStyles, // Link styles
  drawerStyles, // Sidebar variant for Chakra's drawer
  CardComponent, // Card component
  CardBodyComponent, // Card Body component
  CardHeaderComponent, // Card Header component
  MainPanelComponent, // Main Panel component
  PanelContentComponent, // Panel Content component
  PanelContainerComponent, // Panel Container component
  {
    colors: {
      login: {
        50: "#38B2AC",
        500: "#38B2AC",
        900: "#38B2AC",
        label: "#2D3748",
        darkText: "#171923",
        gray: "#5D6470",
        onHover: "#c5c5c5",
        onFocus: "#1E88E5",
        border: "#E2E8F0",
      },
      modalsDark: {
        50: "#38B2AC",
        500: "#38B2AC",
        900: "#38B2AC",
        label: "#2D3748",
        darkText: "#171923",
        gray: "#5D6470",
        onHover: "#c5c5c5",
        onFocus: "#1E88E5",
        border: "#E2E8F0",
      },
      modalsLight: {
        50: "#EDF2F7",
        500: "#EDF2F7",
        900: "#EDF2F7",
        label: "#2D3748",
        darkText: "#171923",
        gray: "#5D6470",
        onHover: "#c5c5c5",
        onFocus: "#1E88E5",
        border: "#E2E8F0",
      },
      main: {
        icon: "#718096",
        iconDisabled: "rgba(93, 100, 112, 0.3)",
      },
      table: {
        header: "#718096",
        data: "#4A5568",
        background: "#fff",
      },
      warning: {
        50: "#4FD1C5",
        500: "#4FD1C5",
        900: "#4FD1C5",
        label: "#2D3748",
        darkText: "#171923",
        gray: "#5D6470",
        onHover: "#c5c5c5",
        onFocus: "#1E88E5",
        border: "#E2E8F0",
      },
    },
    components: {
      Select: selectTheme,
      Radio: {
        parts: ["label"],
        baseStyle: {
          label: {
            wordWrap: "unset",
            color: "#2D3748",
          },
        },
      },
    },
    Button: {
      variants: {
        outline: {
          color: "#4FD1C5",
          background: "#fff",
          outline: "#4FD1C5",
        },
      },
    },
  }
);
