import {
  Avatar,
  Box,
  Flex,
  HStack,
  Image,
  Stack,
  Text,
  VStack,
  IconButton,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import DownArrow from "assets/svg/Arrow (1).svg";
import SideArrow from "assets/svg/SideArrow.svg";
import FiUser from "assets/svg/FiUser.svg";
import MdDate from "assets/svg/MdDateRange.svg";
import MdConfirmation from "assets/svg/MdOutlineConfirmationNumber.svg";
import {
  ChevronDownIcon as CollapseIcon,
  // ChevronRightIcon as ExpandIcon,
  // AddIcon,
} from "@chakra-ui/icons";

import GoLocation from "assets/svg/GoLocation.svg";
import House from "assets/svg/BsHouseDoor.svg";
import ConfirmationNumber from "assets/svg/BsHouseDoor.svg";
import { useDispatch, useSelector } from "react-redux";
import { actions, reducer, sliceKey } from "../../slice";
import { watchJobsAndManagement } from "../../saga";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import * as selectors from "../../selectors";
import { fetchSasurl } from "services";
import Status from "./Table Components/Status";
import AuditorTackedInfo from "../../../../components/auditorLocationTracking/AuditorTackedInfo";
import _ from "lodash";

const leftTextStyle = {
  color: "var(--Gray-Gray-500, #718096)",
  fontSize: "16px",
  fontWeight: "400",
};
const rightTextStyle = {
  color: "var(--Gray-Gray-500, #718096)",
  fontSize: "16px",
  fontWeight: "600",
};

const ViewForm = ({
  taskId,
  originId,
  jobId,
  inspectionId,
  formNameWithStatus,
}) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: watchJobsAndManagement });
  const [openSections, setOpenSections] = useState([]);
  const [isTopOpen, setIsTopOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [taskData, setTaskData] = useState(null);
  const [displayData, setDisplayData] = useState([]);

  const sectionsForBlocks = useSelector(selectors.selectSectionsForBlocks);
  const blocksForComposites = useSelector(selectors.selectBlocksForComposites);
  const compositesForComponents = useSelector(
    selectors.selectCompositesForComponents
  );
  const componentsForElements = useSelector(
    selectors.selectComponentsForElements
  );
  const reviewData = useSelector(selectors.selectReviewData);
  const warehouseDetails = useSelector(selectors.selectWarehouseDetail);
  const tasksData = useSelector(selectors.selectTasksData);
  const userDetails = useSelector(selectors.selectUserDetails);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [user, setUser] = useState(null);
  const [clientDetails, setClientDetails] = useState({
    name: "",
    avatar: "",
  });
  const [disabledSectionCheck, setDisabledSectionCheck] = useState([]);
  const [dataArr, setDataArr] = useState([]);
  const jobDetails = useSelector(selectors.selectJob);
  const blobNames = useSelector(selectors.selectImageUrls);
  const [previewData, setPreviewData] = useState(null);
  const trackLocation = useSelector(selectors.selectTrackLocation);
  const locationLoading = useSelector(selectors.selectLocationLoading);

  const fetchAvatarUrl = async (fileName, type) => {
    try {
      const response = await fetchSasurl({ fileName });
      const sasURL = response && response.data.payload.url;

      type === "user"
        ? setAvatarUrl(sasURL)
        : setClientDetails((prev) => {
            return { ...prev, avatar: sasURL };
          });
    } catch (error) {
      console.error("Error fetching blob name:", error);
    }
  };

  useEffect(() => {
    dispatch(actions.fetchJob({ inspectionId, jobId }));
    return () => {
      dispatch(actions.clearStates());
    };
  }, []);

  useEffect(() => {
    if (Object.keys(jobDetails).length) {
      const { name, avatar } = jobDetails?.clientDetails;
      if (name)
        setClientDetails((prev) => {
          return { ...prev, name };
        });
      if (avatar) {
        fetchAvatarUrl(avatar, "client");
      }
    }
  }, [jobDetails]);

  useEffect(() => {
    if (userDetails && userDetails?.length > 0) {
      let avatar = userDetails[0].data.payload.avatar;
      if (avatar) {
        fetchAvatarUrl(avatar, "user");
      }
      setUser(userDetails[0].data.payload);
    }
  }, [userDetails]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (taskId && tasksData) {
      const data = tasksData.find((task) => task.id === taskId);
      if (data) {
        dispatch(actions.fetchUserDetailsRequest([data.assignedTo.id]));
      }
      setTaskData(data);
    }
  }, [tasksData?.length, taskId]);

  useEffect(() => {
    if (reviewData && reviewData.length > 0) {
      let tempFormData = reviewData?.reduce((acc, cv) => {
        acc[cv.templateItemId] = { ...cv };
        return acc;
      }, {});

      // to get all image url of form
      let imageFileNames = [];
      reviewData.forEach((itemData) => {
        if (
          (itemData.itemType === "fileUpload" ||
            itemData.itemType === "multiFileUpload") &&
          Array.isArray(itemData?.attachments)
        ) {
          itemData.attachments.forEach((item) => {
            if (item) {
              try {
                const files = JSON.parse(item);
                imageFileNames.push(...files);
              } catch {
                imageFileNames.push(item);
              }
            }
          });
        }
      });
      if (imageFileNames?.length > 0) {
        dispatch(actions.fetchImageUrls({ fileNames: imageFileNames }));
      }

      setFormData(tempFormData);
    }
  }, [reviewData]);

  useEffect(() => {
    if (formData && blocksForComposites && blocksForComposites.length) {
      JSON.parse(JSON.stringify(blocksForComposites))
        .sort((a, b) => a.order - b.order)
        .forEach((block) => {
          const elements = getElements(block.id);

          if (elements && elements.length) {
            elements.forEach((element) => {
              const elementId = element.element.id;
              const elementData = formData[elementId];
              let value = null;
              if (elementData) {
                value = elementData;
              }
              // if (
              //   value &&
              //   value.attachments &&
              //   (value.itemType === "multiFileUpload" ||
              //     value.itemType === "singleFileUpload" ||
              //     value?.itemType === "fileUpload")
              // ) {
              //   value.attachments.forEach((image) => {
              //     fetchBlobName(image, image);
              //   });
              // }
            });
          }
        });
    }
  }, [formData, sectionsForBlocks]);

  function hasPageNumber(arr, pageNumber) {
    let res = -1;
    arr.forEach((el, i) => {
      if (el.pageNumber === pageNumber) {
        res = i;
      }
    });
    return res;
  }

  const getDisplayData = useCallback(() => {
    let displayData = [];
    if (sectionsForBlocks) {
      sectionsForBlocks.forEach((section, index) => {
        displayData.push({
          pageNumber: section.order,
          sections: section?.structure?.blocks || [],
          pageId: section.id,
        });
      });
    }
    displayData.sort((a, b) => a.pageNumber - b.pageNumber);
    return displayData;
  }, [sectionsForBlocks]);

  useEffect(() => {
    setDisplayData(getDisplayData());
  }, [getDisplayData]);

  useEffect(() => {
    if (blocksForComposites && blocksForComposites.length) {
      let data = blocksForComposites.map((block) => getElements(block.id));
      setDataArr(data);
    }
  }, [sectionsForBlocks]);

  const getComponents = useCallback(
    (blockId) => {
      let elementDisplayData = [];
      if (
        blocksForComposites &&
        compositesForComponents &&
        componentsForElements
      ) {
        blocksForComposites.forEach((blockForComposites) => {
          blockForComposites.structure.composites.forEach((composite) => {
            compositesForComponents.forEach((compositeForComponents) => {
              if (composite.id === compositeForComponents.id) {
                compositeForComponents.structure.components.forEach(
                  (component) => {
                    componentsForElements.forEach((componentForElements) => {
                      if (componentForElements.id === component.id) {
                        elementDisplayData.push({
                          blockId: blockForComposites.id,
                          component: componentForElements,
                        });
                      }
                    });
                  }
                );
              }
            });
          });
        });
      }
      const elements = [];
      elementDisplayData.forEach((element) => {
        if (element.blockId === blockId) {
          elements.push(element);
        }
      });
      return elements;
    },
    [blocksForComposites, compositesForComponents, componentsForElements]
  );

  const getElements = useCallback(
    (blockId) => {
      let elementDisplayData = [];
      if (
        blocksForComposites &&
        compositesForComponents &&
        componentsForElements
      ) {
        blocksForComposites.forEach((blockForComposites) => {
          blockForComposites.structure.composites.forEach((composite) => {
            compositesForComponents.forEach((compositeForComponents) => {
              if (composite.id === compositeForComponents.id) {
                compositeForComponents.structure.components.forEach(
                  (component) => {
                    componentsForElements.forEach((componentForElements) => {
                      if (componentForElements.id === component.id) {
                        componentForElements.structure.elements.forEach(
                          (element) => {
                            elementDisplayData.push({
                              blockId: blockForComposites.id,
                              element,
                            });
                          }
                        );
                      }
                    });
                  }
                );
              }
            });
          });
        });
      }
      const elements = [];
      elementDisplayData.forEach((element) => {
        if (element.blockId === blockId) {
          elements.push(element);
        }
      });
      return elements;
    },
    [blocksForComposites, compositesForComponents, componentsForElements]
  );

  useEffect(() => {
    if (dataArr && dataArr.length && formData) {
      let sectionMap = displayData?.reduce((acc, curr) => {
        acc[curr.pageNumber] = curr.pageId;
        return acc;
      }, {});

      let sectionArr = displayData?.map((pageData) => ({
        ...pageData.sections[0],
        id: pageData.pageId,
        pageNumber: pageData.pageNumber,
      }));
      dataArr.map((el) => {
        el.map((currData) => {
          let currPage;
          let navPage;
          let blockId = currData.blockId;
          let navSectionId;
          let currentSectionId = displayData.find((page) => {
            let hasSection = false;
            page.sections.forEach((section) => {
              if (section.id === blockId) {
                hasSection = true;
              }
            });
            return hasSection;
          })?.pageId;

          const value = formData[currData.element.id]?.value || "";

          if (
            currData.element.htmlType === "radio" &&
            currData.element.properties.navigations
          ) {
            currData.element.properties.navigations.forEach((navOption) => {
              if (value === navOption.value) {
                navSectionId = navOption.sectionId;
              }
            });

            sectionArr.forEach((section) => {
              if (navSectionId !== undefined) {
                if (section.id === currentSectionId) {
                  currPage = section.pageNumber;
                }
                if (section.id === navSectionId) {
                  navPage = section.pageNumber;
                }
              }
            });
            if (currPage && navPage) {
              let arr = [];
              for (let i = currPage + 1; i < navPage; i++) {
                arr.push(sectionMap[i]);
              }
              setDisabledSectionCheck((prevState) => {
                return [...new Set([...prevState, ...arr])];
              });
            }
          }
        });
      });
    }
  }, [dataArr, formData]);

  const handleTopCard = () => {
    setIsTopOpen(!isTopOpen);
  };

  const handleToggle = (index) => {
    setOpenSections((prevOpenSections) => {
      if (prevOpenSections.includes(index)) {
        return prevOpenSections.filter((i) => i !== index);
      } else {
        return [...prevOpenSections, index];
      }
    });
  };

  const formatDate = useCallback(
    (dateString) => {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return "N.A.";
      }
      const options = { day: "numeric", month: "short", year: "numeric" };
      return date.toLocaleDateString("en-GB", options);
    },
    [taskId]
  );

  // get tract location of auditor
  useEffect(() => {
    dispatch(actions.fetchTrackLocation({ jobId, taskId, inspectionId }));
  }, [taskId, inspectionId, jobId]);
  return (
    <Flex
      padding="8px"
      gap="24px"
      w="100%"
      borderRadius="8px"
      direction="column"
    >
      <TopCard
        handleTopCard={handleTopCard}
        isTopOpen={isTopOpen}
        user={user}
        avatarUrl={avatarUrl}
        warehouseDetails={warehouseDetails}
        formatDate={formatDate}
        taskData={taskData}
        originId={originId}
        clientDetails={clientDetails}
        formNameWithStatus={formNameWithStatus}
        trackLocation={trackLocation}
        locationLoading={locationLoading}
      />
      {!formNameWithStatus.status || formNameWithStatus.status === "todo" ? (
        sectionsForBlocks?.length ? (
          JSON.parse(JSON.stringify(sectionsForBlocks))
            .sort((a, b) => a.order - b.order)
            .map((section, index) => {
              return (
                <ExpandableSection
                  key={section.id}
                  sectionTitle={section.title}
                  page={index + 1}
                  isOpen={openSections.includes(index)}
                  onToggle={() => handleToggle(index)}
                  totalPages={sectionsForBlocks.length}
                  getElements={getElements}
                  section={section}
                  getComponents={getComponents}
                />
              );
            })
        ) : (
          <></>
        )
      ) : sectionsForBlocks && sectionsForBlocks.length ? (
        JSON.parse(JSON.stringify(sectionsForBlocks))
          .sort((a, b) => a.order - b.order)
          .map((section, index) => {
            if (disabledSectionCheck.includes(section.id)) return <></>;
            return (
              <ExpandableSection
                key={index}
                sectionTitle={section.title}
                page={index + 1}
                isOpen={openSections.includes(index)}
                onToggle={() => handleToggle(index)}
                totalPages={sectionsForBlocks.length}
                getElements={getElements}
                section={section}
                data={formData}
                blobNames={blobNames}
                getComponents={getComponents}
              />
            );
          })
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default ViewForm;

const ExpandableSection = ({
  sectionTitle,
  page,
  isOpen,
  onToggle,
  totalPages,
  section,
  getElements,
  data,
  blobNames,
  getComponents,
}) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "N.A.";
    }
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };
  return (
    <VStack
      w="100%"
      gap="48px"
      border="2px solid var(--Grey-Gray-100, #EDF2F7)"
      background="var(--White, #FFF)"
      pb={isOpen ? "48px" : "0px"}
      borderRadius="20px"
    >
      <Flex
        background="var(--Grey-Gray-600, #4A5568)"
        pt="16px"
        pb="16px"
        w="100%"
        borderTopRadius="8px"
        justifyContent="center"
        borderBottomRadius={isOpen ? "0px" : "8px"}
      >
        <Flex w="70%" justifyContent="space-between" alignItems="center">
          <Flex gap="16px">
            <Image
              src={SideArrow}
              cursor={"pointer"}
              onClick={onToggle}
              transform={isOpen ? "rotate(90deg)" : "rotate(0deg)"}
              transition="transform 0.3s ease"
            ></Image>
            <Text
              fontSize="20px"
              fontWeight="500"
              color="var(--Grey-Gray-100, #EDF2F7)"
            >
              Page No. {page}
            </Text>
          </Flex>
          <Text
            fontSize="16px"
            fontWeight="400"
            color="var(--Grey-Gray-100, #EDF2F7)"
          >{`Page ${page} of ${totalPages}`}</Text>
        </Flex>
      </Flex>
      {section?.structure?.blocks?.map((block) => {
        const elements = getElements(block.id);
        // const components = getComponents(block.id);
        let components = getComponents(block.id);
        let totalQuestion = elements?.length;
        let totalAns = 0;
        elements?.forEach((element) => {
          const elementId = element.element.id;
          const elementData = data && data[elementId];
          let value = null;
          if (elementData) {
            value = elementData;
          }
          if (value !== null) {
            if (value.attachments.length > 0 || value.value !== "") {
              totalAns += 1;
            }
          }
        });
        let percentage = Math.round((totalAns / totalQuestion) * 100);
        return (
          <Box display={isOpen ? "block" : "none"} w="100%">
            <Flex w="100%" justifyContent="center">
              <VStack
                w="70%"
                gap="24px"
                border="2px solid var(--Grey-Gray-100, #EDF2F7)"
                borderRadius="8px"
              >
                <Flex
                  w="100%"
                  background="var(--Grey-Gray-100, #EDF2F7)"
                  p="16px"
                  h="92px"
                  borderTopRadius="8px"
                >
                  <HStack
                    align="center"
                    justifyContent="space-between"
                    w="100%"
                  >
                    <Flex gap="16px" alignItems="center">
                      <Image src={DownArrow}></Image>
                      <Text
                        size="22px"
                        fontWeight="600"
                        color="var(--Grey-Gray-600, #4A5568)"
                      >
                        {block.title}
                      </Text>
                    </Flex>
                    <Text
                      color="#4A5568"
                      fontSize="16px"
                      fontWeight="400"
                    >{`${totalAns} / ${totalQuestion} (${percentage}%)`}</Text>
                  </HStack>
                </Flex>
                <Flex direction="column" w="82%" gap="16px" pb="16px">
                  {/* {elements && elements.length ? (
                    elements.map((element, index) => {
                      const elementId = element.element.id;

                      const elementData = data && data[elementId];

                      let value = null;
                      if (elementData) {
                        value = elementData;
                      }

                      return (
                        <Flex
                          gap="8px"
                          pb="16px"
                          borderBottom="1px solid #E2E8F0"
                        >
                          <Flex
                            border="1px solid #545454"
                            borderRadius="50%"
                            h="16px"
                            w="16px"
                            justifyContent="center"
                            alignItems="center"
                            padding="10px"
                          >
                            <Text fontSize="8px" color="#545454">
                              {index + 1}
                            </Text>
                          </Flex>
                          <VStack gap="12px" alignItems="left" w="100%">
                            <Text
                              fontSize="16px"
                              fontWeight="500"
                              color="#2D3748"
                            >
                              {element.element.label}
                            </Text>
                            {value?.value && (
                              <Text
                                fontSize="16px"
                                fontWeight="400"
                                color="#4A5568"
                              >
                                {value.itemType === "datepicker"
                                  ? formatDate(value.value)
                                  : value.value}
                              </Text>
                            )}
                            {value?.itemType === "multiFileUpload" ||
                            value?.itemType === "singleFileUpload" ||
                            value?.itemType === "fileUpload" ? (
                              <Flex flexWrap="wrap" gap="10px" w="100%">
                                <ImageContainer
                                  value={value}
                                  blobNames={blobNames}
                                />
                              </Flex>
                            ) : (
                              <Text
                                fontSize="16px"
                                fontWeight="400"
                                color="#4A5568"
                              >
                                {value?.attachments.join(", ")}
                              </Text>
                            )}
                          </VStack>
                        </Flex>
                      );
                    })
                  ) : (
                    <></>
                  )} */}
                  {components.map((compEl) => (
                    <Component
                      compEl={compEl}
                      data={data}
                      blobNames={blobNames}
                      formatDate={formatDate}
                    />
                  ))}
                </Flex>
              </VStack>
            </Flex>
          </Box>
        );
      })}
    </VStack>
  );
};

function Component({ compEl, data, blobNames, formatDate }) {
  const isRepeatable = compEl.component.repeatable?.isRepeatable;
  const elements = compEl.component.structure?.elements;

  const [repeats, setRepeats] = useState([{ isHidden: false }]);

  useEffect(() => {
    if (data) {
      const initialRepeats = elements.reduce(
        (acc, element) => {
          const attachmentsLength = data[element.id]?.attachments?.length;
          if (attachmentsLength > acc.length) {
            acc = data[element.id]?.attachments.map((_) => ({
              isHidden: false,
            }));
          }
          return acc;
        },
        [[{ isHidden: false }]]
      );
      if (initialRepeats.length > repeats.length) {
        setRepeats(initialRepeats);
      }
    }
  }, [data]);

  const handleToggleHide = (index) => {
    setRepeats((prev) =>
      prev.map((repeat, i) => {
        if (i === index) {
          return { isHidden: !repeat.isHidden };
        }
        return repeat;
      })
    );
  };

  return (
    <>
      <VStack w="100%" gap="24px" aria-label="component">
        {isRepeatable ? (
          repeats.map((repeat, index) => (
            <VStack
              w="100%"
              key={index}
              gap={isRepeatable ? "8px" : "24px"}
              sx={
                isRepeatable
                  ? {
                      padding: "20px",
                      width: "100%",
                      border: "1.5px solid #CBD5E0",
                      borderRadius: "15px",
                      background: "#FFF",
                      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                      position: "relative",
                    }
                  : {}
              }
              aria-label="component"
            >
              {isRepeatable && index > 0 ? (
                <HStack w="100%" justify="space-between" alignItems="center">
                  <HStack>
                    <IconButton
                      variant="ghost"
                      borderRadius="8px"
                      // isDisabled={index < 1}
                      sx={{
                        ".icon": {
                          color: "#718096",
                        },
                      }}
                      icon={
                        repeat.isHidden ? (
                          <MdArrowForwardIos
                            w="26px"
                            h="26px"
                            className="icon"
                          />
                        ) : (
                          <CollapseIcon w="26px" h="26px" className="icon" />
                        )
                      }
                      onClick={() => {
                        handleToggleHide(index);
                      }}
                    />
                    <Text>#{index}</Text>
                  </HStack>
                  {/* <IconButton
                    variant="ghost"
                    borderRadius="8px"
                    isDisabled
                    sx={{
                      ".icon": {
                        color: "red.500",
                      },
                    }}
                    icon={<MdOutlineDeleteOutline size={18} className="icon" />}
                  /> */}
                </HStack>
              ) : (
                <></>
              )}
              {data &&
                elements.map((element) => {
                  const elementId = element.id;

                  const elementData = data && data[elementId];

                  let value = null;
                  if (elementData) {
                    value = elementData;
                  }
                  return (
                    <Flex
                      w="100%"
                      gap="8px"
                      pb="16px"
                      borderBottom="1px solid #E2E8F0"
                    >
                      {/* <Flex
                        border="1px solid #545454"
                        borderRadius="50%"
                        h="16px"
                        w="16px"
                        justifyContent="center"
                        alignItems="center"
                        padding="10px"
                      >
                        <Text fontSize="8px" color="#545454">
                          1
                        </Text>
                      </Flex> */}
                      <VStack gap="12px" alignItems="left" w="100%">
                        <Text fontSize="16px" fontWeight="500" color="#2D3748">
                          {element?.label}
                        </Text>
                        {value?.value && (
                          <Text
                            fontSize="16px"
                            fontWeight="400"
                            color="#4A5568"
                          >
                            {value?.itemType === "datepicker"
                              ? formatDate(value?.value)
                              : value?.value}
                          </Text>
                        )}
                        {value?.itemType === "multiFileUpload" ||
                        value?.itemType === "singleFileUpload" ||
                        value?.itemType === "fileUpload" ? (
                          <Flex flexWrap="wrap" gap="10px" w="100%">
                            <ImageContainer
                              value={value}
                              blobNames={blobNames}
                              index={index}
                            />
                          </Flex>
                        ) : value?.itemType === "checkbox" ||
                          value?.itemType === "checkboxSearch" ? (
                          // <Text
                          //   fontSize="16px"
                          //   fontWeight="400"
                          //   color="#4A5568"
                          // >
                          //   {JSON.parse(value?.attachments[index]).join(", ")}
                          // </Text>
                          <Checkboxes value={value} index={index} />
                        ) : (
                          <Text
                            fontSize="16px"
                            fontWeight="400"
                            color="#4A5568"
                          >
                            {value?.attachments[index]}
                          </Text>
                        )}
                      </VStack>
                    </Flex>
                  );
                })}
            </VStack>
          ))
        ) : (
          <VStack w="100%" gap="24px" aria-label="component">
            {data &&
              elements.map((element) => {
                const elementId = element.id;

                const elementData = data && data[elementId];

                let value = null;
                if (elementData) {
                  value = elementData;
                }
                return (
                  <Flex
                    w="100%"
                    gap="8px"
                    pb="16px"
                    borderBottom="1px solid #E2E8F0"
                  >
                    {/* <Flex
                      border="1px solid #545454"
                      borderRadius="50%"
                      h="16px"
                      w="16px"
                      justifyContent="center"
                      alignItems="center"
                      padding="10px"
                    >
                      <Text fontSize="8px" color="#545454">
                        1
                      </Text>
                    </Flex> */}
                    <VStack gap="12px" alignItems="left" w="100%">
                      <Text fontSize="16px" fontWeight="500" color="#2D3748">
                        {element?.label}
                      </Text>
                      {value?.value && (
                        <Text fontSize="16px" fontWeight="400" color="#4A5568">
                          {value?.itemType === "datepicker"
                            ? formatDate(value?.value)
                            : value?.value}
                        </Text>
                      )}
                      {value?.itemType === "multiFileUpload" ||
                      value?.itemType === "singleFileUpload" ||
                      value?.itemType === "fileUpload" ? (
                        <Flex flexWrap="wrap" gap="10px" w="100%">
                          {value?.attachments?.map((imageName) => (
                            <a
                              key={imageName}
                              href={blobNames[imageName]}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Image
                                h="94px"
                                src={blobNames[imageName]}
                                borderRadius="4px"
                                w="160px"
                              />
                            </a>
                          ))}
                        </Flex>
                      ) : (
                        <Text fontSize="16px" fontWeight="400" color="#4A5568">
                          {value?.attachments.join(", ")}
                        </Text>
                      )}
                    </VStack>
                  </Flex>
                );
              })}
          </VStack>
        )}
      </VStack>
    </>
  );
}

const TopCard = ({
  handleTopCard,
  isTopOpen,
  user,
  avatarUrl,
  warehouseDetails,
  taskData,
  formatDate,
  originId,
  clientDetails,
  formNameWithStatus,
  trackLocation,
  locationLoading,
}) => {
  return (
    <VStack
      w="100%"
      gap="48px"
      border="2px solid var(--Grey-Gray-100, #EDF2F7)"
      background="var(--White, #FFF)"
      pb={isTopOpen ? "48px" : "0px"}
      borderRadius="8px"
    >
      <Flex
        background="var(--Grey-Gray-600, #4A5568)"
        pt="16px"
        pb="16px"
        w="100%"
        borderTopRadius="8px"
        justifyContent="center"
        borderBottomRadius={isTopOpen ? "0px" : "8px"}
      >
        <Flex w="70%" gap="16px" borderRadius="8px">
          <Image
            src={SideArrow}
            cursor={"pointer"}
            onClick={() => {
              handleTopCard();
            }}
            transform={isTopOpen ? "rotate(90deg)" : "rotate(0deg)"}
          ></Image>
          <Text
            fontSize="24px"
            fontWeight="500"
            color="var(--Grey-Gray-100, #EDF2F7)"
          >
            Overview
          </Text>
        </Flex>
      </Flex>
      <Box
        display={isTopOpen ? "block" : "none"}
        transition="display 0.5s ease-in"
      >
        <VStack maxW="740px" justifyContent="center">
          <VStack
            alignItems="left"
            w="100%"
            gap="18px"
            justifyContent="center"
            borderRadius="8px"
            border="2px solid var(--Grey-Gray-100, #EDF2F7)"
            background="linear-gradient(91deg, #FFF 39.14%, #B6FFF8 263.21%), #FFF"
            p="24px"
          >
            <HStack>
              <Text
                sx={{
                  color: "var(--Gray-Gray-700, #2D3748)",
                  fontSize: "18px",
                  fontWeight: "700",
                }}
              >
                {formNameWithStatus.formName}
              </Text>
              <Status status={formNameWithStatus.status} />
            </HStack>
            <Flex gap="24px">
              <Flex gap="12px" alignItems="center">
                <Box>
                  <Avatar
                    size="xs"
                    src={clientDetails?.avatar}
                    name={clientDetails?.name}
                  />
                </Box>
                <Text fontSize="14px" fontWeight="600" color="#4A5568">
                  {clientDetails?.name || "N.A."}
                </Text>
              </Flex>
              <Stack direction="row" align="center" height="100%">
                <Box>
                  <Image src={FiUser} alt="Dan Abramov" />
                </Box>
                <Text style={leftTextStyle}>Auditor:</Text>
                <Box>
                  <Avatar
                    size="xs"
                    src={avatarUrl}
                    name={user?.firstName || ""}
                  />
                </Box>
                <Text
                  sx={{
                    fontSize: "14px",
                    color: "var(--Grey-Gray-600, #4A5568)",
                    fontStyle: " normal",
                    fontWeight: "500",
                  }}
                >
                  {user?.firstName}
                </Text>
              </Stack>
            </Flex>
            <Flex flexWrap="wrap">
              <DateInfoBlock
                label="Assigned"
                date={formatDate(taskData?.creationDt)}
              />
              <DateInfoBlock
                label="Due Date"
                date={formatDate(taskData?.dueDate)}
              />
              <DateInfoBlock
                label="Completed"
                date={
                  taskData?.completionDt
                    ? formatDate(taskData?.completionDt)
                    : "-"
                }
              />
            </Flex>
            <Flex flexWrap="wrap" gap="12px">
              <Flex gap="6px" alignItems="center" mr="32px">
                <Image src={House} />
                <Text sx={leftTextStyle}>Warehouse Name: </Text>
                <Text sx={rightTextStyle}>
                  {warehouseDetails[originId]?.name}
                </Text>
              </Flex>

              <Flex gap="6px" alignItems="center" mr="32px">
                <Image src={MdConfirmation} />
                <Text sx={leftTextStyle}>Warehouse Code: </Text>
                <Text sx={rightTextStyle}>
                  {warehouseDetails[originId]?.externalCode}
                </Text>
              </Flex>

              <Flex gap="6px" alignItems="flex-start" mr="32px" w="100%">
                <Image src={GoLocation} />
                <Flex w="100%">
                  <Text w="160px" sx={leftTextStyle}>
                    Warehouse Address:{" "}
                  </Text>
                  <Text sx={rightTextStyle} w="calc(100% - 160px)">
                    {warehouseDetails[originId]?.address?.addressLine1 +
                      warehouseDetails[originId]?.address?.addressLine2 || ""}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </VStack>
          <AuditorTackedInfo
            trackLocation={trackLocation}
            locationLoading={locationLoading}
            inspectionCompletedTime={taskData?.completionDt}
          />
        </VStack>
      </Box>
    </VStack>
  );
};

function DateInfoBlock(props) {
  return (
    <Stack direction="row" align="center" mr="32px">
      <Box>
        <Image src={MdDate} alt="Dan Abramov" />
      </Box>
      <Text style={leftTextStyle}>{props.label}:</Text>
      <Text style={rightTextStyle}>{props.date}</Text>
    </Stack>
  );
}

function Checkboxes({ value, index }) {
  let val;
  try {
    val = JSON.parse(value?.attachments[index]);
  } catch (err) {
    if (value?.attachments[index]) {
      val = value?.attachments[index];
    } else {
      val = value?.attachments;
    }
  }
  return (
    <Text fontSize="16px" fontWeight="400" color="#4A5568">
      {val.join(", ")}
    </Text>
  );
}

function ImageContainer({ value, blobNames, index }) {
  let fileList;
  try {
    fileList = JSON.parse(value.attachments[index]);
  } catch (err) {
    fileList = value.attachments;
  }
  return (
    <>
      {fileList?.length ? (
        fileList.map((imageName) => {
          return (
            <>
              <a
                key={imageName}
                href={blobNames[imageName]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  h="94px"
                  src={blobNames[imageName]}
                  borderRadius="4px"
                  w="160px"
                  objectFit="cover"
                />
              </a>
            </>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
}
