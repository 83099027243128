import React, { useEffect, useState } from "react";
import SelectElements from "../../../Components/selectElements";
import Editable from "../../../Components/Editable";
import {
  HStack,
  ButtonGroup,
  Box,
  Text,
  Switch,
  FormLabel,
  Checkbox,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { Input, Button } from "@agnext/reactlib";
import Moveup from "../../../Components/moveup";
import MoveDown from "../../../Components/moveDown";
import Delete from "../../../Components/Delete";
import Duplicate from "../../../Components/duplicate";
import { useDispatch } from "react-redux";
import { actions } from "../../Create/slice";
import Option from "../Option";
import { generateId } from "../../../../../utils/index";
import FileProperties from "views/Workflow Builder/Components/fileProperties";

export default function Element({
  elementId,
  componentId,
  elementOrder,
  elementOrderLast,
  currElement,
  sectionId,
  isValid,
  validationState,
  errorStyle,
}) {
  const elementValidationState = validationState.elements?.find(
    (element) => element.id === elementId
  );

  const [element, setElement] = useState({
    value: "",
    label: "",
  });

  const [mandatoryStatus, setMandatoryStatus] = useState(false);

  const [elementName, setElementName] = useState();

  const [supportingText, setSupportingText] = useState("Supporting Text");

  const [placeholder, setPlaceholder] = useState("Placeholder");

  const [href, setHref] = useState("");

  const [showAddOption, setShowAddOption] = useState(false);

  const [showAddRange, setShowAddRange] = useState(false);

  const [showFileOptions, setShowFileOptions] = useState(false);
  const [option, setOption] = useState({
    value: "",
    label: "",
  });

  const [range, setRange] = useState({
    from: "",
    to: "",
  });
  const [rangeErrorMessage, setRangeErrorMessage] = useState(null);

  const [fileOptions, setFileOptions] = useState({
    maxFileCount: 1,
    maxFileSize: 10,
    allowedFileTypes: [],
  });

  useEffect(() => {
    setElement({ value: currElement.htmlType || "" });
    setMandatoryStatus(currElement.mandatory);
    setElementName(currElement.label || "Element Label");
    setPlaceholder(
      currElement.placeholder ? currElement.placeholder : "Placeholder"
    );
    setSupportingText(currElement.supportingText || "Supporting Text");
  }, [currElement]);

  const dispatch = useDispatch();

  const handleElementNameChange = (value) => {
    setElementName(value);
  };

  const handleplaceHolderChange = (value) => {
    setPlaceholder(value);
  };

  const handleSupportingTextChange = (value) => {
    setSupportingText(value);
  };

  const handleRangeChange = (e) => {
    const { name, value } = e.target;
    const newRange = { ...range, [name]: value.trim() };
    setRange(newRange);
  };

  const handleElementChange = (element) => {
    setElement(element);
    dispatch(
      actions.editElementType({
        elementId,
        htmlType: element.value,
        componentId,
      })
    );
  };

  const handleMandatoryStatusChange = () => {
    dispatch(
      actions.editMandatoryStatusElement({
        elementId,
        componentId,
        mandatory: !mandatoryStatus,
      })
    );
    setMandatoryStatus(!mandatoryStatus);
  };

  const handleOptionValueChange = (e) => {
    setOption((prevState) => {
      return { ...prevState, value: e.target.value };
    });
  };

  const handleOptionLabelChange = (e) => {
    setOption((prevState) => {
      return { ...prevState, label: e.target.value };
    });
  };
  const handleOptionChange = (value) => {
    setFileOptions({
      ...fileOptions,
      allowedFileTypes: [`${value}`],
    });
  };

  const handleCountChange = (e, key) => {
    const newValue = e.target.value;
    setFileOptions((prevState) => ({
      ...prevState,
      [key]: newValue,
    }));
  };
  const handleElementNameSubmit = (elementName) => {
    const payload = {
      elementId,
      componentId,
      elementName,
    };
    dispatch(actions.editElementLabel(payload));
  };

  const handleSupportingTextSubmit = (text) => {
    const payload = {
      elementId,
      componentId,
      text,
    };
    dispatch(actions.editElementText(payload));
  };

  const handleRangeSubmit = () => {
    if (range.from && range.to) {
      if (Number(range.from) < Number(range.to)) {
        const payload = {
          elementId,
          componentId,
          range,
        };
        dispatch(actions.editElementRange(payload));
        setRange({ from: "", to: "" });
        setRangeErrorMessage(null);
      } else {
        setRangeErrorMessage("'From' value should be less than 'To' value");
        return;
      }
    } else {
      return;
    }
  };

  const handleHrefSubmit = () => {
    const payload = {
      elementId,
      componentId,
      href,
    };
    dispatch(actions.editElementHref(payload));
  };

  const handleOptionSubmit = () => {
    if (!option.label || !option.value) return;
    dispatch(actions.addElementOptions({ option, elementId, componentId }));
    setOption({
      value: "",
      label: "",
    });
  };

  const handlePlaceholderSubmit = (placeholder) => {
    const payload = {
      elementId,
      componentId,
      placeholder,
    };
    dispatch(actions.editElementPlaceHolder(payload));
  };

  const handleFileOptionsSubmit = () => {
    dispatch(
      actions.addFileProperties({ fileOptions, elementId, componentId })
    );
  };

  const moveUpHandlerElement = () => {
    const payload = {
      componentId,
      elementId,
    };
    dispatch(actions.moveUpElement(payload));
  };

  const handleElementDelete = () => {
    const payload = {
      componentId,
      elementId,
    };
    dispatch(actions.deletionElement(payload));
  };

  const handleElementDuplicate = async () => {
    const newId = await generateId();
    const payload = {
      newId,
      componentId,
      elementId,
    };
    dispatch(actions.duplicateElement(payload));
  };

  const moveDownHandlerElement = () => {
    const payload = {
      componentId,
      elementId,
    };
    dispatch(actions.moveDownElement(payload));
  };

  useEffect(() => {
    const elementType = element.value;
    if (
      elementType === "radio" ||
      elementType === "checkbox" ||
      elementType === "dropdown" ||
      elementType === "checkboxSearch" ||
      elementType === "dropdownWithSearch"
    ) {
      setShowAddOption(true);
    } else {
      setShowAddOption(false);
    }
    if (elementType === "numberInput") {
      setShowAddRange(true);
    } else {
      setShowAddRange(false);
    }
    if (elementType === "fileUpload") {
      setShowFileOptions(true);
      setFileOptions(currElement.fileOptions);
    } else {
      setShowFileOptions(false);
    }
  }, [element]);

  return (
    <>
      <Box
        w={"100%"}
        style={{
          padding: "16px",
          border: "1px solid #EFEFEF",
          margin: "16px 0 16px 0",
          borderLeft: "2px solid rgba(84, 3, 117, 0.5)",
          borderRadius: "0 8px 8px 0",
        }}
      >
        <HStack w="100%" style={{ padding: "16px" }} justify="space-between">
          <Box
            w="30%"
            sx={!isValid && !elementValidationState?.label ? errorStyle : {}}
          >
            <Editable
              text={elementName}
              handleTextChange={handleElementNameChange}
              handleSubmit={handleElementNameSubmit}
            />
          </Box>
          <HStack w="15%" justify="center">
            <Switch
              isChecked={mandatoryStatus}
              id="mandatory"
              colorScheme="login"
              onChange={handleMandatoryStatusChange}
            />
            <FormLabel color="login.label" htmlFor="remember" mb="0">
              Mandatory
            </FormLabel>
          </HStack>
          {element.value === "textInput" ||
          element.value === "textarea" ||
          element.value === "numberInput" ||
          element.value === "singleFileUpload" ||
          element.value === "multiFileUpload" ? (
            <Box w="20%">
              <Editable
                text={placeholder}
                handleTextChange={handleplaceHolderChange}
                handleSubmit={handlePlaceholderSubmit}
              />
            </Box>
          ) : (
            <></>
          )}
          <Box
            w="20%"
            sx={!isValid && !elementValidationState?.htmlType ? errorStyle : {}}
          >
            <SelectElements
              element={element}
              onElementChange={handleElementChange}
            />
          </Box>
          <ButtonGroup justifyContent="center" size="md">
            <Moveup
              moveUpHandler={moveUpHandlerElement}
              isDisabled={elementOrder === 1 ? true : false}
            />
            <MoveDown
              moveDownHandler={moveDownHandlerElement}
              isDisabled={elementOrder === elementOrderLast ? true : false}
            />
            <Delete deleteHandler={handleElementDelete} />
            <Duplicate duplicateHandler={handleElementDuplicate} />
          </ButtonGroup>
        </HStack>
        <HStack w="70%" gap="16px" style={{ paddingLeft: "16px" }}>
          <Editable
            text={supportingText}
            handleTextChange={handleSupportingTextChange}
            handleSubmit={handleSupportingTextSubmit}
          />
        </HStack>
        {/* {Range} */}
        {showAddRange ? (
          <>
            {currElement.range ? (
              <HStack w="30%" justify="space-between" padding="16px">
                <HStack>
                  <Text>From: </Text>
                  <Text color="login.500">{currElement.range.from}</Text>
                </HStack>
                <HStack>
                  <Text>To: </Text>
                  <Text color="login.500">{currElement.range.to}</Text>
                </HStack>
              </HStack>
            ) : (
              <></>
            )}
            <HStack>
              <HStack w="50%" justify="space-between" paddingLeft="16px">
                <HStack>
                  <Input
                    name="from"
                    variant="flushed"
                    value={range.from}
                    placeholder="From"
                    onChange={handleRangeChange}
                    onInput={(e) => {
                      const inputValue = e.target.value;
                      const decimalCount = (inputValue.match(/\./g) || [])
                        .length;
                      if (decimalCount <= 1) {
                        e.target.value = inputValue.replace(/[^0-9.]/g, "");
                      } else {
                        e.target.value = inputValue.replace(
                          /[.](?=.*[.])/g,
                          ""
                        );
                      }
                    }}
                  />
                </HStack>
                <HStack>
                  <Input
                    name="to"
                    variant="flushed"
                    value={range.to}
                    placeholder="To"
                    onChange={handleRangeChange}
                    onInput={(e) => {
                      const inputValue = e.target.value;
                      const decimalCount = (inputValue.match(/\./g) || [])
                        .length;
                      if (decimalCount <= 1) {
                        e.target.value = inputValue.replace(/[^0-9.]/g, "");
                      } else {
                        e.target.value = inputValue.replace(
                          /[.](?=.*[.])/g,
                          ""
                        );
                      }
                    }}
                  />
                </HStack>
                <Button
                  label="Add range"
                  onClick={handleRangeSubmit}
                  colorScheme="login"
                  size="sm"
                />
              </HStack>
              <Text m="10px" color="red" fontSize="14px">
                {rangeErrorMessage && rangeErrorMessage}
              </Text>
            </HStack>
          </>
        ) : (
          <></>
        )}
        {element.value === "link" ? (
          <HStack w="70%" gap="16px" style={{ paddingLeft: "16px" }}>
            <Text>href</Text>
            <Input
              value={href}
              onChange={(e) => setHref(e.target.value)}
              variant="flushed"
              focusBorderColor="login.500"
              style={{ padding: "4px 16px" }}
            />
            <Button
              colorScheme="login"
              label="Add"
              size="sm"
              onClick={handleHrefSubmit}
            />
          </HStack>
        ) : (
          <></>
        )}
        {showAddOption ? (
          <>
            <HStack
              gap="32px"
              sx={
                !isValid && !elementValidationState?.options ? errorStyle : {}
              }
            >
              <Box w="30%" style={{ paddingLeft: "16px" }}>
                <Text color="login.500">Value</Text>
              </Box>
              <Box w="30%" style={{ paddingLeft: "16px" }}>
                <Text color="login.500">Label</Text>
              </Box>
            </HStack>
            <HStack gap="32px">
              <Box w="30%">
                <Input
                  variant="flushed"
                  focusBorderColor="login.500"
                  style={{ padding: "4px 16px" }}
                  value={option.value}
                  onChange={handleOptionValueChange}
                />
              </Box>
              <Box w="30%">
                <Input
                  variant="flushed"
                  focusBorderColor="login.500"
                  style={{ padding: "4px 16px" }}
                  value={option.label}
                  onChange={handleOptionLabelChange}
                />
              </Box>
              <Box>
                <Button
                  size="lg"
                  onClick={handleOptionSubmit}
                  colorScheme="login"
                  leftIcon={<AddIcon />}
                  variant="ghost"
                />
              </Box>
            </HStack>
            <>
              {currElement.properties &&
                currElement.properties.options &&
                currElement.properties.options.map((option, index) => (
                  <Option
                    key={index}
                    value={option.value}
                    label={option.label}
                    element={currElement}
                    componentId={componentId}
                    sectionId={sectionId}
                  />
                ))}
            </>
          </>
        ) : (
          <></>
        )}
        {showFileOptions ? (
          <>
            <FileProperties
              fileOptions={fileOptions}
              handleOptionChange={handleOptionChange}
              handleCountChange={handleCountChange}
              handleFileOptionsSubmit={handleFileOptionsSubmit}
              isValid={isValid}
              validationFileOptions={elementValidationState?.fileOptions}
              errorStyle={errorStyle}
            />
          </>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}
