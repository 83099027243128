import React, { useState } from "react";
import {
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Input,
  Radio,
  InputGroup,
  InputRightElement,
  IconButton,
  Image,
  Text,
  MenuButton,
  Menu,
  Button,
  Select,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { DropdownArrowIcon } from "components/Icons/Icons";
import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import LocationSvg from "assets/svg/location.svg";
import { SearchBar } from "../SearchBar";
import Close from "assets/svg/ModalCloseButton.svg";
import { capitalizeStringUpdated } from "utils/commonFunctions";

const LocationInfo = ({ label, displayText, location }) => {
  return (
    <Flex
      gap={"11px"}
      justifyContent={{ base: "space-between", md: "flex-start" }}
    >
      {label && (
        <Text
          sx={{
            color: "#4A5568",
            fontWeight: location ? 600 : 700,
            fontSize: "14px",
          }}
        >
          {label}
        </Text>
      )}
      {displayText && (
        <Text
          sx={{
            color: "#2D3748",
            fontWeight: location ? 400 : 500,
            fontSize: "14px",
          }}
        >
          {displayText}
        </Text>
      )}
    </Flex>
  );
};
const BasicInfo = ({ label, displayText, location }) => {
  return (
    <Flex
      gap={"11px"}
      flex={"1"}
      justifyContent={{ base: "space-between", md: "flex-start" }}
    >
      {label && (
        <Text
          sx={{
            color: "#4A5568",
            fontWeight: location ? 600 : 700,
            fontSize: "14px",
          }}
        >
          {label}
        </Text>
      )}
      {displayText && (
        <Text
          sx={{
            color: "#2D3748",
            fontWeight: location ? 400 : 500,
            fontSize: "14px",
          }}
        >
          {displayText}
        </Text>
      )}
    </Flex>
  );
};

const LocationModal = ({
  isOpen,
  onClose,
  isChecked,
  handleSelectedLocation,
  locations,
  clientId,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [visibleLocations, setVisibleLocations] = useState([]);
  const [visibleCount, setVisibleCount] = useState(10);

  const handleSearchQuery = (query) => {
    setSearchQuery(query);
  };

  React.useEffect(() => {
    // Update visible locations when locations or visibleCount changes
    setVisibleLocations(
      locations
        ?.filter((location) => {
          return (
            location.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            location.address.toLowerCase().includes(searchQuery.toLowerCase())
          );
        })
        .slice(0, visibleCount)
    );
  }, [locations, visibleCount, searchQuery]);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight * 2;
    if (bottom) {
      setVisibleCount((prev) => Math.min(prev + 10, locations.length));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setVisibleCount(10);
        setVisibleLocations(locations?.slice(0, 10));
        onClose();
      }}
      isCentered="true"
      size="3xl"
      // maxHeight={"80%"}
    >
      <ModalOverlay />
      <ModalContent>
        {/* <ModalHeader fontSize={"18px"}>Select Location</ModalHeader> */}
        {/* <ModalCloseButton /> */}
        <ModalBody sx={{ padding: "0px" }}>
          <VStack align="stretch">
            <Flex
              padding="16px 24px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text fontSize="18px" fontWeight="700">
                Select Location
              </Text>
              <Box>
                <SearchBar
                  searchQuery={searchQuery}
                  handleSearchQuery={handleSearchQuery}
                  placeholder="Search Location"
                />
              </Box>
              <Box>
                <Image
                  src={Close}
                  onClick={() => {
                    setVisibleCount(10);
                    setVisibleLocations(locations?.slice(0, 10));
                    onClose();
                  }}
                  cursor={"pointer"}
                />
              </Box>
            </Flex>
          </VStack>

          <Box
            p="24px"
            pt="0px"
            // overflowY="auto" maxHeight="70vh"
            onScroll={handleScroll}
            style={{
              // minHeight: "70vh",
              maxHeight: "70vh",
              overflowY: "auto",
            }}
          >
            <Flex
              direction="column"
              gap="16px"
              paddingTop="24px"
              paddingBottom="24px"
            >
              {!clientId ? (
                <HStack justifyContent="center" w="100%">
                  <Text fontWeight="600">Please Select Client ID</Text>
                </HStack>
              ) : (
                <> </>
              )}
              {clientId && visibleLocations?.length ? (
                visibleLocations.map((location) => {
                  return (
                    <Box
                      width={"100%"}
                      border={
                        isChecked(location)
                          ? "1.5px #38B2AC solid"
                          : "0.5px solid  #EEEEEE"
                      }
                      p="4"
                      borderRadius={"15px"}
                      onClick={() => handleSelectedLocation(location, onClose)}
                      key={location.id}
                    >
                      <Flex gap={"18px"}>
                        <Box minWidth={"18px"}>
                          <Image src={LocationSvg} />
                        </Box>
                        <Flex w="100%" justifyContent={"space-between"}>
                          <Flex direction={"column"}>
                            <Flex
                              direction={{ base: "column", md: "row" }}
                              flexWrap={"wrap"}
                              sx={{
                                "> div": {
                                  marginBottom: { base: "12px", md: "12px" }, // Adjust as needed
                                  marginRight: { base: "0px", md: "36px" },
                                },
                              }}
                            >
                              <LocationInfo
                                label={"Name:"}
                                displayText={capitalizeStringUpdated(location?.name) }
                                location={true}
                              />
                              <LocationInfo
                                label={"Location Type:"}
                                displayText={capitalizeStringUpdated(location?.type)}
                                location={true}
                              />
                              <LocationInfo
                                label={"Contact Person:"}
                                displayText={capitalizeStringUpdated(location?.contactPerson)}
                                location={true}
                              />

                              <LocationInfo
                                label={"Phone Number:"}
                                displayText={location.phoneNumber}
                                location={true}
                              />
                              <LocationInfo
                                label={"Email:"}
                                displayText={location.email}
                                location={true}
                              />
                            </Flex>
                            <BasicInfo displayText={capitalizeStringUpdated(location?.address)} />
                          </Flex>
                          <Box justifyContent="flex-end">
                            <Radio isChecked={isChecked(location)} />
                          </Box>
                        </Flex>
                      </Flex>
                    </Box>
                  );
                })
              ) : clientId ?
              ( <HStack justifyContent="center" w="100%">
              <Text fontWeight="600">No Locations Available</Text>
            </HStack>) : <></>
              }
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const Location = ({
  locations,
  selectedLocation,
  handleSelectedLocation,
  clientId,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isChecked = (location) => {
    return location.id == selectedLocation?.id;
  };

  return (
    <Flex>
      <Box width={"100%"} overflow="hidden">
        <Menu>
          <MenuButton
            paddingRight="11px"
            width={"100%"}
            borderRadius="8px"
            as={Button}
            onClick={onOpen}
            bgColor="white"
            border="1px solid #E2E8F0"
            _focus={{ boxShadow: "none" }}
            _active={{ bg: "white" }}
            _hover={{ bg: "white" }}
          >
            <Flex justifyContent={"space-between"}>
              <Text
                fontSize="14px"
                color={selectedLocation ? "#4A5568" : "#718096"}
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {selectedLocation ? capitalizeStringUpdated(selectedLocation?.address) : "Select"}
              </Text>
              <DropdownArrowIcon />
            </Flex>
          </MenuButton>
        </Menu>

        <LocationModal
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          handleSelectedLocation={handleSelectedLocation}
          isChecked={isChecked}
          locations={locations}
          clientId={clientId}
        />
      </Box>
    </Flex>
  );
};

export default Location;
