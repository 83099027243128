import React from "react";
import {
  Box,
  VStack,
  HStack,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import InfoBox from "../InfoBox";
import { Table } from "@agnext/reactlib";
import noRecordImage from "../../../../../assets/img/Empty Case.png";
import { quantityInsightsHeader } from "views/Variables/variables";

const styles = {
  quatityInsightsContainer: {
    // width: "100%",
    alignItems: "flex-start",
    flex: "1 0 0",
    alignSelf: "stretch",
    borderRadius: "15px",
    background: "#FFF",
    boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)",
    border: "1px solid #E2E8F0",
    overflow: "hidden",
  },
  quantityHeaderContainer: {
    width: "100%",
    padding: "20px",
  },
  headerContainer: {
    width: "100%",
    justifyContent: "space-between",

    alignItems: "flex-start",
  },
  header: {
    color: "#7E8DA2",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%",
  },
  popoverStyle: {
    right: "26px",
    outline: "none",
    border: "1px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
  },
  message: {
    fontFamily: "Inter",
    textAlign: "center",
    fontSize: "22px",
  },
  image: {
    maxWidth: "100%",
    height: "auto",
    margin: "auto",
  },
};

export default function MeasuredCommodities({ data }) {
  const { quantityInsightsData } = data;
  // quantityInsightsData = quantityInsightsData?.map((obj) => {
  //   const resKeys = ["quantity", "commodity", "noOfBags"];

  //   const transformData = (val) => {
  //     if (_.isNumber(val) && !_.isInteger(val)) return val.toFixed(2);
  //     if (_.isString(val)) return _.capitalize(val);
  //     return val;
  //   };
  //   return resKeys.reduce((acc, cv) => {
  //     acc[cv] =
  //       (obj && obj[cv] && obj[cv] === "") ||
  //       obj[cv] === null ||
  //       obj[cv] === undefined ||
  //       obj[cv] === 0
  //         ? "-"
  //         : transformData(obj[cv]);
  //     return acc;
  //   }, {});
  // });

  return (
    <VStack sx={styles.quatityInsightsContainer}>
      <Box sx={styles.quantityHeaderContainer}>
        <HStack sx={styles.headerContainer}>
          <Text sx={styles.header}>Measured Commodities</Text>
          <Box sx={{ ...styles.header, position: "relative" }}>
            <Popover>
              <PopoverTrigger>
                <InfoIcon
                  _hover={{ cursor: "pointer" }}
                  boxSize={6}
                  color="#CBD5E0"
                />
              </PopoverTrigger>
              <PopoverContent style={styles.popoverStyle}>
                <InfoBox text="Quantities measured across commodities." />
              </PopoverContent>
            </Popover>
          </Box>
        </HStack>
      </Box>
      <Box w={"100%"}>
        {quantityInsightsData.length === 0 ? (
          <Box style={styles.message}>
            <img
              style={styles.image}
              src={noRecordImage}
              alt="No Records Image"
            />
            <p>No Records Found</p>
          </Box>
        ) : (
          <Table
            overflowY="auto"
            tableContainerHeight={{ base: "441px", sm: "378px" }}
            headerList={quantityInsightsHeader}
            dataList={quantityInsightsData}
            fixedHeader={true}
          />
        )}
      </Box>
    </VStack>
  );
}
