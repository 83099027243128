import {
  Box,
  CircularProgress,
  Flex,
  HStack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { LineChart } from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
import { v4 as uuidv4 } from "uuid";
import "./InsightsAndGraph.css";
import { InsightsLineChart } from "components/Charts/InsightsLineChart";
const styles = {
  linegraphContainer: {
    width: "100%",
    height: "150px",
    overflow: "hidden",
    maxWidth: "100%",
  },
  title: {
    color: "#7E8DA2",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
  },
  amount: {
    color: "#2D3748",
    fontFamily: "Inter",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "140%",
  },
  data: {
    color: "#48BB78",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
    alignSelf: "center",
    margin: "0px",
  },
  insightsCount: {
    minWidth: "163.48px",
    gap: "16px",
    flexDirection: "column-reverse",
    justifyContent: "space-between",
    // height: "100%",
  },
};

function InsightsAndGraph({
  loading,
  percentage,
  title,
  amount,
  icon,
  labels,
  data,
  tooltipData,
}) {
  return (
    <>
      <HStack
        className="insightsGraphMainContainer"
        border="1px solid #E2E8F0"
        w={"100%"}
        bg={"white"}
        borderRadius={"15px"}
        padding={"20px"}
        justifyContent={"space-between"}
      >
        <VStack className="insightsContainer" sx={{ ...styles.insightsCount }}>
          <Stat alignSelf={"self-start"}>
            <StatLabel sx={styles.title}>{title}</StatLabel>

            <Flex>
              <StatNumber sx={styles.amount}>{amount}</StatNumber>
            </Flex>
            {/* {loading ? (
              <CircularProgress
                isIndeterminate
                color="#4FD1C5"
                size="16px"
                alignSelf={"center"}
              />
            ) : (
              <StatHelpText sx={styles.data}>{"+" + percentage}</StatHelpText>
            )} */}
          </Stat>

          <IconBox
            h={"45px"}
            w={"45px"}
            bg="#4FD1C5"
            my="auto"
            alignSelf={"flex-start"}
          >
            {
              <img
                src={icon}
                // style={imageStyle}
                alt={`Icon`}
              />
            }
          </IconBox>
        </VStack>

        <Box
          sx={{
            ...styles.linegraphContainer,
          }}
          className="linegraphContainer"
        >
          <InsightsLineChart
            labels={labels}
            data={data}
            key={uuidv4()}
            showLine={false}
            tooltipData={tooltipData}
          />
        </Box>
      </HStack>
    </>
  );
}

export default InsightsAndGraph;
