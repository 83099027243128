import React from "react";
import "views/Dashboard/Inspection Table/index.css";
import {
  CircularProgress,
  Flex,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  percentage,
} from "@chakra-ui/react";
import IconBox from "components/Icons/IconBox";
function StatCard({ title, amount, percentage, icon, loading }) {
  const styles = {
    data: {
      color: "#48BB78",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "140%",
      alignSelf: "center",
      margin: "0px",
    },
    statMainContainer: {
      "@media (max-width: 900px)": {
        minWidth: "200px",
      },
      "@media (max-width: 571px)": {
        minWidth: "110px",
      },
    },
  };
  return (
    <Flex
      width={"100%"}
      flexDirection={{ base: "column-reverse", md: "row" }}
      gap={{ base: "16px", md: "0px" }}
      sx={styles.statMainContainer}
    >
      <Stat display={"flex"} rowGap={"1px"}>
        <StatLabel className="stat-label">{title}</StatLabel>
        <Flex>
          {/* {loading ? (
            <CircularProgress isIndeterminate color="#4FD1C5" size="20px" />
          ) : ( */}
          <StatNumber>{amount}</StatNumber>
          {/* )}
          <StatHelpText sx={styles.data}>{"+" + percentage}</StatHelpText> */}
        </Flex>
      </Stat>
      <IconBox h={"45px"} w={"45px"} bg="#4FD1C5" my="auto">
        {icon}
      </IconBox>
    </Flex>
  );
}

export default StatCard;
