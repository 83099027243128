import React from "react";
import { HStack, Image, Textarea } from "@chakra-ui/react";
import { HiMenuAlt4 } from "react-icons/hi";

export default function LongAnswer({ data, formData, handleFormDataChange }) {
  return (
    <HStack
      w="100%"
      h="89px"
      style={{ border: "2px solid #E2E8F0", borderRadius: "8px" }}
      align="flex-start"
    >
      <HStack w="48px" h="48px" justify="center" align="center">
        <HiMenuAlt4 size={18} style={{ fill: "#718096" }} />
      </HStack>
      <Textarea
        variant="unstyled"
        placeholder={data.element.placeholder}
        color="#2D3748"
        _placeholder={{ color: "#A0AEC0" }}
        style={{ padding: "12px 12px 12px 0" }}
        name={data.element.id}
        value={formData[data.element.id]?.value}
        onChange={handleFormDataChange}
      />
    </HStack>
  );
}
