export const userStorage = {
  get: () => {
    const local = JSON.parse(localStorage?.getItem("inspectionDashboard"));
    return local ? local?.user : null;
  },
  set: (user) => {
    localStorage?.setItem("inspectionDashboard", JSON.stringify(user));
  },
  remove: () => {
    localStorage?.removeItem("inspectionDashboard");
  },
};

// export const token = {
//   get: () => {
//     const token = JSON.parse(localStorage.getItem("session"));
//     return token;
//   },
//   set: (token) => {
//     localStorage.setItem("session", JSON.stringify(token));
//   },
//   remove: () => {
//     localStorage.removeItem("session");
//   },
// };

// export const getCustomerId = () => {
//   const user = JSON.parse(localStorage.getItem("user"));
//   return user && user.customer_id;
// };

// export const getUserId = () => {
//   const user = JSON.parse(localStorage.getItem("user"));
//   return user && user.user_id;
// };
