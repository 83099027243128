import React from "react";
import Dropdown from "views/shared/Dropdown";

function Location({ uniqueObjects, handleFilterQuery, filterQuery }) {
  return (
    <Dropdown
      options={uniqueObjects}
      placeholder={"Location"}
      handleFilterQuery={handleFilterQuery}
      property={"location"}
      listKey={"city"}
      filterQuery={filterQuery}
    />
  );
}

export default Location;
