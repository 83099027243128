import React from "react";
import { Box, Button, Flex } from "@chakra-ui/react";
import { FilterIcon } from "components/Icons/Icons";
import FilterDrawer from "../FilterDrawer";
import WarehouseFilter from "views/shared/WarehouseFilter";
import Commodity from "./FiltersWidgets/Commodity";
import Location from "./FiltersWidgets/Location";
import DateSelection from "./FiltersWidgets/DateSelection";
import Client from "./FiltersWidgets/Client";

const styles = {
  filtersContainer: {
    padding: "16px 20px",
    justifyContent: "space-between",
    width: "100%",
    backgroundColor: "#FFF",
    boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)",
    borderRadius: "15px",
    gap: "16px",
    border: "1px solid #E2E8F0",
    flexDirection: "row",
  },
  button: {
    backgroundColor: "#F7FAFC",
    color: "#4F4F4F",
    fontSize: "12px",
    borderRadius: "8px",
    padding: "0 16px",
    display: "flex",
    justifyContent: "space-between",
    height: "39px",
    fontWeight: "600",
    lineHeight: "24px",
  },
  greenCircle: {
    position: "absolute",
    top: "-2.5px",
    right: "-3.5px",
    width: "12px",
    height: "12px",
    backgroundColor: "#319795",
    borderRadius: "50%",
    border: "1px solid white",
  },
};

export default function Filters({
  toggleDrawer,
  filterQuery,
  commodityNames,
  uniqueObjects,
  handleFilterQuery,
  isDrawerOpen,
  warehouses,
  inputValue,
  handleQuery,
  handleSelect,
  handleWareHouseChange,
  handleInputVal,
  searchQuery,
  filters,
  handleFromDateChange,
  handleToDateChange,
  entityType,
  clientNames,
}) {
  const local = localStorage.getItem("inspectionDashboard");
  const loginObject = JSON.parse(local);

  return (
    <Flex sx={styles.filtersContainer}>
      {
        entityType === "client" ? (
          <></>
        ) : (
          //
          // Filter Drawer start
          //
          <>
            <Box w="100%" display={{ base: "flex", md: "none" }}>
              <Button
                sx={styles.button}
                rightIcon={<FilterIcon width="30px" height="18px" />}
                variant="outline"
                onClick={toggleDrawer}
                width={"100%"}
              >
                Filters
                {(filterQuery.location !== "" ||
                  filterQuery.commodities !== "") && (
                  <Box sx={styles.greenCircle}></Box>
                )}
              </Button>
              <FilterDrawer
                commodityOptions={commodityNames}
                locationOptions={uniqueObjects}
                handleFilterQuery={handleFilterQuery}
                isDrawerOpen={isDrawerOpen}
                toggleDrawer={toggleDrawer}
                commodities={filterQuery.commodities}
                location={filterQuery.location}
              />
            </Box>
          </>
        )
        //
        // Filter Drawer End
        //
      }
      {entityType === "client" ? (
        <></>
      ) : (
        <Box width="100%">
          <Client
            clientNames={clientNames}
            handleFilterQuery={handleFilterQuery}
            filterQuery={filterQuery}
          />
        </Box>
      )}
      <Box display={{ base: "none", md: "block" }} w="100%">
        <Commodity
          commodityNames={commodityNames}
          handleFilterQuery={handleFilterQuery}
          filterQuery={filterQuery}
        />
      </Box>
      {entityType === "client" && (
        <WarehouseFilter
          warehouseOptions={warehouses}
          inputValue={inputValue}
          handleQuery={handleQuery}
          handleSelect={handleSelect}
          handleWareHouseChange={handleWareHouseChange}
          handleInputVal={handleInputVal}
          searchQuery={searchQuery}
        />
      )}
      <Box display={{ base: "none", md: "block" }} w="100%">
        <Location
          uniqueObjects={uniqueObjects}
          handleFilterQuery={handleFilterQuery}
          filterQuery={filterQuery}
        />
      </Box>

      <DateSelection
        filters={filters}
        handleFromDateChange={handleFromDateChange}
        handleToDateChange={handleToDateChange}
      />
    </Flex>
  );
}
