import React, { useEffect } from "react";
import { Box, Text, Stack, Flex, VStack, Image, Badge } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import IconBox from "components/Icons/IconBox";

import drawer from "assets/svg/default.svg";
import Support from "../../assets/svg/MdSupportAgent.svg";
import "./index.css";
import { Separator } from "components/Separator/Separator";
import { useSidebar } from "../../context/SidebarContext";
import { userStorage } from "utils/helper";
import SideBarRoutes from "SidebarRoutes";
import { useDispatch, useSelector } from "react-redux";
import * as myApprovalSelectors from "views/Users/pages/myApprovals/selectors";
import {
   actions as myApprovalsAction,
   sliceKey,
   reducer,
} from "views/Users/pages/myApprovals/slice";
import { myApprovalSaga } from "views/Users/pages/myApprovals/saga";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { useUserContext } from "context/UserContext";
import { getCurrentProfile } from "utils/commonFunctions";
import { Loader } from "components/Loader/Loader";
import { stringify } from "uuid";
const imageStyle = {
   height: "65%",
};

const SidebarContent = () => {
   useInjectReducer({ key: sliceKey, reducer: reducer });
   useInjectSaga({ key: sliceKey, saga: myApprovalSaga });
   const { sidebarOpen, setIsSidebarOpen } = useSidebar();

   const location = useLocation();
   const dispatch = useDispatch();
   const { userProfileData, handleLogin, handleLogout1 } = useUserContext();

   const isButtonActive = (buttonRoute) => {
      return location.pathname === buttonRoute;
   };
   const handleSidebarOpen = () => {
      setIsSidebarOpen(!sidebarOpen);
   };

   const PendingApprovalsCountForBadge = useSelector(
      myApprovalSelectors.selectPendingApprovalsCountForBadge
   );
   const totalPendingApprovalsCount = useSelector(
      myApprovalSelectors.selectTotalPendingApprovalsCount
   );

   const local = localStorage.getItem("inspectionDashboard");
   const loginObject = JSON.parse(local);

   let displayedRoutes = JSON.parse(JSON.stringify(SideBarRoutes));

   const entity = getCurrentProfile(userProfileData?.profiles);
   const entityType = entity?.ownerEntityType;

   const userRole = entity?.role.name;
   const isManager = userRole?.includes("manager");
   const isClient = userRole?.includes("client");
   const isSuperAdmin = userRole?.includes("super-admin");
   const isAdmin = userRole?.includes("admin");
   const isAuditor = userRole?.includes("auditor");
   const isViewer = userRole?.includes("viewer");

   if (entityType === "customer") {
      //checks for customer

      if (isAuditor) {
         displayedRoutes = displayedRoutes.filter((route) => route.name === "Bank Audit");
      } else if (isAdmin || isManager) {
         displayedRoutes = displayedRoutes.filter(
            (route) => !["Agency", "Bank Audit","Roles & Permissions"].includes(route.name)
         );
      }
   } else if (entityType === "client") {
      //checks for client
      if (isViewer) {
         displayedRoutes = displayedRoutes.filter(
            (route) => route.name === "Dashboard" || route.name === "Inspections" || route.name === "Warehouses" || route.name === "Collateral Managers"
         );
      }
   } else {
      // If platform provider
      displayedRoutes = displayedRoutes.filter((route) => route.name !== "Bank Audit");
   }

   //Getting number of pending approvals list
   useEffect(() => {
      dispatch(
        myApprovalsAction.fetchPendingApprovalCountForBadge({
          payload: {
            status: ["done", "review-inprogress"],
            page: 0,
            size: 1
          },
        })
      );
   }, [totalPendingApprovalsCount]);
   // //console.log(SideBarRoutes.length, displayedRoutes.length);
   return (
      <VStack gap="20px" >
         <VStack w={"100%"} gap="0px">
            <Flex sx={{justifyContent: "space-between",
               width: "226px",
               height: "28.3px",
               margin :  sidebarOpen ?"36px 0px" :"0px" }}>
               <Link to="/admin/dashboard" >
                  {
                     <Image
                        src={ process.env.REACT_APP_LOGO_URL }
                        className="agnext-logo"
                        alt="logo"
                        style={{
                           height: "100%",
                           width: "90%",
                           display: sidebarOpen ? "block" : "none",
                           marginLeft: "10px",
                           alignSelf:"center",
                        }}
                     />
                  }
               </Link>

               <Image
                  src={drawer}
                  className={sidebarOpen ? "drawer-logo" : "drawer-logo-closed logo-position"}
                  alt="logo"
                  style={{ width: "24px", height:"24px", cursor: "pointer", margin: sidebarOpen ? "2px 10px 0 0" :"0px"}}
                  onClick={handleSidebarOpen}
               />
            </Flex>
            <Separator
               width={sidebarOpen ? "110%" : "20%"}
               className={sidebarOpen ? "" : "sidebar-close-seperator"}
            ></Separator>
         </VStack>
         {/* overflowY={"scroll"} overflowX={"hidden"} */}
         <Stack
            direction="column"
            pb="110px"
            mb="40px"
            mt={sidebarOpen ? "0px" : "50px"}
            height={"100%"}
            overflowY={"overlay"}
            className="scrollable"
         >
            {userRole ? (
               displayedRoutes.map((route) => {
                  // console.log({route})
                  return `${loginObject?.user?.orgName}` === "HDFC" &&
                     route.name === "Inspections" ? (
                     ""
                  ) : (
                     <Link to={`${route.layout + route.path}`} key={route.path}>
                        <Box
                           key={route.path}
                           className={sidebarOpen ? "sidebar-content-container" : ""}
                           style={{
                              background:
                                 isButtonActive(`${route.layout + route.path}`) && sidebarOpen
                                    ? "white"
                                    : "",
                              boxShadow:
                                 isButtonActive(`${route.layout + route.path}`) && sidebarOpen
                                    ? "0px 7px 11px rgba(0, 0, 0, 0.04)"
                                    : "",
                           }}
                        >
                           <Box
                              className={sidebarOpen ? "icon-box-sidebar" : "icon-box"}
                              style={{
                                 background:
                                    isButtonActive(`${route.layout + route.path}`) && !sidebarOpen
                                       ? "white"
                                       : "",
                              }}
                           >
                              <IconBox
                                 h={"30px"}
                                 w={"30px"}
                                 bg={
                                    isButtonActive(`${route.layout + route.path}`)
                                       ? "#4FD1C5"
                                       : "white"
                                 }
                                 my="auto"
                                 boxShadow="0px 7px 11px rgba(0, 0, 0, 0.04)"
                              >
                                 <Image
                                    src={
                                       isButtonActive(`${route.layout + route.path}`)
                                          ? route.activeIcon
                                          : route.inActiveIcon
                                    }
                                    style={imageStyle}
                                    alt={route.name + " Logo"}
                                 />
                              </IconBox>
                           </Box>
                           <Text
                              className={sidebarOpen ? "sidebar-content" : "sidebar-close"}
                              position={"relative"}
                           >
                              {route.name === "Pending Approvals" ? (
                                 <>
                                    {route.name}
                                    {PendingApprovalsCountForBadge ? (
                                       <Badge
                                          px="2px"
                                          bg={"#718096"}
                                          position={"absolute"}
                                          color={"#FFFFFF"}
                                          lineHeight={"16px"}
                                          borderRadius={"10px"}
                                          fontSize={"9px"}
                                          textAlign={"center"}
                                          w={"20px"}
                                          left={sidebarOpen ? "-22px" : "46px"}
                                          bottom={sidebarOpen ? "12px" : "25px"}
                                       >
                                          {PendingApprovalsCountForBadge}
                                       </Badge>
                                    ) : (
                                       <></>
                                    )}
                                 </>
                              ) : (
                                 route.name
                              )}
                           </Text>
                        </Box>
                     </Link>
                  );
               })
            ) : (
               <Loader size={"24px"} position="center" />
            )}
         </Stack>
      </VStack>
   );
};

export default SidebarContent;