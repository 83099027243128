import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  imageBlob: null,
  loading: false,
  allCustomers: [],
  profileImgUrlsArr: [],
  customerLinks: {},
  customerIds: {},
  customerDetails: null,
  customerAddress: {},
  customerAddresses: null,
  avatarUrl: null,
  stateDropdown: null,
  cityDropdown: null,
  allUsersForCustomer: null,
  allCustomerUserRoles: [],
  locations: {},
  warehouseDetails: null,
  totalNumOfCustomers : 0,
  isLoadingCustomersData : false

};

const customerPage = createSlice({
  name: "customerPage",
  initialState,
  reducers: {
    fetchImageBlob: (state, action) => {
      state.loading = true;
    },
    fetchImageBlobSuccess: (state, action) => {
      state.loading = false;
      fetchAllCustomersSuccess;
      state.imageBlob = action.payload;
    },
    fetchImageBlobFailure: (state, action) => {
      state.loading = false;
    },
    fetchAllCustomers: (state, action) => {
      state.isLoadingCustomersData= true;
    },
    fetchAllCustomersSuccess: (state, action) => {
      const customers = action.payload?.payload;
      state.allCustomers = customers
      state.totalNumOfCustomers = action.payload?.otherParams?.noOfItems;
      state.isLoadingCustomersData = false;

      if (state.stateDropdown === null && state.cityDropdown === null) {
        const customerCopy = JSON.parse(JSON.stringify(customers)).filter(
          (obj) =>
            obj.city !== null &&
            obj.city !== undefined &&
            obj.state !== null &&
            obj.state !== undefined
        );
        const uniqueCity = customerCopy.reduce((acc, obj) => {
          // Check if city is already in the accumulator array
          if (!acc.some((item) => item.category === obj.city)) {
            acc.push({ id: obj.city, category: obj.city });
          }
          return acc;
        }, []);
        const uniqueState = customerCopy.reduce((acc, obj) => {
          // Check if city is already in the accumulator array
          if (!acc.some((item) => item.category === obj.state)) {
            acc.push({ id: obj.state, category: obj.state });
          }
          return acc;
        }, []);
        state.stateDropdown = uniqueState;
        state.cityDropdown = uniqueCity;
      }
    },
    fetchAllCustomersFailure: (state, action) => {
      state.isLoadingCustomersData = false;
    },
    fetchProfileImgURLArr: (state, action) => {
      state.loading = true;
    },
    fetchProfileImgURLArrSuccess: (state, action) => {
      state.loading = false;
      state.profileImgUrlsArr = action.payload;
    },
    fetchProfileImgURLArrFailure: (state, action) => {
      state.loading = false;
    },
    postCustomerData: (state, action) => {
      state.loading = true;
    },
    postCustomerDataSuccess: (state, action) => {
      state.loading = false;
      state.customerIds = action.payload;
    },
    postCustomerDataFailure: (state, action) => {
      state.loading = false;
    },
    getCustomerLinks: (state, action) => {
      state.loading = true;
    },
    getCustomerLinksSuccess: (state, action) => {
      state.loading = false;
      const data = { ...state.customerLinks };
      const { customerId, response } = action.payload;
      data[customerId] = response;
      state.customerLinks = data;
    },
    getCustomerLinksFailure: (state, action) => {
      state.loading = false;
    },
    addCustomersAddress: (state, action) => {
      state.loading = true;
    },
    addCustomerAddressesSuccess: (state, action) => {
      state.loading = false;
    },
    addCustomerAddressesFailure: (state, action) => {
      state.loading = false;
    },
    getCustomerDetails: (state, action) => {
      state.loading = true;
    },
    getCustomerDetailsSuccess: (state, action) => {
      state.loading = false;
      state.customerDetails = action.payload;
    },
    getCustomerDetailsFailure: (state, action) => {
      state.loading = false;
    },
    fetchCustomerAddresses: (state, action) => {
      state.loading = true;
    },
    fetchCustomerAddressesSuccess: (state, action) => {
      state.loading = false;
      const data = { ...state.customerAddress };
      const { customerId, response } = action.payload;
      data[customerId] = response;
      state.customerAddress = data;
    },
    fetchCustomerAddressesFailure: (state, action) => {
      state.loading = false;
    },
    getCustomerAddresses: (state, action) => {
      state.loading = true;
    },
    getCustomerAddressesSuccess: (state, action) => {
      state.loading = false;
      state.customerAddresses = action.payload;
    },
    getCustomerAddressesFailure: (state, action) => {
      state.loading = false;
    },
    clearStates: (state, action) => {},
    clearStateSuccess: (state, action) => {
      state.customerDetails = null;
      state.customerAddresses = null;
      state.customerLinks = {};
      state.avatarUrl = null;
    },
    clearWarehouseDetails: (state, action) => {
      state.warehouseDetails = null;
    },
    // clearAllStates: (state, action) => {},
    // clearAllStateSuccess: (state, action) => {
    //   state.allCustomers = [];
    //   state.profileImgUrlsArr = [];
    //   state.customerLinks = {};
    //   state.customerIds = {};
    //   state.customerDetails = null;
    //   state.customerAddress = {};
    //   state.customerAddresses = null;
    //   state.avatarUrl = null;
    //   state.stateDropdown = null;
    //   state.cityDropdown = null;
    //   state.allUsersForCustomer = null;
    //   state.allCustomerUserRoles = [];
    // },
    fetchAvatarURL: (state, action) => {
      state.loading = true;
    },
    getAvatarURLSuccess: (state, action) => {
      state.loading = false;
      state.avatarUrl = action.payload;
    },
    getAvatarURLFailure: (state, action) => {
      state.loading = false;
    },

    updateCustomerData: (state, action) => {
      state.loading = true;
    },
    updateCustomerDataSuccess: (state, action) => {
      state.loading = false;
    },
    updateCustomerDataFailure: (state, action) => {
      state.loading = false;
    },
    deleteCustomer: (state, action) => {
      state.loading = true;
    },
    deleteCustomerSuccess: (state, action) => {
      state.loading = false;
    },
    deleteCustomerFailure: (state, action) => {
      state.loading = false;
    },
    updateCustomerAddress: (state, action) => {
      state.loading = true;
    },
    updateCustomerAddressSuccess: (state, action) => {
      state.loading = false;
    },
    updateCustomerAddressFailure: (state, action) => {
      state.loading = false;
    },
    fetchChangeCustomerStatus: (state, action) => {
      state.loading = true;
    },
    fetchChangeCustomerStatusSuccess: (state, action) => {
      state.loading = false;
    },
    fetchChangeCustomerStatusFailure: (state, action) => {
      state.loading = false;
    },
    fetchCustomerUser: (state, action) => {
      state.loading = true;
    },
    fetchCustomerUserSuccess: (state, action) => {
      state.loading = false;
      state.allUsersForCustomer = action.payload;
    },
    fetchCustomerUserFailure: (state, action) => {
      state.loading = false;
    },
    fetchAllCustomerUserRoles: (state, action) => {
      state.loading = true;
    },
    fetchAllCustomerUserRolesSuccess: (state, action) => {
      state.loading = false;
      state.allCustomerUserRoles = action.payload;
    },
    fetchAllCustomerUserRolesFailure: (state, action) => {
      state.loading = false;
    },
    fetchCreateUserRequest: (state, action) => {
      state.loading = true;
    },
    fetchCreateUserSuccess: (state, action) => {
      state.loading = false;
    },
    fetchCreateUserFailure: (state, action) => {
      state.loading = false;
    },
    fetchLocationData: (state, action) => {
      state.loading = true;
    },
    fetchLocationDataSuccess: (state, action) => {
      state.loading = false;
      const data = { ...state.locations };
      const { id, response } = action.payload;
      data[id] = response;
      state.locations = data;
    },
    fetchLocationDataFailure: (state, action) => {
      state.loading = false;
    },
    clearLocationState: (state, action) => {},
    clearLocationStateSuccess: (state, action) => {
      state.locations = {};
    },
    fetchWarehouseDetailsRequest: (state, action) => {
      state.loading = true;
    },
    fetchWarehouseDetailsSuccess: (state, action) => {
      state.loading = false;
      state.warehouseDetails = action.payload;
    },
    fetchWarehouseDetailsFailure: (state, action) => {
      state.loading = false;
    },
    createClientLocation: (state, action) => {
      state.loading = true;
    },
    createClientLocationSuccess: (state, action) => {
      state.loading = false;
    },
    createClientLocationFailure: (state, action) => {
      state.loading = false;
    },
    updateClientLocation: (state, action) => {
      state.loading = true;
    },
    updateClientLocationSuccess: (state, action) => {
      state.loading = false;
    },
    upadateClientLocationFailure: (state, action) => {
      state.loading = false;
    },
    updateLocationStatus: (state, action) => {
      state.loading = true;
    },
    updateLocationStatusSuccess: (state, action) => {
      state.loading = false;
    },
    updateLocationStatusFailure: (state, action) => {
      state.loading = false;
    },
    deleteLocation: (state, action) => {
      state.loading = true;
    },
    deleteLocationSuccess: (state, action) => {
      state.loading = false;
    },
    deleteLocationFailure: (state, action) => {
      state.loading = false;
    },
    // delete additional Address
    deleteAdditionalAddress: (state, action) => {
      state.loading = true;
    },
    deleteAdditionalAddressSuccess: (state, action) => {
      state.loading = false;
      let copyCustomerAddresses = _.cloneDeep(state.customerAddresses);
      copyCustomerAddresses = copyCustomerAddresses.filter(
        (address) => address.isRegistered === true
      );
      state.customerAddresses = copyCustomerAddresses;
    },
    deleteAdditionalAddressFailure: (state, action) => {
      state.loading = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = customerPage;
