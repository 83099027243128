import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { actions } from "./slice";
import * as API from "services/apis";

//Generator Functions

export function* handleFetchCommoditiesData({ payload }) {
  try {
    const res = yield call(API.getCommoditiesData, payload.payload);
    const { data } = res;
    yield put(actions.fetchCommoditiesDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchCommoditiesDataFailure(err?.response?.data));
  }
}
export function* handleFetchWarehousesData({ payload }) {
  try {
    const res = yield call(API.getWarehouses, payload.payload);
    const { data } = res;
    yield put(actions.fetchWarehousesSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchWarehousesFailure(err?.response?.data));
  }
}

export function* handleFetchSubmittedInspectionsData({ payload }) {
  // //console.log(payload);
  try {
    const res = yield call(API.getInspection, payload.payload);
    const { data } = res;
    yield put(actions.fetchSubmittedInspectionDataSuccess(data.payload));
  } catch (error) {
    yield put(
      actions.fetchSubmittedInspectionDataFailure(error?.response?.data)
    );
  }
}

export function* handleFetchSubmittedDataWithoutPages({ payload }) {
  try {
    const res = yield call(API.getAddFilterDropdown, payload.payload);
    const { data } = res;
    yield put(actions.fetchSubmittedDataWithoutPagesSuccess(data.payload));
  } catch (error) {
    yield put(
      actions.fetchSubmittedDataWithoutPagesFailure(error?.response?.data)
    );
  }
}

export function* handleViewReport({ payload }) {
  try {
    const { source, fileName } = payload;
    const res = yield call(API.fetchSASUrl, {
      source,
      blobName: fileName,
    });
    const { url } = res.data;
    // //console.log(JSON.stringify(url));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    document.body.removeChild(link);
    yield put(actions.viewReportSuccess());
  } catch (err) {
    yield put(actions.viewReportFailure());
  }
}
export function* handleClearState() {
  try {
    yield put(actions.clearStateSuccess());
  } catch (err) {
    yield put(actions.clearStateFailure(err));
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export function* myApprovalSaga() {
  yield takeLatest(
    actions.fetchCommoditiesData.type,
    handleFetchCommoditiesData
  );
  yield takeLatest(actions.fetchWarehouses.type, handleFetchWarehousesData);
  yield takeLatest(
    actions.fetchSubmittedInspectionData.type,
    handleFetchSubmittedInspectionsData
  );
  yield takeLatest(
    actions.fetchSubmittedDataWithoutPages.type,
    handleFetchSubmittedDataWithoutPages
  );
  yield takeLatest(actions.clearState.type, handleClearState);
}
