import React, { useState } from "react";
import {
  HStack,
  VStack,
  Text,
  Box,
  useMediaQuery,
  IconButton,
} from "@chakra-ui/react";
import {
  ChevronDownIcon as CollapseIcon,
  ChevronRightIcon as ExpandIcon,
} from "@chakra-ui/icons";
import Element from "../element";
import { MdArrowForwardIos } from "react-icons/md";

export default function Section({
  section,
  data,
  formData,
  handleFormDataChange,
  displayData,
  setPage,
  navIndex,
  setNavIndex,
  formErrorData,
  tempError,
  prevPages,
  setSkippedPages,
  page,
}) {
  const [isMobileScreen] = useMediaQuery("(max-width: 872px)");
  const [isSectionHidden, setIsSectionHidden] = useState(false);

  const handleToggleSectionHidden = () => {
    setIsSectionHidden((prev) => {
      return !prev;
    });
  };
  return (
    <VStack w="100%" aria-label="section" gap="24px">
      <HStack
        style={{
          background: "#EDF2F7",
          borderRadius: "8px",
          padding: "8px 16px 8px 8px",
          width: "100%",
        }}
        justify="space-between"
        align="flex-start"
      >
        <HStack justify="center" align="center">
          <IconButton
            variant="ghost"
            borderRadius="8px"
            // isDisabled={index < 1}
            sx={{
              ".icon": {
                color: "#718096",
              },
            }}
            icon={
              isSectionHidden ? (
                <MdArrowForwardIos w="26px" h="26px" className="icon" />
              ) : (
                <CollapseIcon w="26px" h="26px" className="icon" />
              )
            }
            onClick={handleToggleSectionHidden}
          />
          <HStack align="center">
            <Text
              color="#4A5568"
              fontSize={isMobileScreen ? "16px" : "22px"}
              fontWeight="600"
              lineHeight="1.4"
            >
              {section.title}
            </Text>
            <Text
              color="#718096"
              fontSize={isMobileScreen ? "10px" : "12px"}
              fontWeight="400"
              lineHeight="1.4"
            >
              {section.description}
            </Text>
          </HStack>
        </HStack>
        {/* <Text color="#4A5568" fontSize="16px" fontWeight="400" lineHeight="1.4">
          0 / 1 (0%)
        </Text> */}
      </HStack>
      {data.map((el, index) => {
        const elements = el.component.structure?.elements;
        return (
          <>
            <VStack
              w="100%"
              gap="16px"
              key={index}
              sx={isSectionHidden ? { display: "none" } : {}}
              style={{
                padding: "20px",
                width: "100%",
                border: "1.5px solid #CBD5E0",
                borderRadius: "15px",
                background: "#FFF",
                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
              }}
              aria-label="component"
            >
              {elements.map((element) => {
                return (
                  <Box
                    aria-label="element"
                    key={element.id}
                    style={{
                      padding: "20px",
                      width: "100%",
                      border: "1.5px solid #CBD5E0",
                      borderRadius: "15px",
                      background: "#FFF",
                      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <Text
                      aria-label="element label"
                      color="#2D3748"
                      fontSize="16px"
                      fontWeight="500"
                      lineHeight="1.4"
                    >
                      {element.label}
                      {element.mandatory ? (
                        <span
                          style={{
                            color: "#D93025",
                            paddingLeft: "6px",
                            fontSize: "20px",
                          }}
                        >
                          *
                        </span>
                      ) : (
                        <></>
                      )}
                    </Text>
                    <Element
                      elementType={element.htmlType}
                      formData={formData}
                      handleFormDataChange={handleFormDataChange}
                      data={{ element }}
                      setPage={setPage}
                      displayData={displayData}
                      navIndex={navIndex}
                      setNavIndex={setNavIndex}
                      formErrorData={formErrorData}
                      setSkippedPages={setSkippedPages}
                      prevPages={prevPages}
                      page={page}
                    />
                    {element?.supportingText ? (
                      <Text
                        aria-label="element label"
                        color="#718096"
                        fontSize="12px"
                        fontWeight="400"
                        lineHeight="1.4"
                      >
                        {element.supportingText}
                      </Text>
                    ) : null}
                    {/* range text  */}
                    {element?.range ? (
                      <Text
                        aria-label="element label"
                        color="#718096"
                        fontSize="12px"
                        fontWeight="400"
                        lineHeight="1.4"
                      >
                        Please Enter between {element?.range?.from} and{" "}
                        {element?.range?.to}
                      </Text>
                    ) : null}
                  </Box>
                );
              })}
            </VStack>
          </>
        );
      })}
    </VStack>
  );
}
