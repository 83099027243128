import { call, put, takeLatest, takeEvery, delay } from "redux-saga/effects";
import { formStateManager } from 'utils/formStateManager';
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "services/index";
import * as API1 from "../../../../services/apis";
import { downloadImage } from "utils/commonFunctions";

export function* handleFetchReviewData({ payload }) {
  try {
    const res = yield call(API.getData, payload);
    const { data } = res;
    yield put(actions.fetchReviewDataSuccess(data.payload));
  } catch (err) {
    //console.log(err);
    yield put(actions.fetchReviewDataFailure(err?.response?.data));
  }
}
export function* handleFetchFormData({ payload }) {
  try {
    const res = yield call(API.fetchFormData, payload);
    const { data } = res;
    yield delay(100);
    yield put(actions.fetchFormDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchFormDataFailure(err?.response?.data));
  }
}

export function* handlePostWorkflowData({ payload }) {
  try {
    const res = yield call(API.postFormData, payload.data);
  } catch (err) {
    console.log({ err });
    yield put(actions.postWorkflowDataFailure(err?.response?.data));
  }
}

export function* handleDownloadImage({ payload }) {
  try {
    const { source, imageName } = payload;
    const res = yield call(API1.fetchSASUrl, {
      source,
      blobName: imageName,
    });
    downloadImage(res.data.url, imageName);
    yield put(actions.downloadImageSuccess());
  } catch (err) {
    yield put(actions.downloadImageFailure());
  }
}

export function* handleFetchPreview({ payload }) {
  try {
    const previewData = yield call(API1.previewGenerator, payload);
    const res = yield call(API1.fetchSASUrl, {
      source: { name: process.env.REACT_APP_SAS_URL_GENERATOR_SOURCE },
      blobName: previewData.data.payload.name,
    });
    let { url } = res?.data;
    if (url) {
      yield put(actions.fetchPreviewSuccess());
      window.open(url);
    } else {
      yield put(actions.fetchPreviewFailure());
      payload.onFailureReport();
    }
  } catch (err) {
    console.log(err);
    yield put(actions.fetchPreviewFailure());
    payload.onFailureReport();
  }
}
export function* handlePostFileData({ payload }) {
  const { fileName } = payload;
  try {
    const sasURLData = yield call(API.fetchSasurl, { fileName });
    const sasURL = sasURLData && sasURLData.data.payload.url;
    const res = yield call(API.uploadFile, { ...payload, sasURL });
    if (res.url) {
      payload.onSuccess();
    }
    yield put(actions.postFileDataSuccess({ ...payload, url: res.url }));
  } catch (err) {
    console.log(err);
    payload.onFailure();
    yield put(
      actions.postFileDataFailure({ err: err?.response?.data, payload })
    );
  }
}

export function* handleFetchAllUrls({ payload }) {
  try {
    const { fileNames } = payload;
    const res = yield call(API1.getAllUrls, fileNames);
    yield put(actions.fetchAllUrlSuccess({ id: payload.id, urls: res }));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchAllUrlFailure());
  }
}

export function* handleFetchDonePreview({payload}) {
  try {
    let res1;
    const formState = formStateManager.getFormState(payload.inspectionId);
    if (!(formState && formState.changesSaved)) {

      const res = yield call(API1.getInspectionReport, payload);
      console.log(res)
      if(res?.data?.payload.length == 0){

        const previewData = yield call(API1.previewGenerator, payload)
        const newRes = yield call(API1.fetchSASUrl, {
          source: { name: process.env.REACT_APP_SAS_URL_GENERATOR_SOURCE },
         blobName: previewData?.data?.payload?.name,
        })
        let {url} = newRes?.data;
        if(url) {
          window.open(url)
          yield put(actions.genReportSuccess());
        }
        else {
          yield put(actions.genReportFailure());
          payload.onFailure();
        }
      }
      else{
        const {name} = res?.data?.payload[0];
        res1 = yield call(API1.fetchSASUrl, {
          source: { name: process.env.REACT_APP_SAS_URL_GENERATOR_SOURCE },
          blobName: name,
        })
  
        if(res1?.data?.url != null) {
          yield put(actions.fetchDonePreviewSuccess())
        window.open(res1.data.url)
        
      }
      else {
         yield put(actions.fetchDonePreviewFailure())
         payload.onFailureReport()
        }
      }
      
    }
    else {
      const previewData = yield call(API1.previewGenerator, payload)
        const newRes = yield call(API1.fetchSASUrl, {
          source: { name: process.env.REACT_APP_SAS_URL_GENERATOR_SOURCE },
         blobName: previewData?.data?.payload?.name,
        })
        let {url} = newRes?.data;
        if(url) {
          window.open(url)
          yield put(actions.genReportSuccess());
        }
        else {
          yield put(actions.genReportFailure());
          payload.onFailure();
        }
    }
   
   
  }
  catch (err) {
      console.log(err)
      yield put(actions.fetchDonePreviewFailure())
      payload.onFailureReport()
  }
}

export function* handleGenReport({payload}) {
  try{
    const previewData = yield call(API1.previewGenerator, payload)
    const res = yield call(API1.fetchSASUrl, {
      source: { name: process.env.REACT_APP_SAS_URL_GENERATOR_SOURCE },
     blobName: previewData?.data?.payload?.name,
    })
    let {url} = res?.data;
    if(url) {
      window.open(url)
      yield put(actions.genReportSuccess());
      payload.onSuccess();
    }
    else {
      yield put(actions.genReportFailure());
      payload.onFailure();
    }
  }
  catch(err) {
     yield put(actions.genReportFailure());
     payload.onFailure();
   }
  }
// Auditor location tracking
export function* handleFetchTrackLocation({ payload }) {
  try {
    const res = yield call(API1.getAuditorTrackLocation, payload);
    const { data } = res;
    yield put(actions.fetchTrackLocationSuccess(data?.payload));
  } catch (err) {
    yield put(actions.fetchTrackLocationsFailure(err));
  }
}

// Get origin location
export function* handleFetchOriginLocation({ payload }) {
  try {
    const res = yield call(API1.getOriginLocation, payload);
    const { data } = res;
    yield put(actions.fetchOriginLocationSuccess(data?.payload));
  } catch (err) {
    yield put(actions.fetchOriginLocationFailure(err));
  }
}

// Get Auditor Avatar
export function* handleFetchAuditorAvatarURL({ payload }) {
  try {
    const res = yield call(API1.getPreDefinedURL, payload.payload);
    const { data } = res;
    yield put(actions.fetchAuditorAvatarURLSuccess(data?.payload));
  } catch (err) {
    yield put(actions.fetchAuditorAvatarURLFailure(err));
  }
}
// Get Client Avatar
export function* handleFetchClientAvatarURL({ payload }) {
  try {
    const res = yield call(API1.getPreDefinedURL, payload.payload);
    const { data } = res;
    yield put(actions.fetchClientAvatarURLSuccess(data?.payload));
  } catch (err) {
    yield put(actions.fetchClientAvatarURLFailure(err));
  }
}

/***
 * Root saga manages watcher lifecycle
 ***/

export function* workflowDataSaga() {
  yield takeEvery(actions.postWorkflowData.type, handlePostWorkflowData);
  yield takeEvery(actions.fetchFormData.type, handleFetchFormData);
  yield takeLatest(actions.fetchReviewData.type, handleFetchReviewData);
  yield takeLatest(actions.downloadImage.type, handleDownloadImage);
  yield takeLatest(actions.fetchPreview.type, handleFetchPreview);
  yield takeEvery(actions.postFileData.type, handlePostFileData);
  yield takeEvery(actions.getAllUrls.type, handleFetchAllUrls);
  yield takeLatest(actions.fetchDonePreview.type, handleFetchDonePreview);
  yield takeLatest(actions.genReport.type, handleGenReport);
  yield takeLatest(actions.fetchTrackLocation.type, handleFetchTrackLocation);
  yield takeLatest(actions.fetchOriginLocation.type, handleFetchOriginLocation);
  yield takeLatest(
    actions.fetchAuditorAvatarURL.type,
    handleFetchAuditorAvatarURL
  );
  yield takeLatest(
    actions.fetchClientAvatarURL.type,
    handleFetchClientAvatarURL
  );
}
