import {
  summedData,
  getPercentage,
  formatNumber,
  formattedDates,
  convertMonthNumberToName,
  uniqueIdsArray,
  getDataArray,
  arraySum,
  getMonthArray,
  weekStartingDates,
  weekWiseArray,
  getUniqueObjectsByProperty,
} from "utils/commonFunctions";
import bagImage from "assets/svg/bag.svg";
import commoditiesAudited from "assets/svg/Group 6 (1).svg";
import locationImage from "assets/svg/pin.svg";
import auditImage from "assets/svg/AiOutlineFileSearch.svg";
import { addDays, format } from "date-fns";

const nccfSummaryTableData = {
  summaryTableProcurement: {
    headerList: [
      {
        displayText: "To Convert To",
        keyText: "To Convert",
      },
      {
        displayText: "Into",
        keyText: "Into",
      },
      {
        displayText: "Multiply By",
        keyText: "Multiply By",
        hasHeatmap: true,
        heatmapColor: { r: 32, g: 183, b: 32 },
      },
      {
        displayText: "Date",
        keyText: "Date",
      },
      { keyText: "auditorName", displayText: "Auditor Name" },
      {
        keyText: "value",
        displayText: "Value",
        hasHeatmap: true,
        heatmapColor: { r: 166, g: 32, b: 32 },
      },
    ],

    dataList: [
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 30.48,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 32,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 12,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 22,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 21,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 12,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 15,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 5,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 2,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 2,
      },
    ],
  },
  summaryTableLoading: {
    headerList: [
      {
        displayText: "To Convert To",
        keyText: "To Convert",
      },
      {
        displayText: "Into",
        keyText: "Into",
      },
      {
        displayText: "Multiply By",
        keyText: "Multiply By",
        hasHeatmap: true,
        heatmapColor: { r: 32, g: 183, b: 32 },
      },
      {
        displayText: "Date",
        keyText: "Date",
      },
      { keyText: "auditorName", displayText: "Auditor Name" },
      {
        keyText: "value",
        displayText: "Value",
        hasHeatmap: true,
        heatmapColor: { r: 166, g: 32, b: 32 },
      },
    ],

    dataList: [
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 30.48,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 40,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 12,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 22,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 21,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 12,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 15,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 5,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 2,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 2,
      },
    ],
  },
  summaryTableUnloading: {
    headerList: [
      {
        displayText: "To Convert To",
        keyText: "To Convert",
      },
      {
        displayText: "Into",
        keyText: "Into",
      },
      {
        displayText: "Multiply By",
        keyText: "Multiply By",
        hasHeatmap: true,
        heatmapColor: { r: 32, g: 183, b: 32 },
      },
      {
        displayText: "Date",
        keyText: "Date",
      },
      { keyText: "auditorName", displayText: "Auditor Name" },
      {
        keyText: "value",
        displayText: "Value",
        hasHeatmap: true,
        heatmapColor: { r: 166, g: 32, b: 32 },
      },
    ],

    dataList: [
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 30.48,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 29,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 12,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 22,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 21,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 12,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 15,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 5,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 2,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 2,
      },
    ],
  },
};

const chartData = [
  {
    name: "Jan",
    "Quantity Inspected (MT)": 3900,
    "Quantity Accepted (MT)": 300,
    "Quantity Unaccepted (MT)": 3600,
  },
  {
    name: "Feb",
    "Quantity Inspected (MT)": 3680,
    "Quantity Accepted (MT)": 380,
    "Quantity Unaccepted (MT)": 3300,
  },
  {
    name: "March",
    "Quantity Inspected (MT)": 4100,
    "Quantity Accepted (MT)": 345,
    "Quantity Unaccepted (MT)": 3755,
  },
  {
    name: "April",
    "Quantity Inspected (MT)": 3800,
    "Quantity Accepted (MT)": 450,
    "Quantity Unaccepted (MT)": 3350,
  },
  {
    name: "May",
    "Quantity Inspected (MT)": 4100,
    "Quantity Accepted (MT)": 370,
    "Quantity Unaccepted (MT)": 3730,
  },
  {
    name: "June",
    "Quantity Inspected (MT)": 3333,
    "Quantity Accepted (MT)": 333,
    "Quantity Unaccepted (MT)": 3000,
  },
  {
    name: "July",
    "Quantity Inspected (MT)": 3553,
    "Quantity Accepted (MT)": 353,
    "Quantity Unaccepted (MT)": 3200,
  },
  {
    name: "Aug",
    "Quantity Inspected (MT)": 3700,
    "Quantity Accepted (MT)": 380,
    "Quantity Unaccepted (MT)": 3320,
  },
  {
    name: "Sep",
    "Quantity Inspected (MT)": 3600,
    "Quantity Accepted (MT)": 300,
    "Quantity Unaccepted (MT)": 3300,
  },
  {
    name: "Oct",
    "Quantity Inspected (MT)": 2940,
    "Quantity Accepted (MT)": 120,
    "Quantity Unaccepted (MT)": 2820,
  },
  {
    name: "Nov",
    "Quantity Inspected (MT)": 3200,
    "Quantity Accepted (MT)": 200,
    "Quantity Unaccepted (MT)": 3000,
  },
  {
    name: "Dec",
    "Quantity Inspected (MT)": 3000,
    "Quantity Accepted (MT)": 100,
    "Quantity Unaccepted (MT)": 2900,
  },
];

function tempFormateChartData() {
  return chartData.map((dataItem) => ({
    ...dataItem,
    "Trucks Inspected": dataItem["Quantity Inspected (MT)"],
    "Trucks Accepted": dataItem["Quantity Accepted (MT)"],
    "Trucks Unaccepted": dataItem["Quantity Unaccepted (MT)"],
  }));
}

const dataAdapter = (
  state,
  widgetRef,
  selectedDataType,
  selectedDateType,
  dataSource
) => {
  switch (widgetRef) {
    case "barChart": {
      return {
        chartData:
          dataSource === "barChartProcurement"
            ? chartData
            : tempFormateChartData(chartData),
        title: "Summary Analysis based on Quantity (MT)",
        dataKeys:
          dataSource === "barChartProcurement"
            ? {
                key1: "Quantity Inspected (MT)",
                key2: "Quantity Accepted (MT)",
                key3: "Quantity Unaccepted (MT)",
              }
            : {
                key1: "Trucks Inspected",
                key2: "Trucks Accepted",
                key3: "Trucks Unaccepted",
              },
      };
    }
    case "tableSimple": {
      return nccfSummaryTableData[dataSource];
    }
    case "numberOfInspections1": {
      return {
        value: 1197,
      };
    }
    case "quantityInspected1": {
      return {
        value: 48745.71,
      };
    }
    case "quantityAccepted1": {
      return {
        value: 275.92,
      };
    }
    case "quantityUnaccepted1": {
      return {
        value: 48469.79,
      };
    }
    case "trucksInspected1": {
      return {
        value: 2140,
      };
    }
    case "trucksAccepted1": {
      return {
        value: 10,
      };
    }
    case "trucksUnaccepted1": {
      return {
        value: 2128,
      };
    }
    case "samplingNotDone1": {
      return {
        value: 4,
      };
    }
    case "quantityInspected2": {
      return {
        value: 65522.79,
      };
    }
    case "quantityAccepted2": {
      return {
        value: 290.03,
      };
    }
    case "quantityUnaccepted2": {
      return {
        value: 65173.55,
      };
    }
    case "bagsInspected1": {
      return {
        value: 1267258,
      };
    }

    case "trucksInspected2": {
      return {
        value: 1186,
      };
    }
    case "trucksAccepted2": {
      return {
        value: 8,
      };
    }
    case "trucksUnaccepted2": {
      return {
        value: 1178,
      };
    }

    case "samplingNotDone2": {
      return {
        value: 0,
      };
    }
    case "quantityInspected3": {
      return {
        value: 34992.46,
      };
    }
    case "quantityAccepted3": {
      return {
        value: 229.65,
      };
    }
    case "quantityUnaccepted3": {
      return {
        value: 34762.81,
      };
    }
    case "bagsInspected2": {
      return {
        value: 704132,
      };
    }

    // above code for NCCF
    case "clientName": {
      const clientData = state.clientsOfCustomer || [];
      const clientNames = clientData?.map((obj) => {
        return { id: obj.clientId, value: obj.clientName };
      });
      return clientNames;
    }
    case "commodity": {
      const commoditiesData = state.commoditiesData || [];
      const commodityNames = commoditiesData?.filter((obj) => {
        if (obj.name !== null && obj.name !== undefined) {
          return obj.name;
        }
      });

      return commodityNames;
    }
    case "location": {
      const warehouseData = state.warehousesData;
      const uniqueObjects =
        getUniqueObjectsByProperty(warehouseData, "city") || [];

      return uniqueObjects;
    }
    case "dateRangePicker": {
    }

    case "totalInspections": {
      const inspectionsLineChartData = state?.inspectionsLineChartData || [];
      const barChartData = state?.barChartData || [];
      const insightsGraphCardLoading = state?.portfolioLineChartLoading;

      const inspectionsData = getDataArray(
        inspectionsLineChartData,
        selectedDateType,
        "inspectionCount"
      );
      const inspectionsSum = arraySum(inspectionsData);

      const inspectionsLabel =
        selectedDateType === "day" || selectedDateType === "month"
          ? getMonthArray(inspectionsLineChartData, selectedDateType)
          : weekStartingDates(
              weekWiseArray(inspectionsLineChartData, "inspectionCount")
            );

      const insight = {
        title: "Total Inspections",
        amount: inspectionsSum || 0,
        icon: auditImage,
        labels: inspectionsLabel,
        data: inspectionsData,
        percentage: getPercentage(
          inspectionsLineChartData,
          inspectionsSum,
          selectedDateType,
          "inspectionCount",
          "Inspections"
        ),
      };

      return { insight, insightsGraphCardLoading, barChartData };
    }

    case "quantityInspected": {
      const inspectionsLineChartData = state?.inspectionsLineChartData || [];
      const quantityLineChartData = state?.quantityLineChartData || [];
      const barChartData = state?.barChartData || [];
      const insightsGraphCardLoading = state?.portfolioLineChartLoading;

      const quantityLabel =
        selectedDateType === "day" || selectedDateType === "month"
          ? getMonthArray(quantityLineChartData, selectedDateType)
          : weekStartingDates(
              weekWiseArray(inspectionsLineChartData, "totalWeight")
            );

      const quantityData = getDataArray(
        quantityLineChartData,
        selectedDateType,
        "totalWeight"
      );

      const quantitySum = arraySum(quantityData);

      const insight = {
        title: "Quantity Inspected (MT)",
        amount: isNaN(quantitySum)
          ? formatNumber(0, "Quantity Inspected (MT)")
          : formatNumber(quantitySum, "Quantity Inspected (MT)") || 0,
        icon: locationImage,
        labels: quantityLabel,
        data: quantityData,
        percentage: getPercentage(
          quantityLineChartData,
          quantitySum,
          selectedDateType,
          "totalWeight",
          "Quantity"
        ),
      };

      return { insight, insightsGraphCardLoading, barChartData };
    }

    case "numberOfBags": {
      const analyticsData = state?.analyticsData || [];
      const analyticsLoading = state?.analyticsLoading;

      const bagSum =
        (analyticsData.length > 0 && summedData(analyticsData)) || [];
      const insight = bagSum?.hasOwnProperty("totalNoOfBags")
        ? {
            title: "No of Bags",
            amount: formatNumber(bagSum?.totalNoOfBags, "No of Bags"),
            icon: bagImage,
            percentage: getPercentage(
              analyticsData,
              bagSum?.totalNoOfBags,
              selectedDateType,
              "totalNoOfBags",
              "Bags"
            ),
          }
        : {
            title: "No of Bags",
            amount: formatNumber(0, "No of Bags"),
            icon: bagImage,
            percentage: getPercentage(
              analyticsData,
              0,
              selectedDateType,
              "totalNoOfBags",
              "Bags"
            ),
          };

      return { insight, analyticsLoading };
    }

    case "commodities": {
      const analyticsData = state?.analyticsData || [];
      const analyticsLoading = state?.analyticsLoading;
      const insight = {
        title: "Commodities",
        amount: uniqueIdsArray(analyticsData, "commodities"),
        icon: commoditiesAudited,
        percentage: getPercentage(
          analyticsData,
          uniqueIdsArray(analyticsData, "commodities"),
          selectedDateType,
          "commodities",
          "Commodity"
        ),
      };

      return { insight, analyticsLoading };
    }

    case "sitesInspected": {
      const analyticsData = state?.analyticsData || [];
      const analyticsLoading = state?.analyticsLoading;

      const insight = {
        title: "Sites Inspected",
        amount: uniqueIdsArray(analyticsData, "originInspected"),
        icon: locationImage,
        percentage: getPercentage(
          analyticsData,
          uniqueIdsArray(analyticsData, "originInspected"),
          selectedDateType,
          "originInspected",
          "Sites"
        ),
      };

      return { insight, analyticsLoading };
    }

    case "inspectionCount": {
      const barChartData = state?.barChartData || [];
      let labels = [];
      let data = [];

      if (barChartData?.length > 0) {
        if ("week" in barChartData[0]) {
          labels = formattedDates(barChartData, "weekStartTimestamp");
        } else if ("day" in barChartData[0]) {
          labels = barChartData.map((item) => {
            if (item.month && item.year) {
              return `${item.day} ${convertMonthNumberToName(
                item.month,
                item.year
              )}`;
            }
          });
        } else {
          labels = barChartData.map((item) => {
            if (item.month && item.year) {
              return `${convertMonthNumberToName(item.month, item.year)}`;
            }
          });
        }
        data = barChartData.map((item) => {
          if (item[selectedDataType]) {
            return parseInt(item[selectedDataType]);
          }
        });
      }
      return { barChartData, data, labels };
    }

    case "commodityHealth": {
      const doughnutChartData = state?.doughnutChartData || {};
      const colorMap = { average: "#FFB783", good: "#39AEA3", bad: "#FF6D6D" };
      const selectedKeys = Object.keys(doughnutChartData).filter(
        (key) => doughnutChartData[key] !== 0
      );

      const chartArr = [];
      for (let key in doughnutChartData) {
        if (selectedKeys.includes(key)) {
          chartArr.push(doughnutChartData[key]);
        }
      }

      const selectedColors = selectedKeys.map((key) => colorMap[key]);
      return { chartArr, selectedColors, colorMap };
    }

    case "measuredCommodities": {
      let quantityInsightsData = state?.quantityInsightsData || [];
      quantityInsightsData = quantityInsightsData?.map((obj) => {
        const resKeys = ["quantity", "commodity", "noOfBags"];

        const transformData = (val) => {
          if (_.isNumber(val) && !_.isInteger(val)) return val.toFixed(2);
          if (_.isString(val)) return _.capitalize(val);
          return val;
        };
        return resKeys.reduce((acc, cv) => {
          acc[cv] =
            (obj && obj[cv] && obj[cv] === "") ||
            obj[cv] === null ||
            obj[cv] === undefined ||
            obj[cv] === 0
              ? "-"
              : transformData(obj[cv]);
          return acc;
        }, {});
      });

      return { quantityInsightsData };
    }

    case "warehouseScore": {
      const warehouseScore = state?.warehouseScore || [];
      return { warehouseScore };
    }

    case "geographicalReach": {
      const mapChartData = state?.mapChartData || [];
      return { mapChartData };
    }

    default:
      return null;
  }
};

export default dataAdapter;
