import React from "react";
import { CircularProgress, HStack } from "@chakra-ui/react";
import Insights from "../Insights";
import { uniqueIdsArray, getPercentage } from "utils/commonFunctions";
import locationImage from "assets/svg/pin.svg";
function SitesInspected({ data }) {
  const { insight, analyticsLoading } = data;
  if (analyticsLoading) {
    return (
      <CircularProgress
        isIndeterminate
        color="#4FD1C5"
        size="40px"
        alignSelf={"center"}
      />
    );
  }

  // const insight = {
  //   title: "Sites Inspected",
  //   amount: uniqueIdsArray(analyticsData, "originInspected"),
  //   icon: locationImage,
  //   percentage: getPercentage(
  //     analyticsData,
  //     uniqueIdsArray(analyticsData, "originInspected"),
  //     selectedDateType,
  //     "originInspected",
  //     "Sites"
  //   ),
  // };

  return (
    <HStack flexWrap={"wrap"} gap={{ base: "16px", md: "24px" }} w={"100%"}>
      <Insights
        pVal={"20px"}
        minWidthValue={"364px"}
        isRow={true}
        title={insight.title}
        loading={analyticsLoading}
        // error={error}
        amount={insight.amount}
        icon={
          <img
            src={insight.icon}
            // style={imageStyle}
            alt={`Icon`}
          />
        }
        percentage={insight.percentage}
      />
    </HStack>
  );
}

export default SitesInspected;
