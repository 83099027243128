import React from "react";
import ToggleInsightsSvg from "assets/svg/FiBarChart.svg";
import "../index.css";
import { Box } from "@chakra-ui/react";
function ToggleInsights({ handleIsHide }) {
  return (
    <Box
      className="ColumnFilterContainer"
      padding="0px 10px"
      bg="#F7FAFC"
      onClick={handleIsHide}
    >
      <img src={ToggleInsightsSvg}></img>
    </Box>
  );
}

export default ToggleInsights;
