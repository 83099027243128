import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { RangePicker } from "@agnext/reactlib";
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  subDays,
  startOfMonth,
  startOfYear,
  endOfMonth,
  endOfYear,
  subMonths,
  subWeeks,
  getYear,
} from "date-fns";
import "../index.css";
import "./DateRangePicker.css";

const DateRangePicker = ({
  width,
  filters,
  handleFromDateChange,
  handleToDateChange,
}) => {
  const presetDateRanges = [
    "Today",
    "Yesterday",
    "This Week",
    "Last Week",
    "This Month",
    "Last Month",
    "Kharif Season",
    "Rabi Season",
    "View All",
  ];

  const currentYear = getYear(new Date());
  const handlePresetDateRangeClick = (range) => {
    const dateRangeMap = {
      Today: { fromDate: startOfDay(new Date()), toDate: endOfDay(new Date()) },
      Yesterday: {
        fromDate: startOfDay(subDays(new Date(), 1)),
        toDate: endOfDay(subDays(new Date(), 1)),
      },
      "This Week": {
        fromDate: startOfWeek(new Date()),
        toDate: endOfDay(new Date()),
      },
      "Last Week": {
        fromDate: startOfWeek(subWeeks(new Date(), 1)),
        toDate: endOfWeek(subWeeks(new Date(), 1)),
      },
      "This Month": {
        fromDate: startOfMonth(new Date()),
        toDate: endOfDay(new Date()),
      },
      "Last Month": {
        fromDate: startOfMonth(subMonths(new Date(), 1)),
        toDate: endOfMonth(subMonths(new Date(), 1)),
      },
      "Kharif Season": {
        fromDate: startOfMonth(new Date(currentYear, 6, 1)),
        toDate: endOfMonth(new Date(currentYear, 10, 30)),
      },
      "Rabi Season": {
        fromDate: startOfMonth(new Date(currentYear, 10, 1)),
        toDate: endOfMonth(new Date(currentYear + 1, 2, 31)),
      },
      "View All": { fromDate: null, toDate: null },
    };

    const { fromDate, toDate } = dateRangeMap[range] || {
      fromDate: null,
      toDate: null,
    };

    handleFromDateChange(fromDate);
    handleToDateChange(toDate);
  };

  return (
    <>
      <Box width={width}>
        <RangePicker
          width={"100%"}
          fromDate={filters.fromDate}
          toDate={filters.toDate}
          setFromDate={handleFromDateChange}
          setToDate={handleToDateChange}
          hasButtons={true}
          backgroundSelected="#4FD1C5"
          colorSelected="#fff"
          leftComponent={
            <Box>
              {presetDateRanges.map((item, index) => (
                <Text
                  className="dynamic-dates"
                  key={index}
                  onClick={() => handlePresetDateRangeClick(item)}
                >
                  {item}
                </Text>
              ))}
            </Box>
          }
        />
      </Box>
    </>
  );
};

export default DateRangePicker;
