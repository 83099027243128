import { useState, useRef } from "react";
import {
  Box,
  Flex,
  Badge,
  CloseButton,
  IconButton,
  Text,
} from "@chakra-ui/react";
import "./index.css";
import { SendEmailIcon } from "components/Icons/Icons";
import { actions } from "../../views/Dashboard/Inspection Table/slice";
import { useDispatch } from "react-redux";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { Loader } from "components/Loader/Loader";

const MultiEmailInput = ({
  inspectionId,
  showSuccess,
  showError,
  handleShowSuccess,
  handleShowError,
}) => {
  const [emails, setEmails] = useState([]);
  const [currentEmail, setCurrentEmail] = useState("");
  const [isEmailValid, setEmailValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleKeyDown = (event) => {
    handleShowSuccess(false);
    handleShowError("");
    setEmailValid(true);

    if (event.key === "Enter") {
      const trimmedEmail = currentEmail.trim();
      if (emailPattern.test(trimmedEmail)) {
        // Handle new email
        if (!emails.includes(trimmedEmail)) {
          setEmails([...emails, trimmedEmail]);
          setCurrentEmail("");
          setEmailValid(true);
          handleShowError("");
        } else {
          // Handle duplicate email address
          handleShowSuccess("");
          handleShowError("Email already added.");
          setEmailValid(true);
        }
      } else {
        setEmailValid(false);
        handleShowSuccess("");
      }
    }
  };

  const handlEmailAdd = (e) => {
    setCurrentEmail(e.target.value);
    handleShowSuccess(false);
    handleShowError("");
  };

  const handleEmailSend = () => {
    setIsLoading(true);
    handleShowSuccess(false);
    handleShowError("");
    const trimmedEmail = currentEmail.trim();
    const emailToSend = [];
    if (trimmedEmail.length !== 0) {
      if (emailPattern.test(trimmedEmail)) {
        if (!emails.includes(trimmedEmail)) {
          emailToSend.push(trimmedEmail);
          // Clear input if it's a valid email
        } else {
          // Handle duplicate email address
          handleShowSuccess("");
          handleShowError("Email already added.");
          setIsLoading(false);
          setEmailValid(true);
          return;
        }
      } else {
        setIsLoading(false);
        setEmailValid(false);
        handleShowSuccess("");
        return; // Do not proceed with sending emails if the input is invalid
      }
    }

    if (emails.length) {
      emailToSend.push(...emails);
    }

    if (emailToSend.length) {
      const payload = {
        data: {
          to: emailToSend,
          inspectionId,
          emailType: "sendReport",
          sourceName: process.env.REACT_APP_SAS_URL_GENERATOR_SOURCE,
        },
        onSuccess: () => {
          handleShowSuccess(true);
          handleShowError("");
          setEmailValid(true);
          setEmails([]);
          setCurrentEmail("");
          setIsLoading(false);
        },
        onFailure: (err) => {
          handleShowError(err);
          handleShowSuccess("");
          setIsLoading(false);
        },
      };
      dispatch(actions.sendEmail(payload));
    }
  };

  const handleRemoveEmail = (emailToRemove) => {
    const updatedEmails = emails.filter((email) => email !== emailToRemove);
    setEmails(updatedEmails);
  };

  const handleBoxClick = (event) => {
    if (!event.target.classList.contains("email-badge")) {
      inputRef.current.focus();
    }
  };

  return (
    <>
      <Box className="email-input-container" onClick={handleBoxClick}>
        <Flex flexDirection="column">
          {emails.map((email, index) => (
            <Badge key={index} className="email-badge" variant="subtle">
              {email}
              <CloseButton size="sm" onClick={() => handleRemoveEmail(email)} />
            </Badge>
          ))}
        </Flex>
        <input
          ref={inputRef}
          type="text"
          value={currentEmail}
          onChange={(e) => handlEmailAdd(e)}
          onKeyDown={handleKeyDown}
          placeholder="Type email Id here..."
          style={{
            border: "none",
            outline: "none",
            width: "100%",
            height: "100%",
            padding: "4px",
            fontSize: "14px",
            backgroundColor: "transparent",
          }}
        />
        <IconButton
          background="white"
          icon={<SendEmailIcon />}
          aria-label="Add Email"
          position="absolute"
          bottom="65px"
          right="22px"
          onClick={handleEmailSend}
        />
      </Box>
      {showError && (
        <Flex alignItems="center" gap="8px">
          <WarningIcon color="red" />
          <Text className="response-message">{showError}</Text>
        </Flex>
      )}
      {showSuccess && (
        <Flex alignItems="center" gap="8px">
          <CheckCircleIcon color="green" />
          <Text className="response-message">Report sent successfully!</Text>
        </Flex>
      )}
      {!isEmailValid && (
        <Flex alignItems="center" gap="8px">
          <WarningIcon color="red" />
          <Text className="response-message">
            Please provide a valid email address.
          </Text>
        </Flex>
      )}
      {isLoading ? <Loader size={"16px"} /> : <></>}
    </>
  );
};

export default MultiEmailInput;
