export const styles = {
  dropDownOuterContainer: {
    display: "flex",
    gap: "16px",
    flexDirection: "column",
    height: "100%",
  },
  inputContainer: {
    display: "flex",
    height: "40px",
    alignItems: "flex-start",
    alignSelf: "stretch",
    backroundColor: "#E2E8F0",
  },
  input: {
    display: "flex",
    height: "40px",
    padding: "0px 16px 0px 0px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    flex: "1 0 0",
    borderRadius: "var(--border-radius-sm, 8px)",
    border: "1px solid var(--gray-200, #E2E8F0)",
    background: "#F7FAFC",
  },
  dropdownContainer: {
    width: "100%",
    height: "520px",
    overflowY: "auto",
  },
  dropdownOption: {
    display: "flex",
    padding: "8px 12px",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch",
    color: "#4A5568",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  filterBtn: {
    padding: "0px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    flex: "1 0 0",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "24px",
  },
};
