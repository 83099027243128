import { Box, Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomerTop from "../components/allCustomers/CustomerTop";
import CustomerRoleFilters from "../components/allCustomers/CustomerRoleFilters";
import CustomerRoleTable from "../components/allCustomers/CustomerRoleTable";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, reducer, sliceKey } from "../slice";
import { CustomerSaga } from "../saga";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../selectors";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";
import FallbackToast from "errorsFallback/FallbackToast";
const Customers = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: CustomerSaga });

  const allCustomersDetail = useSelector(selectors.selectAllCustomers);

  const customerProfileImgUrls = useSelector(selectors.selectProfileImgUrlsArr);
  const cityDropdown = useSelector(selectors.selectCityDropdown);
  const stateDropdown = useSelector(selectors.selectStateDropdown);
  const [page, setPage] = useState(0);

  const dispatch = useDispatch();
  // const history = useHistory();

  const [filters, setFilters] = useState({
    fromDate: null,
    toDate: null,
    order: "DESC",
    // businessTypes: [],
    states: [],
    cities: [],
    isActive: "",
  });
  const handleSelection = (prevSelectedItems, itemId) => {
    if (prevSelectedItems.includes(itemId)) {
      return prevSelectedItems.filter((id) => id !== itemId);
    } else {
      return [...prevSelectedItems, itemId];
    }
  };

  const handleCustomerListFilters = (id, key) => {
    resetPageNum();
    setFilters((prevFilters) => {
      switch (key) {
        // case "businessTypes":
        //   return {
        //     ...prevFilters,
        //     businessTypes: handleSelection(prevFilters.businessTypes, id, key),
        //   };
        case "states":
          return {
            ...prevFilters,
            states: handleSelection(prevFilters.states, id, key),
          };
        case "cities":
          return {
            ...prevFilters,
            cities: handleSelection(prevFilters.cities, id, key),
          };
        case "isActive":
          return { ...prevFilters, isActive: id };
        default:
          return prevFilters;
      }
    });
  };

  const handleFromDateChange = (date) => {
    resetPageNum();
    setFilters((prevState) => ({
      ...prevState,
      fromDate: date,
    }));
  };
  const handleToDateChange = (date) => {
    setFilters((prevState) => ({
      ...prevState,
      toDate: date,
    }));
  };
  useEffect(() => {
    dispatch(actions.fetchAllCustomers({ ...filters, page }));
  }, [filters, page]);

  useEffect(() => {
    if (allCustomersDetail) {
      let profileImgNameArr = allCustomersDetail.map((customer) => {
        return customer.avatar;
      });
      dispatch(actions.fetchProfileImgURLArr(profileImgNameArr));
    }
    dispatch(actions.clearStates());
  }, [allCustomersDetail]);

  const resetPageNum = () => {
    setPage(0);
  };

  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <Box mt={"75px"}>
        <Stack spacing={"36px"} direction="column">
          <ErrorBoundary
            fallback={
              <FallbackToast
                message={`Failed to Load Add Agency Option and Agency Count`}
              />
            }
          >
            <CustomerTop />
          </ErrorBoundary>
          <ErrorBoundary
            fallback={<FallbackToast message={`Failed to Load Filters`} />}
          >
            <CustomerRoleFilters
              handleCustomerListFilters={handleCustomerListFilters}
              handleFromDateChange={handleFromDateChange}
              handleToDateChange={handleToDateChange}
              filters={filters}
              cityDropdown={cityDropdown}
              stateDropdown={stateDropdown}
            />
          </ErrorBoundary>
          <ErrorBoundary fallback={<FallbackUI minH="70vh" />}>
            <CustomerRoleTable
              allCustomersDetail={allCustomersDetail}
              customerProfileImgUrls={customerProfileImgUrls}
              page={page}
              setPage={setPage}
            />
          </ErrorBoundary>
        </Stack>
      </Box>
    </ErrorBoundary>
  );
};

export default Customers;
