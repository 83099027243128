import {
  Button,
  Modal,
  ModalContent,
  ModalBody,
  Box,
  VStack,
  CircularProgress,
  HStack,
  Image,
  useDisclosure,
  Spinner,
  Stack,
} from "@chakra-ui/react";

// Custom components
import React, { useCallback, useEffect, useRef } from "react";
import { ActionIcon } from "components/Icons/Icons";
import { useState } from "react";
import { ViewReportIcon } from "components/Icons/Icons";
import { DownloadReportIcon } from "components/Icons/Icons";
import MultiEmailInput from "components/MultiEmailInput/MultiEmailInput";
import { Pagination, Table } from "@agnext/reactlib";
import noRecordImage from "assets/img/Empty Case.png";
import { capitalizeString } from "utils/commonFunctions";
import "../index.css";
import Group from "assets/svg/Group.svg";
import VerificationModal from "./VerificationModal";
import { useDispatch, useSelector } from "react-redux";
import { sliceKey, reducer, actions } from "../slice";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import * as selectors from "../selectors";
import { Link } from "react-router-dom";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  message: {
    fontFamily: "Inter",
    textAlign: "center",
    fontSize: "22px",
  },
  image: {
    maxWidth: "100%",
    height: "auto",
    margin: "auto",
  },
};
const InspectionDataTable = ({
  data,
  onClickViewReport,
  onClickDownloadReport,
  heading,
  page,
  numPages,
  handlePageSelect,
  handleNextPage,
  handlePreviousPage,
  isLoadingPagination,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState("");
  const [showError, setShowError] = useState("");
  const [selectedActionIconPosition, setSelectedActionIconPosition] = useState({
    x: 0,
    y: 0,
  });
  const [inspectionDetails, setInspectionDetails] = useState({
    fileName: null,
    inspectionId: null,
    warehouseCode: null,
    auditDate: null,
    auditorId: null,
    auditorName: null,
    originName: null,
    originLocation: null,
    clientName: null,
    clientAvatar: null,
  });
  const [selectedInspectionId, setSelectedInspectionId] = useState(null);
  const [selectedInspection, setSelectedInspection] = useState(null);
  const jobDetails = useSelector(selectors.selectJobDetails);
  const taskDetails = useSelector(selectors.selectTaskDetails);
  const isHederaEnable = useSelector(selectors.selectIsHederaEnabled);
  const loading = useSelector(selectors.loading);

  const handleOpenModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const iconPosition = e.currentTarget.getBoundingClientRect();

    setSelectedActionIconPosition({
      x: iconPosition.left,
      y: iconPosition.top,
    });
    setIsOpen(true);
    setShowSuccess("");
    setShowError("");
  };

  const visibleHeadings = heading?.filter(
    (heading) => heading.visible === true
  );

  const headerArray = visibleHeadings?.map((obj) => {
    if (obj.value === "action") {
      return {
        keyText: obj.value,
        displayText: obj.display,
      };
    }
    if (typeof data.value !== Number) {
      return {
        keyText: obj.value,
        displayText: obj.display,
        hasTooltip: true,
        hasSort: true,
      };
    }
    return {
      keyText: obj.value,
      displayText: obj.display,
      hasSort: true,
    };
  });

  let capitalizedArray =
    data &&
    data.map((obj) => {
      const lowercasedObject = {};
      for (const key in obj) {
        lowercasedObject[key] =
          typeof obj[key] === "string" && obj[key] !== "N.A."
            ? key === "inspectionReport" ||
              key === "id" ||
              key === "clientAvatar"
              ? obj[key]
              : capitalizeString(obj[key]?.toLowerCase())
            : obj[key];
      }
      return lowercasedObject;
    });

  // If Key is missing in data, inserted with value "N.A."
  function checkKeysAndInsert(heading, capitalisedArray) {
    for (let i = 0; i < capitalisedArray.length; i++) {
      const obj2 = capitalisedArray[i];

      for (let j = 0; j < heading.length; j++) {
        const key = heading[j].value;

        if (!(key in obj2)) {
          obj2[key] = "N.A.";
        }
      }
    }
    return capitalisedArray;
  }

  capitalizedArray =
    heading.length > 0 && capitalizedArray.length > 0
      ? checkKeysAndInsert(heading, capitalizedArray)
      : capitalizedArray;

  data = capitalizedArray?.map((inspection, i) => {
    return {
      ...inspection,
      auditDate: inspection?.auditDate?.slice(0, 10) || "N.A.",
      warehouseCode: inspection?.warehouseCode?.toUpperCase() || "N.A.",
      auditors:
        capitalizeStringUpdated(inspection?.auditors[0].firstName) +
        " " +
        capitalizeStringUpdated(inspection?.auditors[0].lastName),
      action: (
        <ActionIcon
          w="20px"
          h="20px"
          mx="16px"
          onClick={(e) => {
            if (inspection) handleFileName(inspection);
            handleOpenModal(e);
          }}
          _hover={{
            cursor: "pointer",
          }}
        />
      ),
    };
  });

  const handleShowSuccess = (value) => {
    setShowSuccess(value);
  };

  const handleShowError = (value) => {
    setShowError(value);
  };

  const handleCloseModal = () => {
    if (!selectedInspection?.inspectionId) {
      dispatch(actions.fetchClearStates());
    }
    setIsOpen(false);
    setInspectionDetails(null);
  };

  const handleFileName = (inspection) => {
    dispatch(actions.checkIsHederaEnable({inspectionId : inspection.id}))
    dispatch(actions.fetchJobDetailsRequest({ id: inspection.id }));
    setInspectionDetails({
      fileName: inspection.inspectionReport,
      inspectionId: inspection.id,
      warehouseCode: inspection.originCode,
      auditDate: inspection.auditDate,
      originLocation: inspection.originLocation,
      auditorId: inspection?.auditors[0]?.id,
      originName: inspection.originName,
      auditorName: `${inspection?.auditors[0]?.firstName ?? ""} ${
        inspection?.auditors[0]?.lastName ?? ""
      }`,
      clientName: inspection.clientOrgName,
      clientAvatar: inspection.clientAvatar,
    });
  };
  const {
    isOpen: isSideDrawerOpen,
    onOpen: openSideDrawer,
    onClose: closeSideDrawer,
  } = useDisclosure();

  const btnRef = useRef();

  const clearStates = () => {
    setSelectedInspectionId(null);
    setSelectedInspection(null);
  };

  useEffect(() => {
    if (jobDetails && jobDetails.length) {
      const { inspectionId, id } = jobDetails[0];
      if (inspectionId) {
        dispatch(actions.fetchTaskDetailsRequest({ inspectionId, jobId: id }));
      }
    }
  }, [jobDetails]);

  useEffect(() => {
    if (taskDetails && taskDetails.length) {
      const { formId } = taskDetails[0];
      if (formId) dispatch(actions.fetchWorkflowData({ id: formId }));
    }
  }, [taskDetails]);

  useEffect(() => {
    if (isSideDrawerOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isSideDrawerOpen]);

  const dispatch = useDispatch();

  const getBlockChainData = (inspectionId) => {
    let body = {
      fetchFromHedera: false,
    };
    dispatch(actions.fetchBlockchainData({ inspectionId, body }));
  };

  useEffect(() => {
    if (!isSideDrawerOpen) {
      dispatch(actions.fetchClearStatesSuccess());
    }
  }, [isSideDrawerOpen]);

 const {
    workflowId = "",
    formId = "",
    id: taskId = "",
    jobId = "",
    inspectionId = "",
  } = taskDetails?.[0] || {};

  const { originId = "" } = jobDetails?.[0] || {};

  const viewInspectionUrl = `/admin/viewinspection?workflowId=${workflowId}&formId=${formId}&taskId=${taskId}&jobId=${jobId}&inspectionId=${inspectionId}&originId=${originId}`;

  return (
    <>
      <HStack
        display={isLoadingPagination ? "flex" : "none"}
        justifyContent={"center"}
      >
        <CircularProgress isIndeterminate color="#4FD1C5" size="36px" />
      </HStack>
      <Box display={isLoadingPagination ? "none" : "block"}>
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          w={"100%"}
          mb={"10px"}
        >
          <Pagination
            page={page}
            numPages={numPages}
            handlePageSelect={handlePageSelect}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            colorActive="#4FD1C5"
          />
        </Box>
        <VStack
          borderRadius="15px"
          padding="0px"
          backgroundColor="white"
          border={"1px solid #efefef"}
          minH={"400px"}
        >
          {data.length > 0 ? (
            <Table
              headerList={headerArray}
              dataList={data}
              stickyColumn={["Action"]}
            />
          ) : (
            <Box style={styles.message}>
              <img
                style={styles.image}
                src={noRecordImage}
                alt="No Records Image"
              />
              <p>No Records Found</p>
            </Box>
          )}
          {isOpen && (
            <>
              <Modal isOpen={isOpen} onClose={handleCloseModal} display="flex">
                <ModalContent
                  className="action-btn-dropdown"
                  top={selectedActionIconPosition.y + -35 + "px"}
                  left={selectedActionIconPosition.x + -308 + "px"}
                >
                  {loading === false ? (
                    <Box
                      display="flex"
                      rowGap="20px!important"
                      flexDirection="column"
                    >
                      {/* // view inspection start */}
                      {/* Jobs view response component is using to show view inspection */}
                      <Link to={viewInspectionUrl}>
                        <Button
                          variant="outline"
                          className="view-report-btn"
                          rightIcon={<ViewReportIcon />}
                        >
                          View Inspection
                        </Button>
                      </Link>
                      {/* /// End View inspection */}
                      <Button
                        variant="outline"
                        className="view-report-btn"
                        rightIcon={<ViewReportIcon />}
                        onClick={() => {
                          handleCloseModal();
                          onClickViewReport(inspectionDetails.fileName);
                        }}
                      >
                        View Report
                      </Button>
                      <Button
                        className="download-report-btn"
                        rightIcon={<DownloadReportIcon />}
                        variant="outline"
                        onClick={() => {
                          handleCloseModal();
                          onClickDownloadReport(inspectionDetails);
                        }}
                      >
                        Download Report
                      </Button>
                      {isHederaEnable === true ? (
                        <Button
                          ref={btnRef}
                          className="download-report-btn"
                          variant="outline"
                          onClick={() => {
                            getBlockChainData(inspectionDetails.inspectionId);
                            setSelectedInspectionId(
                              inspectionDetails.inspectionId
                            );
                            setSelectedInspection(inspectionDetails);
                            openSideDrawer();
                            handleCloseModal();
                          }}
                        >
                          Verify on Hedera
                          <Image src={Group}></Image>
                        </Button>
                      ) : null}
                      <ModalBody className="email-head">
                        Email Report:
                      </ModalBody>
                      <MultiEmailInput
                        inspectionId={inspectionDetails.inspectionId}
                        showSuccess={showSuccess}
                        handleShowSuccess={handleShowSuccess}
                        showError={showError}
                        handleShowError={handleShowError}
                      />
                    </Box>
                  ) : (
                    <Stack align="center" p="10px">
                      <Spinner
                        thickness="4px"
                        speed="0.80s"
                        emptyColor="gray.200"
                        color="teal.500"
                        size="xl"
                      />
                    </Stack>
                  )}
                </ModalContent>
              </Modal>
            </>
          )}
          {/* </Box> */}
        </VStack>
      </Box>
      <Box display={"flex"} justifyContent={"flex-end"} w={"100%"} mt={"10px"}>
        <Pagination
          page={page}
          numPages={numPages}
          handlePageSelect={handlePageSelect}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          colorActive="#4FD1C5"
        />
      </Box>

      <VerificationModal
        isOpen={isSideDrawerOpen}
        onOpen={openSideDrawer}
        onClose={closeSideDrawer}
        btnRef={btnRef}
        inspectionId={selectedInspectionId}
        selectedInspection={selectedInspection}
        clearStates={clearStates}
      />
    </>
  );
};

export default InspectionDataTable;
