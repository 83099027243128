import React, { useState } from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerCloseButton,
  Flex,
  Heading,
  Input,
  Stack,
  Select,
  InputGroup,
  InputRightElement,
  Text,
  CircularProgress,
} from "@chakra-ui/react";
import { BackIcon } from "components/Icons/Icons";
import { IconButton } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { SideIcon } from "components/Icons/Icons";
// import Dropdown from "views/Dashboard/shared/Dropdown";
import DrawerDropdown from "views/shared/DrawerDropdown";
import { styles } from "./FilterDrawer.module.js";
function FilterDrawer({
  locationOptions,
  isDrawerOpen,
  toggleDrawer,
  commodityOptions,
  handleFilterQuery,
  commodities,
  location,
}) {
  const [openedDropdownId, setOpenedDropdownId] = useState(null);

  const openDropdown = (dropdownId) => {
    setOpenedDropdownId(dropdownId);
  };

  const closeDropdown = () => {
    setOpenedDropdownId(null);
  };
  return (
    <>
      <Drawer isOpen={isDrawerOpen} placement="bottom" size={"full"}>
        <DrawerOverlay />
        <DrawerContent sx={styles.filtersContainer} style={{ height: "90vh" }}>
          <DrawerHeader p="0">
            <Flex justify="space-between" alignItems="center">
              <IconButton
                background="white"
                icon={<BackIcon />}
                position={"absolute"}
                left="16px"
                _focus={{
                  background: "white",
                  transform: "none",
                  borderColor: "transparent",
                }}
                onClick={toggleDrawer}
              />
              <Heading sx={styles.filterHead}>Filters</Heading>
            </Flex>
          </DrawerHeader>
          <DrawerBody mt="36px" p="0px" w="100%">
            <Stack spacing={"24px"} w={"100%"}>
              <Box
                sx={styles.responsiveDropdown}
                onClick={() => {
                  openDropdown("dropdown1");
                }}
              >
                <Text sx={styles.text}>
                  {commodities === "" ? "Commodities" : commodities}
                </Text>
                <Box sx={styles.icon}>
                  <SideIcon />
                </Box>
              </Box>
              <Box
                sx={styles.responsiveDropdown}
                onClick={() => {
                  openDropdown("dropdown2");
                }}
              >
                <Text sx={styles.text}>
                  {" "}
                  {location === "" ? "Location" : location}
                </Text>
                <Box sx={styles.icon}>
                  <SideIcon />
                </Box>
              </Box>

              {/* <InputGroup borderRadius="15px" height="40px">
                <Input placeholder="Search by Commodity Name" />
                <InputRightElement>
                  <SideIcon />
                </InputRightElement>
              </InputGroup> */}
            </Stack>
          </DrawerBody>
          <DrawerFooter p="0px" w="100%" gap="20px">
            <Button
              sx={styles.filterBtn}
              colorScheme="gray"
              w="100%"
              color="#1A202C"
              onClick={() => {
                handleFilterQuery("", "commodities");
                handleFilterQuery("", "location");
              }}
            >
              Clear
            </Button>
            <Button
              sx={styles.filterBtn}
              colorScheme="login"
              w="100%"
              onClick={toggleDrawer}
            >
              Show Results
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Drawer
        isOpen={openedDropdownId === "dropdown1"}
        placement="bottom"
        onClose={closeDropdown}
        size={"full"}
      >
        <DrawerOverlay />
        <DrawerContent sx={styles.filtersContainer} style={{ height: "90vh" }}>
          <DrawerHeader p="0">
            <Flex justify="space-between" alignItems="center">
              <IconButton
                onClick={() => {
                  openDropdown("");
                }}
                background="white"
                icon={<BackIcon />}
                position={"absolute"}
                left="16px"
                _focus={{
                  background: "white",
                  transform: "none",
                  borderColor: "transparent",
                }}
              />
              <Heading sx={styles.filterHead}>Commodities</Heading>
            </Flex>
          </DrawerHeader>
          <DrawerBody mt={"36px"} w={"100%"} p={"0px"}>
            {commodityOptions.length > 0 ? (
              <DrawerDropdown
                handleFilterQuery={handleFilterQuery}
                options={commodityOptions}
                placeholder={"Search by commodity..."}
                property={"commodities"}
                listKey={"name"}
                query={commodities}
              />
            ) : (
              <CircularProgress
                isIndeterminate
                color="#4FD1C5"
                size="24px"
                alignSelf={"center"}
              />
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Drawer
        isOpen={openedDropdownId === "dropdown2"}
        placement="bottom"
        onClose={closeDropdown}
        size={"full"}
      >
        <DrawerOverlay />
        <DrawerContent sx={styles.filtersContainer} style={{ height: "90vh" }}>
          <DrawerHeader p="0">
            <Flex justify="space-between" alignItems="center">
              <IconButton
                onClick={() => {
                  openDropdown("");
                }}
                background="white"
                icon={<BackIcon />}
                position={"absolute"}
                left="16px"
                _focus={{
                  background: "white",
                  transform: "none",
                  borderColor: "transparent",
                }}
              />
              <Heading sx={styles.filterHead}></Heading>
            </Flex>
          </DrawerHeader>
          <DrawerBody mt={"36px"} w={"100%"} p={"0px"}>
            {locationOptions.length > 0 ? (
              <DrawerDropdown
                handleFilterQuery={handleFilterQuery}
                options={locationOptions}
                placeholder={"Search by Location Name"}
                property={"location"}
                listKey={"city"}
                query={location}
              />
            ) : (
              <CircularProgress
                isIndeterminate
                color="#4FD1C5"
                size="24px"
                alignSelf={"center"}
              />
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default FilterDrawer;
