import { createSlice } from "@reduxjs/toolkit";
import {
  move,
  sort,
  deletion,
  duplicate,
  swapOrder,
  generateTitle,
} from "utils/index";
import _ from "lodash";

export const initialState = {
  loading: false,
  error: null,
  workflows: [],
  workflow: {},
  form: {},
  forms: [],
  sections: [],
  blocks: [],
  composites: [],
  components: [],
  userContext: null,
};

const homePage = createSlice({
  name: "homePage",
  initialState,
  reducers: {
    //Fetch User Context
    fetchUserContextData(state, action) {
      state.loading = true;
    },
    fetchUserContextSuccess(state, action) {
      state.loading = false;
      state.userContext = action.payload[0].enabledInspectionTypes;
    },
    fetchUserContextFailure(state, action) {
      state.loading = false;
    },
    // Fetch Data
    fetchWorkflows: (state, action) => {
      state.loading = true;
    },
    fetchWorkflowsSuccess: (state, action) => {
      state.loading = false;
      state.workflows = action.payload;
    },
    fetchWorkflowsFailure: (state, action) => {
      state.loading = false;
    },
    postWorkflow: (state, action) => {
      state.loading = true;
    },
    postWorkflowSuccess: () => {
      state.loading = false;
    },
    postWorkflowFailure: () => {
      state.loading = false;
    },
    fetchFormData: (state, action) => {
      state.loading = true;
    },
    fetchFormDataSuccess: (state, action) => {
      // let newSectionsStructure = action.payload.form.structure.sections;
      // newSectionsStructure = fixOrder(newSectionsStructure);
      state.form = action.payload.form;
      // if (state.form && state.form.structure) {
      //   state.form.structure = { sections: newSectionsStructure };
      // } else {
      //   state.form = {
      //     structure: action.payload.form.structure,
      //   };
      // }
      state.sections = action.payload.sections ? action.payload.sections : [];
      state.blocks = action.payload.blocks ? action.payload.blocks : [];
      state.composites = action.payload.composites
        ? action.payload.composites
        : [];
      state.components = action.payload.components
        ? action.payload.components
        : [];
      state.loading = false;
    },
    fetchFormDataFailure: (state, action) => {
      state.loading = false;
    },
    fetchSectionData: (state, action) => {
      state.loading = true;
    },
    fetchSectionDataSuccess: (state, action) => {
      state.loading = false;
      const sections = [...state.sections];
      sections.push(action.payload);
      state.sections = sections;
    },
    fetchSectionDataFailure: (state, action) => {
      state.loading = false;
    },
    fetchBlockData: (state, action) => {
      state.loading = true;
    },
    fetchBlockDataSuccess: (state, action) => {
      state.loading = false;
      const blocks = [...state.blocks];
      blocks.push(action.payload);
      state.blocks = blocks;
    },
    fetchBlockDataFailure: (state, action) => {
      state.loading = false;
    },
    fetchCompositeData: (state, action) => {
      state.loading = true;
    },
    fetchCompositeDataSuccess: (state, action) => {
      state.loading = false;
      const composites = [...state.composites];
      composites.push(action.payload);
      state.composites = composites;
    },
    fetchCompositeDataFailure: (state, action) => {
      state.loading = false;
    },
    fetchComponentData: (state, action) => {
      state.loading = true;
    },
    fetchComponentDataSuccess: (state, action) => {
      state.loading = false;
      const components = [...state.components];
      components.push(action.payload);
      state.components = components;
    },
    fetchComponentDataFailure: (state, action) => {
      state.loading = false;
    },

    // post
    postFormData: (state, action) => {
      state.loading = true;
    },

    postFormDataSuccess: (state, action) => {
      state.loading = false;
    },

    postFormDataFailure: (state, action) => {
      state.loading = false;
      // state.error = action.payload;
    },

    // Init
    init: (state, action) => {
      const { form, workflow } = action.payload;
      state.workflow = workflow;
      state.forms = [form];
      state.sections = [];
      state.blocks = [];
      state.composites = [];
      state.components = [];
    },

    // clear State Workflow
    clearState: (state, action) => {
      state = initialState;
    },

    // creating shell in backend (workflow, form and workflow-forms relation)
    initWorkflow: (state, action) => {
      state.loading = true;
    },

    initWorkflowSuccess: (state, action) => {
      state.loading = false;
    },

    initWorkflowFailure: (state, action) => {
      state.loading = false;
    },

    fetchWorkflow: (state, action) => {
      state.loading = true;
    },
    fetchWorkflowSuccess: (state, action) => {
      state.loading = false;
      state.workflow = action.payload.workflow;
      state.forms = action.payload.forms;
    },
    fetchWorkflowFailure: (state, action) => {
      state.loading = false;
    },
    clearFormData: (state, action) => {
      state.loading = true;
      state.sections = [];
      state.blocks = [];
      state.composites = [];
      state.components = [];
      state.form = {};
    },
    clearFormStateSuccess: (state, action) => {
      state.loading = false;
    },
    clearFormStateFailure: (state, action) => {
      state.loading = false;
    },
    cloneWorkflow: (state, action) => {
      state.loading = true;
    },
    cloneWorkflowSuccess: (state) => {
      state.loading = false;
    },
    cloneWorkflowFailure: (state, action) => {
      state.loading = false;
    },
    initCloneWorkflow: (state, action) => {
      state.loading = true;
    },
    initCopyWorkflowSuccess: (state) => {
      state.loading = false;
    },
    initCopyWorkflowFailure: (state, action) => {
      state.loading = false;
    },
    copyForms: (state, action) => {
      state.loading = false;
    },
    copyFormsSuccess: (state) => {
      state.loading = false;
    },
    copyFormsFailure: (state, action) => {
      state.loading = false;
    },

    editInspectionType: (state, action) => {
      const { inspectionType, formId } = action.payload;
      state.form.categoryType = inspectionType;
      state.forms = state.forms.map((form) => {
        if (form.id === formId) {
          form.categoryType = inspectionType;
        }
        return form;
      });
    },

    // Adding new form
    addNewForm: (state, action) => {
      let form = action.payload;
      state.forms.push(form);
    },

    fetchForms: (state, action) => {
      state.loading = true;
    },

    fetchFormsSuccess: (state, action) => {
      state.loading = false;
      state.forms = action.payload;
    },

    fetchFormsFailure: (state, action) => {
      state.loading = false;
    },

    // delete form
    deleteForm: (state, action) => {
      state.loading = true;
    },
    deleteFormSuccess: (state, action) => {
      state.loading = false;
    },
    deleteFormFailure: (state, action) => {
      state.loading = false;
    },
    // Init End

    // editformName
    editWorkflowTitle: (state, action) => {
      state.workflow.title = action.payload;
    },

    editWorkflowDescription: (state, action) => {
      state.workflow.description = action.payload;
    },

    editFormName: (state, action) => {
      const { formId, form } = action.payload;
      state.forms.forEach((currForm) => {
        if (currForm.id === formId) {
          currForm.title = form;
        }
      });
    },
    // editformName end

    // editformDescription
    editFormDescription: (state, action) => {
      const { formId, description } = action.payload;
      state.forms.forEach((currForm) => {
        if (currForm.id === formId) {
          currForm.description = description;
        }
      });
    },
    // editformDescription end

    // initNewSection
    initNewSection: (state, action) => {
      const { sectionId, formId } = action.payload;
      const section = { id: sectionId };
      let structureOrder;
      const sections = state.form.structure.sections || [];

      // uncoment line below in case pages are not being created in order
      // state.sections.sort((a, b) => a.order - b.order);

      if (sections.length) {
        structureOrder = sections[sections.length - 1].order + 1;
      } else {
        structureOrder = 1;
      }
      section.order = structureOrder;
      const title = generateTitle("Page", structureOrder);
      section.title = title;
      sections.push(section);
      // forms array
      state.forms = state.forms.map((form) => {
        if (form.id === formId) {
          form.structure.sections.push(section);
        }
        return form;
      });
      // form structure
      state.form.structure = {
        sections,
      };
      // sections array
      state.sections.push(section);
    },
    // initNewSection end

    // editSectionName
    editSectionName: (state, action) => {
      const { sectionId, sectionName } = action.payload;

      // change in structure of form
      state.form.structure?.sections?.forEach((currSection) => {
        if (currSection.id === sectionId) {
          currSection.title = sectionName;
        }
      });

      // change in forms Array
      state.sections.forEach((currSection) => {
        if (currSection.id === sectionId) {
          currSection.title = sectionName;
        }
      });
    },
    // editSectionName end

    // editSectionDescription
    editSectionDescription: (state, action) => {
      const { sectionId, sectionDescription } = action.payload;
      state.sections.forEach((currSection) => {
        if (currSection.id === sectionId) {
          currSection.description = sectionDescription;
        }
      });
    },
    // editSectionDescription end

    // moveUpSection
    moveUpSection: (state, action) => {
      const { sectionId } = action.payload;
      // change in the form structure
      if (state.form?.structure?.sections?.length) {
        swapOrder(state.form?.structure?.sections, sectionId, "up");
      }

      // change in the sections Array
      if (state?.sections?.length) {
        swapOrder(state.sections, sectionId, "up");
      }
    },
    // moveUpSection end

    // drag section
    dragSection: (state, action) => {
      const { startIndex, dropIndex } = action.payload;
      let dropOrder;

      // moving up
      if (startIndex > dropIndex) {
        // modify form structure
        const sectionsFromForm = state.form.structure.sections;
        const sectionForStructure = sectionsFromForm[startIndex];
        dropOrder = sectionsFromForm[dropIndex].order;
        for (let i = dropIndex; i <= startIndex; i++) {
          sectionsFromForm[i].order += 1;
        }
        sectionsFromForm.splice(startIndex, 1);
        sectionForStructure.order = dropOrder;
        sectionsFromForm.splice(dropIndex, 0, sectionForStructure);

        // modify forms array
        const sections = state.sections;
        const section = sections[startIndex];
        for (let i = dropIndex; i <= startIndex; i++) {
          sections[i].order += 1;
        }
        sections.splice(startIndex, 1);
        section.order = dropOrder;
        sections.splice(dropIndex, 0, section);
      }

      // moving down
      if (startIndex < dropIndex) {
        // modify form structure
        const sectionsFromForm = state.form.structure.sections;
        const sectionForStructure = sectionsFromForm[startIndex];
        dropOrder = sectionsFromForm[dropIndex].order;
        for (let i = startIndex; i <= dropIndex; i++) {
          sectionsFromForm[i].order -= 1;
        }
        sectionsFromForm.splice(startIndex, 1);
        sectionForStructure.order = dropOrder;
        sectionsFromForm.splice(dropIndex, 0, sectionForStructure);

        // modify forms array
        const sections = state.sections;
        const section = sections[startIndex];
        for (let i = startIndex; i <= dropIndex; i++) {
          sections[i].order -= 1;
        }
        sections.splice(startIndex, 1);
        section.order = dropOrder;
        sections.splice(dropIndex, 0, section);
      }
    },

    // initNewBlock
    initNewBlock: (state, action) => {
      const { sectionId, blockId } = action.payload;

      // change blocks array inside section structure
      let order;
      let blockTitle;
      state.sections.forEach((currSection) => {
        if (currSection.id === sectionId) {
          if (currSection?.structure?.blocks?.length) {
            const blocks = currSection.structure.blocks;
            blocks.sort((a, b) => a.order - b.order);
            order = blocks[blocks.length - 1].order + 1;
            blockTitle = generateTitle("Section", order);
            blocks.push({
              id: blockId,
              title: blockTitle,
              order,
            });
          } else {
            order = 1;
            blockTitle = generateTitle("Section", order);
            currSection.structure = {
              blocks: [
                {
                  id: blockId,
                  title: blockTitle,
                  order,
                },
              ],
            };
          }
        }
      });

      // change blocks array
      state.blocks.push({
        id: blockId,
        title: blockTitle,
      });
    },
    // initNewBlock end

    // editBlockName
    editBlockName: (state, action) => {
      const { blockName, blockId, sectionId } = action.payload;

      // Change sections array
      state.sections.forEach((currSection) => {
        if (currSection.id === sectionId) {
          currSection.structure.blocks.forEach((currBlock) => {
            if (currBlock.id === blockId) {
              currBlock.title = blockName;
            }
          });
        }
      });
      // Change blocks array
      state.blocks.forEach((currBlock) => {
        if (currBlock.id === blockId) {
          currBlock.title = blockName;
        }
      });
    },
    // editBlockName end

    // editBlockDescription
    editBlockDescription: (state, action) => {
      const { blockId, blockDescription } = action.payload;
      state.blocks.forEach((currBlock) => {
        if (currBlock.id === blockId) {
          currBlock.description = blockDescription;
        }
      });
    },
    // editBlockDescription end

    // editCompositeName
    editCompositeName: (state, action) => {
      const { compositeName, blockId, compositeId } = action.payload;

      // Change blocks array
      state.blocks.forEach((currBlock) => {
        if (currBlock.id === blockId) {
          currBlock.structure.composites.forEach((currComposite) => {
            if (currComposite.id === compositeId) {
              currComposite.title = compositeName;
            }
          });
        }
      });

      // Change blocks array
      state.composites.forEach((currComposite) => {
        if (currComposite.id === compositeId) {
          currComposite.title = compositeName;
        }
      });
    },
    // editCompositeName end

    // initNewComponent
    initNewComponent: (state, action) => {
      const { compositeId, componentId } = action.payload;
      let order, componentTitle;
      // changing composites array
      state.composites.forEach((currComposite) => {
        if (currComposite.id === compositeId) {
          if (currComposite?.structure?.components?.length) {
            const components = currComposite.structure.components;
            components.sort((a, b) => a.order - b.order);
            order = components[components.length - 1].order + 1;
            componentTitle = generateTitle("Component", order);
            components.push({
              id: componentId,
              title: componentTitle,
              order,
            });
          } else {
            order = 1;
            componentTitle = generateTitle("Component", order);
            currComposite.structure = {
              components: [
                {
                  id: componentId,
                  title: componentTitle,
                  order,
                },
              ],
            };
          }
        }
      });

      // changing components array
      state.components.push({
        id: componentId,
        title: componentTitle,
        repeatable: {
          isRepeatable: false,
          unlimitedEntries: false,
          minEntries: 1,
          maxEntries: 1,
        },
      });
    },
    // initNewComponent end

    // moveDownSection
    moveDownSection: (state, action) => {
      const { sectionId } = action.payload;
      // change in the form structure
      if (state.form?.structure?.sections?.length) {
        swapOrder(state.form?.structure?.sections, sectionId, "down");
      }
      // change in the sections Array
      if (state?.sections?.length) {
        swapOrder(state.sections, sectionId, "down");
      }
    },
    // moveDownSection end

    // initNewComposite
    initNewComposite: (state, action) => {
      const { compositeId, blockId } = action.payload;
      let order, compositeTitle;
      // changing blocks array
      state.blocks.forEach((currBlock) => {
        if (currBlock.id === blockId) {
          if (currBlock?.structure?.composites?.length) {
            const composites = currBlock.structure.composites;
            composites.sort((a, b) => a.order - b.order);
            order = composites[composites.length - 1].order + 1;
            compositeTitle = generateTitle("Composite", order);
            composites.push({
              id: compositeId,
              title: compositeTitle,
              order,
            });
          } else {
            order = 1;
            compositeTitle = generateTitle("Composite", order);
            currBlock.structure = {
              composites: [
                {
                  id: compositeId,
                  title: compositeTitle,
                  order,
                },
              ],
            };
          }
        }
      });

      // changing composites array
      state.composites.push({
        id: compositeId,
        title: compositeTitle,
      });
    },
    // initNewComposite end

    // moveUpBlock
    moveUpBlock: (state, action) => {
      const { blockId, sectionId } = action.payload;
      state.sections.forEach((currSection) => {
        if (currSection.id === sectionId) {
          if (currSection?.structure?.blocks?.length) {
            swapOrder(currSection?.structure?.blocks, blockId, "up");
          }
        }
      });
    },
    // moveUpBlock end

    // moveDownBlock
    moveDownBlock: (state, action) => {
      const { blockId, sectionId } = action.payload;
      state.sections.forEach((currSection) => {
        if (currSection.id === sectionId) {
          if (currSection?.structure?.blocks?.length) {
            swapOrder(currSection?.structure?.blocks, blockId, "down");
          }
        }
      });
    },
    // moveDownBlock end

    // moveUpComposite
    moveUpComposite: (state, action) => {
      const { blockId, compositeId } = action.payload;
      state.blocks.forEach((currBlock) => {
        if (currBlock.id === blockId) {
          if (currBlock?.structure?.composites?.length) {
            swapOrder(currBlock?.structure?.composites, compositeId, "up");
          }
        }
      });
    },
    // moveUpComposite end

    // moveDownComposite
    moveDownComposite: (state, action) => {
      const { blockId, compositeId } = action.payload;
      state.blocks.forEach((currBlock) => {
        if (currBlock.id === blockId) {
          if (currBlock?.structure?.composites?.length) {
            swapOrder(currBlock?.structure?.composites, compositeId, "down");
          }
        }
      });
    },
    // moveDownComposite end

    // moveUpComponent
    moveUpComponent: (state, action) => {
      const { componentId, compositeId } = action.payload;
      state.composites.forEach((currComposite) => {
        if (currComposite.id === compositeId) {
          if (currComposite?.structure?.components?.length) {
            swapOrder(currComposite?.structure?.components, componentId, "up");
          }
        }
      });
    },
    // moveUpComponent end

    // editComponentName
    editComponentName: (state, action) => {
      const { componentName, compositeId, componentId } = action.payload;

      // Change composites array
      state.composites.forEach((currComposite) => {
        if (currComposite.id === compositeId) {
          currComposite.structure.components.forEach((currComponent) => {
            if (currComponent.id === componentId) {
              currComponent.title = componentName;
            }
          });
        }
      });

      // Change components array
      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          currComponent.title = componentName;
        }
      });
    },
    // editComponentName end

    // moveDownComponent
    moveDownComponent: (state, action) => {
      const { componentId, compositeId } = action.payload;
      state.composites.forEach((currComposite) => {
        if (currComposite.id === compositeId) {
          if (currComposite?.structure?.components?.length) {
            swapOrder(
              currComposite?.structure?.components,
              componentId,
              "down"
            );
          }
        }
      });
    },
    // moveDownComponent end

    // initNewElement
    initNewElement: (state, action) => {
      const { elementId, componentId } = action.payload;
      let order;
      // changing composites array
      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          if (currComponent?.structure?.elements?.length) {
            const elements = currComponent.structure.elements;
            elements.sort((a, b) => a.order - b.order);
            order = elements[elements.length - 1].order + 1;
            elements.push({
              id: elementId,
              mandatory: true,
              repeatable: {
                isRepeatable: false,
                unlimitedEntries: false,
                minEntries: 1,
                maxEntries: 1,
              },
              order,
            });
          } else {
            order = 1;
            currComponent.structure = {
              elements: [
                {
                  id: elementId,
                  mandatory: true,
                  repeatable: {
                    isRepeatable: false,
                    unlimitedEntries: false,
                    minEntries: 1,
                    maxEntries: 1,
                  },
                  order,
                },
              ],
            };
          }
        }
      });
    },
    // initNewElement end

    // editElementLabel
    editElementLabel: (state, action) => {
      const { elementId, componentId, elementName } = action.payload;
      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          currComponent.structure.elements.forEach((currElement) => {
            if (currElement.id === elementId) {
              currElement.label = elementName;
            }
          });
        }
      });
    },
    // editElementLabel end

    // moveUpElement
    moveUpElement: (state, action) => {
      const { componentId, elementId } = action.payload;
      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          if (currComponent?.structure?.elements?.length) {
            swapOrder(currComponent?.structure?.elements, elementId, "up");
          }
        }
      });
    },
    // moveUpElement end

    // moveDownElement
    moveDownElement: (state, action) => {
      const { componentId, elementId } = action.payload;
      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          if (currComponent?.structure?.elements?.length) {
            swapOrder(currComponent?.structure?.elements, elementId, "down");
          }
        }
      });
    },

    // Drag Element
    dragElement: (state, action) => {
      const { startIndex, dropIndex, startComp, dropComp } = action.payload;
      if (startComp !== dropComp) {
        const startComponent = state.components.find(
          (comp) => comp.id === startComp
        );
        const dropComponent = state.components.find(
          (comp) => comp.id === dropComp
        );
        const elementsLength1 = startComponent.structure.elements.length;
        const elementsLength2 = dropComponent.structure.elements.length;
        for (let i = startIndex; i < elementsLength1; i++) {
          startComponent.structure.elements[i].order -= 1;
        }
        let currElement = startComponent.structure.elements.splice(
          startIndex,
          1
        )[0];
        currElement.order =
          dropComponent.structure.elements[elementsLength2 - 1].order + 1;
        dropComponent.structure.elements.push(currElement);
      } else {
        let dropOrder;
        // move up
        if (startIndex > dropIndex) {
          const component = state.components.find(
            (comp) => comp.id === startComp
          );
          const elements = component?.structure?.elements;
          dropOrder = elements[dropIndex].order;
          for (let i = dropIndex; i <= startIndex; i++) {
            elements[i].order += 1;
          }
          const element = elements.splice(startIndex, 1)[0];
          element.order = dropOrder;
          elements.splice(dropIndex, 0, element);
        } else {
          // move down
          const component = state.components.find(
            (comp) => comp.id === startComp
          );
          const elements = component?.structure?.elements;
          dropOrder = elements[dropIndex].order;
          for (let i = startIndex; i <= dropIndex; i++) {
            elements[i].order -= 1;
          }
          const element = elements.splice(startIndex, 1)[0];
          element.order = dropOrder;
          elements.splice(dropIndex, 0, element);
        }
      }
    },

    // edit composite description
    editCompositeDescription: (state, action) => {
      const { compositeId, compositeDescription } = action.payload;
      state.composites.forEach((currComposite) => {
        if (currComposite.id === compositeId) {
          currComposite.description = compositeDescription;
        }
      });
    },

    // edit element placeholder
    editElementPlaceHolder: (state, action) => {
      const { elementId, componentId, placeholder } = action.payload;

      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          currComponent.structure.elements.forEach((currElement) => {
            if (currElement.id === elementId) {
              currElement.placeholder = placeholder;
            }
          });
        }
      });
    },

    // edit element text
    editElementText: (state, action) => {
      const { elementId, componentId, text } = action.payload;

      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          currComponent.structure.elements.forEach((currElement) => {
            if (currElement.id === elementId) {
              currElement.supportingText = text;
            }
          });
        }
      });
    },

    // edit element range
    editElementRange: (state, action) => {
      const { elementId, componentId, range } = action.payload;

      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          currComponent.structure.elements.forEach((currElement) => {
            if (currElement.id === elementId) {
              currElement.range = range;
            }
          });
        }
      });
    },

    // edit element Href
    editElementHref: (state, action) => {
      const { elementId, componentId, href } = action.payload;

      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          currComponent.structure.elements.forEach((currElement) => {
            if (currElement.id === elementId) {
              currElement.href = href;
            }
          });
        }
      });
    },

    // edit element type
    editElementType: (state, action) => {
      const { elementId, htmlType, componentId } = action.payload;

      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          currComponent.structure.elements.forEach((currElement) => {
            if (currElement.id === elementId) {
              currElement.htmlType = htmlType;
              // delete other Properties from the element structure on change of Element
              delete currElement.properties;
              delete currElement.range;
              delete currElement.fileOptions;
              delete currElement.href;
            }
          });
        }
      });
    },

    // edit Navigation
    editNavigation: (state, action) => {
      const { elementId, componentId, sectionId, value } = action.payload;

      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          currComponent.structure.elements.forEach((currElement) => {
            if (currElement.id === elementId) {
              if (
                currElement.properties &&
                currElement.properties.navigations
              ) {
                currElement.properties.navigations.push({ sectionId, value });
              } else {
                currElement.properties.navigations = [{ sectionId, value }];
              }
            }
          });
        }
      });
    },

    // edit element option
    addElementOptions: (state, action) => {
      const { option, elementId, componentId } = action.payload;
      state.components.map((component) => {
        if (component.id === componentId) {
          const element = component.structure.elements.find(
            (element) => element.id === elementId
          );
          if (element.properties && element.properties.options) {
            element.properties.options.push(option);
          } else {
            element.properties = {
              options: [option],
            };
          }
        }
      });
    },

    // Add domain
    editElementEmailDomain: (state, action) => {
      const { elementId, componentId, emailDomain } = action.payload;

      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          currComponent.structure.elements?.forEach((currElement) => {
            if (currElement.id === elementId) {  
              if(currElement.properties){
                currElement.properties?.emailDomains?.push(emailDomain);
              }else{
                currElement.properties = { emailDomains : [emailDomain] };
              }
            }
          });
        }
      });
    },

    removeElementEmailDomain: (state, action) => {
      const { elementId, componentId, index } = action.payload;
      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          currComponent.structure.elements?.forEach((currElement) => {
            if (currElement.id === elementId) {  
              const emailDomainsLength = currElement.properties?.emailDomains?.length;
              if (index >= 0 && index < emailDomainsLength) {
              // Remove the element at the specified index
                currElement.properties?.emailDomains?.splice(index, 1);
              }
            }
          });
        }
      });
    },

    // Add file properties
    addFileProperties: (state, action) => {
      const { elementId, componentId, fileOptions } = action.payload;

      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          currComponent.structure.elements.forEach((currElement) => {
            if (currElement.id === elementId) {
              currElement.fileOptions = fileOptions;
            }
          });
        }
      });
    },

    // deletionBlock
    deletionBlock: (state, action) => {
      const { sectionId, blockId } = action.payload;
      deletion(blockId, sectionId, state, 2);
    },

    // deletionSection
    deletionSection: (state, action) => {
      const { sectionId } = action.payload;
      deletion(sectionId, null, state, 1);
    },

    // deletionComposite
    deletionComposite: (state, action) => {
      const { blockId, compositeId } = action.payload;
      deletion(compositeId, blockId, state, 3);
    },

    // deletionComponent
    deletionComponent: (state, action) => {
      const { componentId, compositeId } = action.payload;
      deletion(componentId, compositeId, state, 4);
    },

    // deletionElement
    deletionElement: (state, action) => {
      const { componentId, elementId } = action.payload;
      deletion(elementId, componentId, state, 5);
    },

    // Delete Option
    deleteOption: (state, action) => {
      const { elementId, componentId, value } = action.payload;
      // Find the specific component by componentId
      const currComponent = state.components.find(
        (component) => component.id === componentId
      );

      if (currComponent) {
        // Find the specific element by elementId within the found component
        const currElement = currComponent.structure.elements.find(
          (element) => element.id === elementId
        );

        if (currElement) {
          // Filter out the option with the given value
          currElement.properties.options = currElement.properties.options.filter(
            (option) => option.value !== value
          );
        }
      }
    },

    // Edit default
    editDefault: (state, action) => {
      const { elementId, componentId, option } = action.payload;
      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          currComponent.structure.elements.forEach((currElement) => {
            if (currElement.id === elementId) {
              currElement.default = option;
            }
          });
        }
      });
    },

    //duplicate Element
    duplicateElement: (state, action) => {
      const { newId, elementId, componentId } = action.payload;
      duplicate(newId, elementId, componentId, state, 5, 5);
    },

    // duplicate block
    duplicateBlock: (state, action) => {
      const { newId, sectionId, blockId } = action.payload;
      duplicate(newId, blockId, sectionId, state, 2, 2);
    },

    // duplicate component
    duplicateComponent: (state, action) => {
      const { newId, componentId, compositeId } = action.payload;
      duplicate(newId, componentId, compositeId, state, 4, 4);
    },

    // duplicate composite
    duplicateComposite: (state, action) => {
      const { newId, blockId, compositeId } = action.payload;
      duplicate(newId, compositeId, blockId, state, 3, 3);
    },

    // duplicate section
    duplicateSection: (state, action) => {
      const { newId, sectionId } = action.payload;
      duplicate(newId, sectionId, null, state, 1, 1);
    },
    // AddLinkToSection
    addLinkToSection: (state, action) => {
      const { sectionId, linkTo } = action.payload;
      state.sections.forEach((section) => {
        if (section.id === sectionId) {
          if (section.structure && section.structure.links) {
            section.structure.links.push(linkTo);
          } else if (section.structure && !section.structure.links) {
            section.structure.links = [linkTo];
          } else {
            section.structure = { links: [linkTo], blocks: [] };
          }
        }
      });
    },

    // AddLinkToBlock
    addLinkToBlock: (state, action) => {
      const { blockId, linkTo } = action.payload;
      state.blocks.forEach((block) => {
        if (block.id === blockId) {
          if (block.structure && block.structure.links) {
            block.structure.links.push(linkTo);
          } else if (block.structure && !block.structure.links) {
            block.structure.links = [linkTo];
          } else {
            block.structure = { links: [linkTo], composites: [] };
          }
        }
      });
    },

    // AddLinkToComposite
    addLinkToComposite: (state, action) => {
      const { compositeId, linkTo } = action.payload;
      state.composites.forEach((composite) => {
        if (composite.id === compositeId) {
          if (composite.structure && composite.structure.links) {
            composite.structure.links.push(linkTo);
          } else if (composite.structure && !composite.structure.links) {
            composite.structure.links = [linkTo];
          } else {
            composite.structure = { links: [linkTo], components: [] };
          }
        }
      });
    },

    // AddLinkToComponent
    addLinkToComponent: (state, action) => {
      const { componentId, linkTo } = action.payload;
      state.components.forEach((component) => {
        if (component.id === componentId) {
          if (component.structure && component.structure.links) {
            component.structure.links.push(linkTo);
          } else if (component.structure && !component.structure.links) {
            component.structure.links = [linkTo];
          } else {
            component.structure = { links: [linkTo], elements: [] };
          }
        }
      });
    },

    // edit mandatory status
    editMandatoryStatusElement: (state, action) => {
      const { elementId, componentId, mandatory } = action.payload;

      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          currComponent.structure.elements.forEach((currElement) => {
            if (currElement.id === elementId) {
              currElement.mandatory = mandatory;
            }
          });
        }
      });
    },

    // change the repeatable status of element
    editRepeatableElement: (state, action) => {
      const { elementId, componentId, repeatable } = action.payload;

      let component = {};

      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          component = { ...currComponent };
        }
      });

      const elements =
        component.structure &&
        [...component.structure.elements].map((element) => {
          if (element.id !== elementId) return element;
          const newElement = { ...element, repeatable };
          return newElement;
        });

      const components = [...state.components].map((component) => {
        if (component.id !== componentId) return component;
        const newComponent = {
          ...component,
          structure: { elements },
        };
        return newComponent;
      });
      state.components = components;
    },

    // change the repeatable status of component
    editRepeatableComponent: (state, action) => {
      const { componentId, repeatable } = action.payload;

      let component = {};

      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          component = { ...currComponent, repeatable };
        }
      });

      const components = [...state.components].map((currComponent) => {
        if (currComponent.id !== componentId) return currComponent;
        return component;
      });
      state.components = components;
    },

    // AddLinkToComponent End
    // deleteLink
    deleteLink: (state, action) => {
      const { parentType, parentId, link } = action.payload;
      const parentArr = [...state[parentType]];
      let parentObj = {};
      parentArr.forEach((currObj) => {
        if (currObj.id === parentId) {
          parentObj = { ...currObj };
        }
      });
      const links =
        parentObj.structure &&
        parentObj.structure.links &&
        parentObj.structure.links.filter((currLink) => currLink.id !== link.id);

      parentObj.structure.links = links;
      const newParentArr = [...parentArr].map((currParentObj) => {
        if (currParentObj.id !== parentId) return currParentObj;
        return parentObj;
      });
      state[parentType] = newParentArr;
    },
  },
});

export const { actions, reducer, name: sliceKey } = homePage;
