import { customAlphabet } from "nanoid/async";
import { customAlphabet as customAlphabetSync } from "nanoid";
import _ from "lodash";

export async function generateId() {
  const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 16);
  const id = await nanoid();
  return id;
}

export function generateIdSync() {
  const nanoid = customAlphabetSync("1234567890abcdefghijklmnopqrstuvwxyz", 16);
  const id = nanoid();
  return id;
}

export const sort = (arr) => {
  return arr.sort((a, b) => a.order - b.order);
};

export const move = (a, b) => {
  const temp = b.order;
  b.order = a.order;
  a.order = temp;
  return { updated1: a, updated2: b };
};

export const fixOrder = (sections) => {
  const newSections = _.cloneDeep(sections);
  let prevIndex = 0;
  let currIndex = 1;
  let prevOrder = null;
  while (currIndex < newSections.length) {
    if (prevOrder) {
      newSections[currIndex].order = prevOrder + 1;
      prevOrder = prevOrder + 1;
    }
    if (newSections[prevIndex].order === newSections[currIndex].order) {
      prevOrder = newSections[prevIndex].order;
      newSections[currIndex].order = prevOrder + 1;
      prevOrder = prevOrder + 1;
    }
    currIndex++;
    prevIndex++;
  }
  return newSections;
};

const flow = [
  "state",
  "sections",
  "blocks",
  "composites",
  "components",
  "elements",
];

const orderManagement = (component, delOrder) => {
  const newComponent = component.map((element) => {
    let newElement = _.cloneDeep(element);
    if (element.order >= delOrder) {
      newElement.order = newElement.order - 1;
    }
    return newElement;
  });
  return newComponent;
};

const orderManagementAdd = (component) => {
  let temp = 1;
  component = component.map((element) => {
    if (!element.order || element.order < 1) {
      element.order = 1;
    }
    if (element.order === temp) {
      element.order = element.order + 1;
    }
    temp = element.order;
    return element;
  });
};

export const deletion = (id, ParentId, state, flowIndex) => {
  //base case
  if (flowIndex === 1) {
    let parent = state.form;
    let delOrder = 0;
    let newChildComp = parent.structure[flow[flowIndex]];
    newChildComp = newChildComp.filter((comp) => {
      if (comp.id !== id) {
        return true;
      }
      delOrder = comp.order;
      return false;
    });
    newChildComp = orderManagement(newChildComp, delOrder);
    parent.structure[flow[flowIndex]] = newChildComp;
    // update order in section as well
    let newSections = state?.sections;
    newSections = orderManagement(newSections, delOrder)
    state.sections = newSections
  } else if (ParentId !== null && flowIndex !== 1) {
    let delOrder = 0;
    let allDataOfParent = state[flow[flowIndex - 1]];
    allDataOfParent = allDataOfParent.filter(
      (currComponent) => currComponent.id === ParentId
    );
    let parent = allDataOfParent[0];
    let newChildComp = parent.structure[flow[flowIndex]];
    newChildComp = newChildComp.filter((comp) => {
      if (comp.id !== id) {
        return true;
      }
      delOrder = comp.order;
      return false;
    });
    orderManagement(newChildComp, delOrder);
    parent.structure[flow[flowIndex]] = newChildComp;
  }

  let deletedFromState = [];
  let toBeDeletedChildren = [];
  let currComponentInsideState = state[flow[flowIndex]];
  if (flowIndex !== 5) {
    currComponentInsideState = currComponentInsideState.filter((curr) => {
      if (curr.id !== id) {
        return true;
      }
      deletedFromState =
        curr.structure && curr.structure[flow[flowIndex + 1]]
          ? curr.structure[flow[flowIndex + 1]]
          : [];
      return false;
    });
    state[flow[flowIndex]] = currComponentInsideState;
    deletedFromState.forEach((delId) => {
      toBeDeletedChildren.push(delId.id);
    });
    if (toBeDeletedChildren.length) {
      toBeDeletedChildren.forEach((del) => {
        deletion(del, null, state, flowIndex + 1);
      });
    }
  }
};

export const generateTitle = (genericTitle, order) => {
  return genericTitle + " " + order;
};

/*
// tobecompleted
export const duplicate = (newId, id, ParentId, state, flowIndex) => {
  //base case
  let idNext = generateIdSync();
  let tempComp;
  if (flowIndex === 1) {
    let parent = state.workflow;
    let newChildComp = parent.structure[flow[flowIndex]];
    newChildComp = newChildComp.filter((comp) => {
      if (comp.id === id) {
        tempComp = comp;
      }
      return true;
    });
    tempComp = { ...tempComp, id: newId };
    newChildComp = [...newChildComp, tempComp];
    sort(newChildComp);
    orderManagementAdd(newChildComp);
    parent.structure[flow[flowIndex]] = newChildComp;
  } else if (ParentId !== null && flowIndex !== 1) {
    let allDataOfParent = state[flow[flowIndex - 1]];
    allDataOfParent.filter((currComponent) => currComponent.id === ParentId);
    let parent = allDataOfParent[0];
    let newChildComp = parent.structure[flow[flowIndex]];
    newChildComp = newChildComp.filter((comp) => {
      if (comp.id === id) {
        tempComp = comp;
      }
      return true;
    });
    tempComp = { ...tempComp, id: newId };
    newChildComp = [...newChildComp, tempComp];
    sort(newChildComp);
    orderManagementAdd(newChildComp);
    // eval("parent.structure." + flow[flowIndex] + "=" + "newChildComp");
    parent.structure[flow[flowIndex]] = newChildComp;
  }

  let dataFromState = [];
  let toBeAddedChildren = [];
  let currComponentInsideState = state[flow[flowIndex]];
  if (flowIndex !== 5) {
    currComponentInsideState = currComponentInsideState.filter((curr) => {
      if (curr.id === id) {
        tempComp = curr;
        dataFromState =
          curr.structure && curr.structure[flow[flowIndex + 1]]
            ? curr.structure[flow[flowIndex + 1]]
            : [];
      }
      return true;
    });
    tempComp = { ...tempComp, id: newId };
    currComponentInsideState = [...currComponentInsideState, tempComp];
    state[flow[flowIndex]] = currComponentInsideState;
    dataFromState.forEach((delId) => {
      toBeAddedChildren.push(delId.id);
    });
    if (toBeAddedChildren !== []) {
      toBeAddedChildren.forEach((del) => {
        duplicate(idNext, del, null, state, flowIndex + 1);
      });
    }
  }
};

*/

// function duplicate123(obj) {
//   // Base case: If the input is not an object, return it as is
//   if (typeof obj !== "object" || obj === null) {
//     return obj;
//   }

//   // Create a new object to store the modified copy
//   const newObj = Array.isArray(obj) ? [] : {};

//   for (let key in obj) {
//     if (Object.prototype.hasOwnProperty.call(obj, key)) {
//       if (typeof obj[key] === "object" && obj[key] !== null) {
//         newObj[key] = deepCopyAndModify(obj[key]);
//       } else {
//         newObj[key] = obj[key];
//       }
//     }
//   }
//   if (newObj.id) {
//     newObj.id = generateIdSync();
//   }
//   if (newObj.order) {
//     newObj.order += 1;
//   }

//   return newObj;
// }

export const duplicate = (
  newId,
  id,
  ParentId,
  state,
  flowIndex,
  startFlowIndex
) => {
  let tempComp;

  const deepCopy = (obj) => _.cloneDeep(obj);

  // code below is for cloning data inside parent's structure

  if (flowIndex === 1) {
    let parent = deepCopy(state.form);
    let newChildComp = deepCopy(parent.structure[flow[flowIndex]]);

    newChildComp
      .sort((a, b) => a.order - b.order)
      .sort((a, b) => a.pageNumber - b.pageNumber);
    let tempIndex;
    newChildComp = newChildComp.map((comp, index) => {
      // console.log({ index, tempIndex });
      if (comp.id === id) {
        tempComp = _.cloneDeep(comp);
        tempComp.id = newId;
        // increase the order by 1 if section is being cloned
        if (startFlowIndex === 1) {
          tempComp.order = comp.order + 1;
        }
        tempIndex = index;
      }
      // increase the order of all the sections with order higher than that of section being cloned
      if (tempIndex < index) {
        comp.order = comp.order + 1;
      }
      return comp;
    });

    newChildComp.splice(tempIndex + 1, 0, tempComp);
    state.form.structure.sections = newChildComp;
  } else if (ParentId !== null && flowIndex !== 1) {
    let allDataOfParent = state[flow[flowIndex - 1]];
    let parent = {};
    allDataOfParent.forEach((currComp) => {
      if (currComp.id === ParentId) {
        parent = deepCopy(currComp);
      }
    });
    let newChildComp =
      parent.structure && deepCopy(parent.structure[flow[flowIndex]]);

    newChildComp.sort((a, b) => a.order - b.order);

    let tempIndex;
    newChildComp = newChildComp.map((comp, index) => {
      // console.log({ index, tempIndex });
      if (comp.id === id) {
        tempComp = _.cloneDeep(comp);
        tempComp.id = newId;
        // increase the order by 1 if section is being cloned
        if (flowIndex === startFlowIndex) {
          tempComp.order = comp.order + 1;
        }
        tempIndex = index;
      }
      // increase the order of all the sections with order higher than that of section being cloned
      if (tempIndex < index) {
        comp.order = comp.order + 1;
      }
      return comp;
    });

    newChildComp.splice(tempIndex + 1, 0, tempComp);

    newChildComp.forEach((comp) => {
      if (comp.id === id) {
        tempComp = deepCopy(comp);
        tempComp.id = newId;
        // increase the order of the component in case of this comp is the starting point of cloning
        if (flowIndex === startFlowIndex) {
          tempComp.order = tempComp.order + 1;
        }
      }
    });
    parent = { ...parent, structure: { [flow[flowIndex]]: newChildComp } };
    let newAllDataParent = deepCopy(allDataOfParent);
    let newAllDataParent2 = newAllDataParent.map((currParent) => {
      if (currParent.id === parent.id) {
        return parent;
      }
      return currParent;
    });
    state[flow[flowIndex - 1]] = deepCopy(newAllDataParent2);
  }

  // code below for cloning data inside the respective Arrays

  if (flowIndex === 4) {
 
    let currComponentInsideState = deepCopy(state[flow[flowIndex]]);
    [...state[flow[flowIndex]]].forEach((curr) => {
      if (curr.id === id) {
        tempComp = deepCopy(curr);
        tempComp.id = newId;
        if (flowIndex === startFlowIndex) {
          tempComp.order = tempComp.order + 1;
        }
        const elements =
          tempComp.structure && tempComp.structure.elements
            ? tempComp.structure.elements
            : [];
        const newElements = [...elements].map((element) => {
          return { ...element, id: generateIdSync() };
        });
        tempComp = {
          ...tempComp,
          structure: { ...tempComp.structure, elements: newElements },
        };
      }
    });

    currComponentInsideState.push(tempComp);
    //console.log({ currComponentInsideState });
    state[flow[flowIndex]] = currComponentInsideState;
  } else if (flowIndex !== 5) {
    let dataFromState = [];
    let toBeAddedChildren = [];
    let tempIndex;
    let currComponentInsideState = deepCopy(state[flow[flowIndex]]);
    currComponentInsideState = currComponentInsideState.map((curr, index) => {
      if (curr.id === id) {
        if (
          curr.structure &&
          curr.structure[[flow[flowIndex + 1]]] &&
          curr.structure.links
        ) {
          tempComp = {
            ...curr,
            structure: {
              [flow[flowIndex + 1]]: deepCopy(
                curr.structure[flow[flowIndex + 1]]
              ),
              links: deepCopy(curr.structure.links),
            },
          };
        } else if (curr.structure && curr.structure[[flow[flowIndex + 1]]]) {
          tempComp = {
            ...curr,
            structure: {
              [flow[flowIndex + 1]]: deepCopy(
                curr.structure[flow[flowIndex + 1]]
              ).map((currComp) => {
                const genId = generateIdSync();
                toBeAddedChildren.push({ currId: currComp.id, idNext: genId });
                return { ...currComp, id: genId };
              }),
            },
          };
        } else {
          tempComp = deepCopy(curr);
        }
        tempComp.id = newId;

        if (startFlowIndex === flowIndex) {
          tempComp.order = curr.order + 1;
        }
        tempIndex = index;

        dataFromState = tempComp.structure
          ? tempComp.structure[flow[flowIndex]]
          : [];
        //console.log({ dataFromState, flow: [flow[flowIndex]] });
      }
      if (tempIndex < index) {
        curr.order = curr.order + 1;
      }

      return curr;
    });
    currComponentInsideState.splice(tempIndex+1, 0, tempComp);
    state[flow[flowIndex]] = currComponentInsideState;

    (dataFromState ? dataFromState : []).forEach((currComp) => {
      toBeAddedChildren.push(currComp.id);
    });

    if (toBeAddedChildren.length !== 0) {
      toBeAddedChildren.forEach((ids) => {
        duplicate(ids.idNext, ids.currId, null, state, flowIndex + 1, startFlowIndex);
      });
    }
  }
};

const cloneForm = (form, sections, blocks, composites, components) => {
  let newSections = [...sections];
  let newForm = {
    ...form,
    id: generateIdSync(),
    structure: {
      ...form.structure,
      sections: form.structure.sections.map((sectionCurr) => {
        const sectionId = sectionCurr.id;
        const newId = generateIdSync();
        newSections = newSections.map((section) => {
          if (section.id === sectionId) {
            return {
              ...section,
              id: newId,
            };
          }
          return section;
        });
        return {
          ...sectionCurr,
          id: newId,
        };
      }),
    },
  };
  return newForm, newSections;
};

export const duplicate2 = ({
  parentType,
  form,
  sections,
  blocks,
  composites,
  components,
  id,
  parentId,
}) => {
  console.log({
    parentType,
    form,
    sections,
    blocks,
    composites,
    components,
    id,
    parentId,
  });
  if (parentType === "form") {
    const newSection = sections.find((section) => section.id === id);
    newSection.id = generateIdSync();
    const order = newSection.order + 1;
    newSection.order = order;
    form.structure.sections.push({
      id: newSection.id,
      title: newSection.title,
      order: order,
      pageNumber: newSection.pageNumber,
    });
    const sectionStructureBlocks = newSection.structure.blocks.reduce(
      (acc, cv) => {
        const { title, order } = cv;
        const id = generateIdSync();
        cv.id = id;
        acc.push({ id, title, order });
        return acc;
      },
      []
    );
    newSection.structure = { blocks: sectionStructureBlocks };
    sections.push(newSection);
    sections
      .sort((a, b) => a.order - b.order)
      .sort((a, b) => a.pageNumber - b.pageNumber);
    console.log({ form, sections });
  }
};


// the workflow structure ids & form ids are assigned new ids using this function.
export const cloneWorkflowStructure = (obj, newWorkflowTitle) => {
  const idMap = {}; // To track old and new IDs
  const clonedWorkflowData = JSON.parse(JSON.stringify(obj.workflow));
  clonedWorkflowData.title = newWorkflowTitle;
  // Function to recursively replace id keys with generateIdSync
  const replaceIds = (node) => {
      if (typeof node === 'object' && node !== null) {
          for (const key in node) {
              if (key === 'id') {
                  const newId = generateIdSync();
                  idMap[node.id] = newId;
                  node[key] = newId;
              } else {
                  replaceIds(node[key]);
              }
          }
      }
  };

  replaceIds(clonedWorkflowData);
  // Using idMap to replace the existing form id with new assigned ids.
  const updatedFormIds = obj.forms.map(form => {
      const { id, ...rest } = form;
      const oldId = id; // OldId key will be used to identify which form is assigned with which new id when we copy the structure of forms.
      return { id: generateIdSync(), oldId, ...rest };
  });
  return { workflow: clonedWorkflowData, form: updatedFormIds };
};


//This function updates all the id of sections/blocks/composites/components/elements also updates the form id according to the id update in workflow structure.
export const updateFormStructureIds = (formData, newFormId) => {
  const idMap = {}; // To track old and new IDs
  const formDataCopy = JSON.parse(JSON.stringify(formData));

  // Update form ID
  formDataCopy.form.id = newFormId;

  // Update section IDs
  formDataCopy.form.structure.sections.forEach((section) => {
    const oldSectionId = section.id;
    const newSectionId = generateIdSync(); 
    idMap[oldSectionId] = newSectionId;
    section.id = newSectionId;
  });

  // Update block IDs within each section
  formDataCopy.sections.forEach((section) => {
    section.structure.blocks.forEach((block) => {
      const oldBlockId = block.id;
      const newBlockId = generateIdSync(); 
      idMap[oldBlockId] = newBlockId; 
      block.id = newBlockId;
    });
  });

  // Update composite IDs within each block
  formDataCopy.blocks.forEach((block) => {
    block.structure.composites.forEach((composite) => {
      const oldCompositeId = composite.id;
      const newCompositeId = generateIdSync(); 
      idMap[oldCompositeId] = newCompositeId; 
      composite.id = newCompositeId;
    });
  });

  // Update component IDs within each composite
  formDataCopy.composites.forEach((composite) => {
    composite.structure.components.forEach((component) => {
      const oldComponentId = component.id;
      const newComponentId = generateIdSync();
      idMap[oldComponentId] = newComponentId; 
      component.id = newComponentId;
    });
  });

  // Update element IDs within each component
  formDataCopy.components.forEach((component) => {
    component.structure.elements.forEach((element) => {
      const oldElementId = element.id;
      const newElementId = generateIdSync();
      idMap[oldElementId] = newElementId; 
      element.id = newElementId;
    });
  });

  // Update all references to old IDs with new IDs in the form structure
  function updateReferences(object) {
    if (typeof object === 'object' && object !== null) {
      for (const key in object) {
        if (typeof object[key] === 'string' && idMap[object[key]]) {
          object[key] = idMap[object[key]];
        } else if (typeof object[key] === 'object' && object[key] !== null) {
          updateReferences(object[key]); // Recursively update nested objects
        }
      }
    }
  }

  updateReferences(formDataCopy);

  return formDataCopy; 
};

 
 