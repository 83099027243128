import warehouseImage from "assets/svg/warehouse-solid.svg";

//Quantity Insights
export const quantityInsightsHeader = [
  { displayText: "Commodity", keyText: "commodity", hasSort: true },
  { displayText: "Quantity MT", keyText: "quantity", hasSort: true },
  { displayText: "No of Bags", keyText: "noOfBags", hasSort: true },
];
export const warehouseInsightsHeader = [
  {
    displayText: "Warehouse Name",
    keyText: "originName",
    hasSort: true,
    hasTooltip: false,
  },
  { displayText: "Warehouse Code", keyText: "originCode", hasSort: true },
  { displayText: "Warehouse Score", keyText: "warehouseScore", hasSort: true },
  { displayText: "No Of Bags", keyText: "noOfBags", hasSort: true },
  { displayText: "Quantity (MT)", keyText: "quantity", hasSort: true },
  // { displayText: "Valuation (Cr.)", keyText: "portfolioSize", hasSort: true },
];
export const headerArray2 = [
  { displayText: "Auditor Name", keyText: "auditorName", hasSort: true },
  { displayText: "Auditor Score", keyText: "auditorScore", hasSort: true },
  { displayText: "No Of Bags", keyText: "noOfBags", hasSort: true },
  { displayText: "Quantity (MT)", keyText: "quantityMt", hasSort: true },
  { displayText: "Valuation (Cr.)", keyText: "valuation", hasSort: true },
  {
    displayText: "Avg Inspection / Day",
    keyText: "inspectionPerDay",
    hasSort: true,
  },
  { displayText: "Work Days", keyText: "workDays", hasSort: true },
];

export let data2 = [
  {
    auditorName: "Warehouse Name",
    auditorScore: "99%",
    noOfBags: `25,365`,
    quantityMt: `45,365`,
    valuation: `25.3`,
    inspectionPerDay: "20",
    workDays: "25",
  },
  {
    auditorName: "Warehouse Name",
    auditorScore: "99%",
    noOfBags: `25,365`,
    quantityMt: `45,365`,
    valuation: `25.3`,
    inspectionPerDay: "20",
    workDays: "25",
  },
  {
    auditorName: "Warehouse Name",
    auditorScore: "99%",
    noOfBags: `25,365`,
    quantityMt: `45,365`,
    valuation: `25.3`,
    inspectionPerDay: "20",
    workDays: "25",
  },
  {
    auditorName: "Warehouse Name",
    auditorScore: "99%",
    noOfBags: `25,365`,
    quantityMt: `45,365`,
    valuation: `25.3`,
    inspectionPerDay: "20",
    workDays: "25",
  },
  {
    auditorName: "Warehouse Name",
    auditorScore: "99%",
    noOfBags: `25,365`,
    quantityMt: `45,365`,
    valuation: `25.3`,
    inspectionPerDay: "20",
    workDays: "25",
  },
  {
    auditorName: "Warehouse Name",
    auditorScore: "99%",
    noOfBags: `25,365`,
    quantityMt: `45,365`,
    valuation: `25.3`,
    inspectionPerDay: "20",
    workDays: "25",
  },
  {
    auditorName: "Warehouse Name",
    auditorScore: "99%",
    noOfBags: `25,365`,
    quantityMt: `45,365`,
    valuation: `25.3`,
    inspectionPerDay: "20",
    workDays: "25",
  },
  {
    auditorName: "Warehouse Name",
    auditorScore: "99%",
    noOfBags: `25,365`,
    quantityMt: `45,365`,
    valuation: `25.3`,
    inspectionPerDay: "20",
    workDays: "25",
  },
  {
    auditorName: "Warehouse Name",
    auditorScore: "99%",
    noOfBags: `25,365`,
    quantityMt: `45,365`,
    valuation: `25.3`,
    inspectionPerDay: "20",
    workDays: "25",
  },
  {
    auditorName: "Warehouse Name",
    auditorScore: "99%",
    noOfBags: `25,365`,
    quantityMt: `45,365`,
    valuation: `25.3`,
    inspectionPerDay: "20",
    workDays: "25",
  },
];
export const gaugeColor1 = ["#C43333", "#E8CF3A", "#39AEA3"];
export const gaugeColor2 = ["#39AEA3", "#E8CF3A", "#C43333"];
export const arcLength1 = [60, 20, 20];
export const arcLength2 = [20, 20, 80];
export const needlePosition = 0.8;

export const options = [{ id: 1, name: "Kotak Bank" }];

export const line = [
  [
    {
      auditDate: "2023-05-08",
      inspectionCount: "1",
    },
    {
      auditDate: "2023-05-09",
      inspectionCount: "1",
    },
    {
      auditDate: "2023-05-17",
      inspectionCount: "2",
    },
    {
      auditDate: "2023-05-18",
      inspectionCount: "2",
    },
    {
      auditDate: "2023-05-19",
      inspectionCount: "5",
    },
    {
      auditDate: "2023-05-20",
      inspectionCount: "1",
    },
    {
      auditDate: "2023-05-22",
      inspectionCount: "7",
    },
    {
      auditDate: "2023-05-23",
      inspectionCount: "6",
    },
    {
      auditDate: "2023-05-24",
      inspectionCount: "6",
    },
    {
      auditDate: "2023-05-25",
      inspectionCount: "5",
    },
    {
      auditDate: "2023-05-26",
      inspectionCount: "1",
    },
    {
      auditDate: "2023-05-27",
      inspectionCount: "2",
    },
    {
      auditDate: "2023-05-28",
      inspectionCount: "1",
    },
    {
      auditDate: "2023-05-29",
      inspectionCount: "2",
    },
  ],
  [
    {
      auditDate: "2023-06-07",
      inspectionCount: "1",
    },
    {
      auditDate: "2023-06-09",
      inspectionCount: "8",
    },
    {
      auditDate: "2023-06-10",
      inspectionCount: "10",
    },
    {
      auditDate: "2023-06-12",
      inspectionCount: "7",
    },
    {
      auditDate: "2023-06-13",
      inspectionCount: "2",
    },
    {
      auditDate: "2023-06-14",
      inspectionCount: "12",
    },
    {
      auditDate: "2023-06-15",
      inspectionCount: "18",
    },
    {
      auditDate: "2023-06-16",
      inspectionCount: "15",
    },
    {
      auditDate: "2023-06-17",
      inspectionCount: "1",
    },
    {
      auditDate: "2023-06-18",
      inspectionCount: "1",
    },
    {
      auditDate: "2023-06-19",
      inspectionCount: "7",
    },
    {
      auditDate: "2023-06-30",
      inspectionCount: "1",
    },
  ],
  [
    {
      auditDate: "2023-07-01",
      inspectionCount: "1",
    },
    {
      auditDate: "2023-07-05",
      inspectionCount: "9",
    },
    {
      auditDate: "2023-07-06",
      inspectionCount: "14",
    },
    {
      auditDate: "2023-07-07",
      inspectionCount: "18",
    },
    {
      auditDate: "2023-07-08",
      inspectionCount: "4",
    },
    {
      auditDate: "2023-07-10",
      inspectionCount: "8",
    },
    {
      auditDate: "2023-07-11",
      inspectionCount: "27",
    },
    {
      auditDate: "2023-07-12",
      inspectionCount: "14",
    },
    {
      auditDate: "2023-07-13",
      inspectionCount: "12",
    },
    {
      auditDate: "2023-07-14",
      inspectionCount: "17",
    },
    {
      auditDate: "2023-07-15",
      inspectionCount: "21",
    },
    {
      auditDate: "2023-07-16",
      inspectionCount: "3",
    },
    {
      auditDate: "2023-07-17",
      inspectionCount: "1",
    },
    {
      auditDate: "2023-07-18",
      inspectionCount: "5",
    },
    {
      auditDate: "2023-07-19",
      inspectionCount: "4",
    },
    {
      auditDate: "2023-07-20",
      inspectionCount: "5",
    },
    {
      auditDate: "2023-07-21",
      inspectionCount: "7",
    },
    {
      auditDate: "2023-07-22",
      inspectionCount: "3",
    },
  ],
  [
    {
      auditDate: "2023-08-03",
      inspectionCount: "1",
    },
  ],
];

// Your array of objects with timestamps and costs
// const data = [
//   { timestamp: "2023-09-01", cost: 100 },
//   { timestamp: "2023-09-01", cost: 100 },
//   { timestamp: "2023-09-05", cost: 202 },
//   { timestamp: "2023-09-12", cost: 153 },
//   { timestamp: "2023-09-28", cost: 150 },
//   { timestamp: "2023-09-27", cost: 154 },
//   { timestamp: "2023-09-26", cost: 150 },
//   { timestamp: "2023-09-12", cost: 152 },
// ];

// // Convert timestamps to Date objects and group by week
// const groupedData = data.reduce((result, item) => {
//   const date = new Date(item.timestamp);
//   const weekStart = startOfWeek(date);
//   const weekKey = format(weekStart, "yyyy-MM-dd");

//   if (!result[weekKey]) {
//     result[weekKey] = { weekStart, costSum: 0 };
//   }

//   result[weekKey].costSum += item.cost;

//   return result;
// }, {});

// // Convert the grouped data to an array of weeks
// const weeksArray = Object.values(groupedData);

// // Output sums with week numbers skipping weeks without data
// weeksArray.forEach((week, index) => {
//   const weekNumber = index + 1;
//   //console.log(`Week ${weekNumber} sum: ${week.costSum}`);
// });

// My approval page variables

export const auditorNameArray = [
  { id: 1, name: "Ankshit Sharma" },
  { id: 2, name: "Ajay Kumar Sharma" },
  { id: 3, name: "Vinayak Rajesh Gupta" },
  { id: 4, name: "Anup Kumar Singh" },
  { id: 5, name: "Anil Kumar Shekhar" },
];

export const pendingApprovalHeaders = [
  { displayText: "Audit Date", keyText: "auditDate", hasSort: true },
  { displayText: "Client Name", keyText: "orgName", hasSort: true },
  {
    displayText: "Warehouse Name",
    keyText: "originName",
    hasSort: true,
    hasTooltip: true,
  },
  { displayText: "Warehouse Code", keyText: "originCode", hasSort: true },
  { displayText: "Location", keyText: "originLocation", hasSort: true },
  { displayText: "Auditor Name", keyText: "auditorName", hasSort: true },
  { displayText: "Actions", keyText: "action", hasSort: false },
];

export const pendingApprovalData = [
  {
    clientName: "Star Finance Bank",
    warehouseName: "Dhara Logistics Warehouse",
    warehouseCode: "D1M34KR5R",
    location: "Mohali Punjab",
    auditorName: "Ankshit Sharma",
    dateOfRequest: "23 May 2023",
  },
  {
    clientName: "Dumbo Bank",
    warehouseName: "Krishna Storage Solutions",
    warehouseCode: "7H9FH9NSO",
    location: "Chennai, Tamil Nadu",
    auditorName: "Ajay Kumar Sharma",
    dateOfRequest: "23 May 2023",
  },
  {
    clientName: "Star Finance Bank",
    warehouseName: "Dhara Logistics Warehouse",
    warehouseCode: "D1M34KR5R",
    location: "Mohali Punjab",
    auditorName: "Ankshit Sharma",
    dateOfRequest: "23 May 2023",
  },
  {
    clientName: "Dumbo Bank",
    warehouseName: "Krishna Storage Solutions",
    warehouseCode: "7H9FH9NSO",
    location: "Chennai, Tamil Nadu",
    auditorName: "Ajay Kumar Sharma",
    dateOfRequest: "23 May 2023",
  },
  {
    clientName: "Star Finance Bank",
    warehouseName: "Dhara Logistics Warehouse",
    warehouseCode: "D1M34KR5R",
    location: "Mohali Punjab",
    auditorName: "Ankshit Sharma",
    dateOfRequest: "23 May 2023",
  },
  {
    clientName: "Dumbo Bank",
    warehouseName: "Krishna Storage Solutions",
    warehouseCode: "7H9FH9NSO",
    location: "Chennai, Tamil Nadu",
    auditorName: "Ajay Kumar Sharma",
    dateOfRequest: "23 May 2023",
  },
  {
    clientName: "Star Finance Bank",
    warehouseName: "Dhara Logistics Warehouse",
    warehouseCode: "D1M34KR5R",
    location: "Mohali Punjab",
    auditorName: "Ankshit Sharma",
    dateOfRequest: "23 May 2023",
  },
  {
    clientName: "Dumbo Bank",
    warehouseName: "Krishna Storage Solutions",
    warehouseCode: "7H9FH9NSO",
    location: "Chennai, Tamil Nadu",
    auditorName: "Ajay Kumar Sharma",
    dateOfRequest: "23 May 2023",
  },
  {
    clientName: "Star Finance Bank",
    warehouseName: "Dhara Logistics Warehouse",
    warehouseCode: "D1M34KR5R",
    location: "Mohali Punjab",
    auditorName: "Ankshit Sharma",
    dateOfRequest: "23 May 2023",
  },
  {
    clientName: "Dumbo Bank",
    warehouseName: "Krishna Storage Solutions",
    warehouseCode: "7H9FH9NSO",
    location: "Chennai, Tamil Nadu",
    auditorName: "Ajay Kumar Sharma",
    dateOfRequest: "23 May 2023",
  },
  {
    clientName: "Star Finance Bank",
    warehouseName: "Dhara Logistics Warehouse",
    warehouseCode: "D1M34KR5R",
    location: "Mohali Punjab",
    auditorName: "Ankshit Sharma",
    dateOfRequest: "23 May 2023",
  },
  {
    clientName: "Dumbo Bank",
    warehouseName: "Krishna Storage Solutions",
    warehouseCode: "7H9FH9NSO",
    location: "Chennai, Tamil Nadu",
    auditorName: "Ajay Kumar Sharma",
    dateOfRequest: "23 May 2023",
  },
];

export const approvedHeaders = [
  { displayText: "Audit Date", keyText: "auditDate", hasSort: true },
  { displayText: "Approved Date", keyText: "inspectionDate", hasSort: true },
  { displayText: "Client Name", keyText: "orgName", hasSort: true },
  {
    displayText: "Warehouse Name",
    keyText: "originName",
    hasSort: true,
    hasTooltip: true,
  },
  { displayText: "Warehouse Code", keyText: "externalCode", hasSort: true },
  { displayText: "Location", keyText: "originLocation", hasSort: true },
  { displayText: "Auditor Name", keyText: "auditorName", hasSort: true },
  { displayText: "Actions", keyText: "action", hasSort: false },
];

export const approvedData = [
  {
    clientName: "Star Finance Bank",
    warehouseName: "Dhara Logistics Warehouse",
    warehouseCode: "D1M34KR5R",
    location: "Mohali Punjab",
    auditorName: "Ankshit Sharma",
    dateOfRequest: "23 May 2023",
    approvedDate: "28 May 2023",
  },
  {
    clientName: "Dumbo Bank",
    warehouseName: "Krishna Storage Solutions",
    warehouseCode: "7H9FH9NSO",
    location: "Chennai, Tamil Nadu",
    auditorName: "Ajay Kumar Sharma",
    dateOfRequest: "23 May 2023",
    approvedDate: "28 May 2023",
  },
  {
    clientName: "Star Finance Bank",
    warehouseName: "Dhara Logistics Warehouse",
    warehouseCode: "D1M34KR5R",
    location: "Mohali Punjab",
    auditorName: "Ankshit Sharma",
    dateOfRequest: "23 May 2023",
    approvedDate: "28 May 2023",
  },
  {
    clientName: "Dumbo Bank",
    warehouseName: "Krishna Storage Solutions",
    warehouseCode: "7H9FH9NSO",
    location: "Chennai, Tamil Nadu",
    auditorName: "Ajay Kumar Sharma",
    dateOfRequest: "23 May 2023",
    approvedDate: "28 May 2023",
  },
  {
    clientName: "Star Finance Bank",
    warehouseName: "Dhara Logistics Warehouse",
    warehouseCode: "D1M34KR5R",
    location: "Mohali Punjab",
    auditorName: "Ankshit Sharma",
    dateOfRequest: "23 May 2023",
    approvedDate: "28 May 2023",
  },
  {
    clientName: "Dumbo Bank",
    warehouseName: "Krishna Storage Solutions",
    warehouseCode: "7H9FH9NSO",
    location: "Chennai, Tamil Nadu",
    auditorName: "Ajay Kumar Sharma",
    dateOfRequest: "23 May 2023",
    approvedDate: "28 May 2023",
  },
  {
    clientName: "Star Finance Bank",
    warehouseName: "Dhara Logistics Warehouse",
    warehouseCode: "D1M34KR5R",
    location: "Mohali Punjab",
    auditorName: "Ankshit Sharma",
    dateOfRequest: "23 May 2023",
    approvedDate: "28 May 2023",
  },
  {
    clientName: "Dumbo Bank",
    warehouseName: "Krishna Storage Solutions",
    warehouseCode: "7H9FH9NSO",
    location: "Chennai, Tamil Nadu",
    auditorName: "Ajay Kumar Sharma",
    dateOfRequest: "23 May 2023",
    approvedDate: "28 May 2023",
  },
  {
    clientName: "Star Finance Bank",
    warehouseName: "Dhara Logistics Warehouse",
    warehouseCode: "D1M34KR5R",
    location: "Mohali Punjab",
    auditorName: "Ankshit Sharma",
    dateOfRequest: "23 May 2023",
    approvedDate: "28 May 2023",
  },
  {
    clientName: "Dumbo Bank",
    warehouseName: "Krishna Storage Solutions",
    warehouseCode: "7H9FH9NSO",
    location: "Chennai, Tamil Nadu",
    auditorName: "Ajay Kumar Sharma",
    dateOfRequest: "23 May 2023",
    approvedDate: "28 May 2023",
  },
  {
    clientName: "Star Finance Bank",
    warehouseName: "Dhara Logistics Warehouse",
    warehouseCode: "D1M34KR5R",
    location: "Mohali Punjab",
    auditorName: "Ankshit Sharma",
    dateOfRequest: "23 May 2023",
    approvedDate: "28 May 2023",
  },
  {
    clientName: "Dumbo Bank",
    warehouseName: "Krishna Storage Solutions",
    warehouseCode: "7H9FH9NSO",
    location: "Chennai, Tamil Nadu",
    auditorName: "Ajay Kumar Sharma",
    dateOfRequest: "23 May 2023",
    approvedDate: "28 May 2023",
  },
];
