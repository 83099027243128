import React, { useState } from "react";
import {
   Button,
   Box,
   List,
   ListItem,
   Text,
   Collapse,
   Checkbox,
   useOutsideClick,
   InputGroup,
   Input,
   IconButton,
   InputRightElement,
   background,
   Flex,
   Badge,
   CloseButton,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import { DropdownArrowIcon } from "components/Icons/Icons";
import "../../views/Dashboard/Inspection Table/index";
import { getUniqueObjectsByProperty } from "utils/commonFunctions";
import { CloseIcon } from "@chakra-ui/icons";
import { getUniqueObjectsById } from "utils/commonFunctions";
import { truncateWords } from "utils/commonFunctions";
const style = {
   sideDropList: {
      display: "flex",
      padding: "6px 12px",
      alignItems: "center",
      gap: "10px",
      alignSelf: "stretch",
   },
};
const styles = {
   heading: {
      color: "#4A5568",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "20px",
   },
   itemBadge: {
      backgroundColor: "#718096",
      color: "white",
      borderRadius: "6px",
      display: "flex",
      alignItems: "center",
      padding: "0px 6px",
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "142.857%",
      marginRight: "8px",
      width: "fit-content",
   },
   hide: {
      display: "none",
   },
};

function getNameById(id, arrayOfObjects, key) {
   const foundObject = arrayOfObjects.find((obj) => {
      return obj.auditorId === id;
   });
   return foundObject ? foundObject.auditors : null;
}
const DropdownWithCheckboxes = ({
   dropDownList,
   property,
   handleSelectChange,
   selectedItems,
   title,
   isMobile,
   extraProperty,
   handleRemoveItem,
}) => {
   const ref = React.useRef();
   const [inputValue, setInputValue] = useState("");
   const [isOpen, setIsOpen] = useState(false);

   let uniqueObjects =
      dropDownList && dropDownList.length && typeof dropDownList[0][property] === "string"
         ? getUniqueObjectsByProperty(dropDownList, property)
         : getUniqueObjectsById(dropDownList, extraProperty);

   let filteredWarehouseOptions = inputValue
      ? uniqueObjects?.filter((inspection) =>
           inspection[extraProperty || property]?.toLowerCase().includes(inputValue.toLowerCase())
        )
      : uniqueObjects;

   const sortedArray = filteredWarehouseOptions?.sort((a, b) =>
      a[property]?.localeCompare(b[property])
   );

   const handleInputVal = (value) => {
      setInputValue(value);
   };

   const toggleDropdown = () => {
      setIsOpen(!isOpen);
   };

   useOutsideClick({
      ref: ref,
      handler: () => setIsOpen(false),
   });
   const isInputEmpty = inputValue.trim() === "";
   return (
      <Box ref={ref} position="relative">
         <InputGroup borderRadius="15px" height="40px">
            <Input
               _focus={{
                  bg: "#F7FAFC",
               }}
               className="add-filter-btn"
               color="#718096"
               _placeholder={{
                  fontSize: "15px",
               }}
               value={inputValue}
               placeholder={title}
               onClick={() => {
                  if (isOpen !== true) {
                     toggleDropdown(true);
                  }
               }}
               onChange={(e) => {
                  handleInputVal(e.target.value);
               }}
            />
            <InputRightElement>
               {isInputEmpty ? (
                  <IconButton
                     bg="inherit"
                     _hover={{ cursor: "pointer" }}
                     _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                     }}
                     _focus={{
                        boxShadow: "none",
                     }}
                     icon={<DropdownArrowIcon />}
                     onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown();
                     }}
                  ></IconButton>
               ) : (
                  <IconButton
                     bg="inherit"
                     _hover="none"
                     _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                     }}
                     _focus={{
                        boxShadow: "none",
                     }}
                     icon={<CloseIcon color="gray.400" height="15px" mt="11px" />}
                     onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown();
                        handleInputVal("");
                     }}
                  ></IconButton>
               )}
            </InputRightElement>
         </InputGroup>
         <Flex
            marginTop="16px"
            flexWrap="wrap"
            rowGap="8px"
            position="relative"
            sx={selectedItems && selectedItems[property]?.length > 0 ? "" : styles.hide}
         >
            {selectedItems &&
               selectedItems[property]?.map((item, index) => {
                  const modifyItem = extraProperty
                     ? getNameById(item, sortedArray, extraProperty)
                     : item;
                  return (
                     <Badge key={index} sx={styles.itemBadge} variant="subtle">
                        {modifyItem && modifyItem.split(" ").length > 2
                           ? truncateWords(modifyItem)
                           : modifyItem}
                        <CloseButton size="sm" onClick={() => handleRemoveItem(item, property)} />
                     </Badge>
                  );
               })}
         </Flex>
         <Collapse
            className={isOpen ? (isMobile ? "side-drop-down-mobile" : "side-drop-down") : "hide"}
            in={isOpen}
         >
            <List className="scrollable-dropdown">
               {sortedArray?.length === 0 ? (
                  <Box className="name-location-container">
                     <Text key={uuidv4()} className="warehouse-name-text">
                        {`No matching ${title}`}
                     </Text>
                  </Box>
               ) : (
                  sortedArray?.map((item, index) => {
                     return (
                        <ListItem
                           key={item.id}
                           sx={style.sideDropList}
                           _hover={{ background: "#F9FDFD" }}
                           style={{ textTransform: "capitalize" }}
                           display="flex"
                           justifyContent="flex-start"
                        >
                           <Checkbox
                              cursor="pointer"
                              colorScheme="teal"
                              isChecked={selectedItems[property]?.includes(item[`${property}`])}
                              onChange={() => handleSelectChange(item[`${property}`], property)}
                           />
                           <Text
                              onClick={() => {
                                 handleSelectChange(item[`${property}`], property);
                              }}
                              fontSize="14px"
                              fontFamily="Inter"
                              cursor="pointer"
                           >
                              {item[`${extraProperty || property}`]?.toLowerCase()}
                           </Text>
                        </ListItem>
                     );
                  })
               )}
            </List>
         </Collapse>
      </Box>
   );
};

export default DropdownWithCheckboxes;
