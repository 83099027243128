import React from "react";
import { HStack, VStack, Box, Text } from "@chakra-ui/react";
import MapChart from "components/Charts/MapChart";

const styles = {
  geoInsightsContainer: {
    borderRadius: "15px",
    background: "#FFF",
    boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)",
    gap: "0px",
    border: "1px solid #E2E8F0",
  },
  geoheaderContainer: {
    width: "100%",
    justifyContent: "space-between",
    borderBottom: "1px solid #E2E8F0",
  },
  header: {
    color: "#7E8DA2",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%",
  },
  quantityHeaderContainer: {
    width: "100%",
    padding: "20px",
  },
};

export default function GeographicalReach({ data }) {
  const { mapChartData } = data;
  return (
    <VStack sx={styles.geoInsightsContainer} bg={"#FFF"} width={"100%"}>
      <HStack sx={styles.geoheaderContainer}>
        <Box sx={styles.quantityHeaderContainer}>
          <Text sx={styles.header}>Geographical Reach</Text>
        </Box>
      </HStack>
      <Box width={"100%"}>
        {mapChartData.length !== 0 && <MapChart regionData={mapChartData} />}
      </Box>
    </VStack>
  );
}
