import React, { useRef, useContext, useState } from "react";
import { Button, Box } from "@chakra-ui/react";
import { useOutsideClick } from "@chakra-ui/react";
import { FilterIcon } from "components/Icons/Icons";
import FilterCategory from "components/FilterCategory/FilterCategory";
import { AddFilterContext } from "../../context/AddFilterContext";
import "../index.css";
import { styles } from "./AddFilter.module";

function AddFilter({ dropDownList, allFilterCategories, handleApplyButton, isMobile }) {
   const {
      selectedItems,
      toggleCollapse,
      handleRemoveItem,
      handleSelectChange,
      handleItemClick,
      handleClear,
      isOpen,
      setIsOpen,
      handleQuery,
      query,
   } = useContext(AddFilterContext);
   const ref = useRef();

   useOutsideClick({
      ref: ref,
      handler: () => setIsOpen(false),
   });

   return (
      <Box ref={ref} style={{ position: "relative" }}>
         <Button
            sx={styles.button}
            rightIcon={<FilterIcon width="30px" height="18px" color="#A0AEC0" />}
            variant="outline"
            onClick={toggleCollapse}
         >
            Add Filter
            {(query.cmName.length > 0 ||
               query.auditorId.length > 0 ||
               query.originLocation.length > 0) && <Box sx={styles.greenCircle}></Box>}
         </Button>
         <Box className={isOpen ? (isMobile ? "drop-Down-mobile" : "drop-Down") : "hide"}>
            {allFilterCategories.map((category) => (
               <FilterCategory
                  key={category.property}
                  title={category.title}
                  property={category.property}
                  selectedItems={selectedItems}
                  handleRemoveItem={handleRemoveItem}
                  handleSelectChange={handleSelectChange}
                  handleItemClick={handleItemClick}
                  dropDownList={dropDownList}
                  isMobile={isMobile}
                  extraProperty={category.extraProperty}
               />
            ))}

            <Box sx={styles.clearApplyBtnContainer}>
               <Button sx={styles.clearApplyBtn} onClick={handleClear}>
                  Clear
               </Button>
               <Button
                  sx={styles.clearApplyBtn}
                  onClick={() => {
                     handleQuery();
                     handleApplyButton();
                  }}
                  colorScheme="login"
               >
                  Apply
               </Button>
            </Box>
         </Box>
      </Box>
   );
}

export default AddFilter;
