import React, { useCallback, useEffect, useState } from "react";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, sliceKey, reducer } from "./slice";
import { workflowDataSaga } from "./saga";
import * as selectors from "./selectors";
import { useSelector, useDispatch } from "react-redux";
import { formStateManager } from "utils/formStateManager";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { postData } from "services/index";

// Chakra UI imports and SVGs
import {
  Text,
  HStack,
  VStack,
  Box,
  useDisclosure,
  useToast,
  Flex,
  Stack,
  Avatar,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  UnorderedList,
  PopoverBody,
  ListItem,
  PopoverArrow,
} from "@chakra-ui/react";

// Other assets
import MdDate from "assets/svg/MdDateRange.svg";
import SideArrow from "assets/svg/SideArrow.svg";
import Location from "assets/svg/locationModified.svg";
import FiUser from "assets/svg/FiUser.svg";

// Other utility imports
import {
  capitalizeStringUpdated,
  extractDayMonthYearFromTimestamp,
} from "utils/commonFunctions";
import { validateEmail, validateMobileNumber } from "utils/helper";
import useToastNotification from "utils/useToastNotification";

// Reactlib Button (keep this as it's commonly used and may not benefit from lazy loading)
import { Button } from "@agnext/reactlib";

// Lazy loaded components
const HeaderGray = React.lazy(() => import("../../components/headerGray"));
const Loader = React.lazy(() =>
  import("../../../../components/Loader/Loader").then((module) => ({
    default: module.Loader,
  }))
);
const Section = React.lazy(() => import("./section"));
const AreYouSureModal = React.lazy(() =>
  import("./popups/AreYouSure").then((module) => ({
    default: module.AreYouSureModal,
  }))
);
const DiscardModal = React.lazy(() =>
  import("./popups/DiscardModal").then((module) => ({
    default: module.DiscardModal,
  }))
);
const AuditorTackedInfo = React.lazy(() =>
  import("components/auditorLocationTracking/AuditorTackedInfo")
);
const StatusBadge = React.lazy(() =>
  import("components/StatusBadge/StatusBadge")
);

export default function ReviewWorkflow({
  formId,
  inspectionId,
  jobId,
  taskId,
  workflowData,
  handleFormClick,
  isFormSelected,
  selectedFormStyleMemo,
  inspectionDetails,
  tasksData,
}) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: workflowDataSaga });

  const dispatch = useDispatch();
  const history = useHistory();

  const [files, setFiles] = useState([]);

  const [changesSaved, setChangesSaved] = useState(false);

  const [formData, setFormData] = useState({});
  const [page, setPage] = useState(0);

  const [reviewData, setReviewData] = useState([]);

  const [discardButton, setDiscardButton] = useState(0);

  const [displayData, setDisplayData] = useState([]);

  const [prevPages, setPrevPages] = useState([]);

  const [dataArr, setdataArr] = useState([]);

  const [btnStatusArr, setBtnStatusArr] = useState([]);

  const [sectionElementMap, setSectionElementMap] = useState({});

  const [disabledSectionCheck, setDisabledSectionCheck] = useState([]);

  const [allElementsId, setAllElementsId] = useState({});

  const [tempError, setTempError] = useState(false);
  const [isValidFormat, setIsValidFormat] = useState(null);

  // const showToast = useToastNotification();

  const handleFilesChange = (file) => {
    const newFiles = [...files];
    newFiles.push(file);
    setFiles(newFiles);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure(); // Change variable names here

  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  const toast = useToast();
  const nToast = useToastNotification();

  const showSuccessToast = () => {
    toast({
      title: "Changes Saved",
      description: "Your changes have been saved successfully.",
      status: "success",
      duration: 1500,
      isClosable: true,
    });
  };

  const showApprovedToast = () => {
    toast({
      title: "Approved Successfully",
      description: "Your form is approved .",
      status: "success",
      duration: 1500,
      isClosable: true,
    });
  };

  const form = useSelector(selectors.selectForm);
  const toBeReviewedData = useSelector(selectors.selecReviewData);
  const sections = useSelector(selectors.selectSections);
  const [navIndex, setNavIndex] = useState(null);

  const sectionsForBlocks = useSelector(selectors.selectSectionsForBlocks);

  const blocksForComposites = useSelector(selectors.selectBlocksForComposites);

  const compositesForComponents = useSelector(
    selectors.selectCompositesForComponents
  );

  const componentsForElements = useSelector(
    selectors.selectComponentsForElements
  );

  const reportLoading = useSelector(selectors.selectReportLoad);

  const reviewDataLoading = useSelector(selectors.selectReviewDataLoading);
  const trackLocation = useSelector(selectors.selectTrackLocation);
  const locationLoading = useSelector(selectors.selectLocationLoading);

  // const { inspectionId } = useParams();
  const getElementsState = useCallback(() => {
    const elements = componentsForElements.reduce((acc, cv) => {
      const elementsCurr = cv.structure.elements.map((element) => element);
      elementsCurr.forEach((currEl) => {
        acc[currEl.id] = {
          mandatory: currEl.mandatory,
        };
      });
      return acc;
    }, {});
    return elements;
  }, [componentsForElements, form]);
  //Changes
  const getElements = useCallback(
    (blockId) => {
      let elementDisplayData = [];
      let componentsDisplayData = [];
      if (
        blocksForComposites &&
        compositesForComponents &&
        componentsForElements
      ) {
        blocksForComposites.forEach((blockForComposites) => {
          blockForComposites.structure.composites.forEach((composite) => {
            compositesForComponents.forEach((compositeForComponents) => {
              if (composite.id === compositeForComponents.id) {
                compositeForComponents.structure.components.forEach(
                  (component) => {
                    componentsForElements.forEach((componentForElements) => {
                      if (componentForElements.id === component.id) {
                        componentsDisplayData.push({
                          blockId: blockForComposites.id,
                          component: componentForElements,
                        });
                        componentForElements.structure.elements.forEach(
                          (element) => {
                            elementDisplayData.push({
                              blockId: blockForComposites.id,
                              element,
                            });
                          }
                        );
                      }
                    });
                  }
                );
              }
            });
          });
        });
      }
      const elements = [];
      const components = [];
      elementDisplayData.forEach((element) => {
        if (element.blockId === blockId) {
          elements.push(element);
        }
      });
      componentsDisplayData.forEach((element) => {
        if (element.blockId === blockId) {
          components.push(element);
        }
      });
      return { elements, components };
    },
    [blocksForComposites, compositesForComponents, componentsForElements]
  );

  const getComponents = useCallback(
    (blockId) => {
      let elementDisplayData = [];
      if (
        blocksForComposites &&
        compositesForComponents &&
        componentsForElements
      ) {
        blocksForComposites.forEach((blockForComposites) => {
          blockForComposites.structure.composites.forEach((composite) => {
            compositesForComponents.forEach((compositeForComponents) => {
              if (composite.id === compositeForComponents.id) {
                compositeForComponents.structure.components.forEach(
                  (component) => {
                    componentsForElements.forEach((componentForElements) => {
                      if (componentForElements.id === component.id) {
                        elementDisplayData.push({
                          blockId: blockForComposites.id,
                          component: componentForElements,
                        });
                      }
                    });
                  }
                );
              }
            });
          });
        });
      }
      const elements = [];
      elementDisplayData.forEach((element) => {
        if (element.blockId === blockId) {
          elements.push(element);
        }
      });
      return elements;
    },
    [blocksForComposites, compositesForComponents, componentsForElements]
  );

  const getDisplayData = useCallback(() => {
    let displayData = [];
    if (sectionsForBlocks) {
      sectionsForBlocks.forEach((section, index) => {
        displayData.push({
          pageNumber: section?.order,
          sections: section?.structure?.blocks || [],
          pageId: section.id,
        });
      });
    }
    return displayData;
  }, [sectionsForBlocks]);

  useEffect(() => {
    setDisplayData(getDisplayData());
  }, [getDisplayData]);

  const handleFormDataChange = (e, val, id, mimeType, order) => {
    setTempError(false);
    setIsValidFormat(null);

    // for repeatables delete
    if (val === "repeatable-delete") {
      setFormData((prevState) => {
        let attachments = prevState[e].attachments.filter(
          (_, index) => index !== id
        );
        return {
          ...prevState,
          [e]: {
            ...prevState[e],
            attachments,
          },
        };
      });
      return;
    }
    //for repeatable Dropdown & Radio
    if (val === "repeatableDD") {
      setFormData((prevState) => {
        let attachments = _.cloneDeep(prevState[id].attachments);
        attachments[mimeType - 1] = e;
        return {
          ...prevState,
          [id]: {
            ...prevState[id],
            attachments,
          },
        };
      });
      return;
    }
    // for repeatable dates
    if (val === "repeatableDate") {
      setFormData((prevState) => {
        let attachments = _.cloneDeep(prevState[id].attachments);
        attachments[mimeType] = e;
        return {
          ...prevState,
          [id]: {
            ...prevState[id],
            attachments,
          },
        };
      });
      return;
    }

    // for repeatables
    if (val === "repeatable") {
      const { value, name } = e.target;
      setFormData((prevState) => {
        let attachments = _.cloneDeep(prevState[name].attachments);
        attachments[id - 1] = value;
        return {
          ...prevState,
          [name]: {
            ...prevState[name],
            attachments,
          },
        };
      });
      return;
    }

    // for repeatable checkbox
    if (val === "repeatableCB") {
      setFormData((prevState) => {
        let attachments = _.cloneDeep(prevState[id].attachments);
        attachments[order - 1] = e;
        return {
          ...prevState,
          [id]: {
            ...prevState[id],
            attachments,
          },
        };
      });
      return;
    }

    // for repeatable file upload
    if (_.isArray(val) && order) {
      setFormData((prevState) => {
        let attachments = _.cloneDeep(prevState[id].attachments);
        attachments[order - 1] = JSON.stringify(val);
        let itemInfo = _.cloneDeep(prevState[id]?.itemInfo);
        let newHashArray;
        if (itemInfo && itemInfo.hashArray) {
          newHashArray = itemInfo.hashArray;
          newHashArray[order - 1] = e;
        } else {
          newHashArray = [];
          newHashArray[order - 1] = e;
        }
        let newMimeTypes =
          prevState[id].mimeType && prevState[id].mimeType !== "NA"
            ? JSON.parse(prevState[id].mimeType)
            : [];
        newMimeTypes[order - 1] = mimeType;
        return {
          ...prevState,
          [id]: {
            ...prevState[id],
            attachments,
            itemInfo: {
              ...prevState[id]?.itemInfo,
              hashArray: newHashArray,
            },
            mimeType: JSON.stringify(newMimeTypes),
          },
        };
      });
      return;
    }

    if (_.isArray(val)) {
      setFormData((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          attachments: [...val],
          itemInfo: {
            ...prevState[id]?.itemInfo,
            hashArray: e,
          },
          mimeType,
        },
      }));
      return;
    }
    if (_.isArray(e)) {
      setFormData((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          attachments: e,
          mimeType,
        },
      }));
      return;
    }
    if (_.isString(e) || _.isDate(e)) {
      setFormData((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          value: e,
        },
      }));
      return;
    }
    if (val && id) {
      setFormData((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          value: val,
        },
      }));
      return;
    }
    const { name, value, checked } = e.target;
    if (!val) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value: value || value === "" || value === 0 ? value : checked,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value: val,
        },
      }));
    }
  };

  const handlePreview = async () => {
    if (changesSaved) {
      dispatch(
        actions.genReport({
          inspectionId,
          jobId,
          taskId,
          status: "preview",
          onFailure: () => {
            nToast({
              message: "Failed to generate report preview!",
              status: "error",
            });
          },
          onSuccess: () => {
            setChangesSaved(false);
            formStateManager.setFormState(inspectionId, {
              changesSaved: false,
            });
          },
        })
      );
    } else {
      dispatch(
        actions.fetchDonePreview({
          inspectionId,
          jobId,
          taskId,
          status: "preview",
          onFailureReport: () => {
            nToast({
              message: "Failed to generate report preview!",
              status: "error",
            });
          },
        })
      );
    }
  };

  const handleSubmit = async (status) => {
    let data = [];
    const blocks = sections
      .filter((sectionObj) => disabledSectionCheck?.includes(sectionObj.id))
      .flatMap((sectionObj) => sectionObj.structure.blocks);
    // Get the IDs of elements to clear
    const toClearElementIds = blocks
      .flatMap((block) => sectionElementMap[block.id])
      .filter((id) => id);

    Object.keys(formData).forEach((el) => {
      data.push(formData[el]);
    });
    data = data.map((element) => {
      let el = { ...element };
      if (toClearElementIds.includes(element.templateItemId)) {
        el = { ...element, attachments: [], value: "" };
      } else {
        if (el.itemType === "email") {
          if (el.value) {
            el.value = el.value.toLowerCase();
          } else if (el.attachments.some((val) => val)) {
            el.attachments = el.attachments.map((val) =>
              val ? val.toLowerCase() : ""
            );
          }
        }
      }
      return el;
    });
    let modifiedFormData = data?.reduce((acc, cv) => {
      acc[cv.templateItemId] = { ...cv };
      return acc;
    }, {});

    const postRes = await postData({
      status,
      data,
      inspectionId,
      jobId,
      taskId,
    });
    if (postRes?.data?.success === true) {
      if (status === "draft") {
        history.push("/admin/pendingApprovals");
        setFormData({});
        setReviewData([]);
      } else {
        if (status === "done" || status === "review-inprogress") {
          showSuccessToast();
          setChangesSaved(true);
          setReviewData(modifiedFormData);
          setBtnStatusArr([]);
          setFormData(modifiedFormData);
          // localStorage.setItem('changesSaved', 'true');
          formStateManager.setFormState(inspectionId, { changesSaved: true });
        } else {
          formStateManager.clearFormState(inspectionId);
          history.push("/admin/pendingApprovals");
          setTimeout(() => {
            showToast({
              title: "Approved Successfully",
              description: "We'll notify you when report is ready",
              status: "success",
              duration: 8000,
              isClosable: true,
            });
          }, 1000);
        }
      }
    }
  };

  useEffect(() => {
    const savedState = formStateManager.getFormState(inspectionId);
    if (savedState) {
      setChangesSaved(savedState.changesSaved);
    }
  }, [inspectionId]);

  useEffect(() => {
    inspectionId &&
      jobId &&
      taskId &&
      dispatch(
        actions.fetchReviewData({
          inspectionId,
          jobId,
          taskId,
        })
      );
    dispatch(actions.fetchFormData({ id: formId }));
    return () => {
      dispatch(actions.clearReviewDetails());
      setFormData({});
      setReviewData([]);
    };
  }, [dispatch, jobId, taskId]);

  useEffect(() => {
    if (toBeReviewedData && toBeReviewedData?.length > 0) {
      let modifyReviewData = toBeReviewedData?.reduce((acc, cv) => {
        acc[cv.templateItemId] = { ...cv };
        return acc;
      }, {});

      // to get all image url of form
      let imageFileNames = [];
      toBeReviewedData.forEach((itemData) => {
        if (
          (itemData.itemType === "fileUpload" ||
            itemData.itemType === "multiFileUpload") &&
          Array.isArray(itemData?.attachments)
        ) {
          itemData.attachments.forEach((item) => {
            if (item) {
              try {
                const files = JSON.parse(item);
                imageFileNames.push(...files);
              } catch {
                imageFileNames.push(item);
              }
            }
          });
        }
      });
      if (imageFileNames?.length > 0) {
        dispatch(actions.fetchImageUrls({ fileNames: imageFileNames }));
      }

      setFormData(modifyReviewData);
      setReviewData(modifyReviewData);
    }
  }, [toBeReviewedData]);

  useEffect(() => {
    if (blocksForComposites && blocksForComposites.length) {
      let data = blocksForComposites.map(
        (block) => getElements(block.id).elements
      );
      const result = data.reduce((accumulator, currentArray) => {
        currentArray.forEach((item) => {
          const blockId = item.blockId;
          const elementId = item.element.id;

          if (!accumulator[blockId]) {
            accumulator[blockId] = [elementId];
          } else if (!accumulator[blockId].includes(elementId)) {
            accumulator[blockId].push(elementId);
          }
        });

        return accumulator;
      }, {});
      setdataArr(data);
      setSectionElementMap(result);
    }
  }, [blocksForComposites]);

  //Logic to check any element change in review page
  const isObjectMatching = (id) => {
    const formObject = formData[id];
    const reviewObject = reviewData[id];
    const hasValueInForm = formObject && formObject.hasOwnProperty("value");
    const hasValueInReview =
      reviewObject && reviewObject.hasOwnProperty("value");

    // Compare "value" property first
    if (hasValueInForm || hasValueInReview) {
      if (
        typeof formObject?.value == "object" &&
        formObject?.value instanceof Date &&
        typeof reviewObject?.value === "string"
      ) {
        return formObject?.value.toISOString() === reviewObject?.value;
      }
      if (
        formObject &&
        reviewObject &&
        formObject.value !== reviewObject.value
      ) {
        return false;
      }
    }

    // If both "value" properties are empty, then compare "attachments"
    const hasAttachmentsInForm =
      formObject && formObject.attachments && formObject.attachments.length > 0;
    const hasAttachmentsInReview =
      reviewObject &&
      reviewObject.attachments &&
      reviewObject.attachments.length > 0;

    if (hasAttachmentsInForm || hasAttachmentsInReview) {
      if (
        JSON.stringify(formObject.attachments) !==
        JSON.stringify(reviewObject.attachments)
      ) {
        return false;
      }
    }

    return true;
  };

  //Logic to conditionally showing sections based on Yes/No selection
  useEffect(() => {
    setDisabledSectionCheck([]);
    if (dataArr && dataArr.length && formData) {
      let sectionMap = displayData?.reduce((acc, curr) => {
        acc[curr.pageNumber] = curr.pageId;
        return acc;
      }, {});

      let sectionArr = displayData?.map((pageData) => ({
        ...pageData.sections[0],
        id: pageData.pageId,
        pageNumber: pageData.pageNumber,
      }));

      dataArr.map((el) => {
        el.map((currData) => {
          let currPage;
          let navPage;
          let blockId = currData.blockId;
          let navSectionId;
          let currentSectionId = displayData.find((page) => {
            let hasSection = false;
            page.sections.forEach((section) => {
              if (section.id === blockId) {
                hasSection = true;
              }
            });
            return hasSection;
          })?.pageId;

          const value = formData[currData.element.id]?.value || "";

          if (
            currData.element.htmlType === "radio" &&
            currData.element.properties.navigations
          ) {
            currData.element.properties.navigations.forEach((navOption) => {
              if (value === navOption.value) {
                navSectionId = navOption.sectionId;
              }
            });

            sectionArr.forEach((section) => {
              if (navSectionId !== undefined) {
                if (section.id === currentSectionId) {
                  currPage = section.pageNumber;
                }
                if (section.id === navSectionId) {
                  navPage = section.pageNumber;
                }
              }
            });

            if (currPage && navPage) {
              let arr = [];
              for (let i = currPage + 1; i < navPage; i++) {
                arr.push(sectionMap[i]);
              }

              setDisabledSectionCheck((prevState) => {
                return [...new Set([...prevState, ...arr])];
              });
            }
          }
        });
      });
    }
  }, [dataArr, formData]);

  useEffect(() => {
    dataArr.map((el) => {
      el.map((data) => {
        //Logic to check changes in any element to handle save and discard button enable/disable
        const blocks = sections
          .filter((sectionObj) => disabledSectionCheck?.includes(sectionObj.id))
          .flatMap((sectionObj) => sectionObj.structure.blocks);
        // Get the IDs of elements to clear
        const toClearElementIds = blocks
          .flatMap((block) => sectionElementMap[block.id])
          .filter((id) => id);

        const isMatching = isObjectMatching(data.element.id);
        setBtnStatusArr((prevState) => {
          let updatedState = [...prevState];
          if (isMatching) {
            if (updatedState.length === 0) return [];
            updatedState = updatedState.filter((id) => id !== data.element.id);
          } else {
            updatedState.push(data.element.id);
          }
          updatedState = updatedState.filter(
            (id) => !toClearElementIds.includes(id)
          );
          return [...new Set([...updatedState])];
        });
      });
    });
  }, [disabledSectionCheck]);

  const getErrorState = useCallback(() => {
    const allElementIds = [];
    getDisplayData()?.forEach((elementData) => {
      const elements = [];
      elementData.sections.forEach((section) => {
        const newElements = getElements(section.id).elements;
        elements.push(...newElements);
      });
      elements.forEach((element) => {
        allElementIds.push(element.element.id);
      });
    });
    return allElementIds;
  }, [getDisplayData]);

  useEffect(() => {
    setAllElementsId(getErrorState());
  }, [getErrorState]);

  const handleValidate = () => {
    setIsValidFormat(null);
    let isValid = true;
    const blocks = sections
      .filter((sectionObj) => disabledSectionCheck?.includes(sectionObj.id))
      .flatMap((sectionObj) => sectionObj.structure.blocks);
    // Get the IDs of elements to clear
    const toClearElementIds = blocks
      .flatMap((block) => sectionElementMap[block.id])
      .filter((id) => id); // Filter out undefined values

    let dataToCheck = allElementsId.filter(
      (id) => !toClearElementIds?.includes(id)
    );
    let data = getElementsState();

    dataToCheck.forEach((key) => {
      let parentId = formData[key].parentId;

      componentsForElements.map((component) => {
        //for components

        if (component?.repeatable?.isRepeatable && component.id == parentId) {
          let minimumEntry = component?.repeatable?.minEntries;
          let actualArray = formData[key]?.attachments?.filter((ele) => {
            if (Array.isArray(ele)) {
              return ele.length !== 0;
            } else {
              return ele !== "";
            }
          });

          if (actualArray.length < minimumEntry && data[key]?.mandatory) {
            isValid = false;
          }
        }

        //for elements
        else if (
          !component?.repeatable?.isRepeatable &&
          component.id == parentId &&
          !formData[key].value
        ) {
          component.structure?.elements.map((ele) => {
            if (key == ele.id) {
              let minElementEntries = ele?.repeatable?.minEntries;
              let actualArray = formData[key]?.attachments?.filter((ele) => {
                if (Array.isArray(ele)) {
                  return ele.length !== 0;
                } else {
                  return ele !== "";
                }
              });

              if (
                actualArray.length < minElementEntries &&
                data[key]?.mandatory
              ) {
                isValid = false;
              }
            }
          });
        }
      });

      if (
        data[key].mandatory &&
        !formData[key].value &&
        !formData[key].attachments.length
      ) {
        isValid = false;
      }

      // email validation
      if (formData[key]?.itemType === "email") {
        const domainArr = formData[key]?.itemInfo?.properties?.emailDomains;
        const isEmailValidatedVal = validateEmail(
          formData[key]?.value,
          domainArr
        );
        let isEmailValidatedAttach = { isValid: true, message: "" };
        if (formData[key].attachments?.length > 0) {
          for (let val of formData[key]?.attachments) {
            isEmailValidatedAttach = validateEmail(val, domainArr);
            if (!isEmailValidatedAttach.isValid) {
              break;
            }
          }
        }

        if (!isEmailValidatedVal?.isValid || !isEmailValidatedAttach?.isValid) {
          isValid = false;
          setIsValidFormat("Please validate field format");
        }
      }
      //Phone Validation
      if (formData[key]?.itemType === "mobile") {
        // if mobile number attachement has data then this element is a RepeatableElement
        if (formData[key]?.attachments.length > 0) {
          for (let value of formData[key]?.attachments) {
            const valueArr = value.split(",");
            const number = valueArr?.length > 1 ? valueArr[1] : valueArr[0];
            const countryCode = valueArr?.length > 1 ? valueArr[0] : "+91";
            if (!number && data[key]?.mandatory) {
              isValid = false;
            } else if (number) {
              const isValidMobileNumber = validateMobileNumber(
                number,
                countryCode
              );
              if (!isValidMobileNumber?.isValid) {
                isValid = false;
                setIsValidFormat("Please validate field Format");
              }
            }
          }
        } else {
          const valueArr = formData[key]?.value?.split(",");
          const number = valueArr?.length > 1 ? valueArr[1] : valueArr[0];
          const countryCode = valueArr?.length > 1 ? valueArr[0] : "+91";
          if (!number && data[key]?.mandatory) {
            isValid = false;
            // return isValid;
          } else if (number) {
            const isValidMobileNumber = validateMobileNumber(
              number,
              countryCode
            );
            if (!isValidMobileNumber?.isValid) {
              isValid = false;
              setIsValidFormat("Please validate field format");
            }
          }
        }
      }
    });
    return isValid;
  };

  const handleResetClick = (id) => {
    const reviewObject = reviewData[id];
    setFormData({
      ...formData,
      [id]: reviewObject,
    });
  };

  const handleImageDownload = (imageName) => {
    dispatch(
      actions.downloadImage({
        source: { name: process.env.REACT_APP_SAS_URL_GENERATOR_SOURCE },
        imageName,
      })
    );
  };
  const [openOverview, setOpenOverview] = useState(true);
  // close and open overview section
  const handleOverSection = () => {
    setOpenOverview(!openOverview);
  };
  // get tracked location of auditor
  useEffect(() => {
    dispatch(actions.fetchTrackLocation({ jobId, taskId, inspectionId }));
  }, [taskId, inspectionId, jobId]);

  return (
    <React.Suspense fallback={<Box>Loading...</Box>}>
      <Flex gap="24px" boxSizing="border-box" w={"100%"}>
        {reviewDataLoading &&
        formData !== undefined &&
        sections &&
        sections.length ? (
          <>
            <Flex
              w="260px"
              direction="column"
              bg="#F9F9FB"
              borderRadius="15px"
              padding={"20px"}
            >
              {workflowData &&
                workflowData?.forms.map((form) => {
                  return (
                    <Box
                      padding="16px 12px"
                      onClick={() => handleFormClick(form.id)}
                      style={
                        isFormSelected(form.id) ? selectedFormStyleMemo : {}
                      }
                    >
                      <Text sx={{ cursor: "pointer" }}>
                        {capitalizeStringUpdated(form.title)}
                      </Text>
                    </Box>
                  );
                })}
            </Flex>
            <VStack w="100%" gap={"36px"} p={"20px"} bg={"#F9F9FB"}>
              <InspectionOverviewSection
                inspectionDetails={inspectionDetails}
                tasksData={tasksData}
                locationLoading={locationLoading}
                trackLocation={trackLocation}
                handleOverSection={handleOverSection}
                openOverview={openOverview}
              />
              <Box w="100%" borderRadius={"15px"}>
                <HeaderGray
                  form={form && form[0]}
                  pageNumber={page}
                  numPages={getDisplayData().length}
                  hidePagesDetails={true}
                />
                <Box
                  w="100%"
                  style={{ background: "white", position: "relative" }}
                >
                  <VStack
                    w={"100%"}
                    style={{
                      margin: "0 auto",
                      padding: "64px 24px",
                    }}
                    gap="32px"
                    area-label="sections"
                    justify="flex-start"
                    align="flex-start"
                  >
                    {JSON.parse(JSON.stringify(sections))
                      .sort((a, b) => a.order - b.order)
                      .map((section) => {
                        return (
                          <Section
                            key={section.id}
                            section={section}
                            getElements={getElements}
                            formData={formData}
                            reviewData={reviewData}
                            handleFormDataChange={handleFormDataChange}
                            handleFilesChange={handleFilesChange}
                            displayData={displayData}
                            setPage={setPage}
                            navIndex={navIndex}
                            setNavIndex={setNavIndex}
                            handleResetClick={handleResetClick}
                            discardButton={discardButton}
                            handleImageDownload={handleImageDownload}
                            prevPages={prevPages}
                            disabledSectionCheck={disabledSectionCheck}
                            isObjectMatching={isObjectMatching}
                            components={componentsForElements}
                            sections={sectionsForBlocks}
                          />
                        );
                      })}
                  </VStack>
                </Box>
              </Box>
              {tempError ? (
                <HStack w="100%" justify="center" align="center">
                  <Text
                    aria-label="element label"
                    color="#D93025"
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="1.4"
                    textAlign="center"
                  >
                    {!isValidFormat
                      ? `Please, Fill in all the mandatory fields.`
                      : isValidFormat}
                  </Text>
                </HStack>
              ) : (
                <></>
              )}
              <HStack
                padding={"32px 0px"}
                w={"100%"}
                justifyContent={"space-between"}
              >
                <Button
                  isDisabled={btnStatusArr.length === 0 ? true : false}
                  variant={"outlined"}
                  w={"244px"}
                  h={"64px"}
                  style={{
                    border: "1px solid #E53E3E",
                    color: "#E53E3E",
                    borderRadius: "8px",
                  }}
                  onClick={onOpen2}
                >
                  Discard All Changes
                </Button>
                <HStack>
                  <Button
                    isDisabled={btnStatusArr.length === 0 ? true : false}
                    variant={"outlined"}
                    w={"244px"}
                    h={"64px"}
                    style={{
                      border: "1px solid #38B2AC",
                      color: "#38B2AC",
                      borderRadius: "8px",
                    }}
                    onClick={() => {
                      if (handleValidate()) {
                        setTempError(false);
                        onOpen1();
                      } else {
                        setTempError(true);
                      }
                    }}
                  >
                    Save Changes
                  </Button>
                  <Button
                    variant={"solid"}
                    w={"244px"}
                    h={"64px"}
                    style={{
                      border: "1px solid #48BB78",
                      color: "#48BB78",
                      borderRadius: "8px",
                    }}
                    onClick={handlePreview}
                    isDisabled={reportLoading ? true : false}
                  >
                    {!reportLoading ? (
                      "Preview"
                    ) : (
                      <Loader
                        color={"green"}
                        position={"center"}
                        margin={"auto"}
                      />
                    )}
                  </Button>
                  <Button
                    variant={"solid"}
                    w={"244px"}
                    h={"64px"}
                    style={{
                      border: "1px solid #48BB78",
                      color: "#48BB78",
                      borderRadius: "8px",
                    }}
                    onClick={() => {
                      if (handleValidate()) {
                        setTempError(false);
                        onOpen();
                      } else {
                        setTempError(true);
                      }
                    }}
                  >
                    Approve
                  </Button>
                </HStack>
              </HStack>
              <AreYouSureModal
                handleSubmit={(status) => {
                  handleSubmit(status);
                }}
                isOpen={isOpen}
                onClose={onClose}
                status={"approved"}
                msg="Are you sure you want to submit changes?"
                btnText="Submit"
              />
              <AreYouSureModal
                isOpen={isOpen1}
                onClose={onClose1}
                handleSubmit={(status) => {
                  handleSubmit(status);
                }}
                status={"review-inprogress"}
                msg="Are you sure you want to save changes?"
                btnText="Save"
              />
              <DiscardModal
                isOpen={isOpen2}
                onClose={onClose2}
                msg="Are you sure you want to discard all changes?"
                btnText="Discard"
                setFormData={setFormData}
                setTempError={setTempError}
                setIsValidFormat={setIsValidFormat}
                setDiscardButton={setDiscardButton}
                discardButton={discardButton}
                reviewData={reviewData}
              />
            </VStack>
          </>
        ) : (
          <Loader size="44px" position="center" />
        )}
      </Flex>
    </React.Suspense>
  );
}

// review inspection overview section
const InspectionOverviewSection = ({
  inspectionDetails,
  tasksData,
  locationLoading,
  trackLocation,
  handleOverSection,
  openOverview,
}) => {
  const dispatch = useDispatch();
  const originLocation = useSelector(selectors.selectOriginLocation);
  const auditorAvatarUrl = useSelector(selectors.selectAuditorAvatarUrl);
  const clientAvatarUrl = useSelector(selectors.selectClientAvatarUrl);

  const clientDetails =
    inspectionDetails && inspectionDetails[0]?.clientDetails;
  const auditorDetails = tasksData && tasksData[0]?.assignedTo;

  useEffect(() => {
    dispatch(
      actions.fetchOriginLocation({ originId: inspectionDetails[0]?.originId })
    );
  }, [inspectionDetails[0]?.originId]);
  // fetch avatar url
  useEffect(() => {
    if (auditorDetails?.avatar) {
      const { avatar } = auditorDetails;
      dispatch(actions.fetchAuditorAvatarURL({ payload: avatar }));
    }
    if (clientDetails?.avatar) {
      const { avatar } = clientDetails;
      dispatch(actions.fetchClientAvatarURL({ payload: avatar }));
    }
  }, [auditorDetails, clientDetails]);

  return (
    <VStack
      w="100%"
      gap="48px"
      border="2px solid var(--Grey-Gray-100, #EDF2F7)"
      background="var(--White, #FFF)"
      pb={openOverview ? "48px" : "0px"}
      borderRadius="8px"
    >
      <Flex
        background="var(--Grey-Gray-600, #4A5568)"
        pt="16px"
        pb="16px"
        w="100%"
        borderTopRadius="8px"
        justifyContent="center"
        borderBottomRadius={openOverview ? "0px" : "8px"}
      >
        <Flex w="70%" gap="16px" borderRadius="8px">
          <Image
            src={SideArrow}
            cursor={"pointer"}
            onClick={() => {
              handleOverSection();
            }}
            transform={openOverview ? "rotate(90deg)" : "rotate(0deg)"}
          ></Image>
          <Text
            fontSize="24px"
            fontWeight="500"
            color="var(--Grey-Gray-100, #EDF2F7)"
          >
            Overview
          </Text>
        </Flex>
      </Flex>
      <Box
        display={openOverview ? "block" : "none"}
        transition="display 0.5s ease-in"
        w="100%"
        p="0 24px"
      >
        <VStack w="100%" justifyContent="center" gap="48px">
          <VStack
            alignItems="left"
            w="100%"
            gap="18px"
            justifyContent="center"
            borderRadius="8px"
            border="2px solid var(--Grey-Gray-100, #EDF2F7)"
            background="linear-gradient(91deg, #FFF 39.14%, #B6FFF8 263.21%), #FFF"
            p="24px"
          >
            <VStack gap="24px" alignItems="flex-start">
              <VStack gap="12px" alignItems="flex-start">
                <HStack gap="16px">
                  <Text
                    sx={{
                      color: "var(--Gray-Gray-700, #2D3748)",
                      fontSize: "18px",
                      fontWeight: "700",
                    }}
                  >
                    {tasksData && tasksData[0]?.name}
                  </Text>
                  <StatusBadge status={tasksData && tasksData[0]?.status} />
                </HStack>
                <HStack gap="24px">
                  <HStack>
                    <Box>
                      <Avatar
                        size="xs"
                        src={clientAvatarUrl}
                        name={clientDetails?.name}
                      />
                    </Box>
                    <Text fontSize="14px" fontWeight="600" color="#4A5568">
                      {clientDetails?.name || "N.A."}
                    </Text>
                  </HStack>
                  <HStack>
                    <Box>
                      <Image src={FiUser} alt="Dan Abramov" />
                    </Box>
                    <Text style={leftTextStyle}>Auditor:</Text>
                    <Box>
                      <Avatar
                        size="xs"
                        src={auditorAvatarUrl}
                        name={auditorDetails?.firstName || ""}
                      />
                    </Box>
                    <Text
                      sx={{
                        fontSize: "14px",
                        color: "#4A5568",
                        fontWeight: "500",
                      }}
                    >
                      {`${auditorDetails?.firstName} ${auditorDetails?.lastName}`}
                    </Text>
                  </HStack>
                  {/* originLocation */}
                  <Stack position="relative">
                    <Popover placement="bottom">
                      <PopoverTrigger>
                        <Button
                          colorScheme="gray"
                          variant="outline"
                          size="xs"
                          border="1px solid #E2E8F0"
                          borderRadius="8px"
                          p="0px 12px"
                          h="32px"
                          leftIcon={<Image src={Location} />}
                        >
                          {originLocation?.name}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent
                        mt="35px"
                        padding="16px"
                        borderRadius={"15px"}
                      >
                        <PopoverArrow />
                        <PopoverBody p="0px">
                          <UnorderedList
                            sx={{
                              listStyleType: "none",
                              color: "#4A5568",
                              lineHeight: "21px",
                              fontSize: "14px",
                              fontWeight: 600,
                              display: "flex",
                              flexDirection: "column",
                              gap: "16px",
                              margin: "0px",
                            }}
                          >
                            <ListItem>
                              City:
                              <span style={{ fontWeight: "400" }}>
                                {capitalizeStringUpdated(
                                  originLocation?.city
                                    ? originLocation?.city
                                    : ""
                                )}
                              </span>
                            </ListItem>
                            <ListItem>
                              Country:
                              <span style={{ fontWeight: "400" }}>
                                {capitalizeStringUpdated(
                                  originLocation?.country
                                    ? originLocation?.country
                                    : ""
                                )}
                              </span>{" "}
                            </ListItem>
                            <ListItem>
                              OriginType:
                              <span style={{ fontWeight: "400" }}>
                                {capitalizeStringUpdated(
                                  originLocation?.originType
                                    ? originLocation?.originType
                                    : ""
                                )}
                              </span>
                            </ListItem>
                          </UnorderedList>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Stack>
                  {/* EndOriginLocation  */}
                </HStack>
              </VStack>
              <Flex flexWrap="wrap" gap="32px">
                <DateInfoBlock
                  label="Assigned"
                  date={extractDayMonthYearFromTimestamp(
                    tasksData && tasksData[0]?.creationDt
                  )}
                />
                <DateInfoBlock
                  label="Due Date"
                  date={extractDayMonthYearFromTimestamp(
                    tasksData && tasksData[0]?.dueDate
                  )}
                />
                <br />
                <DateInfoBlock
                  label="Completed"
                  date={extractDayMonthYearFromTimestamp(
                    tasksData && tasksData[0]?.completionDt
                  )}
                />
              </Flex>
            </VStack>
          </VStack>
          <AuditorTackedInfo
            trackLocation={trackLocation}
            locationLoading={locationLoading}
            inspectionCompletedTime={tasksData[0]?.completionDt}
          />
        </VStack>
      </Box>
    </VStack>
  );
};

function DateInfoBlock(props) {
  return (
    <Stack direction="row" align="center">
      <Box>
        <Image src={MdDate} alt="Dan Abramov" />
      </Box>
      <Text style={leftTextStyle}>{props.label}:</Text>
      <Text style={rightTextStyle}>{props.date}</Text>
    </Stack>
  );
}

const leftTextStyle = {
  color: "var(--Gray-Gray-500, #718096)",
  fontSize: "16px",
  fontWeight: "400",
};

const rightTextStyle = {
  color: "var(--Gray-Gray-500, #718096)",
  fontSize: "16px",
  fontWeight: "600",
};
