import { useEffect, useRef, useState } from "react";
import { useOutsideClick } from "@chakra-ui/react";
import {
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Collapse,
  Box,
  Text,
} from "@chakra-ui/react";
import { DropdownArrowIcon } from "components/Icons/Icons";
import { CloseIcon } from "@chakra-ui/icons";

const styles = {
  scrollableDropdown: {
    backgroundColor: "#FFF",
    position: "absolute",
    top: "47px",
    zIndex: "1",
    maxHeight: "300px",
    overflowY: "auto",
    width: "100%",
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
    boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
    padding: "8px",
  },
  optionsContainer: {
    display: "flex",
    padding: "8px 12px",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch",
    borderRadius: "8px",
    textTransform: "capitalize",
  },
  dropdownLabel: {
    color: "#4A5568",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
};

function Dropdown({
  options,
  placeholder,
  handleFilterQuery,
  property,
  listKey,
  filterQuery,
}) {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleSelect = (value) => {
    handleFilterQuery(value, property);
  };

  useEffect(() => {
    if (filterQuery) {
      setInputValue(filterQuery[property]);
    }
  }, [filterQuery[property]]);

  const handleInputVal = (value) => {
    setInputValue(value);
  };

  let filteredOptions = options?.filter((option) =>
    option[listKey]?.toLowerCase().includes(inputValue?.toLowerCase())
  );
  filteredOptions = filteredOptions?.sort((a, b) =>
    a[listKey].localeCompare(b[listKey])
  );
  const ref = useRef();
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  useOutsideClick({
    ref: ref,
    handler: () => {
      if (isDropdownOpen) {
        setDropdownOpen(false);
      }
    },
  });
  const isInputEmpty = inputValue?.trim() === "";

  return (
    <>
      <Box
        width="100%"
        ref={ref}
        style={{ position: "relative", flex: "1" }}
        onClick={() => {
          if (isDropdownOpen !== true) {
            setDropdownOpen(true);
          }
        }}
      >
        <InputGroup borderRadius="15px" height="40px">
          <Input
            bg="#F7FAFC"
            fontSize="12px"
            value={inputValue ? inputValue : filterQuery[property]}
            placeholder={placeholder}
            onChange={(e) => {
              handleInputVal(e.target.value);
              // handleQuery(e.target.value);
            }}
          />
          <InputRightElement>
            {isInputEmpty ? (
              <IconButton
                bg="inherit"
                _hover="none"
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "none",
                }}
                icon={<DropdownArrowIcon />}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDropdown();
                }}
              ></IconButton>
            ) : (
              <IconButton
                bg="inherit"
                _hover="none"
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "none",
                }}
                icon={<CloseIcon color="gray.400" height="10px" />}
                onClick={() => {
                  toggleDropdown();
                  handleSelect("");
                }}
              ></IconButton>
            )}
          </InputRightElement>
        </InputGroup>

        <Collapse in={isDropdownOpen}>
          <Box sx={styles.scrollableDropdown}>
            {filteredOptions?.length === 0 ? (
              <Box className="name-location-container">
                <Text className="warehouse-name-text">
                  No Matching {placeholder}
                </Text>
              </Box>
            ) : (
              <Box>
                {filteredOptions.map((option) => (
                  <Box
                    sx={styles.optionsContainer}
                    _hover={{ cursor: "pointer", bg: "#F7FAFC" }}
                    key={option.id}
                    onClick={() => {
                      handleSelect(option[listKey]);
                      toggleDropdown();
                    }}
                  >
                    <Text sx={styles.dropdownLabel}>
                      {option[listKey].toLowerCase()}
                    </Text>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Collapse>
      </Box>
    </>
  );
}
export default Dropdown;
