export const styles = {
    button: {
      backgroundColor: "#F7FAFC",
      width: "106px",
      fontSize: "10px",
      borderRadius: "8px",
      padding: "0 12px",
      display: "flex",
      justifyContent: "space-between",
      height: "39px",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      rowGap: "8px",
      width: "100%",
    },
  
    clearApplyBtnContainer: {
      display: "flex",
      alignItems: "flex-start",
      gap: "20px",
      alignSelf: "stretch",
    },
    clearApplyBtn: {
      display: "flex",
      width: "131px",
      height: "40px",
      padding: "0px 8px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "12px",
    },
    greenCircle: {
      position: "absolute",
      top: "-2.5px",
      right: "-3.5px",
      width: "12px",
      height: "12px",
      backgroundColor: "#319795",
      borderRadius: "50%",
      border: "1px solid white",
    },
  };