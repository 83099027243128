import { Center, Spinner, Stack } from "@chakra-ui/react";
import React, { useState } from "react";

function PlantInspection() {
  const [isLoading, setIsLoading] = useState(true);
  const iframeStyle = {
    width: '100%',
    height: '100%',
    border: 'none', 
    borderRadius: "8px"
  };
// bg="#EEEEEE"
  return (
    <Stack>
    <Stack mt="75px" overflow="hidden" h='calc(100vh - 120px)'>
      {isLoading && (
        <Center position="absolute" top="90px" left="10" right="5" bottom="5"  borderRadius="8px">
          <Spinner
            thickness="5px"
            speed="0.65s"
            emptyColor="gray.200"
            color="teal.500"
            size="xl"
          />
        </Center>
      )}

      <iframe
        src="https://lookerstudio.google.com/embed/reporting/9689d8e8-418a-4af3-8947-637a9dba1dd5/page/REK6D"
        onLoad={() => setIsLoading(false)}
        style={iframeStyle}
        allowfullscreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      ></iframe>
    </Stack>
    </Stack>
  );
}

export default PlantInspection;
