import React from "react";
import { CircularProgress, HStack } from "@chakra-ui/react";
import Insights from "../Insights";
import bagImage from "assets/svg/bag.svg";
import { summedData } from "utils/commonFunctions";
import { formatNumber } from "utils/commonFunctions";
import { getPercentage } from "utils/commonFunctions";

function NoOfBags({ data }) {
  const { insight, analyticsLoading } = data;
  if (analyticsLoading) {
    return (
      <CircularProgress
        isIndeterminate
        color="#4FD1C5"
        size="40px"
        alignSelf={"center"}
      />
    );
  }
  // const bagSum = (analyticsData.length > 0 && summedData(analyticsData)) || [];
  // const insight = bagSum?.hasOwnProperty("totalNoOfBags") && {
  //   title: "No of Bags",
  //   amount: formatNumber(bagSum?.totalNoOfBags, "No of Bags"),
  //   icon: bagImage,
  //   percentage: getPercentage(
  //     analyticsData,
  //     bagSum?.totalNoOfBags,
  //     selectedDateType,
  //     "totalNoOfBags",
  //     "Bags"
  //   ),
  // };

  return (
    <HStack flexWrap={"wrap"} gap={{ base: "16px", md: "24px" }} w={"100%"}>
      <Insights
        pVal={"20px"}
        minWidthValue={"364px"}
        isRow={true}
        title={insight?.title}
        loading={analyticsLoading}
        // error={error}
        amount={insight?.amount}
        icon={
          <img
            src={insight?.icon}
            // style={imageStyle}
            alt={`Icon`}
          />
        }
        percentage={insight?.percentage}
      />
    </HStack>
  );
}

export default NoOfBags;
