import { Pagination, Table } from "@agnext/reactlib";
import { Box, CircularProgress, Flex, HStack, Image, Img, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import Eye from "assets/svg/eye-icon.svg";
import Edit from "assets/svg/edit-icon.svg";
import AvatarWithName from "views/jobs/components/viewJob/Table Components/AvatarWithName";
import { extractDayMonthYearFromTimestamp } from "utils/commonFunctions";
import Email from "./TableComponents/Email";
import { calculateTotalPages } from "utils/commonFunctions";
import { useSelector } from "react-redux";
import * as selectors from "../../selectors";
import noRecordImage from "assets/img/Empty Case.png";

const Icons = ({ customerId }) => {
  return (
    <Flex gap={2}>
      <Link to={`/admin/agency/viewAgency?agencyId=${customerId}`}>
        <Box
          background={"#EDF2F7"}
          height={"40px"}
          width={"48px"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius={"12px"}
          style={{ cursor: "pointer" }}
        >
          <Image src={Eye} alt="" />
        </Box>
      </Link>
      <Link to={`/admin/agency/updateAgency?agencyId=${customerId}`}>
        <Box
          background={"#EDF2F7"}
          height={"40px"}
          width={"48px"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius={"12px"}
          style={{ cursor: "pointer" }}
        >
          <Image src={Edit} alt="" />
        </Box>
      </Link>
    </Flex>
  );
};

const CustomerRoleTable = ({ allCustomersDetail, customerProfileImgUrls, page, setPage }) => {
  const totalCustomers = useSelector(selectors.selectTotalNumOfCustomers)
  const isLoadingCustomerData = useSelector(selectors.isLoadingCustomerData)
  

  const Header = [
    {
      displayText: "Organization Name",
      keyText: "orgName",
      hasSort: false,
    },
    // {
    //   displayText: "Business Type",
    //   keyText: "business",
    //   hasSort: true,
    // },
    {
      displayText: "Registered Address",
      keyText: "address",
      hasSort: false,
    },
    {
      displayText: "Email",
      keyText: "email",
      hasSort: false,
    },
    {
      displayText: "Created Date",
      keyText: "createdDate",
      hasSort: true,
    },
    {
      displayText: "Actions",
      keyText: "actions",
    },
  ];
  // const businessType = ["Bank", "Retail", "Inspection Agency"];
  const modifiedData = allCustomersDetail?.map((obj, index) => {
    return {
      ...obj,
      //  businessType: businessType[index]
    };
  });
  const tableList = allCustomersDetail?.map((customer, index) => {
    return {
      orgName: (
        <AvatarWithName
          avatarUrl={
            customerProfileImgUrls ? customerProfileImgUrls[index]?.url : ""
          }
          name={customer.name}
        />
      ),
      // business: customer.businessType,
      address: (
        <Box>
          {customer?.hasOwnProperty("addresses") &&
          customer?.addresses &&
          customer?.addresses?.length &&
          customer?.addresses?.[0]?.city
            ? `${customer.addresses[0].city}, ${customer.addresses[0].state}`
            : "-"}
        </Box>
      ),
      email: <Email links={customer?.links} />,
      createdDate: extractDayMonthYearFromTimestamp(customer.creationDt),
      actions: <Icons customerId={customer.customerId} />,
    };
  });

  // Pagination 
  const numPages = calculateTotalPages(totalCustomers, 10);
  const handleNextPage = () => {
    if (page === numPages - 1) return;
      setPage(page + 1);
  };
  const handlePreviousPage = () => {
    if (page === 0) return;
    setPage(page - 1);
  };
  
  const handlePageSelect = (pageNumber) => {
    if (page === pageNumber) return;
      setPage(pageNumber);
  };
  
  
  return (
    <Stack>
      <Stack display={"flex"} justifyContent={"flex-end"} w={"100%"} mb="10px">
        <Pagination
          page={page}
          numPages={numPages}
          handlePageSelect={handlePageSelect}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          colorActive="#4FD1C5"
        />
      </Stack>
      <Stack>
        <HStack
          display={isLoadingCustomerData ? "flex" : "none"}
          justifyContent={"center"}
          h="40vh"
        >
          <CircularProgress
            isIndeterminate
            color="#4FD1C5"
            size="70px"
            thickness="8px"
          />
        </HStack>
        <Stack
          display={isLoadingCustomerData ? "none" : "block"}
          background={"white"}
          borderRadius={"12px"}
        >
          {totalCustomers > 0 ? (
            <Table
              overflow="auto"
              overflowY="auto"
              // tableContainerHeight={{ base: "441px", sm: "378px" }}
              headerList={Header}
              dataList={tableList}
              stickyColumn={["Actions"]}
            />
          ) : (
            <Stack justifyContent="center" alignItems="center" h="50vh" fontSize="22px">
              <Img src={noRecordImage} alt="No Records Image" />
              <p>No Records Found</p>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CustomerRoleTable;
