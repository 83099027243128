import { Pagination, Table } from "@agnext/reactlib";
import { Box, CircularProgress, Flex, HStack, Image, Img, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import Eye from "assets/svg/eye-icon.svg";
import Edit from "assets/svg/edit-icon.svg";
import AvatarWithName from "views/jobs/components/viewJob/Table Components/AvatarWithName";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import { extractDayMonthYearFromTimestamp } from "utils/commonFunctions";
import Email from "./TableComponents/Email";
import { calculateTotalPages } from "utils/commonFunctions";
import { useSelector } from "react-redux";
import * as selectors from "../../selectors";
import noRecordImage from "assets/img/Empty Case.png";

const Icons = ({ clientId, customerId, userRole,}) => {
  return (
    <Flex gap={2}>
      <Link
        to={`/admin/clients/viewClient?clientId=${clientId}&customerId=${customerId}`}
      >
        <Box
          background={"#EDF2F7"}
          height={"40px"}
          width={"48px"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius={"12px"}
          style={{ cursor: "pointer" }}
        >
          <Image src={Eye} alt="" />
        </Box>
      </Link>
      {userRole === "admin" ? (
        <Link
          to={`/admin/clients/updateClient?clientId=${clientId}&customerId=${customerId}`}
        >
          <Box
            background={"#EDF2F7"}
            height={"40px"}
            width={"48px"}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius={"12px"}
            style={{ cursor: "pointer" }}
          >
            <Image src={Edit} alt="" />
          </Box>
        </Link>
      ) : null}
    </Flex>
  );
};

const ClientRoleTable = ({
  clientsOfCustomer,
  customerProfileImgUrls,
  customerId,
  userRole,
  totalNumberOfClients,
  page,
  setPage
}) => {
  const isLoadingClientData = useSelector(selectors.isLoadingClientData)
  const Header = [
    {
      displayText: "Client Name",
      keyText: "clientName",
      hasSort: false,
    },
    // {
    //   displayText: "Buisness Type",
    //   keyText: "buisnessType",
    //   hasSort: true,
    // },
    {
      displayText: "Registered Address",
      keyText: "address",
      hasSort: false,
    },
    {
      displayText: "Email",
      keyText: "email",
      hasSort: false,
    },
    {
      displayText: "Created Date",
      keyText: "createdDate",
      hasSort: true,
    },
    {
      displayText: "Actions",
      keyText: "actions",
    },
  ];

  const tableList = clientsOfCustomer?.map((client, index) => {
    return {
      clientName: (
        <AvatarWithName
          avatarUrl={
            customerProfileImgUrls ? customerProfileImgUrls[index]?.url : ""
          }
          name={capitalizeStringUpdated(client.clientName)}
        />
      ),
      // buisnessType: "Expert Traders",
      address: (
        <Box>
          {client?.hasOwnProperty("addresses") &&
          client?.addresses &&
          client?.addresses?.length &&
          client?.addresses?.[0]?.city
            ? `${client.addresses[0].city}, ${client.addresses[0].state}`
            : "-"}
        </Box>
      ),
      email: <Email links={client?.links} />,
      createdDate: extractDayMonthYearFromTimestamp(client.creationDt),
      actions: <Icons clientId={client.clientId} customerId={customerId}  userRole={userRole} />,
    };
  });

    // Pagination 
    const numPages = calculateTotalPages(totalNumberOfClients, 10);
    const handleNextPage = () => {
      if (page === numPages - 1) return;
      setPage(page + 1);
    };
    const handlePreviousPage = () => {
      if (page === 0) return;
      setPage(page - 1);
    };
  
    const handlePageSelect = (pageNumber) => {
      if (page === pageNumber) return;
      setPage(pageNumber);
    };

  return (
    <Stack>
      <Stack display={"flex"} justifyContent={"flex-end"} w={"100%"} mb="10px">
        <Pagination
          page={page}
          numPages={numPages}
          handlePageSelect={handlePageSelect}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          colorActive="#4FD1C5"
        />
      </Stack>

      <Stack>
        <HStack
          display={isLoadingClientData ? "flex" : "none"}
          justifyContent={"center"}
          h="40vh"
        >
          <CircularProgress
            isIndeterminate
            color="#4FD1C5"
            size="70px"
            thickness="8px"
          />
        </HStack>

        <Stack
          display={isLoadingClientData ? "none" : "block"}
          background={"white"}
          borderRadius={"12px"}
        >
          {totalNumberOfClients > 0 ? (
            <Table
              overflow="auto"
              overflowY="auto"
              // tableContainerHeight={{ base: "441px", sm: "378px" }}
              headerList={Header}
              dataList={tableList}
              stickyColumn={["Actions"]}
            />
          ) : (
            <Stack
              justifyContent="center"
              alignItems="center"
              h="50vh"
              fontSize="22px"
            >
              <Img src={noRecordImage} alt="No Records Image" />
              <p>No Records Found</p>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ClientRoleTable;
