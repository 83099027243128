import React, { useState } from "react";
import Select from "../../../../../jobs/components/Select";
function Client({ clientNames, handleFilterQuery, filterQuery }) {
  return (
    <Select
      options={clientNames}
      placeholder="Client"
      handleOptionChange={handleFilterQuery}
      customKey="clientIds"
      bg="#F7FAFC"
      value={filterQuery["clientIds"].value}
    />
  );
}

export default Client;
