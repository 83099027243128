import { Box, Flex } from "@chakra-ui/react";
import DropdownWithCheckboxesLatest from "components/DropdownWithCheckboxes/DropdownWithCheckBoxesLatest";
import React, { useState } from "react";
import DateRangePicker from "views/Dashboard/Inspection Table/components/DateRangePicker";
import { SearchBar } from "views/RoleAndPermission/components/SearchBar/SearchBar";

const style = {
  /* height: 72px; */
  backgroundColor: "white",
  borderRadius: "15px",
  gap: "16px",
  padding: "16px",
  justifyContent: "space-between",
  boxShadow: "0px 3.49px 5.5px 0px rgba(0, 0, 0, 0.02)",
};
const ClientRoleFilters = ({
  handleClientListFilters,
  filters,
  handleFromDateChange,
  handleToDateChange,
  cityDropdown,
  stateDropdown,
}) => {
  const status = [
    { id: true, category: "Active" },
    { id: false, category: "Inactive" },
  ];
  return (
    <Flex sx={style}>
      {/* <Box flex={"1"}>
        <SearchBar />
      </Box> */}
      <Box flex={"1"}>
        <DropdownWithCheckboxesLatest
          dropDownList={cityDropdown}
          property="cities"
          handleSelectChange={handleClientListFilters}
          selectedItems={filters["cities"]}
          title="City"
          flex="1"
          showCheckBox
        />
      </Box>
      <Box flex={"1"}>
        <DropdownWithCheckboxesLatest
          dropDownList={stateDropdown}
          property="states"
          handleSelectChange={handleClientListFilters}
          selectedItems={filters["states"]}
          title="State"
          flex="1"
          showCheckBox
        />
      </Box>
      <Box flex={"1"}>
        <DropdownWithCheckboxesLatest
          dropDownList={status}
          property="isActive"
          handleSelectChange={handleClientListFilters}
          selectedItems={filters["isActive"]}
          title="Status"
          flex="1"
        />
      </Box>
      <Box flex={"1"}>
        <DateRangePicker
          filters={filters}
          width="100%"
          handleFromDateChange={handleFromDateChange}
          handleToDateChange={handleToDateChange}
        />
      </Box>
    </Flex>
  );
};

export default ClientRoleFilters;
