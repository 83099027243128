import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { actions } from "../Create/slice";
import * as API1 from "../../../../services/apis";
import * as API from "services/index";

export function* handleFetchFormData({ payload }) {
  try {
    const res = yield call(API.fetchFormData, payload);
    const { data } = res;
    data.payload.sections.sort((a, b) => a.order - b.order);
    yield put(actions.fetchFormDataSuccess(data.payload));
    payload.onSuccess();
  } catch (err) {
    console.log({ err });
    yield put(actions.fetchFormDataFailure(err?.response?.data));
  }
}

export function* handleFetchSectionData({ payload }) {
  try {
    const res = yield call(API.fetchSectionData, payload);
    const { data } = res;
    yield put(actions.fetchSectionDataSuccess(data.payload[0]));
  } catch (err) {
    //console.log({ err });
    yield put(actions.fetchSectionDataFailure(err?.response?.data));
  }
}

export function* handleFetchBlockData({ payload }) {
  try {
    const res = yield call(API.fetchBlockData, payload);
    const { data } = res;
    yield put(actions.fetchBlockDataSuccess(data.payload[0]));
  } catch (err) {
    yield put(actions.fetchBlockDataFailure(err?.response?.data));
  }
}

export function* handleFetchCompositeData({ payload }) {
  try {
    const res = yield call(API.fetchCompositeData, payload);
    const { data } = res;
    yield put(actions.fetchCompositeDataSuccess(data.payload[0]));
  } catch (err) {
    yield put(actions.fetchCompositeDataFailure(err?.response?.data));
  }
}

export function* handleFetchComponentData({ payload }) {
  try {
    const res = yield call(API.fetchComponentData, payload);
    const { data } = res;
    yield put(actions.fetchComponentDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchComponentDataFailure(err?.response?.data));
  }
}

export function* handlePostFormData({ payload }) {
  try {
    const res = yield call(API.postFormData, payload.data);
    payload.onSuccess();
    //console.log({ res });
    // let data = JSON.parse(res.data);
    // yield put(actions.postWorkflowDataSuccess(data));
  } catch (err) {
    payload.onError();
    //console.log({ err });
    yield put(actions.postFormDataFailure(err?.response?.data));
  }
}

export function* handleFetchWorkflow({ payload }) {
  try {
    //console.log("saga was hit");
    const res = yield call(API.fetchWorkflow, payload.workflowId);
    //console.log("res", res);
    const data = res.data.payload;
    yield put(
      actions.fetchWorkflowSuccess({
        workflow: data.workflow,
        forms: data.forms,
      })
    );
  } catch (err) {
    // console.log(err);
    yield put(actions.fetchWorkflowFailure(err?.response?.data));
  }
}
export function* handleClearFormState({ payload }) {
  try {
    yield put(actions.clearFormStateSuccess());
  } catch (err) {
    console.log(err);
    yield put(actions.clearFormStateFailure(err?.response?.data));
  }
}
export function* handleFetchUserContext({ payload }) {
  try {
    const res = yield call(API1.getUserContext, payload);
    const { data } = res;
    yield put(actions.fetchUserContextSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchUserContextFailure(err));
  }
}
/***
 * Root saga manages watcher lifecycle
 ***/

export function* UpdateFormDataSaga() {
  yield takeLatest(actions.fetchWorkflow.type, handleFetchWorkflow);
  yield takeLatest(actions.postFormData.type, handlePostFormData);
  yield takeLatest(actions.fetchFormData.type, handleFetchFormData);
  yield takeEvery(actions.fetchSectionData.type, handleFetchSectionData);
  yield takeEvery(actions.fetchBlockData.type, handleFetchBlockData);
  yield takeEvery(actions.fetchCompositeData.type, handleFetchCompositeData);
  yield takeEvery(actions.fetchComponentData.type, handleFetchComponentData);
  yield takeLatest(actions.clearFormData.type, handleClearFormState);
  yield takeLatest(actions.fetchUserContextData.type, handleFetchUserContext);
}
