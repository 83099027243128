import React from "react";
import { Select } from "@agnext/reactlib";
import _ from "lodash";


export default function SelectInspectionType({
  option,
  onOptionChange,
  errorStyle,
  options,
}) {
  const getOption = (value) => {
    let el = {};
    options.forEach((option) => {
      if (option.value === value) {
        el = { ...option };
      }
    });
    return el;
  };
  console.log(options);
  return (
    <Select
      value={option.value}
      options={_.orderBy(options, "value", "asc")}
      onChange={(e) => {
        onOptionChange(getOption(e.target.value));
      }}
      placeholder="Select Inspection Type"
      focusBorderColor="login.500"
      fontSize="16px"
      sx={errorStyle}
    />
  );
}
