import React from "react";
import {
  Box,
  HStack,
  VStack,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@chakra-ui/react";
import { styles } from "../../style.module";
import { InfoIcon } from "@chakra-ui/icons";
import InfoBox from "../InfoBox";
import GaugeCharts from "components/Charts/GaugeChart";
import { gaugeColor1, arcLength1 } from "views/Variables/variables";

function WarehouseScoreComp({ data }) {
  const { warehouseScore } = data;
  return (
    <HStack
      sx={styles.gaugeOuterContainer}
      flexDirection={{ base: "column", md: "row" }}
      gap={{ sm: "36px", md: "24px" }}
    >
      <VStack sx={styles.gaugeChartContainer}>
        <HStack sx={styles.headerContainer}>
          <Text sx={styles.header}>Warehouse Score</Text>
          <Box sx={{ ...styles.header, position: "relative" }}>
            <Popover>
              <PopoverTrigger>
                <InfoIcon
                  _hover={{ cursor: "pointer" }}
                  boxSize={6}
                  color="#CBD5E0"
                />
              </PopoverTrigger>
              <PopoverContent
                style={{
                  right: "40px",
                  outline: "none",
                  border: "1px solid #E2E8F0",
                  borderRadius: "15px",
                  boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
                }}
              >
                <InfoBox text="The Warehouse Score is a metric that assesses the overall organization and documentation quality of a warehouse. It encompasses various parameters such as the condition of the warehouse structure, ventilation, plinth height, roof condition, availability and condition of fire equipment, presence of leaks, hygiene and cleanliness, electrical connections, security measures, documentation (ID cards, visitor register, stock register, etc.), valid warehouse license, incident reporting (theft, other major incidents, natural calamities), and more. This comprehensive evaluation aims to ensure efficient operations, safety, and regulatory compliance in the warehouse." />
              </PopoverContent>
            </Popover>
          </Box>
        </HStack>
        <Box>
          <GaugeCharts
            color={gaugeColor1}
            arcLength={arcLength1}
            needlePosition={warehouseScore}
          />
        </Box>
      </VStack>
      {/* <VStack sx={styles.gaugeChartContainer}>
          <HStack sx={styles.headerContainer}>
            <Text sx={styles.header}>Inspection Score</Text>
          </HStack>
          <Box>
            <GaugeCharts
              color={gaugeColor2}
              arcLength={arcLength2}
              needlePosition={needlePosition}
            />
          </Box>
        </VStack> */}
    </HStack>
  );
}

export default WarehouseScoreComp;
