// Chakra Icons
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
// Chakra Imports
import { Avatar, Button, Flex, IconButton, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuItem, MenuList, Text, Wrap, WrapItem, useColorModeValue, Modal, ModalContent, ModalHeader, Box, HStack } from "@chakra-ui/react";
import "./style.css";
import "./style.js";
// Assets

// Custom Components

import SidebarResponsive from "components/Sidebar/SidebarResponsive";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import routes from "routes.js";
import { ItemContent } from "components/Menu/ItemContent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { styles } from "./style.js";
import { formatTimeDifference } from "utils/commonFunctions";
import * as selectors from "views/Notification/selectors";
import * as selector from "views/Accounts/selectors";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "views/Notification/slice";
import { actions as accountActions } from "views/Accounts/slice";
import { actions as myApprovalActions } from "views/Users/pages/myApprovals/slice";
import { actions as rolesAndPermisionActions } from "views/RoleAndPermission/slice";
import { actions as analyticDashboardActions } from "views/Dashboard/Analytics Dashboard/slice";
import { actions as inspectionTableActions } from "views/Dashboard/Inspection Table/slice";
import { actions as warehousesActions } from "views/Dashboard/Warehouses/slice";
import { actions as collateralManagersActions } from "views/Dashboard/Collateral Manager/slice";
import { sliceKey } from "views/Notification/slice";
import { reducer } from "views/Notification/slice";
import { notificationSaga } from "views/Notification/saga";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { useUserContext } from "context/UserContext";
// import {
//   actions as customerActions,
//   sliceKey as customerSliceKey,
//   reducer as customerReducer,
// } from "views/Customer/slice";

import { actions as clientActions, sliceKey as clientSliceKey, reducer as clientReducer } from "views/Client/slice";

import { actions as userActions, sliceKey as userSliceKey, reducer as userReducer } from "views/User/slice";

export default function HeaderLinks(props) {
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: notificationSaga });

    // useInjectReducer({ key: customerSliceKey, reducer: customerReducer });
    // useInjectSaga({ key: customerSliceKey, saga: notificationSaga });

    useInjectReducer({ key: clientSliceKey, reducer: clientReducer });
    useInjectSaga({ key: clientSliceKey, saga: notificationSaga });

    useInjectReducer({ key: userSliceKey, reducer: userReducer });
    useInjectSaga({ key: userSliceKey, saga: notificationSaga });

    const dispatch = useDispatch();
    const history = useHistory();

    const local = localStorage.getItem("inspectionDashboard");
    const loginData = JSON.parse(local);
    const token = loginData?.token;
    // const userProfileData = useUserContext();
    const { userProfileData, handleLogout1 } = useUserContext();

    const [isOpen, setIsOpen] = useState(false);
    const [selectedActionIconPosition, setSelectedActionIconPosition] = useState({
        x: 0,
        y: 0,
    });
    let data = useSelector(selectors?.selectNotificationData) || [];
    let userProfile = useSelector(selectors?.selectUserData) || "";
    let uploadURL = useSelector(selectors?.selectAvatarURL) || "";
    let newProfileImg = useSelector(selector?.selectNewProfileURL) || "";

    const handleOpenModal = (e) => {
        e.preventDefault(); // Prevent default link behavior
        e.stopPropagation();

        const iconPosition = e.currentTarget.getBoundingClientRect();
        setSelectedActionIconPosition({
            x: iconPosition.left,
            y: iconPosition.top,
        });
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const handleLogout = () => {
        dispatch(actions.clearState());
        dispatch(accountActions.clearState());
        dispatch(myApprovalActions.clearState());
        dispatch(rolesAndPermisionActions.clearState());
        dispatch(analyticDashboardActions.clearState());
        dispatch(inspectionTableActions.clearState());
        dispatch(warehousesActions.clearState());
        dispatch(collateralManagersActions.clearState());
        dispatch(clientActions.clearAllStates());
        dispatch(userActions.clearAllStates());
        handleLogout1();
        localStorage.removeItem("inspectionDashboard");
        localStorage.removeItem("roleName");

        history.push("/auth/signin");
    };

    const handleMarkAllRead = () => {
        dispatch(actions.fetchNotifications({ payload: loginData }));
    };

    const modifiedResponse = data?.map((resObj) => {
        return { ...resObj, time: formatTimeDifference(resObj.creationDt) };
    });
    const { variant, children, fixed, secondary, onOpen, ...rest } = props;

    // Chakra Color Mode
    let mainText = useColorModeValue("gray.700", "gray.200");
    let navbarIcon = useColorModeValue("gray.500", "gray.200");

    if (secondary) {
        navbarIcon = "white";
        mainText = "white";
    }

    useEffect(() => {
        // dispatch(actions.fetchNotifications({ payload: loginData }));
        if (token) {
            dispatch(actions.fetchUserData({ id: loginData?.user?.id }));
        }
    }, [newProfileImg]);

    useEffect(() => {
        if (userProfile?.avatar) {
            dispatch(actions.fetchAvatarURL({ payload: userProfile?.avatar }));
        }
    }, [userProfile]);

    return (
        <Flex
            w={{ sm: "96%", md: "99%" }}
            // alignItems="center"
            justifyContent={"flex-end"}
            flexDirection="row"
            gap="24px"
        >
            <SidebarResponsive logoText={props.logoText} secondary={props.secondary} routes={routes} {...rest} />
            {/* <Menu h="498px">
        <MenuButton>
          <BellIcon color={navbarIcon} w="18px" h="18px" />
        </MenuButton>
        <MenuList sx={styles.notificationMainContainer}>
          <Flex sx={styles.notificationInnerContainer}>
            <HStack sx={styles.notificationTopContainer}>
              <Text sx={styles.notificationNewText}>
                {modifiedResponse?.length + " " + "New Notifications"}
              </Text>
              <Text
                sx={styles.notificationMarkReadText}
                onClick={handleMarkAllRead}
              >
                Mark all as read
              </Text>
            </HStack>
            <Box borderTopRadius="8px" border="1px solid #E2E8F0" w="260px">
              {modifiedResponse?.map((notification) => (
                <MenuItem
                  m="0"
                  p="0"
                  borderBottom="1px solid #E2E8F0"
                  borderTopRadius="8px"
                  key={notification.id}
                >
                  <ItemContent
                    time={notification.time}
                    info={"Audit report available for"}
                    boldInfo={notification.message.text}
                  />
                </MenuItem>
              ))}
            </Box>
            <Link to="/admin/notifications">
              <Button w="100%" borderRadius="12px">
                View All
              </Button>
            </Link>
          </Flex>
        </MenuList>
      </Menu> */}
            <Wrap>
                <WrapItem>
                    <Avatar
                        bg={uploadURL === "" ? "teal.400" : "#fff"}
                        size="sm"
                        onClick={(e) => {
                            handleOpenModal(e);
                        }}
                        _hover={{
                            cursor: "pointer",
                        }}
                        src={uploadURL}
                    />
                </WrapItem>
            </Wrap>
            {isOpen && (
                <Modal isOpen={isOpen} onClose={handleCloseModal}>
                    <ModalContent bg="white" width="322px" borderRadius="15px" position="absolute" boxShadow="0px 25px 50px -12px rgba(0, 0, 0, 0.25)" p="20px" top={selectedActionIconPosition.y + -26 + "px"} left={selectedActionIconPosition.x + -288 + "px"}>
                        <Flex className="UserDropdown">
                            <Box className="UserDetailsContainer">
                                <Text className="User">{userProfileData ? userProfileData?.firstName + " " + userProfileData?.lastName : ""}</Text>
                                <Text className="UserEmail">{userProfileData?.email}</Text>
                            </Box>
                            <Box className="UserDropdownBtnContainer">
                                <Link to="/admin/account">
                                    <Button
                                        display="unset"
                                        align="left"
                                        className="AccountBtn"
                                        variant="outline"
                                        onClick={() => {
                                            handleCloseModal();
                                        }}
                                    >
                                        Account
                                    </Button>
                                </Link>

                                <Button
                                    display="unset"
                                    className="LogoutBtn"
                                    variant="outline"
                                    onClick={() => {
                                        handleLogout();
                                        handleCloseModal();
                                    }}
                                >
                                    Logout
                                </Button>
                            </Box>
                        </Flex>
                    </ModalContent>
                </Modal>
            )}
        </Flex>
    );
}

HeaderLinks.propTypes = {
    variant: PropTypes.string,
    fixed: PropTypes.bool,
    secondary: PropTypes.bool,
    onOpen: PropTypes.func,
};
