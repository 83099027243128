import { Badge, Box, CloseButton, Flex, Text, background } from "@chakra-ui/react";
import DropdownWithCheckboxes from "components/DropdownWithCheckboxes/DropdownWithCheckboxes";
import React from "react";
import { truncateWords } from "utils/commonFunctions";
const styles = {
   heading: {
      color: "#4A5568",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "20px",
   },
   itemBadge: {
      backgroundColor: "#718096",
      color: "white",
      borderRadius: "6px",
      display: "flex",
      alignItems: "center",
      padding: "0px 6px",
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "142.857%",
      marginRight: "8px",
      width: "fit-content",
   },
   hide: {
      display: "none",
   },
};

function FilterCategory({
   title,
   property,
   selectedItems,
   handleRemoveItem,
   handleSelectChange,
   handleItemClick,
   dropDownList,
   handleSetSelection,
   isMobile,
   extraProperty,
}) {
   return (
      <Box width="100%">
         <Box display="flex" flexDirection="column" rowGap="8px">
            <Text sx={styles.heading}>{title}</Text>
            <DropdownWithCheckboxes
               title={title}
               dropDownList={dropDownList}
               property={property}
               handleSelectChange={handleSelectChange}
               selectedItems={selectedItems}
               handleItemClick={handleItemClick}
               handleSetSelection={handleSetSelection}
               isMobile={isMobile}
               extraProperty={extraProperty}
               handleRemoveItem={handleRemoveItem}
            />
         </Box>

         {/* <Flex
            marginTop="16px"
            flexWrap="wrap"
            rowGap="8px"
            position="relative"
            sx={selectedItems && selectedItems[property]?.length > 0 ? "" : styles.hide}
         >
            {selectedItems &&
               selectedItems[property]?.map((item, index) => (
                  <Badge key={index} sx={styles.itemBadge} variant="subtle">
                     {item && item.split(" ").length > 2 ? truncateWords(item) : item}
                     <CloseButton size="sm" onClick={() => handleRemoveItem(item, property)} />
                  </Badge>
               ))}
         </Flex> */}
      </Box>
   );
}

export default FilterCategory;
