import { useCallback, useEffect, useRef, useState } from "react";
import {
  Flex,
  Image,
  InputLeftElement,
  useOutsideClick,
} from "@chakra-ui/react";
import {
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Collapse,
  Box,
  Text,
} from "@chakra-ui/react";
import { DropdownArrowIcon } from "components/Icons/Icons";
import { CloseIcon } from "@chakra-ui/icons";
import Low from "assets/svg/Low.svg";
import Critical from "assets/svg/Critical.svg";
import High from "assets/svg/High.svg";
import Medium from "assets/svg/Medium.svg";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import { capitalizeString } from "utils/commonFunctions";

const styles = {
  scrollableDropdown: {
    backgroundColor: "#FFF",
    position: "absolute",
    zIndex: "1",
    maxHeight: "300px",
    overflowY: "auto",
    width: "100%",
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
    boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
    padding: "8px",
  },
  optionsContainer: {
    display: "flex",
    padding: "8px 12px",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch",
    borderRadius: "8px",
    textTransform: "capitalize",
  },
  dropdownLabel: {
    color: "#4A5568",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  dropdownLabelBottom: {
    color: "#4A5568",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
};

export default function Select({
  customKey,
  options,
  placeholder,
  handleOptionChange,
  bg,
  handleAssigneeChange,
  formId,
  value,
  isPositionTop,
  isLeftIcon
}) {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value || "");
  const [filteredOptions, setFilteredOptions] = useState(null);

  useEffect(() => {
    if (value === undefined || value === null || value === "")
      setInputValue("");
    else handleInputVal(value);
  }, [value]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    return () => {
      setInputValue("");
    };
  }, []);

  const handleSelect = (selectedOption) => {
    setInputValue(capitalizeStringUpdated(selectedOption.value));
    if (formId) {
      handleAssigneeChange(selectedOption.id, formId);
    } else {
      handleOptionChange(selectedOption.id, customKey);
    }
  };

  const handleInputVal = (value) => {
    setInputValue(value);
    const filtered =
      options.length &&
      options.filter((option) =>
        option.value.toLowerCase().includes(value.toLowerCase())
      );
    setFilteredOptions(filtered);
  };

  const ref = useRef();
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  useOutsideClick({
    ref: ref,
    handler: () => {
      if (isDropdownOpen) {
        setDropdownOpen(false);
      }
    },
  });
  const isInputEmpty = inputValue.trim() === "";

  const getPriority = useCallback(
    (priority) => {
      switch (priority.toLowerCase()) {
        case "low":
          return Low;
        case "critical":
          return Critical;
        case "high":
          return High;
        case "medium":
          return Medium;
        default:
          return null;
      }
    },
    [inputValue]
  );

  return (
    <>
      <Box
        ref={ref}
        style={{ position: "relative", flex: "1" }}
        onClick={() => {
          if (isDropdownOpen !== true) {
            setDropdownOpen(true);
          }
        }}
      >
        <InputGroup borderRadius="15px" height={"40px"}>
          <Input
            bg={bg ? bg : "white"}
            fontSize="14px"
            value={capitalizeStringUpdated(inputValue)}
            placeholder={placeholder}
            onChange={(e) => {
              handleInputVal(e.target.value);
            }}
          />
          {inputValue && getPriority(inputValue) ? (
            <InputLeftElement>
              <IconButton
                bg="none"
                icon={<Image src={getPriority(inputValue)} />}
              />
            </InputLeftElement>
          ) : (
            <></>
          )}
          <InputRightElement>
            {isInputEmpty ? (
              <IconButton
                bg="inherit"
                _hover="none"
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "none",
                }}
                icon={<DropdownArrowIcon />}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDropdown();
                }}
              />
            ) : (
              <IconButton
                bg="inherit"
                _hover="none"
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "none",
                }}
                icon={<CloseIcon color="gray.400" height="10px" />}
                onClick={() => {
                  toggleDropdown();
                  handleSelect("");
                  handleInputVal("");
                }}
              />
            )}
          </InputRightElement>
        </InputGroup>

        <Collapse in={isDropdownOpen}>
          <Box
            sx={styles.scrollableDropdown}
            style={{ [isPositionTop ? "bottom" : "top"]: "47px" }}
          >
            {filteredOptions?.length === 0 ? (
              <Box className="name-location-container">
                <Text className="warehouse-name-text">
                  No Matching {placeholder}
                </Text>
              </Box>
            ) : (
              <Box>
                {filteredOptions?.length &&
                  filteredOptions?.map((option) => (
                    <Flex
                      sx={styles.optionsContainer}
                      positionOfOption="47px"
                      // top= {positionOfOption === "top" ? "47px" : 0}
                      // bottom =  {positionOfOption === "bottom" ? "47px" : 0}

                      _hover={{ cursor: "pointer", bg: "#F7FAFC" }}
                      key={option.id}
                      onClick={() => {
                        handleSelect(option);
                        toggleDropdown();
                      }}
                      bg={
                        option?.value?.toLowerCase() ===
                        inputValue?.toLowerCase()
                          ? "#F7FAFC"
                          : "white"
                      }
                    >
                      {getPriority(option.value) && (
                        <Image src={getPriority(option.value)}></Image>
                      )}
                      <Flex direction="column">
                        <Text sx={styles.dropdownLabel}>{option?.value}</Text>
                        {option?.email && (
                          <Text sx={styles.dropdownLabelBottom}>
                            {option?.email}
                          </Text>
                        )}
                      </Flex>
                    </Flex>
                  ))}
              </Box>
            )}
          </Box>
        </Collapse>
      </Box>
    </>
  );
}
