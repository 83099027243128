export const styles = {
    responsiveDropdown: {
      display: "flex",
      height: "45px",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "10px",
      borderRadius: "8px",
      border: "1px solid #E2E8F0",
      background: "#F7FAFC",
    },
    text: {
      padding: "0px 48px 0px 16px",
    },
    icon: {
      paddingRight: "15px",
    },
    filtersContainer: {
      display: "flex",
      padding: "20px 16px",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      borderRadius: "15px 15px 0px 0px",
      background: "#FFF",
    },
    filterHead: {
      color: "#2D3748",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "140%",
    },
    filterBtn: {
      padding: "0px 16px",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      flex: "1 0 0",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "24px",
    },
  };