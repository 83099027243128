import React from "react";
import { Box, CircularProgress, HStack } from "@chakra-ui/react";
import { v4 as id } from "uuid";
import noRecordImage from "../../../../../assets/img/Empty Case.png";
import InsightsAndGraphChild from "../InsightsAndGraph";
import { weekStartingDates } from "utils/commonFunctions";
import { weekWiseArray } from "utils/commonFunctions";
import { getDataArray } from "utils/commonFunctions";
import { arraySum } from "utils/commonFunctions";
import { formatNumber } from "utils/commonFunctions";
import locationImage from "assets/svg/pin.svg";
import { getPercentage } from "utils/commonFunctions";
import { getMonthArray } from "utils/commonFunctions";

const styles = {
  message: {
    fontFamily: "Inter",
    textAlign: "center",
    fontSize: "22px",
  },
  image: {
    maxWidth: "100%",
    height: "auto",
    margin: "auto",
  },
  insightsContainer: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
  },
};

function QuantityInspected({ data }) {
  const { insight, insightsGraphCardLoading, barChartData } = data;
  // const quantityLabel =
  //   selectedDateType === "day" || selectedDateType === "month"
  //     ? getMonthArray(quantityLineChartData, selectedDateType)
  //     : weekStartingDates(
  //         weekWiseArray(inspectionsLineChartData, "totalWeight")
  //       );

  // const quantityData = getDataArray(
  //   quantityLineChartData,
  //   selectedDateType,
  //   "totalWeight"
  // );

  // const quantitySum = arraySum(quantityData);

  // const insight = {
  //   title: "Quantity Inspected (MT)",
  //   amount: isNaN(quantitySum)
  //     ? formatNumber(0, "Quantity Inspected (MT)")
  //     : formatNumber(quantitySum, "Quantity Inspected (MT)") || 0,
  //   icon: locationImage,
  //   labels: quantityLabel,
  //   data: quantityData,
  //   percentage: getPercentage(
  //     quantityLineChartData,
  //     quantitySum,
  //     selectedDateType,
  //     "totalWeight",
  //     "Quantity"
  //   ),
  // };

  if (insightsGraphCardLoading) {
    return (
      <CircularProgress
        isIndeterminate
        color="#4FD1C5"
        size="40px"
        alignSelf={"center"}
      />
    );
  }

  if (insight) {
    return (
      <>
        {/* <HStack
          gap={{ base: "16px", md: "24px" }}
          w={"100%"}
          sx={{
            "@media (max-width: 1200px)": {
              flexDirection: "column",
            },
          }}
          flexDirection={{ base: "column", md: "row" }}
        > */}
        <InsightsAndGraphChild
          title={insight.title}
          amount={insight.amount}
          icon={insight.icon}
          key={id()}
          labels={insight.labels}
          data={insight.data}
          loading={insightsGraphCardLoading}
          percentage={insight.percentage}
          tooltipData={barChartData}
        />
        {/* </HStack> */}
      </>
    );
  }

  return (
    <Box style={styles.message}>
      <img style={styles.image} src={noRecordImage} alt="No Records Image" />
      <p>No Insights For Selected Filters</p>
    </Box>
  );
}

export default QuantityInspected;
