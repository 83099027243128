import React from "react";
import { Box, CircularProgress, HStack } from "@chakra-ui/react";
import { v4 as id } from "uuid";
import noRecordImage from "../../../../../assets/img/Empty Case.png";
import InsightsAndGraphChild from "../InsightsAndGraph";
import auditImage from "assets/svg/AiOutlineFileSearch.svg";
import {
  getPercentage,
  getMonthArray,
  weekStartingDates,
  arraySum,
  getDataArray,
  weekWiseArray,
} from "utils/commonFunctions";

const styles = {
  message: {
    fontFamily: "Inter",
    textAlign: "center",
    fontSize: "22px",
  },
  image: {
    maxWidth: "100%",
    height: "auto",
    margin: "auto",
  },
  insightsContainer: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
  },
};

function TotalInspections({ data }) {
  const { insight, insightsGraphCardLoading, barChartData } = data;

  // const inspectionsData = getDataArray(
  //   inspectionsLineChartData,
  //   selectedDateType,
  //   "inspectionCount"
  // );
  // const inspectionsSum = arraySum(inspectionsData);

  // const inspectionsLabel =
  //   selectedDateType === "day" || selectedDateType === "month"
  //     ? getMonthArray(inspectionsLineChartData, selectedDateType)
  //     : weekStartingDates(
  //         weekWiseArray(inspectionsLineChartData, "inspectionCount")
  //       );

  // const insight = {
  //   title: "Total Inspections",
  //   amount: inspectionsSum || 0,
  //   icon: auditImage,
  //   labels: inspectionsLabel,
  //   data: inspectionsData,
  //   percentage: getPercentage(
  //     inspectionsLineChartData,
  //     inspectionsSum,
  //     selectedDateType,
  //     "inspectionCount",
  //     "Inspections"
  //   ),
  // };

  if (insightsGraphCardLoading) {
    return (
      <CircularProgress
        isIndeterminate
        color="#4FD1C5"
        size="40px"
        alignSelf={"center"}
      />
    );
  }

  if (insight) {
    return (
      <>
        {/* <HStack
          gap={{ base: "16px", md: "24px" }}
          w={"100%"}
          sx={{
            "@media (max-width: 1200px)": {
              flexDirection: "column",
            },
          }}
          flexDirection={{ base: "column", md: "row" }}
        > */}
        <InsightsAndGraphChild
          title={insight.title}
          amount={insight.amount}
          icon={insight.icon}
          key={id()}
          labels={insight.labels}
          data={insight.data}
          loading={insightsGraphCardLoading}
          percentage={insight.percentage}
          tooltipData={barChartData}
        />
        {/* </HStack> */}
      </>
    );
  }

  return (
    <Box style={styles.message}>
      <img style={styles.image} src={noRecordImage} alt="No Records Image" />
      <p>No Insights For Selected Filters</p>
    </Box>
  );
}

export default TotalInspections;
