import {
  Box,
  HStack,
  VStack,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import InfoBox from "../InfoBox";
import React from "react";
import { styles } from "../../style.module";
import DoughnutChart from "components/Charts/DoughnutChart";
import RatingList from "../RatingList";

function CommodityHealth({ data }) {
  const { chartArr, selectedColors, colorMap } = data;
  //Doughnut Chart Data
  // const colorMap = { average: "#FFB783", good: "#39AEA3", bad: "#FF6D6D"};
  // const selectedKeys = Object.keys(doughnutChartData).filter(
  //   (key) => doughnutChartData[key] !== 0
  // );

  // const chartArr = [];
  // for (let key in doughnutChartData) {
  //   if (selectedKeys.includes(key)) {
  //     chartArr.push(doughnutChartData[key]);
  //   }
  // }

  // const selectedColors = selectedKeys.map((key) => colorMap[key]);

  return (
    <VStack
      sx={{
        ...styles.doughnutChartContainer,
        "@media (max-width: 768px)": {
          maxHeight: "350px",
        },
      }}
    >
      <HStack sx={styles.headerContainer}>
        <Text sx={styles.header} padding={"20px"}>
          Commodity Health
        </Text>
        <Box padding={"20px"} sx={{ ...styles.header, position: "relative" }}>
          <Popover>
            <PopoverTrigger>
              <InfoIcon
                _hover={{ cursor: "pointer" }}
                boxSize={6}
                color="#CBD5E0"
              />
            </PopoverTrigger>
            <PopoverContent
              style={{
                right: "150px",
                outline: "none",
                border: "1px solid #E2E8F0",
                borderRadius: "15px",
                boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
              }}
            >
              <InfoBox text="The graph shows the overall health of the commodity stored in the warehouse." />
            </PopoverContent>
          </Popover>
        </Box>
      </HStack>
      <HStack alignSelf={"center"}>
        {chartArr[0] === 0 && chartArr[1] === 0 && chartArr[2] === 0 ? (
          <Box style={styles.message}>
            <img
              style={styles.image}
              src={noRecordImage}
              alt="No Records Image"
            />
            <p>No Records Found</p>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                ...styles.doughnutContainer,
                "@media (max-width: 768px)": {
                  height: "211px",
                  width: "211px",
                },
                "@media (min-width: 769px)": {
                  height: "292px",
                  width: "292px",
                },
              }}
            >
              <DoughnutChart
                doughnutData={chartArr}
                selectedColors={selectedColors}
              />
            </Box>
            <RatingList colorMap={colorMap} />
          </>
        )}
      </HStack>
    </VStack>
  );
}

export default CommodityHealth;
