import React, { useEffect, useState } from "react";
import { DatePicker } from "@agnext/reactlib";
import { useMediaQuery } from "@chakra-ui/react";

export default function DateTimePicker2({
  data,
  formData,
  reviewData,
  handleFormDataChange,
}) {
  const [date, setDate] = useState(() => {
    const initialValue = formData[data.element.id]?.value;

    if (initialValue !== "" && initialValue) {
      if (typeof initialValue === "object" && initialValue instanceof Date) {
        return initialValue;
      } else if (typeof initialValue === "string") {
        return new Date(initialValue);
      }
    }
    return "";
  });

  useEffect(() => {
    const initialValue = formData[data.element.id]?.value;
    if (initialValue) {
      if (typeof initialValue === "object" && initialValue instanceof Date) {
        setDate(initialValue);
      } else if (typeof initialValue === "string") {
        setDate(new Date(initialValue));
      }
    }
  }, [data.element.id, reviewData]);

  useEffect(() => {
    const initialValue = formData[data.element.id]?.value;
    if (typeof date === "object" && date instanceof Date) {
      if (typeof initialValue === "object" && initialValue instanceof Date) {
        if (date.toISOString !== initialValue.toISOString) {
          setDate(initialValue);
        }
      } else if (initialValue == "" && initialValue !== date.toISOString()) {
        setDate("");
      } else if (typeof initialValue === "string") {
        if (date.toISOString !== initialValue) {
          setDate(new Date(initialValue));
        }
      }
    } else if (typeof date === "string") {
      if (typeof initialValue === "string") {
        if (date !== initialValue) {
          setDate(new Date(initialValue));
        }
      }
    }
  }, [formData[data.element.id]?.value]);

  const [isMobileScreen] = useMediaQuery("(max-width: 500px)");
  useEffect(() => {
    handleFormDataChange(date, "", data.element.id, "NA");
  }, [data.element.id, date]);

  return (
    <>
      {date === "" ? (
        <DatePicker
          setDate={setDate}
          width={isMobileScreen ? 245 : 330}
          colorSelected="#fff"
          backgroundSelected="#4FD1C5"
        />
      ) : (
        <DatePicker
          setDate={setDate}
          date={date}
          width={isMobileScreen ? 245 : 330}
          colorSelected="#fff"
          backgroundSelected="#4FD1C5"
        />
      )}
    </>
  );
}
