import { useState } from "react";
import {
  HStack,
  Box,
  FormControl,
  FormLabel,
  Input,
  Text,
  Button,
  Flex,
  CircularProgress,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { LuUploadCloud } from "react-icons/lu";
import { useDispatch } from "react-redux";
import { userStorage } from "utils/helper";
import { useEffect } from "react";
import { actions } from "../slice";
import { actions as action } from "views/Notification/slice";
import { WarningIcon } from "@chakra-ui/icons";

export default function Profile({
  countryCode,
  emailId,
  firstName,
  lastName,
  mobile,
  uploadURL,
  uploadLoading,
  avatar,
}) {
  const [avatarName, setAvatarName] = useState("");
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [btnStatus, setBtnStatus] = useState(true);
  const userStorageData = userStorage?.get();

  const handleInputClick = (id) => {
    document.getElementById(id).click();
  };

  useEffect(() => {
    // Update component state when user data changes
    setFullName(firstName ? firstName + " " + lastName : "");
    setEmail(emailId || "");
    setPhone(mobile || "");
  }, [firstName, lastName, emailId, mobile]);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    setMessage("");
    const nameArr = fullName.trim().split(" ");
    if (nameArr.length < 2) {
      setMessage("Both first name and last name are required");
      return;
    }
    if (phone.trim().length !== 10) {
      setMessage(`Phone number must be 10 digits`);
    }
    let updatedProfileData = {
      firstName: nameArr[0],
      lastName: fullName.slice(nameArr[0].length + 1).trim(),
      email,
      countryCode,
      mobile: phone,
    };
    if (profileImagePreview !== null) {
      updatedProfileData = { ...updatedProfileData, avatar: avatarName };
    }
    // if (
    //   firstName !== updatedProfileData.firstName ||
    //   lastName !== updatedProfileData.lastName ||
    //   mobile !== updatedProfileData.mobile
    // ) {
    dispatch(
      actions.updateProfile({
        payload: { ...updatedProfileData },
        id: userStorageData?.id,
        onSuccess: () => {
          setMessage("Update Profile Details Successfully");
          dispatch(action.fetchUserData({ id: userStorageData?.id }));
          setBtnStatus(true);
        },
      })
    );
    // }

    if (profileImagePreview) {
      dispatch(
        actions.uploadFileBlob({
          payload: {
            uploadURL,
            fileBlob: profileImagePreview,
          },
          onSuccess: () => {
            setBtnStatus(true);
          },
        })
      );
      setProfileImagePreview(null);
    }
  };

  const handleFileChange = (event) => {
    setMessage("");
    const file = event.target.files[0];
    setProfileImagePreview(file);
    if (file) {
      const blobName = userStorageData?.id + "." + file.type.split("/")[1];
      setAvatarName(blobName);
      dispatch(
        actions.getPreDefinedURL({
          payload: blobName,
          onSuccess: () => {
            setBtnStatus(false);
          },
        })
      );
    }
  };

  return (
    <Box
      sx={{
        background: "#fff",
        width: "100%",
        padding: "32px",
        borderRadius: "15px",
      }}
    >
      <Text
        style={{
          color: "#2D3748",
          fontSize: "18px",
          fontWeight: "700",
          marginBottom: "32px",
        }}
      >
        Profile
      </Text>
      <FormControl sx={{ margin: "20px auto 20px 0" }}>
        <FormLabel>Profile Image</FormLabel>
        <Box>
          <Input
            id="input_profile_img"
            onChange={handleFileChange}
            style={{ display: "none" }}
            type="file"
          />
          <Box
            style={{
              width: "120px",
              height: "120px",
              border: "2px dashed #E2E8F0",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              handleInputClick("input_profile_img");
            }}
          >
            {profileImagePreview ? (
              <img
                src={URL.createObjectURL(profileImagePreview)}
                alt="Profile Image"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ) : null}
            {!profileImagePreview && (
              <LuUploadCloud
                size="24px"
                color="#718096"
                style={{ position: "absolute" }}
              />
            )}
          </Box>
        </Box>
      </FormControl>
      <FormControl sx={{ margin: "20px auto 20px 0" }}>
        <FormLabel>Full Name</FormLabel>
        <Input
          value={fullName}
          onChange={(e) => {
            setMessage("");
            let changedName = e.target.value;
            if (
              changedName !== firstName + " " + lastName ||
              profileImagePreview
            ) {
              setBtnStatus(false);
            } else {
              setBtnStatus(true);
            }
            setFullName(changedName);
          }}
          type="text"
          placeholder="Enter your full name"
        />
      </FormControl>
      <FormControl sx={{ margin: "20px auto 20px 0" }}>
        <FormLabel>Email</FormLabel>
        <Input
          disabled
          value={emailId}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          placeholder={emailId}
        />
      </FormControl>
      <FormControl sx={{ margin: "20px auto 20px 0" }}>
        <FormLabel>Phone Number</FormLabel>
        <Input
          value={phone}
          onChange={(e) => {
            setMessage("");
            let val = e.target.value;
            if (
              (mobile !== val && val.trim().length === 10) ||
              profileImagePreview
            ) {
              setBtnStatus(false);
            } else {
              setBtnStatus(true);
            }
            setPhone(val);
          }}
          type="number"
          placeholder="9876543210"
        />
      </FormControl>
      <HStack justifyContent={"flex-end"}>
        {message && (
          <Flex
            align="center"
            color={message.includes("Successfully") ? "green" : "red"}
            mt={2}
            gap={2}
          >
            <WarningIcon boxSize={6} mr={1} />
            <Text>{message}</Text>
          </Flex>
        )}
        {/* {!uploadLoading ? (
          <CircularProgress isIndeterminate color="#4FD1C5" size="24px" />
        ) : ( */}
        <Button
          w="132px"
          h="40px"
          colorScheme="login"
          style={{
            marginRight: "6px 0 0 auto",
          }}
          type="submit"
          onClick={handleSubmit}
          isDisabled={btnStatus}
        >
          Save
        </Button>
      </HStack>
    </Box>
  );
}
