import { createSlice } from "@reduxjs/toolkit";
import {
  move,
  sort,
  deletion,
  duplicate,
  duplicate2,
  generateTitle,
  generateIdSync,
  fixOrder,
} from "utils/index";
import _ from "lodash";

export const initialState = {
  loading: false,
  error: null,
  workflows: [],
  workflow: {},
  form: {},
  forms: [],
  sections: [],
  blocks: [],
  composites: [],
  components: [],
  userContext: null,
};

const homePage = createSlice({
  name: "homePage",
  initialState,
  reducers: {
    //Fetch User Context
    fetchUserContextData(state, action) {
      state.loading = true;
    },
    fetchUserContextSuccess(state, action) {
      state.loading = false;
      state.userContext = action.payload[0].enabledInspectionTypes;
    },
    fetchUserContextFailure(state, action) {
      state.loading = false;
    },
    // Fetch Data
    fetchWorkflows: (state, action) => {
      state.loading = true;
    },
    fetchWorkflowsSuccess: (state, action) => {
      state.loading = false;
      state.workflows = action.payload;
    },
    fetchWorkflowsFailure: (state, action) => {
      state.loading = false;
    },
    postWorkflow: (state, action) => {
      state.loading = true;
    },
    postWorkflowSuccess: () => {
      state.loading = false;
    },
    postWorkflowFailure: () => {
      state.loading = false;
    },
    fetchFormData: (state, action) => {
      state.loading = true;
    },
    fetchFormDataSuccess: (state, action) => {
      // let newSectionsStructure = action.payload.form.structure.sections;
      // newSectionsStructure = fixOrder(newSectionsStructure);
      state.form = action.payload.form;
      // if (state.form && state.form.structure) {
      //   state.form.structure = { sections: newSectionsStructure };
      // } else {
      //   state.form = {
      //     structure: action.payload.form.structure,
      //   };
      // }
      state.sections = action.payload.sections ? action.payload.sections : [];
      state.blocks = action.payload.blocks ? action.payload.blocks : [];
      state.composites = action.payload.composites
        ? action.payload.composites
        : [];
      state.components = action.payload.components
        ? action.payload.components
        : [];
      state.loading = false;
    },
    fetchFormDataFailure: (state, action) => {
      state.loading = false;
    },
    fetchSectionData: (state, action) => {
      state.loading = true;
    },
    fetchSectionDataSuccess: (state, action) => {
      state.loading = false;
      const sections = [...state.sections];
      sections.push(action.payload);
      state.sections = sections;
    },
    fetchSectionDataFailure: (state, action) => {
      state.loading = false;
    },
    fetchBlockData: (state, action) => {
      state.loading = true;
    },
    fetchBlockDataSuccess: (state, action) => {
      state.loading = false;
      const blocks = [...state.blocks];
      blocks.push(action.payload);
      state.blocks = blocks;
    },
    fetchBlockDataFailure: (state, action) => {
      state.loading = false;
    },
    fetchCompositeData: (state, action) => {
      state.loading = true;
    },
    fetchCompositeDataSuccess: (state, action) => {
      state.loading = false;
      const composites = [...state.composites];
      composites.push(action.payload);
      state.composites = composites;
    },
    fetchCompositeDataFailure: (state, action) => {
      state.loading = false;
    },
    fetchComponentData: (state, action) => {
      state.loading = true;
    },
    fetchComponentDataSuccess: (state, action) => {
      state.loading = false;
      const components = [...state.components];
      components.push(action.payload);
      state.components = components;
    },
    fetchComponentDataFailure: (state, action) => {
      state.loading = false;
    },

    // post
    postFormData: (state, action) => {
      state.loading = true;
    },

    postFormDataSuccess: (state, action) => {
      state.loading = false;
    },

    postFormDataFailure: (state, action) => {
      state.loading = false;
      // state.error = action.payload;
    },

    // Init

    init: (state, action) => {
      const { form, workflow } = action.payload;
      state.workflow = workflow;
      state.forms = [form];
      state.sections = [];
      state.blocks = [];
      state.composites = [];
      state.components = [];
    },

    // clear State Workflow

    clearState: (state, action) => {
      state = initialState;
    },

    // creating shell in backend (workflow, form and workflow-forms relation)
    initWorkflow: (state, action) => {
      state.loading = true;
    },

    initWorkflowSuccess: (state, action) => {
      state.loading = false;
    },

    initWorkflowFailure: (state, action) => {
      state.loading = false;
    },

    fetchWorkflow: (state, action) => {
      state.loading = true;
    },
    fetchWorkflowSuccess: (state, action) => {
      state.loading = false;
      state.workflow = action.payload.workflow;
      state.forms = action.payload.forms;
    },
    fetchWorkflowFailure: (state, action) => {
      state.loading = false;
    },
    clearFormData: (state, action) => {
      state.loading = true;
      state.sections = [];
      state.blocks = [];
      state.composites = [];
      state.components = [];
      state.form = {};
    },
    clearFormStateSuccess: (state, action) => {
      state.loading = false;
    },
    clearFormStateFailure: (state, action) => {
      state.loading = false;
    },
    cloneWorkflow: (state, action) => {
      state.loading = true;
    },
    cloneWorkflowSuccess: (state) => {
      state.loading = false;
    },
    cloneWorkflowFailure: (state, action) => {
      state.loading = false;
    },
    initCloneWorkflow: (state, action) => {
      state.loading = true;
    },
    initCopyWorkflowSuccess: (state) => {
      state.loading = false;
    },
    initCopyWorkflowFailure: (state, action) => {
      state.loading = false;
    },
    copyForms: (state, action) => {
      state.loading = false;
    },
    copyFormsSuccess: (state) => {
      state.loading = false;
    },
    copyFormsFailure: (state, action) => {
      state.loading = false;
    },

    editInspectionType: (state, action) => {
      const { inspectionType, formId } = action.payload;
      state.form.categoryType = inspectionType;
      state.forms = state.forms.map((form) => {
        if (form.id === formId) {
          form.categoryType = inspectionType;
        }
        return form;
      });
    },

    // Adding new form

    addNewForm: (state, action) => {
      let form = action.payload;
      state.forms.push(form);
    },

    fetchForms: (state, action) => {
      state.loading = true;
    },

    fetchFormsSuccess: (state, action) => {
      state.loading = false;
      state.forms = action.payload;
    },

    fetchFormsFailure: (state, action) => {
      state.loading = false;
    },

    // delete form
    deleteForm: (state, action) => {
      state.loading = true;
    },
    deleteFormSuccess: (state, action) => {
      state.loading = false;
    },
    deleteFormFailure: (state, action) => {
      state.loading = false;
    },

    // Init End

    // editformName

    editWorkflowTitle: (state, action) => {
      state.workflow.title = action.payload;
    },

    editWorkflowDescription: (state, action) => {
      state.workflow.description = action.payload;
    },

    editFormName: (state, action) => {
      const { formId, form } = action.payload;
      state.forms.forEach((currForm) => {
        if (currForm.id === formId) {
          currForm.title = form;
        }
      });
    },

    // editformName end

    // editformDescription

    editFormDescription: (state, action) => {
      const { formId, description } = action.payload;
      state.forms.forEach((currForm) => {
        if (currForm.id === formId) {
          currForm.description = description;
        }
      });
    },

    // editformDescription end

    // initNewSection
    initNewSection: (state, action) => {
      const { sectionId, formId } = action.payload;
      const section = { id: sectionId };
      let structureOrder;
      // console.log({forms: state.forms})
      const sections = state.form.structure.sections || [];

      state.sections.sort((a, b) => a.order - b.order);

      if (sections.length) {
        structureOrder = sections[sections.length - 1].order + 1;
      } else {
        structureOrder = 1;
      }
      section.order = structureOrder;
      // section.tableName = "inspectionItems";
      const title = generateTitle("Page", structureOrder);
      section.title = title;
      section.structure = { blocks: [] };
      const sectionForStructure = {
        id: sectionId,
        title,
        order: structureOrder,
      };
      sections.push(sectionForStructure);
      state.forms = state.forms.map((form) => {
        if (form.id === formId) {
          form.structure.sections.push(sectionForStructure);
        }
        return form;
      });
      state.form.structure = {
        sections,
      };
      state.sections.push({
        ...section,
      });
    },

    // initNewSection end

    // editSectionName
    editSectionName: (state, action) => {
      const { sectionId, sectionName } = action.payload;
      let sections =
        state.form.structure && state.form.structure.sections
          ? [...state.form.structure.sections]
          : [];
      let section = {};
      sections.forEach((currSection) => {
        if (currSection.id === sectionId) {
          section = { ...currSection };
          section.title = sectionName;
        }
      });
      sections = sections.map((currSection) => {
        if (currSection.id === sectionId) {
          const newSection = { ...currSection, title: sectionName };
          return newSection;
        }
        return currSection;
      });
      const structure = { sections };
      state.form = { ...state.form, structure };
      let newSections = [...state.sections];
      newSections = newSections.map((currSection) => {
        if (currSection.id === sectionId) {
          const newSection = { ...currSection, title: sectionName };
          return newSection;
        }
        return currSection;
      });
      state.sections = newSections;
    },

    // editSectionName end

    // editSectionDescription

    editSectionDescription: (state, action) => {
      const { sectionId, sectionDescription } = action.payload;

      let newSections = [...state.sections];
      newSections = newSections.map((currSection) => {
        if (currSection.id === sectionId) {
          const newSection = {
            ...currSection,
            description: sectionDescription,
          };
          return newSection;
        }
        return currSection;
      });
      state.sections = newSections;
    },

    // editSectionDescription end

    // editLinkTable

    editLinkTable: (state, action) => {
      const { sectionId, tableName } = action.payload;

      let newSections = [...state.sections];
      newSections = newSections.map((currSection) => {
        if (currSection.id === sectionId) {
          const newSection = {
            ...currSection,
          };
          return newSection;
        }
        return currSection;
      });
      state.sections = newSections;
    },

    // editLinkTable end

    // moveUpSection

    moveUpSection: (state, action) => {
      const { sectionId } = action.payload;
      let sections =
        state.form.structure && state.form.structure.sections
          ? [...state.form.structure.sections]
          : [];

      for (
        let currSectionIndex = 1;
        currSectionIndex < sections.length;
        currSectionIndex++
      ) {
        if (sections[currSectionIndex].id === sectionId) {
          let section1 = { ...sections[currSectionIndex] };
          let section2 = { ...sections[currSectionIndex - 1] };
          if (section1.order === section2.order) {
            fixOrder(sections);
          }
          let { updated1, updated2 } = move(section1, section2);
          sections = sections.map((currSection) => {
            if (currSection.id === updated1.id) {
              const newSection1 = { ...updated1 };
              return newSection1;
            } else if (currSection.id === updated2.id) {
              const newSection2 = { ...updated2 };
              return newSection2;
            } else {
              return currSection;
            }
          });
          sort(sections);
          const structure = { sections };
          state.form = { ...state.form, structure };
          break;
        }
      }
      /// section Array up
      let sectionsArray = state.sections ? state.sections : [];
      sort(sectionsArray);
      for (
        let currSectionIndex = 1;
        currSectionIndex < sectionsArray.length;
        currSectionIndex++
      ) {
        if (sectionsArray[currSectionIndex].id === sectionId) {
          let section1 = { ...sectionsArray[currSectionIndex] };
          let section2 = { ...sectionsArray[currSectionIndex - 1] };
          if (section1.order === section2.order) {
            fixOrder(sectionsArray);
          }
          let { updated1, updated2 } = move(section1, section2);
          sectionsArray = sectionsArray.map((currSection) => {
            if (currSection.id === updated1.id) {
              const newSection1 = { ...updated1 };
              return newSection1;
            } else if (currSection.id === updated2.id) {
              const newSection2 = { ...updated2 };
              return newSection2;
            } else {
              return currSection;
            }
          });
          sort(sectionsArray);
          state.sections = sectionsArray;
          break;
        }
      }
      ///
    },

    // moveUpSection end

    // initNewBlock

    initNewBlock: (state, action) => {
      const { sectionId, blockId } = action.payload;
      const block = { id: blockId, structure: { composites: [] } };
      const section = state.sections.reduce((acc, cv) => {
        if (cv.id === sectionId) {
          acc = { ...cv };
        }
        return acc;
      }, {});

      let blocks;

      if (section.structure) {
        blocks = [...section.structure.blocks];
      } else {
        blocks = [];
      }

      let structureOrder;
      if (blocks.length) {
        structureOrder = blocks[blocks.length - 1].order + 1;
      } else {
        structureOrder = 1;
      }
      block.order = structureOrder;
      const title = generateTitle("Section", structureOrder);
      block.title = title;
      blocks.push({ id: blockId, title, order: structureOrder });
      section.structure = { blocks };

      const newSections = state.sections.map((currSection) => {
        if (currSection.id === sectionId) {
          return section;
        }
        return currSection;
      });
      state.sections = newSections;
      let order;
      const newBlocks = state.blocks;
      if (newBlocks.length) {
        order = newBlocks[newBlocks.length - 1].order + 1;
      } else {
        order = 1;
      }
      let newBlock = { ...block };
      newBlock.order = order;
      newBlocks.push(newBlock);
      state.blocks = newBlocks;
    },

    // initNewBlock end

    // editBlockName

    editBlockName: (state, action) => {
      const { blockName, blockId, sectionId } = action.payload;

      let section = {};

      state.sections.forEach((currSection) => {
        if (currSection.id === sectionId) {
          section = { ...currSection };
        }
      });

      const blocks =
        section.structure &&
        [...section.structure.blocks].map((block) => {
          if (block.id !== blockId) return block;
          const newBlock = { ...block, title: blockName };
          return newBlock;
        });

      const sections = [...state.sections].map((section) => {
        if (section.id !== sectionId) return section;
        const newSection = {
          ...section,
          structure: { blocks },
        };
        return newSection;
      });
      state.sections = sections;

      const newBlocks = [...state.blocks].map((block) => {
        if (block.id !== blockId) return block;
        const newBlock = {
          ...block,
          title: blockName,
        };
        return newBlock;
      });
      state.blocks = newBlocks;
    },

    // editBlockName end

    // editBlockDescription

    editBlockDescription: (state, action) => {
      const { blockId, blockDescription } = action.payload;

      let newBlocks = [...state.blocks];
      newBlocks = newBlocks.map((currBlock) => {
        if (currBlock.id === blockId) {
          const newBlock = {
            ...currBlock,
            description: blockDescription,
          };
          return newBlock;
        }
        return currBlock;
      });
      state.blocks = newBlocks;
    },

    // editBlockDescription end

    // editCompositeName

    editCompositeName: (state, action) => {
      const { compositeName, blockId, compositeId } = action.payload;

      let block = {};

      state.blocks.forEach((currBlock) => {
        if (currBlock.id === blockId) {
          block = { ...currBlock };
        }
      });

      const composites =
        block.structure &&
        [...block.structure.composites].map((composite) => {
          if (composite.id !== compositeId) return composite;
          const newComposite = { ...composite, title: compositeName };
          return newComposite;
        });

      const blocks = [...state.blocks].map((block) => {
        if (block.id !== blockId) return block;
        const newBlock = {
          ...block,
          structure: { composites },
        };
        return newBlock;
      });
      state.blocks = blocks;

      const newComposites = [...state.composites].map((composite) => {
        if (composite.id !== compositeId) return composite;
        const newComposite = {
          ...composite,
          title: compositeName,
        };
        return newComposite;
      });
      state.composites = newComposites;
    },

    // editCompositeName end

    // initNewComponent

    initNewComponent: (state, action) => {
      const { compositeId, componentId } = action.payload;
      const component = { id: componentId, structure: { elements: [] } };
      const composite = state.composites.reduce((acc, cv) => {
        if (cv.id === compositeId) {
          acc = { ...cv };
        }
        return acc;
      }, {});

      let components;

      if (composite.structure) {
        components = [...composite.structure.components];
      } else {
        components = [];
      }

      let structureOrder;
      if (components.length) {
        structureOrder = components[components.length - 1].order + 1;
      } else {
        structureOrder = 1;
      }
      component.order = structureOrder;
      const title = generateTitle("Component", structureOrder);
      component.title = title;
      components.push({ id: componentId, title, order: structureOrder });

      composite.structure = { components };

      const newComposites = state.composites.map((currComposite) => {
        if (currComposite.id === compositeId) {
          return composite;
        }
        return currComposite;
      });
      state.composites = newComposites;
      let order;
      const newComponents = state.components;
      if (newComponents.length) {
        order = newComponents[newComponents.length - 1].order + 1;
      } else {
        order = 1;
      }
      let newComponent = { ...component };
      newComponent.order = order;
      newComponents.push(newComponent);
      state.components = newComponents;
    },

    // initNewComponent end

    // moveDownSection

    moveDownSection: (state, action) => {
      const { sectionId } = action.payload;
      let sections =
        state.form.structure && state.form.structure.sections
          ? [...state.form.structure.sections]
          : [];
      for (
        let currSectionIndex = 0;
        currSectionIndex < sections.length - 1;
        currSectionIndex++
      ) {
        if (sections[currSectionIndex].id === sectionId) {
          let section1 = { ...sections[currSectionIndex] };
          let section2 = { ...sections[currSectionIndex + 1] };
          let { updated1, updated2 } = move(section1, section2);
          sections = sections.map((currSection) => {
            if (currSection.id === updated1.id) {
              const newSection = { ...updated1 };
              return newSection;
            }
            if (currSection.id === updated2.id) {
              const newSection = { ...updated2 };
              return newSection;
            }
            return currSection;
          });
          sort(sections);
          const structure = { sections };
          state.form = { ...state.form, structure };
          break;
        }
      }

      // sections array move down
      let sectionsArray = state.sections ? state.sections : [];
      sort(sectionsArray);
      for (
        let currSectionIndex = 0;
        currSectionIndex < sectionsArray.length - 1;
        currSectionIndex++
      ) {
        if (sectionsArray[currSectionIndex].id === sectionId) {
          let section1 = { ...sectionsArray[currSectionIndex] };
          let section2 = { ...sectionsArray[currSectionIndex + 1] };
          let { updated1, updated2 } = move(section1, section2);
          sectionsArray = sectionsArray.map((currSection) => {
            if (currSection.id === updated1.id) {
              const newSection = { ...updated1 };
              return newSection;
            }
            if (currSection.id === updated2.id) {
              const newSection = { ...updated2 };
              return newSection;
            }
            return currSection;
          });
          sort(sectionsArray);
          state.sections = sectionsArray;
          break;
        }
      }
      ///
    },

    // moveDownSection end

    // initNewComposite

    initNewComposite: (state, action) => {
      const { compositeId, blockId } = action.payload;
      const composite = { id: compositeId, structure: { components: [] } };
      const block = state.blocks.reduce((acc, cv) => {
        if (cv.id === blockId) {
          acc = { ...cv };
        }
        return acc;
      }, {});

      let composites;

      if (block.structure) {
        composites = [...block.structure.composites];
      } else {
        composites = [];
      }

      let structureOrder;
      if (composites.length) {
        structureOrder = composites[composites.length - 1].order + 1;
      } else {
        structureOrder = 1;
      }
      composite.order = structureOrder;
      const title = generateTitle("Composite", structureOrder);
      composite.title = title;
      composites.push({ id: compositeId, title, order: structureOrder });

      block.structure = { composites };

      const newBlocks = state.blocks.map((currBlock) => {
        if (currBlock.id === blockId) {
          return block;
        }
        return currBlock;
      });
      state.blocks = newBlocks;
      let order;
      const newComposites = state.composites;
      if (newComposites.length) {
        order = newComposites[newComposites.length - 1].order + 1;
      } else {
        order = 1;
      }
      let newComposite = { ...composite };
      newComposite.order = order;
      newComposites.push(newComposite);
      state.composites = newComposites;
    },

    // initNewComposite end

    // moveUpBlock

    moveUpBlock: (state, action) => {
      const { blockId, sectionId } = action.payload;
      let sections = state.sections ? [...state.sections] : [];
      let section = {};
      sections.forEach((currSection) => {
        if (currSection.id === sectionId) {
          section = { ...currSection };
        }
      });
      let blocks =
        section.structure && section.structure.blocks
          ? [...section.structure.blocks]
          : [];
      for (
        let currBlockIndex = 1;
        currBlockIndex < blocks.length;
        currBlockIndex++
      ) {
        if (blocks[currBlockIndex].id === blockId) {
          let block1 = { ...blocks[currBlockIndex] };
          let block2 = { ...blocks[currBlockIndex - 1] };
          let { updated1, updated2 } = move(block1, block2);
          blocks = blocks.map((currBlock) => {
            if (currBlock.id === updated1.id) {
              const newBlock1 = { ...updated1 };
              return newBlock1;
            } else if (currBlock.id === updated2.id) {
              const newBlock2 = { ...updated2 };
              return newBlock2;
            } else {
              return currBlock;
            }
          });
          sort(blocks);
          const structure = { blocks };
          section = { ...section, structure };
          sections = sections.map((currSection) => {
            if (currSection.id === sectionId) {
              return section;
            }
            return currSection;
          });
          state.sections = sections;
          break;
        }
      }
    },

    // moveUpBlock end

    // moveDownBlock

    moveDownBlock: (state, action) => {
      const { blockId, sectionId } = action.payload;
      let sections = state.sections ? [...state.sections] : [];
      let section = {};
      sections.forEach((currSection) => {
        if (currSection.id === sectionId) {
          section = { ...currSection };
        }
      });
      let blocks =
        section.structure && section.structure.blocks
          ? [...section.structure.blocks]
          : [];
      for (
        let currBlockIndex = 0;
        currBlockIndex < blocks.length - 1;
        currBlockIndex++
      ) {
        if (blocks[currBlockIndex].id === blockId) {
          let block1 = { ...blocks[currBlockIndex] };
          let block2 = { ...blocks[currBlockIndex + 1] };
          let { updated1, updated2 } = move(block1, block2);
          blocks = blocks.map((currBlock) => {
            if (currBlock.id === updated1.id) {
              const newBlock1 = { ...updated1 };
              return newBlock1;
            } else if (currBlock.id === updated2.id) {
              const newBlock2 = { ...updated2 };
              return newBlock2;
            } else {
              return currBlock;
            }
          });
          sort(blocks);
          const structure = { blocks };
          section = { ...section, structure };
          sections = sections.map((currSection) => {
            if (currSection.id == sectionId) {
              return section;
            }
            return currSection;
          });
          state.sections = sections;
          break;
        }
      }
    },

    // moveDownBlock end

    // moveUpComposite

    moveUpComposite: (state, action) => {
      const { blockId, compositeId } = action.payload;
      let blocks = state.blocks ? [...state.blocks] : [];
      let block = {};
      blocks.forEach((currBlock) => {
        if (currBlock.id === blockId) {
          block = { ...currBlock };
        }
      });
      let composites =
        block.structure && block.structure.composites
          ? [...block.structure.composites]
          : [];
      for (
        let currCompositeIndex = 1;
        currCompositeIndex < composites.length;
        currCompositeIndex++
      ) {
        if (composites[currCompositeIndex].id === compositeId) {
          let composite1 = { ...composites[currCompositeIndex] };
          let composite2 = { ...composites[currCompositeIndex - 1] };
          let { updated1, updated2 } = move(composite1, composite2);
          composites = composites.map((currComposite) => {
            if (currComposite.id === updated1.id) {
              const newComposite1 = { ...updated1 };
              return newComposite1;
            } else if (currComposite.id === updated2.id) {
              const newComposite2 = { ...updated2 };
              return newComposite2;
            } else {
              return currComposite;
            }
          });
          sort(composites);
          const structure = { composites };
          block = { ...block, structure };
          blocks = blocks.map((currBlock) => {
            if (currBlock.id == blockId) {
              return block;
            }
            return currBlock;
          });
          state.blocks = blocks;
          break;
        }
      }
    },

    // moveUpComposite end

    // moveDownComposite

    moveDownComposite: (state, action) => {
      const { blockId, compositeId } = action.payload;
      let blocks = state.blocks ? [...state.blocks] : [];
      let block = {};
      blocks.forEach((currBlock) => {
        if (currBlock.id === blockId) {
          block = { ...currBlock };
        }
      });
      let composites =
        block.structure && block.structure.composites
          ? [...block.structure.composites]
          : [];
      for (
        let currCompositeIndex = 0;
        currCompositeIndex < composites.length - 1;
        currCompositeIndex++
      ) {
        if (composites[currCompositeIndex].id === compositeId) {
          let composite1 = { ...composites[currCompositeIndex] };
          let composite2 = { ...composites[currCompositeIndex + 1] };
          let { updated1, updated2 } = move(composite1, composite2);
          composites = composites.map((currComposite) => {
            if (currComposite.id === updated1.id) {
              const newComposite1 = { ...updated1 };
              return newComposite1;
            } else if (currComposite.id === updated2.id) {
              const newComposite2 = { ...updated2 };
              return newComposite2;
            } else {
              return currComposite;
            }
          });
          sort(composites);
          const structure = { composites };
          block = { ...block, structure };
          blocks = blocks.map((currBlock) => {
            if (currBlock.id == blockId) {
              return block;
            }
            return currBlock;
          });
          state.blocks = blocks;
          break;
        }
      }
    },

    // moveDownComposite end

    // moveUpComponent

    moveUpComponent: (state, action) => {
      const { componentId, compositeId } = action.payload;
      let composites = state.composites ? [...state.composites] : [];
      let composite = {};
      composites.forEach((currComposite) => {
        if (currComposite.id === compositeId) {
          composite = { ...currComposite };
        }
      });
      let components =
        composite.structure && composite.structure.components
          ? [...composite.structure.components]
          : [];
      for (
        let currComponentIndex = 1;
        currComponentIndex < components.length;
        currComponentIndex++
      ) {
        if (components[currComponentIndex].id === componentId) {
          let component1 = { ...components[currComponentIndex] };
          let component2 = { ...components[currComponentIndex - 1] };
          let { updated1, updated2 } = move(component1, component2);
          components = components.map((currComponent) => {
            if (currComponent.id === updated1.id) {
              const newComponent1 = { ...updated1 };
              return newComponent1;
            } else if (currComponent.id === updated2.id) {
              const newComponent2 = { ...updated2 };
              return newComponent2;
            } else {
              return currComponent;
            }
          });
          sort(components);
          const structure = { components };
          composite = { ...composite, structure };
          composites = composites.map((currComposite) => {
            if (currComposite.id === compositeId) {
              return composite;
            }
            return currComposite;
          });
          state.composites = composites;
          break;
        }
      }
    },

    // moveUpComponent end

    // editComponentName
    editComponentName: (state, action) => {
      const { componentName, compositeId, componentId } = action.payload;

      let composite = {};

      state.composites.forEach((currComposite) => {
        if (currComposite.id === compositeId) {
          composite = { ...currComposite };
        }
      });

      const components =
        composite.structure &&
        [...composite.structure.components].map((component) => {
          if (component.id !== componentId) return component;
          const newComponent = { ...component, title: componentName };
          return newComponent;
        });

      const composites = [...state.composites].map((composite) => {
        if (composite.id !== compositeId) return composite;
        const newComposite = {
          ...composite,
          structure: { components },
        };
        return newComposite;
      });
      state.composites = composites;

      const newComponents = [...state.components].map((component) => {
        if (component.id !== componentId) return component;
        const newComponent = {
          ...component,
          title: componentName,
        };
        return newComponent;
      });
      state.components = newComponents;
    },

    // editComponentName end

    // moveDownComponent

    moveDownComponent: (state, action) => {
      const { componentId, compositeId } = action.payload;
      let composites = state.composites ? [...state.composites] : [];
      let composite = {};
      composites.forEach((currComposite) => {
        if (currComposite.id === compositeId) {
          composite = { ...currComposite };
        }
      });
      let components =
        composite.structure && composite.structure.components
          ? [...composite.structure.components]
          : [];
      for (
        let currComponentIndex = 0;
        currComponentIndex < components.length - 1;
        currComponentIndex++
      ) {
        if (components[currComponentIndex].id === componentId) {
          let component1 = { ...components[currComponentIndex] };
          let component2 = { ...components[currComponentIndex + 1] };
          let { updated1, updated2 } = move(component1, component2);
          components = components.map((currComponent) => {
            if (currComponent.id === updated1.id) {
              const newComponent1 = { ...updated1 };
              return newComponent1;
            } else if (currComponent.id === updated2.id) {
              const newComponent2 = { ...updated2 };
              return newComponent2;
            } else {
              return currComponent;
            }
          });
          sort(components);
          const structure = { components };
          composite = { ...composite, structure };
          composites = composites.map((currComposite) => {
            if (currComposite.id === compositeId) {
              return composite;
            }
            return currComposite;
          });
          state.composites = composites;
          break;
        }
      }
    },

    // moveDownComponent end

    // initNewElement

    initNewElement: (state, action) => {
      const { elementId, componentId } = action.payload;
      const element = { id: elementId, mandatory: true };
      const component = state.components.reduce((acc, cv) => {
        if (cv.id === componentId) {
          acc = { ...cv };
        }
        return acc;
      }, {});

      let elements;

      if (component.structure) {
        elements = [...component.structure.elements];
      } else {
        elements = [];
      }

      let structureOrder;
      if (elements.length) {
        structureOrder = elements[elements.length - 1].order + 1;
      } else {
        structureOrder = 1;
      }
      element.order = structureOrder;
      elements.push(element);

      component.structure = { elements };

      const newComponents = state.components.map((currComponent) => {
        if (currComponent.id === componentId) {
          return component;
        }
        return currComponent;
      });
      state.components = newComponents;
    },

    // initNewElement end

    // editElementLabel

    editElementLabel: (state, action) => {
      const { elementId, componentId, elementName } = action.payload;

      let component = {};

      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          component = { ...currComponent };
        }
      });

      const elements =
        component.structure &&
        [...component.structure.elements].map((element) => {
          if (element.id !== elementId) return element;
          const newElement = { ...element, label: elementName };
          return newElement;
        });

      const components = [...state.components].map((component) => {
        if (component.id !== componentId) return component;
        const newComponent = {
          ...component,
          structure: { elements },
        };
        return newComponent;
      });
      state.components = components;
    },

    // editElementLabel end

    // moveUpElement

    moveUpElement: (state, action) => {
      const { componentId, elementId } = action.payload;
      let components = state.components ? [...state.components] : [];
      let component = {};
      components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          component = { ...currComponent };
        }
      });
      let elements =
        component.structure && component.structure.elements
          ? [...component.structure.elements]
          : [];
      for (
        let currElementIndex = 1;
        currElementIndex < elements.length;
        currElementIndex++
      ) {
        if (elements[currElementIndex].id === elementId) {
          let element1 = { ...elements[currElementIndex] };
          let element2 = { ...elements[currElementIndex - 1] };
          let { updated1, updated2 } = move(element1, element2);
          elements = elements.map((currElement) => {
            if (currElement.id === updated1.id) {
              const newElement1 = { ...updated1 };
              return newElement1;
            } else if (currElement.id === updated2.id) {
              const newElement2 = { ...updated2 };
              return newElement2;
            } else {
              return currElement;
            }
          });
          sort(elements);
          const structure = { elements };
          component = { ...component, structure };
          components = components.map((currComponent) => {
            if (currComponent.id === componentId) {
              return component;
            }
            return currComponent;
          });
          state.components = components;
          break;
        }
      }
    },

    // moveUpElement end

    // moveDownElement

    moveDownElement: (state, action) => {
      const { componentId, elementId } = action.payload;
      let components = state.components ? [...state.components] : [];
      let component = {};
      components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          component = { ...currComponent };
        }
      });
      let elements =
        component.structure && component.structure.elements
          ? [...component.structure.elements]
          : [];
      for (
        let currElementIndex = 0;
        currElementIndex < elements.length - 1;
        currElementIndex++
      ) {
        if (elements[currElementIndex].id === elementId) {
          let element1 = { ...elements[currElementIndex] };
          let element2 = { ...elements[currElementIndex + 1] };
          //console.log("before", element1, element2);
          let { updated1, updated2 } = move(element1, element2);
          //console.log("after", updated1, updated2);
          elements = elements.map((currElement) => {
            if (currElement.id === updated1.id) {
              const newElement1 = { ...updated1 };
              return newElement1;
            } else if (currElement.id === updated2.id) {
              const newElement2 = { ...updated2 };
              return newElement2;
            } else {
              return currElement;
            }
          });
          sort(elements);
          const structure = { elements };
          component = { ...component, structure };
          components = components.map((currComponent) => {
            if (currComponent.id === componentId) {
              return component;
            }
            return currComponent;
          });
          state.components = components;
          break;
        }
      }
    },

    editCompositeDescription: (state, action) => {
      const { compositeId, compositeDescription } = action.payload;

      let newComposites = [...state.composites];
      newComposites = newComposites.map((currComposite) => {
        if (currComposite.id === compositeId) {
          const newComposite = {
            ...currComposite,
            description: compositeDescription,
          };
          return newComposite;
        }
        return currComposite;
      });
      state.composites = newComposites;
    },

    editElementPlaceHolder: (state, action) => {
      const { elementId, componentId, placeholder } = action.payload;

      let component = {};

      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          component = { ...currComponent };
        }
      });

      const elements =
        component.structure &&
        [...component.structure.elements].map((element) => {
          if (element.id !== elementId) return element;
          const newElement = { ...element, placeholder };
          return newElement;
        });

      const components = [...state.components].map((component) => {
        if (component.id !== componentId) return component;
        const newComponent = {
          ...component,
          structure: { elements },
        };
        return newComponent;
      });
      state.components = components;
    },

    editElementText: (state, action) => {
      const { elementId, componentId, text } = action.payload;

      let component = {};

      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          component = { ...currComponent };
        }
      });

      const elements =
        component.structure &&
        [...component.structure.elements].map((element) => {
          if (element.id !== elementId) return element;
          const newElement = { ...element, supportingText: text };
          return newElement;
        });

      const components = [...state.components].map((component) => {
        if (component.id !== componentId) return component;
        const newComponent = {
          ...component,
          structure: { elements },
        };
        return newComponent;
      });
      state.components = components;
    },

    editElementRange: (state, action) => {
      const { elementId, componentId, range } = action.payload;

      let component = {};

      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          component = { ...currComponent };
        }
      });

      const elements =
        component.structure &&
        [...component.structure.elements].map((element) => {
          if (element.id !== elementId) return element;
          const newElement = { ...element, range };
          return newElement;
        });

      const components = [...state.components].map((component) => {
        if (component.id !== componentId) return component;
        const newComponent = {
          ...component,
          structure: { elements },
        };
        return newComponent;
      });
      state.components = components;
    },

    editElementHref: (state, action) => {
      const { elementId, componentId, href } = action.payload;

      let component = {};

      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          component = { ...currComponent };
        }
      });

      const elements =
        component.structure &&
        [...component.structure.elements].map((element) => {
          if (element.id !== elementId) return element;
          const newElement = { ...element, href };
          return newElement;
        });

      const components = [...state.components].map((component) => {
        if (component.id !== componentId) return component;
        const newComponent = {
          ...component,
          structure: { elements },
        };
        return newComponent;
      });
      state.components = components;
    },

    editElementType: (state, action) => {
      const { elementId, htmlType, componentId } = action.payload;

      let component = {};

      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          component = { ...currComponent };
        }
      });

      const elements =
        component.structure &&
        [...component.structure.elements].map((element) => {
          if (element.id !== elementId) return element;
          const newElement = { ...element, htmlType };
          return newElement;
        });

      const components = [...state.components].map((component) => {
        if (component.id !== componentId) return component;
        const newComponent = {
          ...component,
          structure: { elements },
        };
        return newComponent;
      });
      state.components = components;
    },

    editNavigation: (state, action) => {
      const { elementId, componentId, sectionId, value } = action.payload;
      let component = {};
      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          component = { ...currComponent };
        }
      });
      let element = {};
      component.structure &&
        component.structure.elements.forEach((currElement) => {
          if (currElement.id === elementId) {
            element = { ...currElement };
          }
        });
      let navigations = [];
      if (element.properties && element.properties.navigations) {
        navigations = element.properties.navigations;
      } else {
        element.properties.navigations = [];
      }
      navigations.push({ sectionId, value });
      let properties = element.properties;
      element.properties = { ...properties, navigations };

      const newElements =
        component.structure &&
        [...component.structure.elements].map((currElement) => {
          if (currElement.id !== elementId) return currElement;
          return element;
        });

      component = { ...component, structure: { elements: newElements } };

      const newComponents = [...state.components].map((currComponent) => {
        if (currComponent.id !== componentId) return currComponent;
        return component;
      });

      state.components = newComponents;
    },

    addElementOptions: (state, action) => {
      const { option, elementId, componentId } = action.payload;
      state.components.map((component) => {
        if (component.id === componentId) {
          const element = component.structure.elements.find(
            (element) => element.id === elementId
          );
          if (element.properties && element.properties.options) {
            element.properties.options.push(option);
          } else {
            element.properties = {
              options: [option],
            };
          }
        }
      });
    },
    addFileProperties: (state, action) => {
      const { elementId, componentId, fileOptions } = action.payload;

      let component = {};

      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          component = { ...currComponent };
        }
      });

      const elements =
        component.structure &&
        [...component.structure.elements].map((element) => {
          if (element.id !== elementId) return element;
          const newElement = { ...element, fileOptions };
          return newElement;
        });

      const components = [...state.components].map((component) => {
        if (component.id !== componentId) return component;
        const newComponent = {
          ...component,
          structure: { elements },
        };
        return newComponent;
      });
      state.components = components;
    },
    // deletionBlock

    deletionBlock: (state, action) => {
      const { sectionId, blockId } = action.payload;
      deletion(blockId, sectionId, state, 2);
    },

    // deletionBlock end

    // deletionSection

    deletionSection: (state, action) => {
      const { sectionId } = action.payload;
      deletion(sectionId, null, state, 1);
    },

    // deletionSection end

    // deletionComposite

    deletionComposite: (state, action) => {
      const { blockId, compositeId } = action.payload;
      deletion(compositeId, blockId, state, 3);
    },

    // deletionComposite end

    // deletionComponent

    deletionComponent: (state, action) => {
      const { componentId, compositeId } = action.payload;
      deletion(componentId, compositeId, state, 4);
    },

    // deletionComponent end

    // deletionElement

    deletionElement: (state, action) => {
      const { componentId, elementId } = action.payload;
      //console.log({ componentId, elementId });
      deletion(elementId, componentId, state, 5);
    },

    deleteOption: (state, action) => {
      const { elementId, componentId, value } = action.payload;

      let component = {};

      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          component = { ...currComponent };
        }
      });

      let element = {};

      component.structure &&
        component.structure.elements.forEach((currElement) => {
          if (elementId === currElement.id) {
            element = { ...currElement };
          }
        });

      const options = _.cloneDeep(element.properties.options);

      const newOptions = options.filter(
        (currOption) => currOption.value !== value
      );

      element.properties.options = newOptions;

      const newElements =
        component.structure &&
        component.structure.elements.map((currElement) => {
          if (currElement.id !== elementId) return currElement;
          return element;
        });

      component.structure = { elements: newElements };

      const newComponents = state.components.map((currComponent) => {
        if (currComponent.id !== componentId) return currComponent;
        return component;
      });

      state.components = newComponents;
    },

    editDefault: (state, action) => {
      const { elementId, componentId, option } = action.payload;

      let component = {};

      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          component = { ...currComponent };
        }
      });

      let element = {};

      component.structure &&
        component.structure.elements.forEach((currElement) => {
          if (elementId === currElement.id) {
            element = { ...currElement };
          }
        });

      element.default = { ...option };

      const newElements =
        component.structure &&
        component.structure.elements.map((currElement) => {
          if (currElement.id !== elementId) return currElement;
          return element;
        });

      component.structure = { elements: newElements };

      const newComponents = state.components.map((currComponent) => {
        if (currComponent.id !== componentId) return currComponent;
        return component;
      });

      state.components = newComponents;
    },
    // deletion element end

    //duplicate Element

    duplicateElement: (state, action) => {
      const { newId, elementId, componentId } = action.payload;
      duplicate(newId, elementId, componentId, state, 5, 5);
      // duplicate2({
      //   form: _.cloneDeep(state.form),
      //   sections: _.cloneDeep(state.sections),
      //   blocks: _.cloneDeep(state.blocks),
      //   composites: _.cloneDeep(state.composites),
      //   components: _.cloneDeep(state.components),
      //   id: elementId,
      //   parentId: componentId,
      //   parentType: "components",
      // });
    },

    //duplicate Element end

    // duplicate block

    duplicateBlock: (state, action) => {
      const { newId, sectionId, blockId } = action.payload;
      duplicate(newId, blockId, sectionId, state, 2, 2);
      // duplicate2({
      //   state,
      //   id: blockId,
      //   parentId: sectionId,
      //   parentType: "sections",
      // });
    },

    // duplicate block end

    // duplicate component

    duplicateComponent: (state, action) => {
      const { newId, componentId, compositeId } = action.payload;
      duplicate(newId, componentId, compositeId, state, 4, 4);
      // duplicate2({
      //   state,
      //   id: componentId,
      //   parentId: compositeId,
      //   parentType: "composites",
      // });
    },

    // duplicate component end

    // duplicate composite

    duplicateComposite: (state, action) => {
      const { newId, blockId, compositeId } = action.payload;
      duplicate(newId, compositeId, blockId, state, 3, 3);
      // duplicate2({
      //   state,
      //   id: compositeId,
      //   parentId: blockId,
      //   parentType: "blocks",
      // });
    },

    //duplicate composite end

    // duplicate section

    duplicateSection: (state, action) => {
      const { newId, sectionId } = action.payload;
      duplicate(newId, sectionId, null, state, 1, 1);
      // duplicate2({
      //   form: _.cloneDeep(state.form),
      //   sections: _.cloneDeep(state.sections),
      //   blocks: _.cloneDeep(state.blocks),
      //   composites: _.cloneDeep(state.composites),
      //   components: _.cloneDeep(state.components),
      //   id: sectionId,
      //   parentId: null,
      //   parentType: "form",
      // });
      // const newSection = _.cloneDeep(
      //   state.sections.find((section) => section.id === sectionId)
      // );
      // const order =
      //   state.form.structure.sections.find(
      //     (section) => section.id === sectionId
      //   ).order + 1;
      // const id = generateIdSync();
      // // const order = newSection.order + 1;
      // newSection.id = id;
      // newSection.order = order;
      // console.log({
      //   id,
      //   title: newSection.title,
      //   order: order,
      //   pageNumber: newSection.pageNumber,
      // });
      // state.form.structure.sections.push({
      //   id,
      //   title: newSection.title,
      //   order: order,
      //   pageNumber: newSection.pageNumber,
      // });
      // const sectionStructureBlocks = newSection.structure.blocks.reduce(
      //   (acc, cv) => {
      //     const { title, order } = cv;
      //     const id = generateIdSync();
      //     cv.id = id;
      //     state.blocks.push(cv);
      //     acc.push({ id, title, order });
      //     return acc;
      //   },
      //   []
      // );
      // newSection.structure = { blocks: sectionStructureBlocks };
      // state.sections.push(newSection);
      // state.sections
      //   .sort((a, b) => a.order - b.order)
      //   .sort((a, b) => a.pageNumber - b.pageNumber);
      // console.log({ form, sections });
    },

    // duplciate section end
    // Edit section Page
    // editSectionPageNumber: (state, action) => {
    //   const { sectionId, pageNumber } = action.payload;
    //   const newSections = [...state.sections].map((currSection) => {
    //     if (sectionId !== currSection.id) return currSection;
    //     return { ...currSection, pageNumber };
    //   });
    //   state.sections = newSections;
    // },

    // Edit Setion Page End
    // AddLinkToSection

    addLinkToSection: (state, action) => {
      const { sectionId, linkTo } = action.payload;
      // //console.log({sectionId, linkTo})
      let section = {};
      state.sections.forEach((currSection) => {
        if (currSection.id === sectionId) {
          section = { ...currSection };
        }
      });
      if (section.structure && section.structure.links) {
        section.structure.links.push(linkTo);
      } else if (section.structure && !section.structure.links) {
        section.structure.links = [linkTo];
      } else {
        section.structure = { links: [linkTo], blocks: [] };
      }

      const newSections = [...state.sections].map((currSection) => {
        if (currSection.id !== sectionId) {
          return currSection;
        }
        return section;
      });

      state.sections = newSections;
    },

    // AddLinkToSection End
    // AddLinkToBlock

    addLinkToBlock: (state, action) => {
      const { blockId, linkTo } = action.payload;
      // //console.log({sectionId, linkTo})
      let block = {};
      state.blocks.forEach((currBlock) => {
        if (currBlock.id === blockId) {
          block = { ...currBlock };
        }
      });
      if (block.structure && block.structure.links) {
        block.structure.links.push(linkTo);
      } else if (block.structure && !block.structure.links) {
        block.structure.links = [linkTo];
      } else {
        block.structure = { links: [linkTo], composites: [] };
      }

      const newBlocks = [...state.blocks].map((currBlock) => {
        if (currBlock.id !== blockId) {
          return currBlock;
        }
        return block;
      });

      state.blocks = newBlocks;
    },

    // AddLinkToBlock End
    // AddLinkToComposite

    addLinkToComposite: (state, action) => {
      const { compositeId, linkTo } = action.payload;
      // //console.log({sectionId, linkTo})
      let composite = {};
      state.composites.forEach((currComposite) => {
        if (currComposite.id === compositeId) {
          composite = { ...currComposite };
        }
      });
      if (composite.structure && composite.structure.links) {
        composite.structure.links.push(linkTo);
      } else if (composite.structure && !composite.structure.links) {
        composite.structure.links = [linkTo];
      } else {
        composite.structure = { links: [linkTo], components: [] };
      }

      const newComposites = [...state.composites].map((currComposite) => {
        if (currComposite.id !== compositeId) {
          return currComposite;
        }
        return composite;
      });

      state.composites = newComposites;
    },

    // AddLinkToComposite End
    // AddLinkToComponent

    addLinkToComponent: (state, action) => {
      const { componentId, linkTo } = action.payload;
      // //console.log({sectionId, linkTo})
      let component = {};
      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          component = { ...currComponent };
        }
      });
      if (component.structure && component.structure.links) {
        component.structure.links.push(linkTo);
      } else if (component.structure && !component.structure.links) {
        component.structure.links = [linkTo];
      } else {
        component.structure = { links: [linkTo], elements: [] };
      }

      const newComponents = [...state.components].map((currComponent) => {
        if (currComponent.id !== componentId) {
          return currComponent;
        }
        return component;
      });

      state.components = newComponents;
    },

    editMandatoryStatusElement: (state, action) => {
      const { elementId, componentId, mandatory } = action.payload;

      let component = {};

      state.components.forEach((currComponent) => {
        if (currComponent.id === componentId) {
          component = { ...currComponent };
        }
      });

      const elements =
        component.structure &&
        [...component.structure.elements].map((element) => {
          if (element.id !== elementId) return element;
          const newElement = { ...element, mandatory };
          return newElement;
        });

      const components = [...state.components].map((component) => {
        if (component.id !== componentId) return component;
        const newComponent = {
          ...component,
          structure: { elements },
        };
        return newComponent;
      });
      state.components = components;
    },

    // AddLinkToComponent End
    // deleteLink

    deleteLink: (state, action) => {
      const { parentType, parentId, link } = action.payload;
      const parentArr = [...state[parentType]];
      let parentObj = {};
      parentArr.forEach((currObj) => {
        if (currObj.id === parentId) {
          parentObj = { ...currObj };
        }
      });
      const links =
        parentObj.structure &&
        parentObj.structure.links &&
        parentObj.structure.links.filter((currLink) => currLink.id !== link.id);

      parentObj.structure.links = links;
      const newParentArr = [...parentArr].map((currParentObj) => {
        if (currParentObj.id !== parentId) return currParentObj;
        return parentObj;
      });
      state[parentType] = newParentArr;
    },
  },
});

export const { actions, reducer, name: sliceKey } = homePage;
