import {
  Box,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Text,
} from "@chakra-ui/react";
import { toUpper } from "lodash";
import React, { useEffect, useState } from "react";
import { LuUploadCloud } from "react-icons/lu";
import Select from "views/shared/Select";

const myStyles = {
  background: "white",
  boxShadow: "0px 3.5px 5.499999523162842px rgba(0, 0, 0, 0.02)",
  borderRadius: 15,
};
const flexStyles = {
  gap: "18px",
  direction: { base: "column", md: "row" },
  justifyContent: "space-between",
  width: "100%",
};

const CustomInput = ({
  label,
  placeholder,
  value,
  type,
  onChange,
  isValid,
  errorMessage,
  handleOptionChange,
  links,
  isEditing,
}) => {
  const countryCodes = ["India +91", "UAE +971"];
  let code = "";
  {
    // isEditing === true &&
    links?.map((link) => {
      if (link.type === "phone") {
        if (link.countryCode === "91") {
          code = "India +91";
        } else if (link.countryCode === "971") {
          code = "UAE +971";
        }
      }
    });
  }
  // console.log("links-->", links);
  // console.log("code -->", code);
  return (
    <Flex direction="column" gap="8px" flex={{ base: "1", md: "1" }}>
      <Text fontSize={"12px"} fontWeight={"700"}>
        {label}
      </Text>
      <InputGroup>
        {label === "Phone Number" && (
          <InputLeftAddon p={"0"} br={"2px"} border={"none"}>
            <Select
              placeholder="(+XX)"
              customKey="countryCode"
              value={code}
              handleOptionChange={handleOptionChange}
              options={countryCodes}
              // isDisabled={isEditing}
              minWidth="120px"
              maxWidth="120px"
            />
          </InputLeftAddon>
        )}
        <Input
          type={type || "text"}
          placeholder={placeholder}
          value={value}
          width="100%"
          alignSelf="stretch"
          focusBorderColor="#3182ce"
          onChange={(event) => onChange(event.target.value)}
          fontSize="14px"
        />
      </InputGroup>
      {!isValid && (
        <Text fontSize="12px" color="red.500">
          {errorMessage}
        </Text>
      )}
    </Flex>
  );
};

const countries = ["India", "UAE"];
// const businessTypes = ["Inspection Agency", "Clients Directives"];
const linkTypeCheck = (clientEmailPhone, check) => {
  return clientEmailPhone?.filter((linkObj) => linkObj.type === check);
};
const AddClientTop = ({
  isEditing,
  handleInputChange,
  handleFileChange,
  profileImagePreview,
  clientDetails,
  links,
  handleLinksChange,
  oldClientAddress,
  handleRegisteredAddress,
  emailIsValid,
  setEmailIsValid,
  isPhoneValid,
  setIsPhoneValid,
  setLinks,
  setTempAddress,
  tempAddress,
  setPinCodeIsValid,
  locationArray,
}) => {
  const handleInputClick = () => {
    document.getElementById("input_profile_img").click();
  };
  const [countryCode, setCountryCode] = useState();
  const [country, setCountry] = useState("");
  const handleOptionChange = (value, customKey) => {
    if (
      customKey === "orgName" ||
      customKey === "businessType" ||
      customKey === "country"
    ) {
      handleInputChange(customKey, value);
    }

    if (customKey === "country") {
      handleInputChange(customKey, value); // Update input value first
      setCountry(value); // Then update country state
      handleRegisteredAddress(customKey, value);
      setTempAddress({ ...tempAddress, city: "", state: "", pinCode: "" }); // if country change,
      setPinCodeIsValid(true);

      if (!links[1].value) {
        const newCountryCode =
          value !== "" ? (value === "India" ? "91" : "971") : "";
        setCountryCode(newCountryCode);
        setLinks(
          links?.map((link) => {
            if (link.type === "phone") {
              if (link.value !== null) {
                setIsPhoneValid(validatePhone(link.value, newCountryCode));
              }
              return { ...link, countryCode: newCountryCode };
            }
            return link;
          })
        );
      }
    }

    if (customKey === "countryCode") {
      const newCountryCode = value && (value === "India +91" ? "91" : "971");
      // handleLinksChange("phone", "", newCountryCode);
      setCountryCode(newCountryCode);
      setLinks(
        links?.map((link) => {
          if (link.type === "phone") {
            if (link.value !== null) {
              setIsPhoneValid(validatePhone(link.value, newCountryCode));
            }
            return { ...link, countryCode: newCountryCode };
          }
          return link;
        })
      );
    }
  };

  // console.log("links", links)

  const validateEmail = (email) => {
    if (email?.trim() === "") {
      return true;
    }
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  };
  const validatePhone = (phone, countryCode) => {
    if (phone?.trim() === "") {
      return true;
    }
    if (countryCode === "91") {
      // Indian mobile number validation
      const pattern = /^[6-9]\d{9}$/;
      return pattern.test(phone);
    } else if (countryCode === "971") {
      // UAE mobile number validation
      const pattern = /^[0-9]{9}$/;
      return pattern.test(phone);
    }
    return false;
  };
  // const validatePhoneWithCountry = (phone, currCountryCode) => {
  //   console.log("ccc")
  //   if (currCountryCode === "India +91") {
  //     // Indian mobile number validation
  //     const pattern = /^[6-9]\d{9}$/;
  //     console.log(pattern, currCountryCode, "currCountryCode" )
  //     return pattern.test(phone);
  //   } else if (currCountryCode === "UAE +971") {
  //     // UAE mobile number validation
  //     const pattern = /^[0-9]{9}$/;
  //     return pattern.test(phone);
  //   }
  //   return false;
  // };
  const handleEmailChange = (email) => {
    handleLinksChange("email", email);
    setEmailIsValid(validateEmail(email));
  };

  useEffect(() => {
    if (clientDetails.country) {
      setCountry(clientDetails.country);
    }
  }, [clientDetails]);
  useEffect(() => {
    if (oldClientAddress && Object.keys(oldClientAddress).length > 0) {
      setCountry(oldClientAddress?.country);
    }
  }, [oldClientAddress]);

  // useEffect(() => {
  //   if (country) {
  //     let countryCode = country.toLowerCase() === "india" ? 91 : 971;
  //     setLinks((prevLinks) => {
  //       return prevLinks.map((prevLink) => {
  //         if (prevLink.type === "phone") return { ...prevLink, countryCode };
  //         return { ...prevLink };
  //       });
  //     });
  //   }
  // }, [country]);

  return (
    <>
      <Stack spacing={"24px"}>
        <Box>
          <Text fontWeight={600}>Basic Info</Text>
        </Box>
        <Box sx={myStyles}>
          <Box padding={"16px 24px"}>
            <Flex gap={"32px"} direction={"column"}>
              <Text
                sx={{ color: "#718096", fontSize: "14px", fontWeight: "600" }}
              >
                Clients Details
              </Text>
              <Flex direction={{ base: "column", md: "row" }} gap={"32px"}>
                <Flex direction={"column"} gap={"12px"}>
                  <Text fontSize={"12px"} fontWeight={"700"}>
                    Upload Logo
                  </Text>
                  <Box>
                    <Input
                      id="input_profile_img"
                      display={"none"}
                      type="file"
                      onChange={handleFileChange}
                    />
                    <Box
                      width="120px"
                      height="120px"
                      border="2px dashed #E2E8F0"
                      borderRadius="8px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      cursor="pointer"
                      onClick={handleInputClick}
                    >
                      {profileImagePreview ? (
                        <Image
                          src={profileImagePreview}
                          alt="Profile Image"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <LuUploadCloud
                          size="24px"
                          color="#718096"
                          position={"absolute"}
                        />
                      )}
                    </Box>
                  </Box>
                </Flex>
                <Flex direction="column" gap="18px" w="100%">
                  <Flex sx={flexStyles}>
                    <Flex
                      direction="column"
                      gap="8px"
                      flex={{ base: "1", md: "1" }}
                    >
                      <Text fontSize={"12px"} fontWeight={"700"}>
                        Country*
                      </Text>
                      <Select
                        isDisabled={locationArray?.length > 0 || isEditing}
                        customKey="country"
                        options={countries}
                        placeholder="Select"
                        value={clientDetails.country}
                        handleOptionChange={handleOptionChange}
                      />
                    </Flex>
                    <CustomInput
                      label="Client Name*"
                      placeholder="eg. ABC Pvt Ltd"
                      value={clientDetails.orgName}
                      onChange={(value) => handleInputChange("orgName", value)}
                    />
                    {/* <Flex
                      direction={"column"}
                      gap={"8px"}
                      flex={{ base: "1", md: "1" }}
                    >
                      <Text fontSize={"12px"} fontWeight={"700"}>
                        Buisness Type
                      </Text>
                      <Select
                        customKey="businessType"
                        options={businessTypes}
                        placeholder="Select"
                        value={clientDetails.businessType}
                        handleOptionChange={handleOptionChange}
                      />
                    </Flex> */}
                    <CustomInput
                      label="Email"
                      placeholder="eg. abc@gmail.com"
                      value={
                        links &&
                        links.length &&
                        linkTypeCheck(links, "email").length
                          ? linkTypeCheck(links, "email")[0].value
                          : null
                      }
                      onChange={(value) => handleEmailChange(value)}
                      isValid={emailIsValid}
                      errorMessage="Invalid email format"
                    />
                  </Flex>
                  <Flex sx={flexStyles}>
                    <CustomInput
                      label="Phone Number"
                      type={"number"}
                      placeholder={
                        country === "UAE"
                          ? "eg. 87 000 ####"
                          : "eg. 876 000 ####"
                      } //for uae digit count will be 9
                      value={
                        links &&
                        links.length &&
                        linkTypeCheck(links, "phone").length
                          ? linkTypeCheck(links, "phone")[0].value
                          : null
                      }
                      isValid={isPhoneValid}
                      errorMessage={
                        countryCode !== ""
                          ? "Please enter valid mobile number"
                          : "Please select a Country Code"
                      }
                      onChange={(value) => {
                        setIsPhoneValid(
                          validatePhone(
                            value,
                            links &&
                              links.length &&
                              linkTypeCheck(links, "phone").length
                              ? linkTypeCheck(links, "phone")[0].countryCode
                              : null
                          )
                        );
                        handleLinksChange("phone", value);
                      }}
                      handleOptionChange={handleOptionChange}
                      links={links}
                      isEditing={isEditing}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default AddClientTop;
