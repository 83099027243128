import React, { useState } from "react";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  CircularProgress,
  Flex,
  HStack,
  Image,
  Img,
  Modal,
  ModalBody,
  ModalContent,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Eye from "assets/svg/eye-icon.svg";
import Edit from "assets/svg/edit-icon.svg";
import { ViewReportIcon } from "components/Icons/Icons";
import noRecordImage from "assets/img/Empty Case.png";
import { EditIcon } from "components/Icons/Icons";
import { Table, Pagination } from "@agnext/reactlib";
import { ActionIcon } from "components/Icons/Icons";
import JobName from "./Table Components/JobName";
import AvatarWithName from "../viewJob/Table Components/AvatarWithName";
import AvatarGroupWithName from "./Table Components/AvatarGroupWithName";
import PriorityWIthIcon from "./Table Components/PriorityWIthIcon";
import Status from "../viewJob/Table Components/Status";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import { extractDayMonthYearFromTimestamp } from "utils/commonFunctions";
import { extractAuditorNames } from "utils/commonFunctions";

const styles = {
  width: "280px",
  padding: "20px",
  borderRadius: "15px",
  position: "absolute",
  boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
  zIndex: "15",
};
const styles1 = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  message: {
    fontFamily: "Inter",
    textAlign: "center",
    fontSize: "22px",
  },
  image: {
    maxWidth: "100%",
    height: "auto",
    margin: "auto",
  },
};
const AllJobsTable = ({
  allJobs,
  clientImageUrls,
  page,
  handleNextPage,
  isLoadingPagination,
  handlePreviousPage,
  handlePageSelect,
  numPages,
  // allTasksDetail,
  // allUserAvatarUrls,
  customerId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [jobDetails, setJobDetails] = useState({
    jobId: null,
    inspectionId: null,
    canViewReport: false,
    canEdit: false,
  });
  const [selectedActionIconPosition, setSelectedActionIconPosition] = useState({
    x: 0,
    y: 0,
  });
  const handleJobDetails = (job) => {
    setJobDetails({
      jobId: job.id,
      inspectionId: job.inspectionId,
      canViewReport: job.status === "draft",
      canEdit:
        job.status !== "draft" &&
        job.status !== "todo" &&
        job.status !== "not-required",
      //  &&
      // job.status !== "inprogress",
    });
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setJobDetails({
      jobId: null,
      inspectionId: null,
      canViewReport: false,
      canEdit: false,
    });
  };
  const handleOpenModal = (e) => {
    e.preventDefault(); // Prevent default link behavior
    e.stopPropagation();
    const iconPosition = e.currentTarget.getBoundingClientRect();

    setSelectedActionIconPosition({
      x: iconPosition.left,
      y: iconPosition.top,
    });

    setIsOpen(true);
  };
  const Header = [
    {
      displayText: "Job Id",
      keyText: "jobName",
      // hasSort: true,
    },
    {
      displayText: "Client Name",
      keyText: "clientName",
    },
    {
      displayText: "Assignee",
      keyText: "assignee",
      hasSort: false,
    },
    {
      displayText: "Location",
      keyText: "location",
    },
    {
      displayText: "Priority",
      keyText: "priority",
    },
    {
      displayText: "Status",
      keyText: "status",
    },
    {
      displayText: "Due Date",
      keyText: "dueDate",
    },
    { displayText: "Actions", keyText: "actions" },
  ];
  // assignee: (
  //   // <AvatarGroupWithName
  //   //   avatarUrlArr={allUserAvatarUrls ? allUserAvatarUrls[index] : [""]}
  //   // />
  // ),
  const tableList = allJobs.map((job, index) => {
    const { clientDetails } = job;
    return {
      jobName: <JobName jobName={job.internalCode} />,

      location: (
        <Text color="#4A5568" fontSize="14px" fontWeight="500">
          {(job?.origin && capitalizeStringUpdated(job?.origin?.city)) ||
          job?.origin?.state
            ? capitalizeStringUpdated(job?.origin?.city) +
              ", " +
              capitalizeStringUpdated(job?.origin?.state)
            : "-"}
        </Text>
      ),
      assignee: (
        <Text color="#4A5568" fontSize="14px" fontWeight="500">
          {extractAuditorNames(job.auditors)}
        </Text>
      ),
      priority: <PriorityWIthIcon priority={job.priority} />,
      status: <Status status={job.status} />,
      clientName: (
        <AvatarWithName
          name={clientDetails?.name || ""}
          avatarUrl={clientImageUrls[index]?.url || ""}
        />
      ),
      dueDate: (
        <Text color="#4A5568" fontSize="14px" fontWeight="500">
          {extractDayMonthYearFromTimestamp(job.dueDate)}
        </Text>
      ),
      actions: (
        <ActionIcon
          w="20px"
          h="20px"
          mx="16px"
          onClick={(e) => {
            handleJobDetails(job);
            handleOpenModal(e);
          }}
          _hover={{
            cursor: "pointer",
          }}
        />
      ),
    };
  });

  return (
    <Box>
      {tableList.length > 0 ? (
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          w={"100%"}
          mb={"10px"}
        >
          <Pagination
            page={page}
            numPages={numPages}
            handlePageSelect={handlePageSelect}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            colorActive="#4FD1C5"
          />
        </Box>
      ) : (
        <></>
      )}
      <Box>
        <HStack
          display={isLoadingPagination ? "flex" : "none"}
          justifyContent={"center"}
        >
          <CircularProgress isIndeterminate color="#4FD1C5" size="36px" />
        </HStack>
        <Box
          display={isLoadingPagination ? "none" : "block"}
          background={"white"}
          borderRadius={"12px"}
        >
          {tableList.length > 0 ? (
            <Table
              overflow="auto"
              overflowY="auto"
              headerList={Header}
              dataList={tableList}
              stickyColumn={["Actions"]}
            />
          ) : (
            <Box style={styles1.message}>
              <Img
                style={styles1.image}
                src={noRecordImage}
                alt="No Records Image"
              />
              <p>No Records Found</p>
            </Box>
          )}
          {isOpen && (
            <Modal isOpen={isOpen} onClose={handleCloseModal} sx={styles}>
              <ModalContent
                className="action-btn-dropdown"
                top={selectedActionIconPosition.y + -35 + "px"}
                left={selectedActionIconPosition.x + -308 + "px"}
              >
                <Box
                  display="flex"
                  rowGap="20px!important"
                  flexDirection="column"
                >
                  {!jobDetails.canEdit && (
                    <Link
                      to={`/admin/edit-job/${jobDetails.inspectionId}/${jobDetails.jobId}?customerId=${customerId}`}
                    >
                      <Button
                        // display={jobDetails.canEdit ? 'none' : 'flex'}
                        className="download-report-btn"
                        variant="outline"
                        rightIcon={<EditIcon />}
                        onClick={() => {
                          handleCloseModal();
                        }}
                      >
                        Edit Job
                      </Button>
                    </Link>
                  )}
                  {!jobDetails.canViewReport && (
                    <Link
                      to={`/admin/viewjob/${jobDetails.inspectionId}/${jobDetails.jobId}?customerId=${customerId}`}
                    >
                      <Button
                        className="download-report-btn"
                        variant="outline"
                        rightIcon={<ViewReportIcon />}
                        onClick={() => {
                          handleCloseModal();
                        }}
                      >
                        View Job
                      </Button>
                    </Link>
                  )}
                </Box>
              </ModalContent>
            </Modal>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AllJobsTable;
