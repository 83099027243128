import React, { useState } from "react";
import Dropdown from "views/shared/Dropdown";

function Commodity({ commodityNames, handleFilterQuery, filterQuery }) {
  return (
    <Dropdown
      options={commodityNames}
      placeholder={"Commodity"}
      handleFilterQuery={handleFilterQuery}
      property={"commodities"}
      listKey={"name"}
      filterQuery={filterQuery}
    />
  );
}

export default Commodity;
