import React, { useEffect, useState } from "react";
import { DatePicker } from "@agnext/reactlib";
import { useMediaQuery } from "@chakra-ui/react";
import { format, parseISO, isEqual } from "date-fns";
import _ from "lodash";

export default function DateTimePicker({
  data,
  formData,
  handleFormDataChange,
}) {
  // //console.log(formData[data.element.id]?.value);

  const [date, setDate] = useState(() => {
    const initialValue = formData[data.element.id]?.value;

    if (initialValue !== "" && initialValue) {
      if (typeof initialValue === "object" && initialValue instanceof Date) {
        return initialValue;
      } else if (typeof initialValue === "string") {
        return new Date(initialValue);
      }
    }
    return new Date();
  });

  useEffect(() => {
    const initialValue = formData[data.element.id]?.value;
    if (typeof date === "object" && date instanceof Date) {
      if (typeof initialValue === "object" && initialValue instanceof Date) {
        if (date.toISOString !== initialValue.toISOString) {
          setDate(initialValue);
        }
      } else if (initialValue == "" && initialValue !== date.toISOString()) {
        setDate(new Date());
      } else if (typeof initialValue === "string") {
        if (date.toISOString !== initialValue) {
          setDate(new Date(initialValue));
        }
      }
    } else if (typeof date === "string") {
      if (typeof initialValue === "string") {
        if (date !== initialValue) {
          setDate(new Date(initialValue));
        }
      }
    }
  }, [formData[data.element.id]?.value]);

  const [isMobileScreen] = useMediaQuery("(max-width: 500px)");
  useEffect(() => {
    if (formData[data.element.id]?.value != undefined) {
      handleFormDataChange(date, "", data.element.id, "NA");
    }
  }, [data.element.id, date]);

  return (
    <>
      <DatePicker
        setDate={setDate}
        date={date}
        width={isMobileScreen ? 245 : 330}
        colorSelected="#fff"
        backgroundSelected="#4FD1C5"
      />
    </>
  );
}
