import {
  Box,
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  Stack,
  Text,
  useDisclosure,
  Image,
  useToast,
  Switch,
  HStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import AddLocation from "views/shared/AddLocation";
import { Table } from "@agnext/reactlib";
import { EditIcon } from "components/Icons/Icons";
import deleteIcon from "assets/svg/deleteRed.svg";
import { ActionIcon } from "components/Icons/Icons";
import { generateIdSync } from "utils";
import { useDispatch } from "react-redux";
import { actions } from "../../slice";
import {
  validateExternalCode,
  validateEmail,
  validatePhone,
} from "utils/validationFunctions";

const headers = [
  {
    displayText: "Name",
    keyText: "name",
    hasSort: false,
  },
  {
    displayText: "Warehouse Code",
    keyText: "externalCode",
    hasSort: false,
  },
  {
    displayText: "Contact Person",
    keyText: "contactPerson",
    hasSort: true,
  },
  {
    displayText: "Email",
    keyText: "email",
    hasSort: true,
  },
  {
    displayText: "Phone Number",
    keyText: "phoneNumber",
  },
  {
    displayText: "Location Type",
    keyText: "locationType",
    hasSort: true,
  },
  {
    displayText: "Address",
    keyText: "address",
    hasSort: true,
  },
  {
    displayText: "Status",
    keyText: "status",
  },
  {
    displayText: "Actions",
    keyText: "actions",
  },
];

const styles = {
  width: "280px",
  padding: "20px",
  borderRadius: "15px",
  position: "absolute",
  boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
  zIndex: "15",
};

const AddCustomerLocation = ({
  locationArray,
  locationCompareArray,
  customerId,
  refreshLocations,
  isEditing,
  customerDetails,
  locations,
}) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const dynamicToast = (
    message,
    { status = "success", duration = 1000, isClosable = true }
  ) => {
    toast({
      title: message,
      status: status,
      duration: duration,
      isClosable: isClosable,
    });
  };

  const [isValidLocationPINCode, setIsValidLocationPINCode] = useState(true);
  const [isValidMobileNumber, setIsValidMobileNumber] = useState(true);
  const [isValidLocationEmail, setIsValidLocationEmail] = useState(true);
  const [isValidExternalCode, setIsValidExternalCode] = useState(true);
  const [idToDelete, setIdToDelete] = useState(null);
  const [idToChangeStatus, setIdToChangeStatus] = useState(null);
  const [idToEdit, setIdToEdit] = useState(null);
  const [newLocation, setNewLocation] = useState({
    id: generateIdSync(),
    name: "",
    externalCode: null,
    area: null,
    additionalProperties: {},
    city: "",
    contactPerson: "",
    originType: "",
    addressLine1: "",
    addressLine2: "",
    postalCode: "",
    state: "",
    links: [
      { value: null, type: "phone", countryCode: "" },
      { value: null, type: "email", countryCode: null },
    ],
    isEditing: false,
    isFromDB: false,
    isChanged: false,
  });
  const resetNewLocationState = () => {
    setNewLocation({
      id: generateIdSync(),
      name: "",
      externalCode: null,
      area: null,
      additionalProperties: {},
      city: "",
      contactPerson: "",
      originType: "",
      addressLine1: "",
      addressLine2: "",
      postalCode: "",
      state: "",
      links: [
        { value: null, type: "phone", countryCode: "" },
        { value: null, type: "email", countryCode: null },
      ],
      isEditing: false,
      isFromDB: false,
      isChanged: false,
    });
  };

  const {
    isOpen: isOpenLocation,
    onOpen: onOpenLocation,
    onClose: onCloseLocation,
  } = useDisclosure();
  const {
    isOpen: activateLocationIsOpen,
    onOpen: openActivateLocationModal,
    onClose: closeActivateLocationModal,
  } = useDisclosure();

  const {
    isOpen: deactivateLocationIsOpen,
    onOpen: openDeactivateLocationModal,
    onClose: closeDeactivateLocationModal,
  } = useDisclosure();

  const {
    isOpen: deleteLocationIsOpen,
    onOpen: openDeleteLocationModal,
    onClose: closeDeleteLocationModal,
  } = useDisclosure();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedActionIconPosition, setSelectedActionIconPosition] = useState({
    x: 0,
    y: 0,
  });

  const handleAddLocation = () => {
    if (isEditing) {
      if (newLocation.isFromDB) {
        let locationPayload = { ...newLocation };
        const originId = locationPayload.id;
        delete locationPayload.id;
        delete locationPayload.isFromDB;
        delete locationPayload.isEditing;
        delete locationPayload.isChanged;
        const updatedLinks = locationPayload.links?.map((link) => {
          if (link.value === "") {
            return { ...link, value: null, countryCode: null };
          } else {
            return link;
          }
        });
        locationPayload.links = updatedLinks;
        let manager = { id: customerId, type: "customer" };
        locationPayload.managers = [manager];
        dispatch(
          actions.updateClientLocation({
            locationPayload,
            originId,
            onSuccess: () => {
              dynamicToast("Location Updation Successfully", {
                status: "success",
              });
              refreshLocations();
            },
            onFailure: () => {
              dynamicToast("Failed to Update Location", { status: "error" });
            },
          })
        );
      } else {
        let locationPayload = { ...newLocation };
        delete locationPayload.id;
        delete locationPayload.isFromDB;
        delete locationPayload.isEditing;
        let manager = { id: customerId, type: "customer" };

        const updatedLinks = locationPayload.links?.map((link) => {
          if (link.value === "") {
            return { ...link, value: null, countryCode: null };
          } else {
            return link;
          }
        });

        locationPayload = {
          ...locationPayload,
          country: customerDetails.country,
          managers: [manager],
          links: updatedLinks,
        };

        dispatch(
          actions.createClientLocation({
            locationPayload,
            onSuccess: () => {
              dynamicToast("Location Added Successfully", {
                status: "success",
              });
              refreshLocations();
            },
            onFailure: () => {
              dynamicToast("Failed to Add Location", { status: "error" });
            },
          })
        );
      }

      resetNewLocationState();
      setIdToEdit(null);
      setIdToDelete(null);
    }
  };

  const handleLocationStatus = (status) => {
    dispatch(
      actions.updateLocationStatus({
        originId: idToChangeStatus,
        managerId: customerId,
        status,
        onSuccess: () => {
          dynamicToast("Status Changed Successfully", {
            status: "success",
          });
          refreshLocations();
        },
        onFailure: () => {
          dynamicToast("Failed to Change Location Status", { status: "error" });
        },
      })
    );
  };

  const handleDeleteLocation = () => {
    dispatch(
      actions.deleteLocation({
        idToDelete,
        onSuccess: () => {
          dynamicToast("Location Deleted Successfully", {
            status: "success",
          });
          refreshLocations();
        },
        onFailure: () => {
          dynamicToast("Failed to Delete Location", { status: "error" });
        },
      })
    );
  };

  const handleNewLocationInputChange = (e) => {
    const { name, value } = e.target;
    setNewLocation((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "externalCode") {
      if (!value) setIsValidExternalCode(true);
      else
        setIsValidExternalCode(
          validateExternalCode(value, 5, 30, /^[a-zA-Z0-9/_-]+$/)
        );
    }
    if (name === "postalCode") {
      if (!value) setIsValidLocationPINCode(true);
      else setIsValidLocationPINCode(validateNewLocationPINCode(value));
    }
  };

  const handleLocationLinksChange = (e, type) => {
    if (type === "phone") {
      setIsValidMobileNumber(
        validatePhone(
          e.target.value,
          newLocation?.links?.find((link) => link.type === "phone")?.countryCode
            ? newLocation?.links?.find((link) => link.type === "phone")
                ?.countryCode === "91"
              ? "India +91"
              : "UAE +971"
            : ""
        )
      );
    } else {
      setIsValidLocationEmail(validateEmail(e.target.value));
    }

    const updatedLinks = (newLocation.links || []).map((link) => {
      if (link.type === type) {
        return { ...link, value: e.target.value };
      }
      return link;
    });
    setNewLocation((prevState) => ({
      ...prevState,
      links: updatedLinks,
    }));
  };
  const handleNewLocationSelectChange = (value, type) => {
    if (type === "countryCode") {
      let code = value ? (value === "India +91" ? "91" : "971") : "";
      const updatedLinks = (newLocation.links || []).map((link) => {
        if (link.type === "phone") {
          return { ...link, countryCode: code };
        }
        return link;
      });
      setNewLocation((prevState) => ({
        ...prevState,
        links: updatedLinks,
      }));

      setIsValidMobileNumber(
        validatePhone(
          newLocation?.links?.find((link) => link.type === "phone")?.value ??
            "",
          value
        )
      );
    } else {
      setNewLocation((prevState) => ({
        ...prevState,
        [type]: value,
      }));
    }
    if (type === "state") {
      dispatch(
        actions.fetchLocationData({
          country:
            customerDetails?.country === "UAE"
              ? "United Arab Emirates"
              : customerDetails?.country,
          id: newLocation?.id,
          state: value,
          city: newLocation.city,
        })
      );
      if (!value)
        setNewLocation((prev) => ({ ...prev, city: "", postalCode: "" }));
    }
    if (type === "city") {
      dispatch(
        actions.fetchLocationData({
          country:
            customerDetails?.country === "UAE"
              ? "United Arab Emirates"
              : customerDetails?.country,
          id: newLocation?.id,
          state: newLocation.state,
          city: value,
        })
      );
      if (!value)
        setNewLocation((prev) => ({ ...prev, city: "", postalCode: "" }));
    }
  };

  const validateNewLocationPINCode = (pin) => {
    const pattern = /^\d{6}$/;

    return pattern.test(pin);
  };

  useEffect(() => {
    if (customerDetails && customerDetails?.country) {
      dispatch(
        actions.fetchLocationData({
          country:
            customerDetails?.country === "UAE"
              ? "United Arab Emirates"
              : customerDetails?.country,
          id: newLocation?.id,
          state: newLocation.state,
          city: newLocation.city,
        })
      );
    }
  }, [customerDetails.country, newLocation?.id]);

  useEffect(() => {
    if (newLocation?.postalCode?.length === 6) {
      dispatch(
        actions.fetchLocationData({
          id: newLocation?.id,
          postalCode: newLocation?.postalCode,
        })
      );
    }
  }, [newLocation.postalCode]);

  useEffect(() => {
    if (
      (locations[newLocation?.id]?.states?.length === 1 &&
        locations[newLocation?.id]?.cities?.length) === 1
    ) {
      setNewLocation((prev) => {
        return {
          ...prev,
          city: locations[newLocation?.id]?.cities[0],
          state: locations[newLocation?.id]?.states[0],
        };
      });
    }
  }, [locations]);

  const addLocationButtonStatus = () => {
    let res = false;
    if (
      !newLocation.name ||
      !newLocation.originType ||
      !newLocation.state ||
      !newLocation.city ||
      (customerDetails?.country === "India" && !newLocation.postalCode) ||
      !newLocation.addressLine1 ||
      !isValidLocationEmail ||
      !isValidMobileNumber ||
      !isValidLocationPINCode ||
      !isValidExternalCode
    ) {
      res = true;
    }
    return res;
  };

  const updateLocationButtonStatus = () => {
    let res = true;
    const locationToCompare = locationCompareArray.find(
      (location) => location.id === newLocation.id
    );

    const checkProperty = (prop) => {
      if (newLocation[prop] && newLocation[prop] !== locationToCompare[prop])
        res = false;
    };

    checkProperty("name");
    checkProperty("originType");
    checkProperty("state");
    checkProperty("city");
    checkProperty("addressLine1");
    checkProperty("externalCode");

    if (
      newLocation.postalCode &&
      newLocation.postalCode.length === 6 &&
      newLocation.postalCode !== locationToCompare.postalCode
    )
      res = false;
    if (newLocation?.contactPerson !== locationToCompare?.contactPerson)
      res = false;
    if (newLocation?.addressLine2 !== locationToCompare?.addressLine2)
      res = false;
    newLocation?.links?.forEach((currLink) => {
      if (currLink.type === "email") {
        const existingEmailLink = locationToCompare?.links?.find(
          (link) => link.type === "email"
        );
        if (
          currLink?.value !== existingEmailLink?.value &&
          isValidLocationEmail
        ) {
          res = false;
        }
      } else if (currLink.type === "phone") {
        const existingPhoneLink = locationToCompare?.links?.find(
          (link) => link.type === "phone"
        );
        if (
          currLink?.value !== existingPhoneLink?.value &&
          isValidMobileNumber
        ) {
          res = false;
        }
        if (
          currLink?.countryCode !== existingPhoneLink?.countryCode &&
          isValidMobileNumber
        ) {
          res = false;
        }
      }
    });
    if (addLocationButtonStatus()) res = true;
    return res;
  };

  const tableData =
    isEditing &&
    locationArray?.length &&
    locationArray
      .sort(({ name: a }, { name: b }) => (a > b ? 1 : b > a ? -1 : 0))
      .map((location) => {
        let phoneNumber = "-";
        const phoneLink = location?.links?.find(
          (link) => link.type === "phone"
        );
        if (phoneLink?.value) {
          phoneNumber = `+${phoneLink?.countryCode} ${phoneLink?.value}`;
        }
        return {
          name: location?.name || "-",
          externalCode: location?.externalCode || "-",
          contactPerson: location?.contactPerson || "-",
          email: Array.isArray(location?.links)
            ? location?.links.find((link) => link?.type === "email")?.value ??
              ""
            : "-",
          phoneNumber,
          locationType: location?.originType || "-",
          address:
            (location?.addressLine1 ? location?.addressLine1 : "") +
            (location?.addressLine2 ? "," + location?.addressLine2 : "") +
            (location?.city ? ", " + location?.city : "") +
            (location?.state ? ", " + location?.state : "") +
            (location?.postalCode ? ", " + location?.postalCode : ""),
          status: (
            <HStack gap="8px" alignItems="center">
              <Text
                fontSize="12px"
                fontWeight="400"
                color="var(--gray-800, #1A202C)"
              >
                Deactivate
              </Text>
              <Switch
                colorScheme="login"
                onChange={() => {
                  setIdToChangeStatus(location?.id);
                  location?.status === "active"
                    ? openDeactivateLocationModal()
                    : openActivateLocationModal();
                }}
                isChecked={location?.status !== "active"}
                size="sm"
              />
            </HStack>
          ),
          actions: (
            <ActionIcon
              w="20px"
              h="20px"
              mx="16px"
              onClick={(e) => {
                handleOpenModal(e);
                setIdToDelete(location.id);
                setIdToEdit(location.id);
              }}
              _hover={{
                cursor: "pointer",
              }}
            />
          ),
        };
      });

  const handleCloseModal = () => {
    setIsOpen(false);
  };
  const handleOpenModal = (e) => {
    e.preventDefault(); // Prevent default link behavior
    e.stopPropagation();
    const iconPosition = e.currentTarget.getBoundingClientRect();

    setSelectedActionIconPosition({
      x: iconPosition.left,
      y: iconPosition.top,
    });
    setIsOpen(true);
  };

  return (
    <>
      <Stack spacing={"24px"}>
        <Box>
          <Text fontWeight={600}>Locations</Text>
        </Box>
        {locationArray?.length ? (
          <Box background={"white"} borderRadius={"12px"}>
            <Table
              overflow="auto"
              overflowY="auto"
              headerList={headers}
              dataList={tableData}
              containerStyle={{ maxHeight: "300px" }}
              stickyColumn={["Actions"]}
            />
          </Box>
        ) : (
          <></>
        )}
        <Button
          size="lg"
          height="48px"
          width="100%"
          borderColor="white"
          bg="white" // Set the background color to white
          onClick={onOpenLocation}
          isDisabled={!customerDetails.country}
        >
          + Add Location
        </Button>
      </Stack>
      <Modal
        isOpen={isOpenLocation}
        onClose={() => {
          onCloseLocation();
          resetNewLocationState();
        }}
        size={"4xl"}
      >
        <ModalOverlay />
        <ModalContent w={"100%"}>
          <ModalHeader>Add Location</ModalHeader>
          <ModalCloseButton />
          <AddLocation
            handleNewLocationInputChange={handleNewLocationInputChange}
            newLocation={newLocation}
            setNewLocation={setNewLocation}
            handleLinksChange={handleLocationLinksChange}
            handleNewLocationSelectChange={handleNewLocationSelectChange}
            states={locations[newLocation?.id]?.states || []}
            cities={locations[newLocation?.id]?.cities || []}
            validateNewLocationPINCode={validateNewLocationPINCode}
            isValidLocationPINCode={isValidLocationPINCode}
            setIsValidLocationPINCode={setIsValidLocationPINCode}
            isValidMobileNumber={isValidMobileNumber}
            isValidLocationEmail={isValidLocationEmail}
            country={customerDetails.country}
            isValidExternalCode={isValidExternalCode}
          />
          <ModalFooter>
            <Button
              colorScheme="modalsLight"
              mr={3}
              onClick={() => {
                onCloseLocation();
                resetNewLocationState();
              }}
              color={"black"}
            >
              Cancel
            </Button>

            <Button
              colorScheme="teal"
              onClick={() => {
                handleAddLocation();
                onCloseLocation();
              }}
              isDisabled={
                newLocation.isEditing
                  ? updateLocationButtonStatus()
                  : addLocationButtonStatus()
              }
            >
              {newLocation.isEditing ? "Save Changes" : "Add Location"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={() => {
            handleCloseModal();
            setIdToDelete(null);
            setIdToEdit(null);
          }}
          sx={styles}
        >
          <ModalContent
            className="action-btn-dropdown"
            top={selectedActionIconPosition.y + -35 + "px"}
            left={selectedActionIconPosition.x + -308 + "px"}
          >
            <Box display="flex" rowGap="20px!important" flexDirection="column">
              <Button
                // display={jobDetails.canEdit ? 'none' : 'flex'}
                className="download-report-btn"
                variant="outline"
                rightIcon={<EditIcon />}
                onClick={() => {
                  let locationToEdit = locationArray.find(
                    (x) => x.id === idToEdit
                  );
                  if (locationToEdit) {
                    setNewLocation({
                      ...locationToEdit,
                      isEditing: true,
                    });
                    onOpenLocation();
                  }
                  handleCloseModal();
                }}
              >
                Edit Location
              </Button>

              <Button
                className="download-report-btn"
                variant="outline"
                rightIcon={<Image mr="4px" src={deleteIcon} />}
                onClick={() => {
                  openDeleteLocationModal();
                  handleCloseModal();
                }}
              >
                Delete Location
              </Button>
            </Box>
          </ModalContent>
        </Modal>
      )}
      <Modal
        isOpen={deactivateLocationIsOpen}
        onClose={() => {
          setIdToChangeStatus(null);
          closeDeactivateLocationModal();
        }}
        onOpen={openDeactivateLocationModal}
        isCentered
      >
        <ModalOverlay />

        <ModalContent maxW="436px">
          <ModalHeader fontWeight={700} fontSize={"18px"}>
            Deactivate Location
          </ModalHeader>
          <ModalCloseButton onClick={() => setIdToDelete(null)} />
          <ModalBody>
            <Text color="#319795" fontSize={"16px"}>
              When you deactivate this location, it will be temporarily
              suspended and inaccessible for any activities.
            </Text>
            <Text color="#319795" fontSize={"16px"} mt={"16px"}>
              Are you sure you want to proceed and deactivate this location?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="modalsLight"
              mr={3}
              onClick={() => {
                setIdToChangeStatus(null);
                closeDeactivateLocationModal();
              }}
              color={"black"}
            >
              Close
            </Button>

            <Button
              colorScheme="red"
              onClick={() => {
                handleLocationStatus("inactive");
                closeDeactivateLocationModal();
              }}
            >
              Deactivate
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={activateLocationIsOpen}
        onClose={() => {
          setIdToChangeStatus(null);
          closeActivateLocationModal();
        }}
        onOpen={openActivateLocationModal}
        isCentered
      >
        <ModalOverlay />

        <ModalContent maxW="436px">
          <ModalHeader fontWeight={700} fontSize={"18px"}>
            Activate Location
          </ModalHeader>
          <ModalCloseButton onClick={() => setIdToDelete(null)} />
          <ModalBody>
            <Text color="#319795" fontSize={"16px"}>
              Activating this location will make it accessible again for various
              activities.
            </Text>
            <Text color="#319795" fontSize={"16px"} mt={"16px"}>
              Are you sure you want to proceed and activate this location?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="modalsLight"
              mr={3}
              onClick={() => {
                setIdToChangeStatus(null);
                closeActivateLocationModal();
              }}
              color={"black"}
            >
              Close
            </Button>

            <Button
              colorScheme="modalsDark"
              onClick={() => {
                handleLocationStatus("active");
                closeActivateLocationModal();
              }}
            >
              Activate
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={deleteLocationIsOpen}
        onClose={() => {
          setIdToDelete(null);
          closeDeleteLocationModal();
        }}
        onOpen={openDeleteLocationModal}
        isCentered
      >
        <ModalOverlay />

        <ModalContent maxW="436px">
          <ModalHeader fontWeight={700} fontSize={"18px"}>
            Confirm Delete
          </ModalHeader>
          <ModalCloseButton onClick={() => setIdToDelete(null)} />
          <ModalBody>
            <Text color="#718096">
              Are you certain you want to proceed with deletion?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="modalsLight"
              mr={3}
              onClick={() => {
                setIdToDelete(null);
                closeDeleteLocationModal();
              }}
              color={"black"}
            >
              Close
            </Button>

            <Button
              colorScheme="modalsDark"
              onClick={() => {
                handleDeleteLocation();
                closeDeleteLocationModal();
              }}
            >
              Yes, Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddCustomerLocation;
