import React, { useEffect, useState } from "react";
import { BasicInfo } from "views/Client/components/addClient/AddClientLocation";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import AddAddress from "views/shared/AddAddress";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../../selectors";
import { actions } from "../../slice";
import { generateIdSync } from "utils";

const AddUserLocation = ({
  registeredAddress,
  setRegisteredAddress,
  userDetails,
  tempAddress,
  setTempAddress,
  setIsChanged,
  isChanged,
  oldUserDetails,
  clientIdFromEdit,
  customerId,
  isEditing,
}) => {
  const toast = useToast();
  const {
    isOpen: isOpenAddress,
    onOpen: onOpenAddress,
    onClose: onCloseAddress,
  } = useDisclosure();

  const {
    isOpen: deleteAddressIsOpen,
    onOpen: openDeleteAddressModal,
    onClose: closeDeleteAddressModal,
  } = useDisclosure();

  const dispatch = useDispatch();
  const locations = useSelector(selectors.selectLocation);
  const [pinCodeIsValid, setPinCodeIsValid] = useState(true);

  const handleOptionChange = (value, customKey) => {
    if (!isChanged) setIsChanged(true);
    handleTempAddress(customKey, value);
  };

  const handleTempAddress = (field, value) => {
    if (field === "country" && !value) {
      setTempAddress({
        ...tempAddress,
        pinCode: "",
        country: "",
        state: "",
        city: "",
      });
      setPinCodeIsValid(true);
    } else if (field === "country" && value === "UAE") {
      setTempAddress({
        ...tempAddress,
        pinCode: "",
        country: "UAE",
        state: "",
        city: "",
      });
      setPinCodeIsValid(true);
    } else {
      if (field === "country") {
        setTempAddress({
          ...tempAddress,
          state: "",
          city: "",
          [field]: value,
        });
      } else if (field === "state") {
        setTempAddress({
          ...tempAddress,
          city: "",
          pinCode: "",
          [field]: value,
        });
      } else if (field === "city") {
        setTempAddress({
          ...tempAddress,
          pinCode: "",
          [field]: value,
        });
      } else {
        setTempAddress({
          ...tempAddress,
          [field]: value,
        });
      }
    }
  };

  const handleAddAddressButton = () => {
    if (!isChanged) setIsChanged(true);

    setRegisteredAddress((prevValues) => {
      return { ...prevValues, ...tempAddress };
    });
    onCloseAddress();
  };

  const isDisabled = () => {
    return (
      Object.entries(tempAddress).some(([key, value]) => {
        if (tempAddress.country === "India")
          return key !== "addressLine2" && value === "";
        else return key !== "addressLine2" && key !== "pinCode" && value === "";
      }) || !pinCodeIsValid
    );
  };

  // delete Address of users
  // console.log("oldUserDetails--->", oldUserDetails);

  const handleDeleteAddress = () => {
    const oldAddressId = oldUserDetails?.addresses[0]?.userAddressId;
    const userId = oldUserDetails?.userId;
    dispatch(
      actions.deleteUserAddress({
        customerId,
        clientId: clientIdFromEdit,
        userId,
        oldAddressId,
        onSuccess: (status) => {
          if (status === true) {
            toast({
              title: "Address deleted successfully",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
            // setIsAddressDeleted(true);
            if (!isChanged) setIsChanged(true);
            setRegisteredAddress({
              id: generateIdSync(),
              addressLine1: "",
              addressLine2: "",
              pinCode: "",
              city: "",
              state: "",
              country: "",
            });
            setTempAddress({
              addressLine1: "",
              addressLine2: "",
              pinCode: "",
              city: "",
              state: "",
              country: "",
            });
            // dispatch(actions.clearClientAddress());
          }
        },
        onFailure: () => {
          toast({
            title: "Error in deleting address",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        },
      })
    );
  };

  useEffect(() => {
    if (
      Object.values(tempAddress).every((value) => {
        return value === "";
      })
    ) {
      setRegisteredAddress((prev) => {
        return {
          ...prev,
          addressLine1: "",
          addressLine2: "",
          pinCode: "",
          city: "",
          state: "",
          country: "",
        };
      });
    }
  }, [tempAddress]);

  useEffect(() => {
    if (tempAddress && tempAddress?.country) {
      dispatch(
        actions.fetchLocationData({
          id: registeredAddress?.id,
          country:
            tempAddress?.country === "UAE"
              ? "United Arab Emirates"
              : tempAddress?.country,
        })
      );
    }
  }, [tempAddress.country]);

  useEffect(() => {
    if (tempAddress && tempAddress?.country) {
      dispatch(
        actions.fetchLocationData({
          id: registeredAddress?.id,
          country:
            tempAddress?.country === "UAE"
              ? "United Arab Emirates"
              : tempAddress?.country,
          state: tempAddress?.state,
        })
      );
    }
  }, [tempAddress.state]);

  useEffect(() => {
    if (tempAddress && tempAddress?.country) {
      dispatch(
        actions.fetchLocationData({
          id: registeredAddress?.id,
          country:
            tempAddress?.country === "UAE"
              ? "United Arab Emirates"
              : tempAddress?.country,
          state: tempAddress?.state,
          city: tempAddress?.city,
        })
      );
    }
  }, [tempAddress?.city]);

  useEffect(() => {
    // console.log("tempAddress?.pinCode.length", tempAddress);
    if (tempAddress?.pinCode?.length === 6) {
      dispatch(
        actions.fetchLocationData({
          id: registeredAddress?.id,
          postalCode: tempAddress?.pinCode,
          onSuccess: () => {
            if (!tempAddress.state && !tempAddress.city)
              dispatch(
                actions.fetchLocationData({
                  id: registeredAddress?.id,
                  country:
                    tempAddress?.country === "UAE"
                      ? "United Arab Emirates"
                      : tempAddress?.country,
                })
              );
          },
        })
      );
    }
  }, [tempAddress.pinCode]);

  useEffect(() => {
    if (
      (locations[registeredAddress?.id]?.states?.length === 1 &&
        locations[registeredAddress?.id]?.cities?.length) === 1
    ) {
      setTempAddress((prev) => {
        return {
          ...prev,
          city: locations[registeredAddress?.id]?.cities[0],
          state: locations[registeredAddress?.id]?.states[0],
        };
      });
    }
    // if (tempAddress?.pinCode) {
    //   setPinCodeIsValid(validatePINCode(tempAddress?.pinCode));
    // }
  }, [locations]);

  const validatePINCode = (pin) => {
    const pattern = /^\d{6}$/;
    // if (locations[registeredAddress?.id]?.postalCodes?.length > 0) {
    //   return (
    //     pattern.test(pin) &&
    //     locations[registeredAddress?.id]?.postalCodes?.includes(pin)
    //   );
    // }
    return pattern.test(pin);
    // && locations[registeredAddress?.id]?.length > 0;
  };
  
  return (
    <>
      <Stack spacing={"24px"}>
        <Box>
          <Text fontWeight={600}>Address</Text>
        </Box>

        {registeredAddress && registeredAddress.addressLine1 !== "" ? (
          <Flex gap={"24px"} direction={{ base: "column", md: "row" }}>
            <Box
              style={{
                background: "white",
                boxShadow: "0px 3.5px 5.499999523162842px rgba(0, 0, 0, 0.02)",
                borderRadius: 15,
                flex: "1",
              }}
            >
              <Box padding={"16px 24px"}>
                <BasicInfo
                  openDeleteModal={openDeleteAddressModal}
                  isEditing={isEditing}
                  onOpenAddress={onOpenAddress}
                  displayText={capitalizeStringUpdated(
                    [
                      registeredAddress?.addressLine1,
                      registeredAddress?.addressLine2?.trim(),
                      registeredAddress?.city,
                      registeredAddress?.state,
                      registeredAddress?.country && registeredAddress?.pinCode
                        ? `${registeredAddress.country} ${registeredAddress.pinCode}`
                        : registeredAddress?.country,
                    ]
                      .filter(Boolean)
                      .join(", ")
                  )}
                  address={oldUserDetails?.addresses}
                  // showDeleteBtn={true}
                />
              </Box>
            </Box>
          </Flex>
        ) : (
          <Button
            size="lg"
            height="48px"
            width="100%"
            borderColor="white"
            bg="white" // Set the background color to white
            onClick={onOpenAddress}
          >
            + Add Address
          </Button>
        )}
      </Stack>

      <Modal
        isOpen={isOpenAddress}
        onClose={onCloseAddress}
        size={"4xl"}
        isCentered
      >
        <ModalOverlay />
        <ModalContent w={"100%"}>
          <ModalHeader>Add Address</ModalHeader>
          <ModalCloseButton />
          <AddAddress
            handleTempAddress={handleTempAddress}
            tempAddress={tempAddress}
            handleOptionChange={handleOptionChange}
            pinCodeIsValid={pinCodeIsValid}
            setPinCodeIsValid={setPinCodeIsValid}
            states={locations[registeredAddress?.id]?.states || []}
            cities={locations[registeredAddress?.id]?.cities || []}
            validatePINCode={validatePINCode}
          />
          <ModalFooter>
            <Button
              colorScheme="modalsLight"
              mr={3}
              onClick={onCloseAddress}
              color={"black"}
            >
              Cancel
            </Button>

            <Button
              colorScheme="teal"
              onClick={handleAddAddressButton}
              isDisabled={isDisabled()}
            >
              Add Address
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={deleteAddressIsOpen}
        onClose={closeDeleteAddressModal}
        isCentered
      >
        <ModalOverlay />

        <ModalContent maxW="436px">
          <ModalHeader fontWeight={700} fontSize={"18px"}>
            Confirm Delete
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text color="#718096">
              Are you certain you want to proceed with deletion?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="modalsLight"
              mr={3}
              onClick={closeDeleteAddressModal}
              color={"black"}
            >
              Close
            </Button>

            <Button
              colorScheme="modalsDark"
              onClick={() => {
                handleDeleteAddress();
                closeDeleteAddressModal();
              }}
            >
              Yes, Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddUserLocation;
