import { Text, VStack } from "@chakra-ui/react";
import React, { useCallback, useEffect } from "react";
import ViewJobTopCard from "../components/viewJob/ViewJobTopCard";
import JobInstructionCard from "../components/viewJob/JobInstructionCard";
import { JobTaskTable } from "../components/viewJob/JobTaskTable";
import { useDispatch, useSelector } from "react-redux";
import { actions, reducer, sliceKey } from "../slice";
import { watchJobsAndManagement } from "../saga";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import * as selectors from "../selectors";
import { useHistory, useParams } from "react-router-dom";
import ViewJobBottomCard from "../components/viewJob/ViewJobBottomCard";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";
import FallbackToast from "errorsFallback/FallbackToast";
const styles = {
  text1: {
    justifyContent: "flex-start",
  },
};

const ViewJob = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: watchJobsAndManagement });

  const dispatch = useDispatch();
  const history = useHistory();

  const { inspectionid, jobid } = useParams();

  const warehouseDetail = useSelector(selectors.selectWarehouseDetail);
  console.log("warehouseDetail", warehouseDetail)
  const assigneeUserDetails = useSelector(selectors.selectUserDetail);
  const assigneeProfileImgUrls = useSelector(
    selectors.selectuserProfileImgUrls
  );
  const taskList = useSelector(selectors.selectTasksData);
  const assignedToIds = taskList?.map((task) => {
    return task.assignedTo.id;
  });
  const jobDetails = useSelector(selectors.selectJob);
  const userDetails = useSelector(selectors.selectUserDetails);
  const loadingUserDetails = useSelector(selectors.selectLoadingUserDetails);
  const workflowData = useSelector(selectors.selectWorkflowData);
  const jobLoadingStatus = useSelector(selectors.jobLoadingStatus);
  const inspectionReportDetails = useSelector(
    selectors.inspectionReportDetails
  );

  const searchParams = new URLSearchParams(location.search);
  const customerId = searchParams.get("customerId");
  const {
    id,
    inspectionId,
    name,
    workflowId,
    originId,
    priority,
    status,
    instructions,
    createdBy,
    updatedBy,
    creationDt,
    updateDt,
    dueDate,
    clientDetails,
    internalCode,
  } = jobDetails;

  const userIds = [createdBy, updatedBy].filter(
    (id) => id !== null || undefined || ""
  );

  const createdByName =
    userDetails &&
    userDetails[0]?.data?.payload.firstName +
      " " +
      userDetails[0]?.data?.payload.lastName;
  const updatedByName =
    userDetails &&
    userDetails[1]?.data?.payload.firstName +
      " " +
      userDetails[1]?.data?.payload.lastName;

  useEffect(() => {
    dispatch(actions.fetchJob({ inspectionId: inspectionid, jobId: jobid }));
    return () => {
      dispatch(actions.clearStates());
    };
  }, []);

  useEffect(() => {
    if (assigneeUserDetails) {
      let profileImgNameArr = assigneeUserDetails.map((userDetail) => {
        return userDetail.avatar;
      });
      if (clientDetails && clientDetails.avatar) {
        profileImgNameArr.push(clientDetails.avatar);
      }
      dispatch(actions.fetchUserProfileImgURL(profileImgNameArr));
    }
  }, [assigneeUserDetails]);

  useEffect(() => {
    if (taskList && Object.keys(taskList).length) {
      dispatch(actions.fetchUserDetail(assignedToIds));
    }
  }, [taskList]);

  useEffect(() => {
    if (Object.keys(jobDetails).length) {
      console.log("------")
      dispatch(actions.fetchUserDetailsRequest(userIds));
      dispatch(actions.fetchWarehouseDetailsById({ warehouseId: originId }));
      dispatch(
        actions.fetchGetTasksRequest({
          inspectionId: inspectionid,
          jobId: jobid,
        })
      );

      dispatch(actions.fetchWorkflowDataRequest({ workflowId }));
      if (status === "approved") {
        dispatch(actions.fetchInspectionReport({ inspectionId: inspectionid }));
      }
    }
  }, [jobDetails]);
  const onClickDownloadReport = () => {
    const data = {
      fileName: inspectionReportDetails[0]?.name,
      warehouseCode: warehouseDetail[originId]?.externalCode,
      auditDate: taskList[0]?.completionDt,
    };
    dispatch(
      actions.downloadReport({
        source: { name: process.env.REACT_APP_SAS_URL_GENERATOR_SOURCE },
        data,
      })
    );
  };

  const handleDeleteJobDispatch = () => {
    dispatch(
      actions.deleteJob({
        inspectionId,
        jobId: jobid,
        onSuccess: () => {
          history.push("/admin/jobs");
        },
      })
    );
  };

  const handleUpdateJobDispatch = (check) => {
    dispatch(
      actions.updateJob({
        inspectionId,
        jobId: jobid,
        status: check === "not-required" ? "draft" : "not-required",
        onSuccess: () => {
          history.push("/admin/jobs");
        },
      })
    );
  };
  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <VStack mt={"75px"} gap={"36px"} w={"100%"}>
        <ViewJobTopCard
          name={name}
          internalCode={internalCode}
          status={status}
          jobDetails={jobDetails}
          createdBy={createdByName}
          updatedBy={updatedByName}
          priority={priority}
          creationDt={creationDt}
          updateDt={updateDt}
          warehouseDetail={warehouseDetail[originId]}
          jobId={jobid}
          inspectionId={inspectionid}
          dueDate={dueDate}
          workflowId={workflowId}
          task={taskList[0]}
          loadingUserDetails={loadingUserDetails}
          originId={originId}
          assigneeProfileImgUrls={assigneeProfileImgUrls}
          clientDetails={clientDetails}
          customerId={customerId}
          onClickViewReport={onClickDownloadReport}
        />
        <ErrorBoundary
          fallback={<FallbackToast message={`Failed to Load instruction`} />}
        >
          <JobInstructionCard instructions={instructions} />
        </ErrorBoundary>
        <ErrorBoundary
          fallback={<FallbackToast message={`Failed to Load Tasks`} />}
        >
          <JobTaskTable
            taskList={taskList}
            assigneeUserDetails={assigneeUserDetails}
            assigneeProfileImgUrls={assigneeProfileImgUrls}
            originId={originId}
          />
        </ErrorBoundary>
        <ErrorBoundary
          fallback={<FallbackToast message={`Failed to Load Job Management`} />}
        >
          {status !== "done" && status !== "approved" && !jobLoadingStatus ? (
            <ViewJobBottomCard
              handleDeleteJobDispatch={handleDeleteJobDispatch}
              handleUpdateJobDispatch={handleUpdateJobDispatch}
              status={status}
            />
          ) : (
            <></>
          )}
        </ErrorBoundary>
      </VStack>
    </ErrorBoundary>
  );
};

export default ViewJob;
