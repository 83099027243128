import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, sliceKey, reducer } from "../Create/slice";
import { WorkflowsSaga } from "./saga";
import { WorkflowManagerSaga } from "../WorkflowManager/saga";
import * as selectors from "./selectors";
import { Table, Button } from "@agnext/reactlib";
import { userStorage } from "utils/helper";
import { generateIdSync, generateId } from "../../../../utils";

import {
  Box,
  HStack,
  PopoverBody,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  ButtonGroup,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon, AddIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import WorkflowAction from "../../Components/WorkflowAction";
import Status from "views/Workflow Builder/Components/Status/Status";
import WorkflowImport from "views/Workflow Builder/Components/WorkflowImport";
import { cloneWorkflowStructure } from "../../../../utils/index";
import { dateWithTime } from "../../../../utils/commonFunctions";
import useToastNotification from "utils/useToastNotification";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";

const headerList = [
  {
    displayText: "Title",
    keyText: "title",
    hasTooltip: true,
  },
  {
    displayText: "Description",
    keyText: "description",
    hasTooltip: true,
  },
  {
    displayText: "Status",
    keyText: "status",
    hasTooltip: false,
  },
  {
    displayText: "Modified Date",
    keyText: "updateDt",
    hasTooltip: false,
  },
  {
    displayText: "Creation Date",
    keyText: "creationDt",
    hasTooltip: false,
  },
  { keyText: "actions", displayText: "Actions" },
];

function WorkflowsComp() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: WorkflowsSaga });

  const initialFocusRef = React.useRef();

  const loggedInUser = userStorage.get()?.id;

  const history = useHistory();

  const { isOpen } = useDisclosure();

  const dispatch = useDispatch();

  const workflows = useSelector(selectors.selectWorkflows) || [];
  const workflow = useSelector(selectors.selectWorkflow);
  const showToast = useToastNotification();

  const handleWorkflowDuplicate = (workflowId, newWorkflowTitle) => {
    dispatch(
      actions.cloneWorkflow({
        workflowId,
        onSuccess: (workflowStructure) => {
          const clonedWorkflow = cloneWorkflowStructure(
            workflowStructure,
            newWorkflowTitle
          );
          const payload = {
            workflowData: {
              workflow: { ...clonedWorkflow.workflow, status: "draft" },
            },
            formData: { forms: clonedWorkflow.form },
            workflowFormData: {
              workflowForms: clonedWorkflow.form.map((form) => ({
                formId: form.id,
                workflowId: clonedWorkflow.workflow.id,
                status: "draft",
                order: form.order,
              })),
            },
          };
          dispatch(
            actions.initCloneWorkflow({
              payload,
              onSuccess: () => {
                dispatch(actions.fetchWorkflows()); //Update the list of workflows.
                const formIds = clonedWorkflow.form.map((form) =>
                  dispatch(
                    actions.copyForms({ id: form.oldId, newId: form.id })
                  )
                );
                showToast({
                  message: "Workflow Copied!",
                  status: "success",
                });
              },
              onError: () => {
                showToast({
                  message: "Something went wrong",
                  status: "error",
                });
              },
            })
          );
        },
      })
    );
  };

  const dataList = [...workflows]
    .sort((a, b) => {
      const dateA = new Date(a.updateDt || a.creationDt);
      const dateB = new Date(b.updateDt || b.creationDt);
      return dateB - dateA;
    })
    .map((workflow) => {
      return {
        ...workflow,
        updateDt: dateWithTime(workflow?.updateDt) || "-",
        creationDt: dateWithTime(workflow?.creationDt) || "-",
        status: <Status status={workflow?.status} />,
        actions: (
          <WorkflowAction
            id={workflow.id}
            handleWorkflowDuplicate={handleWorkflowDuplicate}
            oldWorkflowTitle={`copy of ${workflow.title}`}
            status={workflow.status}
          />
        ),
      };
    });

  const handleCreateWorkflow = () => {
    const workflowId = generateIdSync();
    const formId = generateIdSync();

    let form = {
      id: formId,
      title: "Form",
      order: 1,
      description: "Description",
      createdBy: loggedInUser,
      application: "perfeqt",
      categoryName: "inspection",
      categoryType: "",
      structure: {
        sections: [],
      },
    };
    const workflow = {
      id: workflowId,
      title: "Workflow",
      description: "Description",
      createdBy: loggedInUser,
      application: "perfeqt",
    };
    dispatch(
      actions.initWorkflow({
        workflowData: { workflow: { ...workflow, status: "draft" } },
        formData: { forms: [form] },
        workflowFormData: {
          workflowForms: [
            { formId, workflowId: workflow.id, status: "draft", order: 1 },
          ],
        },
        onSuccess: () => {
          history.push(`/admin/workflows/${workflowId}`);
        },
        onError: () => {
          console.log("init worflow Failure");
        },
      })
    );
    dispatch(actions.init({ form, workflow }));
  };

  const fetchWorkflows = useCallback(() => {
    dispatch(actions.fetchWorkflows()); //Gets the list of workflows.
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.fetchWorkflows());
    return () => {
      dispatch(actions.clearState());
    };
  }, [dispatch]);
  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <>
        {/* <HStack
        w="100%"
        h="60px"
        style={{ border: "1px solid #EFEFEF" }}
      ></HStack> */}
        <HStack w="100%" gap="0px" alignItems="flex-start">
          {/* <Box
          w="240px"
          h="calc(100vh - 60px)"
          style={{ border: "1px solid #EFEFEF" }}
        ></Box> */}
          <Box w="100% " mt="75px" minH="calc(100vh - 75px)">
            <HStack
              w="100%"
              justify="flex-end"
              style={{ marginBottom: "32px" }}
            >
              <Box w="280px" sx={{ position: "relative" }}>
                <Popover
                  initialFocusRef={initialFocusRef}
                  closeOnBlur={true}
                  matchWidth={true}
                  isLazy
                >
                  <PopoverTrigger>
                    <Button
                      size="md"
                      // onClick={handleAddNewForm}
                      colorScheme="login"
                      borderRadius="8px"
                      rightIcon={
                        isOpen ? (
                          <ChevronUpIcon boxSize={5} />
                        ) : (
                          <ChevronDownIcon boxSize={5} />
                        )
                      }
                      label="Add Workflow"
                      w="280px"
                    />
                  </PopoverTrigger>
                  <PopoverContent
                    w="280px"
                    sx={{
                      position: "absolute",
                      top: "48px",
                      borderRadius: "15px",
                      padding: "8px 12px",
                      boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.08)",
                      background: "#fff",
                    }}
                  >
                    <PopoverBody
                      border="0"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      pb={4}
                    >
                      <ButtonGroup size="sm">
                        <VStack align="flex-start" gap="18px">
                          <WorkflowImport fetchWorkflows={fetchWorkflows} />
                          <Button
                            size="sm"
                            borderRadius="8px"
                            colorScheme="login"
                            leftIcon={<AddIcon />}
                            label="New Workflow"
                            onClick={handleCreateWorkflow}
                          />
                        </VStack>
                      </ButtonGroup>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Box>
            </HStack>
            <ErrorBoundary fallback={<FallbackUI minH="70vh" />}>
              <Box
                borderRadius={"15px"}
                backgroundColor={"#fff"}
                padding="16px 24px"
              >
                <Table
                  headerList={headerList}
                  dataList={dataList}
                  stickyColumn={["Actions"]}
                />
              </Box>
            </ErrorBoundary>
          </Box>
        </HStack>
      </>
    </ErrorBoundary>
  );
}

export default function Workflows() {
  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <WorkflowsComp />
    </ErrorBoundary>
  );
}
