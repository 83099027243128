import {
  Box,
  Button,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  VStack,
} from "@chakra-ui/react";
import { styles } from "./DrawerDropdown.modules.js";
import React from "react";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { Separator } from "components/Separator/Separator.js";

function DrawerDropdown({
  placeholder,
  options,
  handleFilterQuery,
  property,
  listKey,
  query,
}) {
  const [inputValue, setInputValue] = useState("" || query);

  const handleSelect = (value) => {
    setInputValue(value);
  };
  const handleInputVal = (value) => {
    setInputValue(value);
  };
  let filteredOptions = options?.filter((option) =>
    option[listKey]?.toLowerCase().includes(inputValue.toLowerCase())
  );
  filteredOptions = filteredOptions?.sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const isInputEmpty = inputValue.trim() === "";
  return (
    <Box sx={styles.dropDownOuterContainer}>
      <InputGroup sx={styles.inputContainer}>
        <Input
          type={"text"}
          value={inputValue}
          placeholder={placeholder}
          onChange={(e) => {
            handleInputVal(e.target.value);
            // handleQuery(e.target.value);
          }}
        ></Input>
        <InputLeftElement>
          <SearchIcon color={"gray"} w="15px" h="15px" />
        </InputLeftElement>
        <InputRightElement>
          {isInputEmpty ? (
            ""
          ) : (
            <IconButton
              bg="inherit"
              _hover="none"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
              icon={<CloseIcon color="gray.400" height="10px" />}
              onClick={() => {
                handleSelect("");
              }}
            ></IconButton>
          )}
        </InputRightElement>
      </InputGroup>
      <VStack height={"100%"} justifyContent={"space-between"}>
        {filteredOptions.length === 0 ? (
          <Box>No Matching {property}</Box>
        ) : (
          <Box sx={styles.dropdownContainer}>
            {filteredOptions.map((option) => (
              <>
                <Box
                  bg={inputValue === option[listKey] ? "#F7FAFC" : ""}
                  key={option}
                  sx={styles.dropdownOption}
                  _hover={{ cursor: "pointer", bg: "#F7FAFC" }}
                  onClick={() => {
                    handleSelect(option[listKey]);
                  }}
                >
                  {option[listKey]}
                </Box>
                <Box height={"1px"} bg={"#E2E8F0"} my={"8px"}></Box>
              </>
            ))}
          </Box>
        )}
        <HStack w="100%">
          <Button
            sx={styles.filterBtn}
            colorScheme="gray"
            w="100%"
            color="#1A202C"
            onClick={() => {
              handleSelect("");
            }}
          >
            Clear
          </Button>
          <Button
            sx={styles.filterBtn}
            colorScheme="login"
            w="100%"
            onClick={() => {
              handleFilterQuery(inputValue, property);
            }}
          >
            Apply
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
}

export default DrawerDropdown;
