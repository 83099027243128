import React, { useState } from "react";
import { Box, HStack, Text, Select, IconButton } from "@chakra-ui/react";
import { LineChart } from "components/Charts/LineChart";
import BarChart from "components/Charts/BarChart";
import { LineChartIcon } from "components/Icons/Icons";
import { v4 as id } from "uuid";

const styles = {
  barChartContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "36px",
    alignSelf: "stretch",
    borderRadius: "15px",
    border: "1px solid #E2E8F0",
    background: " #FFF",
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
  },
  headerContainer: {
    width: "100%",
    justifyContent: "space-between",

    alignItems: "flex-start",
  },
  header: {
    color: "#7E8DA2",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%",
  },
  chartHeaderBtn: {
    padding: "0px 12px",
    justifyContent: "center",
    height: "32px",
    alignItems: "center",
    border: "1px solid #EDF2F7",
    color: "#4A5568",
    fontFamilpy: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    cursor: "pointer",
  },
  barChartSelectOpt: {
    color: "#2D3748",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  barChart: {
    width: "100%",
    height: "270px",
  },
  barChartSelect: {
    borderRadius: "8px",
    width: "182px",
    border: "1px solid #E2E8F0",
    background: "#F7FAFC",
    display: "flex",
    height: "32px",
    padding: " 6px 8px 6px 12px",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    gap: "12px",
    color: "#4A5568",
    fontSize: "12.5px",
  },
};

export default function InspectionCount({
  selectedOption,
  selectedDateType,
  handleDateType,
  disabledDateTypes,
  handleDataTypeChange,
  inspectionCountData,
  selectedDataType,
}) {
  const [isLineChartVisible, setIsLineChartVisible] = useState(false);
  const toggleChart = () => {
    setIsLineChartVisible(!isLineChartVisible);
  };

  const { barChartData, data, labels } = inspectionCountData;

  return (
    <Box
      sx={styles.barChartContainer}
      padding={{ base: "16px", md: "20px" }}
      w="100%"
    >
      <HStack
        sx={styles.headerContainer}
        flexDirection={{ base: "column", md: "row" }}
      >
        <Text sx={styles.header}>{selectedOption}</Text>
        <HStack>
          <IconButton
            bg="#F7FAFC"
            _hover={{ cursor: "pointer" }}
            _active={{}}
            sx={{ height: "32px", borderRadius: "8px" }}
            icon={<LineChartIcon />}
            onClick={toggleChart}
          ></IconButton>
          <HStack gap={"0px"}>
            <Box
              sx={styles.chartHeaderBtn}
              borderBottomLeftRadius="8px"
              borderTopLeftRadius="8px"
              as="button"
              backgroundColor={selectedDateType === "day" ? "#F7FAFC" : ""}
              onClick={() => handleDateType("day")}
            >
              D
            </Box>
            <Box
              sx={{
                ...styles.chartHeaderBtn,
                cursor: disabledDateTypes?.includes("week")
                  ? "not-allowed"
                  : "pointer",
              }}
              as="button"
              backgroundColor={selectedDateType === "week" ? "#F7FAFC" : ""}
              onClick={() => handleDateType("week")}
              disabled={disabledDateTypes?.includes("week")}
            >
              W
            </Box>
            <Box
              sx={{
                ...styles.chartHeaderBtn,
                cursor: disabledDateTypes?.includes("month")
                  ? "not-allowed"
                  : "pointer",
              }}
              as="button"
              borderBottomRightRadius="8px"
              borderTopRightRadius="8px"
              backgroundColor={selectedDateType === "month" ? "#F7FAFC" : ""}
              onClick={() => handleDateType("month")}
              disabled={disabledDateTypes?.includes("month")}
            >
              M
            </Box>
          </HStack>
          <Select
            sx={styles.barChartSelect}
            onChange={handleDataTypeChange}
            value={selectedDataType}
          >
            <option value="inspectionsCount" sx={styles.barChartSelectOpt}>
              Inspection Count
            </option>
            <option value="noOfBags" sx={styles.barChartSelectOpt}>
              Bag Count
            </option>
            <option value="quantity" sx={styles.barChartSelectOpt}>
              Quantity Inspected (MT)
            </option>
          </Select>
        </HStack>
      </HStack>
      <Box sx={styles.barChart}>
        {isLineChartVisible ? (
          <LineChart
            labels={labels}
            data={data}
            key={id()}
            showLine={true}
            tooltipData={barChartData}
          />
        ) : (
          <BarChart labels={labels} barData={data} tooltipData={barChartData} />
        )}
      </Box>
    </Box>
  );
}
