import React, { useState } from "react";
import {
  HStack,
  VStack,
  Text,
  Box,
  useMediaQuery,
  Image,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import {
  ChevronDownIcon as CollapseIcon,
  ChevronRightIcon as ExpandIcon,
} from "@chakra-ui/icons";
import * as selectors from "../selectors";
import { useSelector } from "react-redux";
import Element from "../element";
import Reset from "assets/svg/BiReset.svg";
import { MdArrowForwardIos } from "react-icons/md";

export default function Section({
  section,
  getElements,
  formData,
  handleFormDataChange,
  handleFilesChange,
  reviewData,
  handleResetClick,
  discardButton,
  handleImageDownload,
  displayData,
  setPage,
  navIndex,
  setNavIndex,
  disabledSectionCheck,
  isObjectMatching,
}) {
  //section.id "j83n8aa8e2b2iqdz" is added in the below code to exclude disclaimer from the review page.
  if (
    disabledSectionCheck?.includes(section.id) ||
    section.title.toLowerCase() === "disclaimer"
  ) {
    return;
  }
  const sections = useSelector(selectors.selectSectionsForBlocks);
  const blocks = sections?.find((currSection) => currSection.id === section.id)
    ?.structure?.blocks;
  const [isMobileScreen] = useMediaQuery("(max-width: 872px)");
  const [isSectionHidden, setIsSectionHidden] = useState({});

  const handleToggleSectionHidden = (blockId) => {
    setIsSectionHidden((prev) => ({
      ...prev,
      [blockId]: !prev[blockId],
    }));
  };

  return (
    <VStack w="100%" aria-label="section" gap="24px">
      {blocks?.map((block) => {
        const isHidden = isSectionHidden[block.id];
        return (
          <VStack w="100%" aria-label="section" gap="24px" key={block.id}>
            <HStack
              style={{
                background: "#EDF2F7",
                borderRadius: "8px",
                padding: "8px 16px 8px 8px",
                width: "100%",
              }}
              justify="space-between"
              align="flex-start"
            >
              <HStack justify="center" align="center">
                <IconButton
                  variant="ghost"
                  borderRadius="8px"
                  // isDisabled={index < 1}
                  sx={{
                    ".icon": {
                      color: "#718096",
                    },
                  }}
                  icon={
                    isHidden ? (
                      <MdArrowForwardIos w="26px" h="26px" className="icon" />
                    ) : (
                      <CollapseIcon w="26px" h="26px" className="icon" />
                    )
                  }
                  onClick={() => {
                    handleToggleSectionHidden(block.id);
                  }}
                />
                <HStack align="center">
                  <Text
                    color="#4A5568"
                    fontSize={isMobileScreen ? "16px" : "22px"}
                    fontWeight="600"
                    lineHeight="1.4"
                  >
                    {block.title}
                  </Text>
                  <Text
                    color="#718096"
                    fontSize={isMobileScreen ? "10px" : "12px"}
                    fontWeight="400"
                    lineHeight="1.4"
                  >
                    {block.description}
                  </Text>
                </HStack>
              </HStack>
              {/* <Text color="#4A5568" fontSize="16px" fontWeight="400" lineHeight="1.4">
          0 / 1 (0%)
        </Text> */}
            </HStack>
            {getElements(block.id).map((el) => {
              const isMatching = isObjectMatching(el.element.id);
              const borderColor = isMatching ? "#CBD5E0" : "#F6AD55";
              return (
                <Box
                  aria-label="component"
                  key={el.element.id}
                  sx={isHidden ? { display: "none" } : {}}
                  style={{
                    padding: "20px",
                    width: "100%",
                    border: `1.5px solid ${borderColor}`,
                    borderRadius: "15px",
                    background: "#FFF",
                    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <Flex justifyContent={"space-between"}>
                    <Text
                      aria-label="element label"
                      color="#2D3748"
                      fontSize="16px"
                      fontWeight="500"
                      lineHeight="1.4"
                    >
                      {el.element.label}
                      {el.element.mandatory ? (
                        <span
                          style={{
                            color: "#D93025",
                            paddingLeft: "6px",
                            fontSize: "20px",
                          }}
                        >
                          *
                        </span>
                      ) : (
                        <></>
                      )}
                    </Text>
                    {el.element.htmlType === "textarea" && (
                      <Box style={{ cursor: "pointer" }}>
                        <Image
                          src={Reset}
                          alt="xyz"
                          onClick={() => handleResetClick(el.element.id)}
                        />
                      </Box>
                    )}
                  </Flex>
                  <Element
                    elementType={el.element.htmlType}
                    formData={formData}
                    reviewData={reviewData}
                    handleFormDataChange={handleFormDataChange}
                    data={el}
                    handleFilesChange={handleFilesChange}
                    discardButton={discardButton}
                    handleImageDownload={handleImageDownload}
                    setPage={setPage}
                    displayData={displayData}
                    navIndex={navIndex}
                    setNavIndex={setNavIndex}
                    sectionId={section.id}
                    // disabledSectionCheck={disabledSectionCheck}
                    // formErrorData={formErrorData}
                  />
                  {/* {formErrorData && formErrorData[el.element.id] ? (
              <Text
                aria-label="element label"
                color="#D93025"
                fontSize="12px"
                fontWeight="400"
                lineHeight="1.4"
              >
                {formErrorData[el.element.id]}
              </Text>
            ) : (
              <></>
            )} */}
                  {el?.element?.supportingText ? (
                    <Text
                      aria-label="element label"
                      color="#718096"
                      fontSize="12px"
                      fontWeight="400"
                      lineHeight="1.4"
                    >
                      {el.element.supportingText}
                    </Text>
                  ) : null}

                  {el?.element?.range ? (
                    <Text
                      aria-label="element label"
                      color="#718096"
                      fontSize="12px"
                      fontWeight="400"
                      lineHeight="1.4"
                    >
                      Please Enter between {el?.element?.range?.from} and{" "}
                      {el?.element?.range?.to}
                    </Text>
                  ) : null}
                </Box>
              );
            })}
          </VStack>
        );
      })}
    </VStack>
  );
}
