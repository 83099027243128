import { Box } from "@chakra-ui/react";
import React from "react";
import StatCard from "views/shared/StatCard";

const styles = {
  statsContainer: {
    display: "flex",
    gap: "10px",
    flex: "1 0 0",
    backgroundColor: "white",
    flexWrap: "wrap",
    minWidh: "364px",
    border: "1px solid #E2E8F0",
    boxShadow: "0px 3.499999761581421px 5.5px rgba(0, 0, 0, 0.02)",
  },
};

const Insights = ({
  title,
  amount,
  percentage,
  icon,
  loading,
  error,
  style,
  minWidthValue,
  pVal,
}) => {
  return (
    <Box borderRadius={"15px"} sx={styles.statsContainer} padding={pVal}>
      <StatCard
        title={title}
        amount={amount}
        percentage={percentage}
        icon={icon}
        loading={loading}
        style={style}
        minWidthValue={minWidthValue}
      />
    </Box>
  );
};

export default Insights;
